import React, { useState } from "react";
import { Toast, ToastHeader, ToastBody } from "reactstrap";
function ToastApiStatus({
  showToast,
  setShowToast,
  toastColors,
  setToastColors,
  toastHeaderText,
  setToastHeaderText,
  toastBodyText,
  setToastBodyText
}) {
  return (
    <>
      <div
        style={{
          position: "fixed",
          top: "0px",
          right: "0px",
          zIndex: "9999"
        }}
      >
        <Toast
          isOpen={showToast}
          style={{
            minWidth: "300px"
          }}
        >
          <ToastHeader
            toggle={() => {
              setShowToast(false);
            }}
            style={{
              background: toastColors[0],
              color: toastColors[1]
            }}
          >
            {toastHeaderText}
          </ToastHeader>
          <ToastBody
            style={{
              background: toastColors[0],
              color: toastColors[1]
            }}
          >
            {toastBodyText}
          </ToastBody>
        </Toast>
      </div>
    </>
  );
}

export default ToastApiStatus;

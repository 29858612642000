const white = "#ffffff";
const bsBorderBlue = "#80bdff";
const bsBorderGrey = "#ced4da";
const bsBoxShadowBlue = "0 0 0 0.2rem rgba(0, 123, 255, 0.25);";
const bsBackgroundOption = "#f8f9fa";
const bsBackgroundOptionSelected = "#eeeeee";
const bsControlTextColor = "#555555";
const bsControlPlaceholderColor = "#999999";

export const styles = {
  control: (base, state) => ({
    ...base,
    height: "calc(2.25rem + 2px)",
    minHeight: 34,
    backgroundColor: state.selectProps.isDisabled ? "#e9ecef" : white,
    borderWidth: 1,
    borderStyle: "solid",
    boxShadow: state.selectProps.menuIsOpen ? bsBoxShadowBlue : null,
    color: bsControlTextColor,
    borderColor: state.selectProps.menuIsOpen ? bsBorderBlue : bsBorderGrey,
    ":hover": {
      borderColor: state.selectProps.menuIsOpen ? bsBorderBlue : bsBorderGrey
    }
  }),
  valueContainer: (base, state) => ({
    ...base,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    paddingLeft: 10
  }),
  input: base => ({
    ...base,
    color: bsControlTextColor
  }),
  singleValue: base => ({
    ...base,
    color: bsControlTextColor
  }),
  placeholder: (base, state) => ({
    display: state.selectProps.menuIsOpen ? "none" : "inline",
    color: bsControlPlaceholderColor,
    paddingLeft: 3
  }),
  // indicatorSeparator: base => ({
  //     display: 'none',
  // }),
  // clearIndicator: base => ({
  //     ...base,
  //     borderWidth: 0,
  //     borderLeftWidth: 1,
  //     borderStyle: 'solid',
  //     borderColor: bsBorderGrey,
  //     backgroundColor: bsBackgroundGrey,
  //     height: '100%',
  //     width: 39,
  //     color: bsControlTextColor,
  //     ':hover': {
  //         color: bsControlTextColor,
  //     },
  // }),
  // dropdownIndicator: base => ({
  //     ...base,
  //     borderWidth: 0,
  //     borderLeftWidth: 1,
  //     borderStyle: 'solid',
  //     borderColor: bsBorderGrey,
  //     borderTopRightRadius: 4,
  //     borderBottomRightRadius: 4,
  //     backgroundColor: bsBackgroundGrey,
  //     height: '100%',
  //     width: 39,
  //     color: bsControlTextColor,
  //     ':hover': {
  //         color: bsControlTextColor,
  //     },
  // }),
  option: (base, state) => ({
    ...base,
    color: bsControlTextColor,
    backgroundColor: state.isSelected
      ? bsBackgroundOptionSelected
      : state.isFocused
      ? bsBackgroundOption
      : white
  })
};

import React, { useState, useEffect } from "react";
import buildSearchQuery from "~/util/buildSearchQuery";
import { useDashboardStore } from "~/store";
import { shallow } from "zustand";
import NewItemCard from "~/components/dashboard/NewItemCard";
import { Row, Col, Container } from "reactstrap";
import { NewLoadingIndicator } from "~/util/loadingWrapper";
import { useDebounce } from "~/util/nolodash";

function CardSection(props) {
  const { updateItem, userProfile } = props;
  const [items, setItems] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [cursor, setNextCursor] = React.useState(null);

  const {
    query,

    workOrderStatuses
  } = useDashboardStore(
    state => ({
      query: state.query,
      workOrderStatuses: state.workOrderStatuses
    }),
    shallow
  );
  // Using debounce to prevent multiple calls to the API
  const debounceDefaultTime = 250;

  const debouncedValues = useDebounce(
    { query, workOrderStatuses },
    debounceDefaultTime
  );

  const loadItems = async (loadMore = false) => {
    const selectedKinds = [props.kind];
    const { data } = await binnacleClient.get(
      `/options/?${buildSearchQuery(
        query,
        workOrderStatuses,
        selectedKinds,
        loadMore ? cursor : null
      )}`
    );

    if (loadMore) {
      setItems([...items, ...data.results]);
    } else {
      setItems(data.results);
    }
    setNextCursor(data.next_cursor);
    setLoading(false);
  };

  useEffect(() => {
    loadItems();
  }, [debouncedValues.query, debouncedValues.workOrderStatuses, props.kind]);

  return (
    <>
      <Row>
        <Col xs="12" md="12">
          <div className="dashboard__content mt-3 d-flex flex-wrap cards-grid">
            <NewLoadingIndicator loading={loading} />
            {!loading && _.isEmpty(items) && <em>No results found</em>}
            {/* ROL Items */}

            {items.map((item, index) => (
              <div
                key={`${props.kind}-option-item-${index}`}
                className="d-flex card-container"
              >
                <NewItemCard
                  {...item}
                  userProfile={props.profile}
                  updateItem={updateItem}
                  className={"mb-2 d-flex"}
                />
              </div>
            ))}
          </div>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center py-2">
        {cursor && items.length > 0 ? (
          <button
            onClick={() => {
              loadItems(true);
            }}
            className="btn btn-primary"
          >
            Load More
          </button>
        ) : (
          ""
        )}
        {items.length > 0 && !cursor ? (
          <div className="text-center">No more items to load</div>
        ) : (
          ""
        )}
      </Row>
    </>
  );
}

export default CardSection;

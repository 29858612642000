import React from "react";
import { Table } from "reactstrap";
import Fa from "~/util/fa";
import pluralize from "pluralize";
import { currency, currencyFloat } from "~/util/currency";
import {
  getSubtotalMarginDiscount,
  getItemsTax
} from "~/components/workOrders/calculations";
import { getUnitName } from "~/components/workOrders/util";

const renderUnitColumn = (piece, item) => {
  let units = item[`${piece}_units`];
  let unit_name = item[`${piece}_unit_name`];
  let uom = item[`${piece}_uom`];

  if (uom === "fixed" || uom === "linear") {
    return null;
  } else if (!units) {
    return null;
  }

  return `${_.round(units, 1)} ${getUnitName(unit_name, units)}`;
};

const columnList = [
  { key: "title", label: "Item" },
  { key: item => renderUnitColumn("labor", item), label: "Labor Units" },
  { key: item => renderUnitColumn("materials", item), label: "Material Units" }
];

const ItemRow = ({ item, columns }) => (
  <tr>
    {columns.map(i => {
      let key = columnList[i].key;
      let value;

      if (_.isFunction(key)) {
        value = key(item);
      } else {
        value = item[key];
      }

      return (
        <td key={`item-${item.id}-col-${i}`}>
          {value || <span>&mdash;</span>}
        </td>
      );
    })}
  </tr>
);

const ColumnHeaders = ({ columns }) => {
  if (columns.length <= 1) return null;
  return (
    <>
      {" "}
      {columns.map(i => (
        <th key={`col-header-${i}`}>
          <strong>{columnList[i].label}</strong>
        </th>
      ))}{" "}
    </>
  );
};

export function WorkItemsDisplay({
  work_items: items = [],
  total_price,
  margin,
  discount,
  columns = [0],
  sales_tax,
  showTotal = true,
  showDiscount = false
}) {
  const rowProps = item => ({
    columns,
    key: `item-row-${item.id}`,
    item
  });

  let prices = getSubtotalMarginDiscount(
    total_price,
    margin,
    discount,
    null,
    items,
    sales_tax
  );
  return (
    <div className="mb-4 WorkItemsDisplay">
      <h3>Work Items</h3>
      <Table striped>
        <thead>
          <tr>
            <ColumnHeaders columns={columns} />
          </tr>
        </thead>
        <tbody>
          {items.map(item => (
            <ItemRow {...rowProps(item)} />
          ))}
        </tbody>
      </Table>
      <table>
        <tbody>
          {showDiscount && prices.discount ? (
            <tr style={{ fontSize: "1.2em" }}>
              <td className="text-right" width="100%">
                <strong className="d-inline-block mr-4">Discount:</strong>
              </td>
              <td style={{ whiteSpace: "nowrap" }} id="discount_price_label">
                {currency(prices.discount)} ({discount}%)
              </td>
            </tr>
          ) : null}
          {showTotal && (
            <>
              <tr style={{ fontSize: "1.6em" }}>
                <td className="text-right" width="100%">
                  <strong className="d-inline-block mr-4">Tax:</strong>
                </td>
                <td id="total_tax_label">{currency(sales_tax)}</td>
              </tr>
              <tr style={{ fontSize: "1.6em" }}>
                <td className="text-right" width="100%">
                  <strong className="d-inline-block mr-4">Total:</strong>
                </td>
                <td id="total_price_label">{currency(total_price || 0)}</td>
              </tr>
            </>
          )}
        </tbody>
      </table>
    </div>
  );
}

const ItemDiffRows = ({ item: { equal__, changed__, added__, removed__ } }) => (
  <>
    {equal__ && (
      <tr>
        <td>{equal__.title}</td>
      </tr>
    )}
    {changed__ && (
      <>
        <tr>
          <td style={{ textDecoration: "line-through" }}>
            {changed__[0].title}
          </td>
        </tr>
        <tr>
          <td style={{ background: "#A5FFBE" }}>{changed__[1].title}</td>
        </tr>
      </>
    )}
    {removed__ && (
      <tr>
        <td style={{ background: "#FFA5C0" }}>
          <Fa icon="minus" className="mr-2" />
          {removed__.title}
        </td>
      </tr>
    )}
    {added__ && (
      <tr>
        <td style={{ background: "#A5FFBE" }}>
          <Fa icon="plus" className="mr-2" />
          {added__.title}
        </td>
      </tr>
    )}
  </>
);

const PriceDiff = ({ price, sales_tax }) => {
  const [prevTotal, newTotal] = _.get(price, "changed__", []);
  const [prevTax, newTax] = _.get(sales_tax, "changed__", []);

  return (
    <table className="float-right text-right" style={{ fontSize: "1.6em" }}>
      <tbody>
        <tr style={{ color: "#999" }}>
          <td>
            <strong>Previous Tax</strong>
          </td>
          <td style={{ paddingLeft: "1em", textDecoration: "line-through" }}>
            {currency(prevTax || 0)}
          </td>
        </tr>
        <tr style={{ color: "#999" }}>
          <td>
            <strong>Previous Total</strong>
          </td>
          <td style={{ paddingLeft: "1em", textDecoration: "line-through" }}>
            {currency(prevTotal || 0)}
          </td>
        </tr>
        <tr>
          <td>
            <strong>New Tax</strong>
          </td>
          <td>{currency(newTax || 0)}</td>
        </tr>
        <tr>
          <td>
            <strong>New Total</strong>
          </td>
          <td>{currency(newTotal || 0)}</td>
        </tr>
      </tbody>
    </table>
  );
};

export function WorkItemsDisplayDiff({
  work_items: items = [],
  total_price,
  sales_tax,
  columns = [0],
  showTotal = true
}) {
  const priceChanged = _.has(total_price, "changed__");

  return (
    <div className="mb-4">
      <h3 style={{ marginLeft: "12px" }}>Work Items</h3>
      <Table striped>
        <tbody>
          {items.map((item, index) => (
            <ItemDiffRows {...{ item, key: `item-row-${index}` }} />
          ))}
        </tbody>
      </Table>
      {priceChanged ? (
        <PriceDiff price={total_price} sales_tax={sales_tax} />
      ) : (
        <>
          <div className="text-right" style={{ fontSize: "1.6em" }}>
            <strong className="d-inline-block mr-4">Tax</strong>{" "}
            {currency(_.get(sales_tax, "equal__") || 0)}
            {/* {currency(getItemsTax(items) || 0)} */}
          </div>
          <div className="text-right" style={{ fontSize: "1.6em" }}>
            <strong className="d-inline-block mr-4">Total</strong>{" "}
            {currency(_.get(total_price, "equal__"))}
          </div>
        </>
      )}
    </div>
  );
}

import React from "react";
import WOUpdatedContactInfo from "~/components/workOrders/WOUpdatedContactInfo";
import { ProfileDisplay } from "~/components/workOrders/ProfileDisplay";
import { Row, Col } from "reactstrap";
import { BoatDisplay } from "~/components/workOrders/BoatDisplay";
import { WorkItemsDisplay } from "~/components/workOrders/WorkItemsDisplay";
import { WorkOrderBase } from "~/views/WorkOrder";
import { PrintLayout } from "~/components/print/PrintLayout";
import {
  DataSheetsDisplay,
  ScheduleDisplay
} from "~/components/workOrders/ScheduleDisplay";

export class PrintWorkList extends WorkOrderBase {
  renderWO() {
    document.title = "Pettit Pro Portal | Print Work List";

    const { workOrder, boatPrimaryImage } = this.state;
    const ownerOrRep = this.getOwnerOrRep();
    return (
        <PrintLayout>
          <h2>Work List</h2>
          {workOrder.updated_profile && (
            <WOUpdatedContactInfo updatedProfile={workOrder.updated_profile} />
          )}
          <Row>
            <Col md="6">
              <BoatDisplay boat={workOrder.boat} />

              {ownerOrRep.profile != null && (
                <ProfileDisplay workOrder={workOrder} />
              )}
            </Col>
            {
              boatPrimaryImage ? 
                <Col md="6" data-testid="boat-feature-image">
                  <img 
                    src={ boatPrimaryImage.url } 
                    alt={workOrder.boat.name} 
                    className="rounded mt-4" 
                    style={{ "objectFit": "contain", "objectPosition": "left", "maxHeight": "475px", "maxWidth": "100%" }} />
                </Col>
              : ""
            }
            <hr className="my-5" />
          </Row>
          <WorkItemsDisplay
            work_items={workOrder.work_items}
            {...workOrder}
            columns={[0, 1, 2]}
            showTotal={false}
          />
          <Row>
            <Col>
              <ScheduleDisplay {...workOrder} />
            </Col>
            <Col>
              <DataSheetsDisplay {...workOrder} />
            </Col>
          </Row>
        </PrintLayout>
    );
  }
}
import React from "react";
const QuickBooksIcon = ({ bg, color, size = "30" }) => {
  return (
    <svg
      width={size}
      height={size}
      fill="none"
      viewBox="29 69 120 40"
      version="1.1"
      id="svg185"
    >
      <path
        fill={bg === "default" || !bg ? "#FFFFFF" : bg}
        d="m 90,135.226 c 24.853,0 45,-20.147 45,-45 0,-24.853 -20.147,-45 -45,-45 -24.853,0 -45,20.147 -45,45 0,24.853 20.147,45 45,45 z"
        id="path181"
      />
      <path
        fill={
          color === "default" || !color
            ? window
                .getComputedStyle(document.documentElement)
                .getPropertyValue("--primary")
            : color
        }
        d="m 57.496,90.224 c 0,9.665 7.835,17.5 17.5,17.5 h 2.5 v -6.5 h -2.5 c -6.066,0 -11,-4.934 -11,-11 0,-6.065 4.934,-11 11,-11 h 6.007 v 34 a 6.5,6.5 0 0 0 6.5,6.5 v -47 H 74.996 c -9.665,0 -17.5,7.836 -17.5,17.5 z m 47.51,-17.497 h -2.5 v 6.5 h 2.5 c 6.064,0 11,4.935 11,11 0,6.065 -4.936,11 -11,11 h -6.01 v -34 a 6.5,6.5 0 0 0 -6.5,-6.5 v 47 h 12.51 c 9.664,0 17.5,-7.835 17.5,-17.5 0,-9.665 -7.836,-17.5 -17.5,-17.5 z"
        id="path183"
      />
    </svg>
  );
};

export default QuickBooksIcon;

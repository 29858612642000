import React, { Component } from "react";
import { binnacleClient, isLoggedIn } from ".";
import { binnacleStore } from "~/util/store";

export function withAuth(WrappedComponent) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.logout = this.logout.bind(this);
      this.login = this.login.bind(this);
      this.checkLoggedIn = this.checkLoggedIn.bind(this);
      this.state = {
        loggedIn: false,
        loading: true
      };
    }

    componentDidMount() {
      this.checkLoggedIn();
    }

    checkLoggedIn() {
      let component = this;
      isLoggedIn().then(function(value) {
        component.setState({ loading: false, loggedIn: value });
      });
    }

    logout() {
      let component = this;

      binnacleClient.post("/users/logout/").then(function(response) {
        binnacleStore.clearUserTokenInfo();

        // component.setState({
        //     loggedIn: false
        // });

        document.location = "/";
      });
    }

    login(data) {
      binnacleStore.clearUserTokenInfo();
      binnacleStore.updateUserTokenInfo(data);

      // this.setState({
      //     loggedIn: true
      // });

      // document.location = '/';
    }

    render() {
      return (
        <WrappedComponent
          authLoading={this.state.loading}
          isAuthenticated={this.state.loggedIn}
          logout={this.logout}
          login={this.login}
          {...this.props}
        />
      );
    }
  };
}

import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { PropTypes } from "prop-types";
import withFacility from "~/util/api/withFacility";
import { useFormState, useFormApi } from "informed";
import AddButton from "~/components/AddButton";

import ServicesMenu from "~/components/services/Menu";

const WorkItemsModal = ({ facility, add, openCardCallback }) => {
  const formState = useFormState();
  const formApi = useFormApi();
  const [isOpen, setOpen] = useState(false);
  const facility_id = facility.id;
  const open = () => setOpen(true);
  const close = () => setOpen(false);
  const submit = () => {
    const services = _.get(formState, "values.services", {});
    formApi.validateField("services");
    if (_.isEmpty(services) || !_.isEmpty(formState.errors.services)) return;
    binnacleClient
      .post("work-orders/work-items/", { facility_id, services })
      .then(({ data: newWorkItems = [] }) => {
        add(newWorkItems);
        close();
      });
      openCardCallback();
  };

  return (
    <>
      <AddButton onClick={open} />
      <Modal isOpen={isOpen} size="lg" data-testid="modal-work-items">
        <ModalHeader>Add Work Items</ModalHeader>
        <ModalBody>
          <ServicesMenu
            field="services"
            menuData={facility.menu_items}
            showCustomerHidden={true}
          />
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-secondary" data-testid="btn-modal-cancel" onClick={close}>
            Cancel
          </button>
          <button className="btn btn-primary" data-testid="btn-modal-add" onClick={submit}>
            Add Items
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

WorkItemsModal.propTypes = {
  callback: PropTypes.func,
};

export default withFacility(WorkItemsModal);

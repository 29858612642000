import React from "react";
import { Link } from "react-router-dom";
// import KindIcon from "~/components/dashboard/KindIcon";
import { valueOrDash } from "~/util/util";
import BoatRoundedImage from "~/components/boat/BoatRoundedImage";
import classnames from "classnames";
export const BoatDisplay = ({ boat, cardStyle, boatPrimaryImage }) => {
  if (_.isEmpty(boat)) return null;

  const boatCardClasses = classnames(
    "mt-4",
    "mb-3",
    "card",
    cardStyle === "primary" ? "card-light-primary" : "card-light-neutral"
  );

  const iconCircleClasses = classnames(
    "d-flex",
    "align-items-center",
    "justify-content-center",
    "m-1",
    cardStyle === "primary" ? "bg-primary" : "bg-secondary"
  );

  return (
    <div className={boatCardClasses} data-testid="card-boat">
      <div className="d-flex flex-row align-items-center p-2">
        <BoatRoundedImage enableModal={true} images={boat?.images} />
        <Link to={`/boat/${boat?.id}`}>
          <h4 className="pt-2 pl-2 boat-name">{boat.name}</h4>
        </Link>
      </div>
      <table className="table table-striped">
        <tbody>
          <tr>
            <td>{boat.make}</td>
            <td className="label" style={{ width: "180px" }}>
              Length
            </td>
            <td>{valueOrDash(boat.specs.length)}</td>
          </tr>
          <tr>
            <td>{boat.model}</td>
            <td className="label" style={{ width: "180px" }}>
              Beam
            </td>
            <td>{valueOrDash(boat.specs.beam)}</td>
          </tr>
          <tr>
            <td>{boat.year}</td>
            <td className="label" style={{ width: "180px" }}>
              Draft
            </td>
            <td>{valueOrDash(boat.specs.draft)}</td>
          </tr>
          <tr>
            <td className="block hidden"></td>
            <td className="label">Surface Area (sq. ft)</td>
            <td>{boat.specs.surface_area}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

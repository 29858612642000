import React from "react";

function flatten_errors(errors) {
  let all_errors = {};

  for (let key in errors) {
    if (_.isArray(errors[key])) {
      if (_.isString(errors[key][0])) {
        all_errors[key] = errors[key];
      } else {
        for (let index in errors[key]) {
          if (_.isArray(errors[key][index])) {
            all_errors[key + `[${index}]`] = errors[key][index];
          } else {
            let sub_errors = flatten_errors(errors[key][index]);
            for (let key2 in sub_errors) {
              all_errors[key + `[${index}].` + key2] = sub_errors[key2];
            }
          }
        }
      }
    } else if (_.isString(errors[key])) {
      all_errors[key] = errors[key];
    } else {
      let sub_errors = flatten_errors(errors[key]);
      for (let key2 in sub_errors) {
        all_errors[key + "." + key2] = sub_errors[key2];
      }
    }
  }

  return all_errors;
}

export function addFormErrors(errors, formApi, nonFieldErrorsField = null) {
  let all_errors = flatten_errors(errors);
  for (let key in all_errors) {
    let fieldKey = key;

    if (fieldKey === "non_field_errors" && nonFieldErrorsField !== null) {
      fieldKey = nonFieldErrorsField;
    }

    if (formApi.fieldExists(fieldKey)) {
      formApi.setError(fieldKey, all_errors[key]);
    } else if (nonFieldErrorsField !== null) {
      formApi.setError(nonFieldErrorsField, all_errors[key]);
    }
  }
}

import React, { Component } from "react";
import { binnacleClient } from ".";
import { addFormErrors } from "~/util/autoform/util";

export function withAPIForm(WrappedComponent) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.getFormApi = this.getFormApi.bind(this);
      this.onFormSubmit = this.onFormSubmit.bind(this);
      this.bindFormSubmit = this.bindFormSubmit.bind(this);

      this.state = {
        sendingRequest: false,
        formApi: null
      };
    }

    getFormApi(formApi) {
      this.setState({
        formApi: formApi
      });
    }

    onFormSubmit(onSuccess, url, config, data) {
      if (this.state.sendingRequest) {
        return;
      }

      this.setState({ sendingRequest: true });

      let configDefaults = {
        nonFieldErrorsField: null,
        onError: null,
        transformApiData: null,
        method: "post",
        extraData: {}
      };

      config = _.defaults(config, configDefaults);

      if (config.transformApiData) {
        data = config.transformApiData(data);
      }

      if (config.extraData) {
        data = _.assign(data, config.extraData);
      }

      let component = this;

      let requestConfig = {
        url: url,
        data: data,
        method: config.method
      };

      binnacleClient(requestConfig)
        .then(response => {
          component.setState({ sendingRequest: false });
          onSuccess(response.data);
        })
        .catch(function(error) {
          component.setState({ sendingRequest: false });

          if (error.response === undefined) {
            console.log(error);
          } else if (
            _.includes([400, 401], error.response.status) &&
            component.state.formApi !== null
          ) {
            addFormErrors(
              error.response.data,
              component.state.formApi,
              config.nonFieldErrorsField
            );
          }

          if (config.onError != null) {
            config.onError(error);
          }
        });
    }

    bindFormSubmit(onSuccess, url, config = {}) {
      return this.onFormSubmit.bind(this, onSuccess, url, config);
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          sendingRequest={this.state.sendingRequest}
          getFormApi={this.getFormApi}
          bindFormSubmit={this.bindFormSubmit}
          onFormSubmit={this.onFormSubmit}
          formApi={this.state.formApi}
        />
      );
    }
  };
}

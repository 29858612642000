import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card } from "reactstrap";
import Fa from "~/util/fa";
import BoatComments from "./Comments";
import ImageUploadCarousel from "./ImageUploadCarousel";
import BoatRoundedImage from "~/components/boat/BoatRoundedImage";

import { boatsClient } from "~/util/api";
export default ({ boat, boat: { specs } }) => {
  const [images, setImages] = useState([]);
  const [carouselImage, setCarouselImage] = useState();
  const [imagesLoading, SetImagesLoading] = useState(true);
  const [imageIndex, setImageIndex] = useState(0);
  // const [extras, setExtras] = useState();
  const refreshImages = async () => {
    return boatsClient.get(`/boats/${boat.id}/`).then(items => {
      if (items?.data?.images) {
        setImages(items.data.images);
      }
      // find primary aka default image
      const primaryImage = items?.data?.images?.find(image => image.is_primary);
      // get index of primary image
      const primaryImageIndex = items?.data?.images?.indexOf(primaryImage);
      setImageIndex(primaryImageIndex);
      setCarouselImage(primaryImage);
      // set loading to false only on first load
      if (imagesLoading) {
        SetImagesLoading(false);
      }
    });
  };
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      refreshImages();
    }

    return () => (mounted = false);
  }, []);
  return (
    <Row>
      <Col sm="6">
        <h4>Boat Details</h4>
      </Col>
      <Col sm="6" className="text-right">
        <Link to={`/boat/${boat.id}/edit`} className="btn btn-primary">
          <Fa icon="edit" className="mr-2" />
          Edit
        </Link>
      </Col>
      <Col xs="12">
        <div className="d-flex align-items-center">
          <BoatRoundedImage size={60} moveRight="6px" images={images} />
          <h2 className="m-0">{boat.name}</h2>
        </div>
      </Col>
      <Col xs="12" md="6">
        <Row>
          <Col xs="12" style={{ marginTop: "2.8rem" }}>
            <h4 className="m-0">Overview</h4>
            <div className=" d-flex align-items-center w-100"></div>
            <hr
              className="w-100 my-3"
              style={{ borderTop: "1px solid color(--secondary)" }}
            />
          </Col>
          <Col lg="6">
            <table
              className="w-100"
              style={{ borderCollapse: "inherit", borderSpacing: "0 10px" }}
            >
              <tbody>
                <tr>
                  <th
                    className="label"
                    style={{ width: "160px", verticalAlign: "top" }}
                  >
                    Make
                  </th>
                  <td>{boat.make}</td>
                </tr>
                <tr>
                  <th
                    className="label"
                    style={{ width: "160px", verticalAlign: "top" }}
                  >
                    Model
                  </th>
                  <td>{boat.model}</td>
                </tr>
                <tr>
                  <th
                    className="label"
                    style={{ width: "160px", verticalAlign: "top" }}
                  >
                    Year
                  </th>
                  <td>{boat.year}</td>
                </tr>
              </tbody>
            </table>
          </Col>
          <Col lg="6">
            <table
              className="w-100"
              style={{ borderCollapse: "inherit", borderSpacing: "0 10px" }}
            >
              <tbody>
                <tr>
                  <th
                    className="label"
                    style={{ width: "160px", verticalAlign: "top" }}
                  >
                    Length (ft)
                  </th>
                  <td>{specs.length}</td>
                </tr>
                <tr>
                  <th
                    className="label"
                    style={{ width: "160px", verticalAlign: "top" }}
                  >
                    Beam (ft)
                  </th>
                  <td>{specs.beam}</td>
                </tr>
                <tr>
                  <th
                    className="label"
                    style={{ width: "160px", verticalAlign: "top" }}
                  >
                    Draft (ft)
                  </th>
                  <td>{specs.draft}</td>
                </tr>
                <tr>
                  <th
                    className="label"
                    style={{ width: "160px", verticalAlign: "top" }}
                  >
                    Surface Area (sq. ft)
                  </th>
                  <td>
                    <div className="d-flex align-items-end mb-3">
                      {specs.surface_area}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
      </Col>
      <Col xs="12" md="6">
        <Row>
          <Col sm="12">
            <ImageUploadCarousel
              boat={boat}
              images={images}
              refreshImages={refreshImages}
              carouselImage={carouselImage}
              setCarouselImage={setCarouselImage}
              imagesLoading={imagesLoading}
              imageIndex={imageIndex}
              setImageIndex={setImageIndex}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

import { transform } from "lodash";
import React, { useState, useEffect } from "react";
import { Card, Collapse, Button } from "reactstrap";

const CollapsibleCard = ({ header, body, isOpen, forceOpen, onForcedOpen, ...props }) => {
  const [isOpenInternal, setIsOpen] = useState(isOpen);

  const toggleIsOpen = e => {
    e.stopPropagation();
    setIsOpen(!isOpenInternal);
  };

  // use separate prop to enforce one-way state flow as much as possible
  useEffect(() => {
    if (forceOpen && !isOpenInternal) {
      setIsOpen(true);
      onForcedOpen();  // callback prop to reset value in parent
    }
  }, [forceOpen]);

  const rotateStyle = {
    transform: `rotate(180deg)`,
    transition: `transform ease-in-out .3s`
  };

  return (
    <Card className="shadow py-3 pl-4 pr-4 my-4" {...props}>
      <div
        className="collapsible-card-header d-flex w-100"
      >
        {header}
        <Button color="link" className="ml-auto" onClick={toggleIsOpen}>
          <i
            className="text-dark fa fa-chevron-down"
            style={isOpenInternal ? rotateStyle : {}}
          ></i>
        </Button>
      </div>
      <Collapse isOpen={isOpenInternal}>{body}</Collapse>
    </Card>
  );
};

export default CollapsibleCard;

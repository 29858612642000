import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, Alert } from "reactstrap";
import { Form, Scope, Text } from "informed";
import DevPre from "~/util/DevPre";
import { withAPIForm } from "~/util/api/withAPIForm";
import LoadingButton from "~/util/loadingButton";
import {
  BootstrapText,
  BootstrapCheckbox,
  BootstrapRadio
} from "~/util/autoform/bootstrap";
import { getValidators } from "~/util/autoform/validators";
import { binnacleClient } from "~/util/api";
import { LoadingIndicator } from "~/util/loadingWrapper";
import { FormStatePre } from "~/util/DevPre";
import { withProfile } from "~/util/api/withProfile";
import NavBar from "~/components/navbar";

const bgImage = require("~/images/bg_boatyard_img.jpg");

const validators_required = getValidators(["required"]);
const profileTypes = [
  "customer",
  "service_writer",
  "service_worker",
  "facility_manager",
  "admin"
];
// const showProfileTypes = _.without(profileTypes, "admin");

export default
@withProfile
@withAPIForm
class EditProfile extends Component {
  constructor(props) {
    super(props);
    this.transformApiData = this.transformApiData.bind(this);
    this.validate = this.validate.bind(this);
    this.state = {
      loading: true,
      profile: {},
      isCreating: false,
      isEditingSelf: false
    };
  }

  getId() {
    // get profile id from route
    return _.get(this.props, "match.params.id");
  }

  // profile gets inserted into props from @withProfile
  setProfile(profile, isEditingSelf = false) {
    if (profile && _.isArray(profile.profile_types)) {
      profile.profile_types = _.mapValues(
        _.keyBy(profile.profile_types),
        // currently re-keying profile types from simple array list to, e.g.
        // [{ customer: true }]
        () => true
      );
    }
    this.setState({ profile, isEditingSelf, loading: false });
  }

  componentDidMount() {
    const id = this.getId();
    const isCreating = _.includes(this.props.match.url, "create");
    const isEditingSelf = !id && _.includes(this.props.match.url, "edit");

    if (isCreating) {
      // Creating new profile
      this.setState({
        profile: { profile_types: { customer: true } },
        isCreating,
        loading: false
      });
    } else if (isEditingSelf) {
      this.setState({ loading: true, isEditingSelf });
      binnacleClient
        .get(`/profiles/my_profile/`)
        .then(({ data }) => this.setProfile(data, true));
    } else if (id) {
      this.setState({ loading: true });
      binnacleClient
        .get(`/profiles/${this.getId()}/`)
        .then(({ data }) => this.setProfile(data));
    }
  }

  // hide quickbooks fields if profile type is service writer, service worker, facility manager, or admin
  hideQuickbooksFields() {
    return this?.props?.formApi?.emitter?.state?.values?.profile_types
      ?.customer;
  }

  transformApiData(formData) {
    console.log("formData", formData);
    let apiData = _.cloneDeep(formData);
    if (this.profileCanEditTypes() && apiData.profile_types) {
      apiData.profile_types = _.keys(_.pickBy(apiData.profile_types));
    }
    if (!this.state.isCreating) {
      apiData.profile_id = this.getId();
    }
    // Fix formData being removed when empty string for qb_customer_name field and qb_customer_id field
    if (!apiData.qb_customer_name) {
      apiData.qb_customer_name = " ";
    }
    if (!apiData.qb_customer_id) {
      apiData.qb_customer_id = " ";
    }
    return apiData;
  }

  profileCanEditTypes() {
    // profileIsType defined in withProfile
    // checks logged in users profile against argument list
    const { profileIsType } = this.props;
    return profileIsType(["facility_manager", "admin"]);
  }

  validate(values) {
    if (this.profileCanEditTypes()) {
      return _.isEmpty(_.keys(_.pickBy(values.profile_types)))
        ? "You must select at least one Account Type"
        : undefined;
    }
    // if qb related fields are shown, validate them
    if (!this.hideQuickbooksFields()) {
      const { qb_customer_name, qb_customer_id } = values;
      if (!qb_customer_name || !qb_customer_id) {
        return "You must fill out both Quickbooks fields";
      }
    }
  }

  renderForm() {
    const { profile } = this.state;
    const isCreating = _.includes(this.props.match.url, "create");

    const apiURL = profile.id ? `/profiles/${profile.id}/` : "/profiles/";
    const method = profile.id ? "patch" : "post";
    const afterUrl = isCreating
      ? "/"
      : `/profile/${profile.id ? profile.id : ""}`; // TODO -- message on dashboard

    const formProps = {
      // bindFormSubmit passed into props from withAPIForm
      onSubmit: this.props.bindFormSubmit(
        () => this.props.history.push(afterUrl),
        apiURL,
        { method, transformApiData: this.transformApiData }
      ),
      getApi: this.props.getFormApi,
      initialValues: profile,
      validate: this.validate
    };
    const baseProps = { required: true, validate: validators_required };

    return (
      <Form {...formProps}>
        {({ formState: { error } }) => (
          <>
            <Row>
              <Col sm={12}>
                <BootstrapText
                  data-testid="input-name"
                  field="name"
                  label="Name"
                  {...baseProps}
                />
              </Col>
              <Col sm={6}>
                <BootstrapText
                  data-testid="input-email"
                  field="email"
                  label="Email"
                  {...baseProps}
                />
              </Col>
              <Col sm={6}>
                <BootstrapText
                  data-testid="input-phone"
                  field="phone"
                  label="Phone"
                  {...baseProps}
                />
              </Col>
              {/* Hide Quickbooks fields when below condition is met */}
              {/* Use className to hide to prevent weird state issue where form field values disappear after being hidden */}
              {!this.hideQuickbooksFields() && (
                <>
                  <div className={"d-flex w-100"}>
                    <Col sm={6}>
                      <BootstrapText
                        require={false}
                        data-testid="input-qb-id"
                        field="qb_customer_id"
                        label="QuickBooks ID"
                      />
                    </Col>
                    <Col sm={6}>
                      <BootstrapText
                        require={false}
                        data-testid="input-qb-name"
                        field="qb_customer_name"
                        label="QuickBooks Customer Name"
                      />
                    </Col>
                  </div>
                </>
              )}
            </Row>
            {this.profileCanEditTypes() && (
              <Scope scope="profile_types">
                <h4>Account Types:</h4>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap"
                  }}
                >
                  {_.map(profileTypes, type => (
                    // Replace below with a radio
                    // <BootstrapCheckbox
                    //   key={`checkbox-${type}`}
                    //   field={type}
                    //   data-testid={`checkbox-${type}`}
                    //   label={_.startCase(type)}
                    //   // type="checkbox"
                    //   showLabel={true}
                    // />
                    <BootstrapRadio
                      key={`radio-${type}`}
                      field={type}
                      data-testid={`radio-${type}`}
                      label={_.startCase(type)}
                      FormGroupClassName="d-flex align-items-center"
                      labelClassName="mr-2"
                      // type="checkbox"
                      showLabel={true}
                      checked={
                        this.props?.formApi?.getValue(
                          `profile_types.${type}`
                        ) || false
                      }
                      onChange={e => {
                        console.log("e.target.checked", e.target.name);
                        // Reset all profile_types to false
                        _.each(profileTypes, type => {
                          this.props?.formApi?.setValue(
                            `profile_types.${type}`,
                            false
                          );
                        });
                        // Set the selected profile_type to true
                        this.props.formApi.setValue(
                          `profile_types.${type}`,
                          e.target.checked
                        );
                      }}
                    />
                  ))}
                </div>
              </Scope>
            )}
            {error && <Alert color="danger">{error}</Alert>}
            <div
              className="mt-3"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Link to="/" className="btn btn-outline-secondary mr-3">
                Cancel
              </Link>
              <LoadingButton
                loading={this.props.sendingRequest}
                className="btn-primary"
              >
                Save
              </LoadingButton>
            </div>
            <FormStatePre />
          </>
        )}
      </Form>
    );
  }

  render() {
    const { profile, loading, isEditingSelf } = this.state;
    const isCreating = _.includes(this.props.match.url, "create");
    if (loading) return <LoadingIndicator />;
    const headerText = `${isCreating ? "Create" : "Edit"} ${
      isEditingSelf ? "your" : ""
    } profile`;

    return (
      <>
        <NavBar details={true} className="bg-white" />
        <Container>
          <Row>
            <Col xs="12" sm={{ size: 8, offset: 2 }}>
              <Card className="p-5 my-3">
                <h1>{headerText}</h1>
                {this.renderForm()}
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="bg-image-full bg-img-light-blur"
          style={{ backgroundImage: `url('${bgImage}')` }}
        ></div>
      </>
    );
  }
}

import React from "react";
import "./BoatImage.scss";
import sampleBoatIMG from "~/images/no-image.svg";

const BoatImage = ({ boatName, getPrimaryImage }) => {
  return (
    <div
      className="justify-content-between d-flex w-100"
    >
      {/* I need an image with  */}
      <div className="d-flex flex-wrap boat__container">
        <div className="d-flex justify-content-center w-100">
          <div className="img__boat_wrapper">
            <img
              data-testid="search-card-boat-img"
              id="boatImageSelect"
              className="img__boat"
              style={{
                objectFit: "cover"
              }}
              src={getPrimaryImage() || sampleBoatIMG}
              alt=""
            />

            <div
              className="img__boat_description_layer d-flex justify-content-between"
              role={"div"}
            >
              <h5
                data-testid="boat-name"
                className="position-absolute px-2 py-1"
                style={{
                  bottom: "0",
                  color: "#FFF",
                  textShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                  fontFamily: "Inter",
                  fontStyle: "italic",
                  fontWeight: 700,
                  fontSize: "20px",
                  lineHeight: "18px"
                }}
              >
                {boatName}
              </h5>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoatImage;

export const phaseList = [
  {
    id: "haul_inspect",
    label: "Haul",
    color: "#D19F3D"
  },
  {
    id: "prepare",
    label: "Prepare",
    color: "#D1723D"
  },
  {
    id: "paint",
    label: "Paint",
    color: "#4398E7"
  },
  {
    id: "detail",
    label: "Detail",
    color: "#7DB419"
  },
  {
    id: "deliver",
    label: "Deliver",
    color: "#7743E7"
  },
  {
    id: "complete",
    label: "Complete"
  }
];
export default _.keyBy(phaseList, "id");

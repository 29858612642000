import React from "react";
import { Card, CardBody } from "reactstrap";

import { binnacleClient } from "~/util/api";
import ImportHome from "./ImportHome";
import ImportUploading from "./ImportUploading";
import ImportReview from "./ImportReview";
import ImportComplete from "./ImportComplete";

export class BoatyardImport extends React.Component {
  state = {
    currentView: "home",
    file: undefined,
    data: undefined,
    error: undefined,
    importing: false
  };

  handleUpload = file => {
    this.setState({ file }, () => {
      this.handleUpdateServer(false)();
    });
  };

  // Double function call allows "confirm" arg to be set first, then call the returned function
  handleUpdateServer = (confirm = false) => async () => {
    if (confirm) this.setState({ importing: true });
    const { file } = this.state;
    if (!file) return new Error("No file data could be found.");

    const url = "/profiles/dockmaster-upload/";
    const formData = new FormData();
    formData.append("dockmaster.csv", file, "dockmaster.csv");
    formData.append("confirm", confirm ? "confirm" : "");

    await binnacleClient
      .post(url, formData, {
        headers: {
          "Content-Type": `multipart/form-data`
        }
      })
      .then(response => {
        this.setState({
          currentView: confirm ? "complete" : "review",
          data: response.data,
          importing: false
        });
      })
      .catch(error => {
        console.error({ error });
        this.setState({
          currentView: "home",
          data: undefined,
          error,
          importing: false
        });
      });
  };

  onGoBackHome = () => {
    this.setState({
      data: undefined,
      file: undefined,
      error: undefined,
      currentView: "home"
    });
  };

  render() {
    const { error, currentView, data, importing } = this.state;

    return (
      <Card className="p-5 my-3" id="BoatyardImport">
        <CardBody>
          {error && <div>{error}</div>}
          {currentView === "home" && (
            <ImportHome onUpload={this.handleUpload} />
          )}
          {currentView === "uploading" && (
            <ImportUploading
              // TODO: add progress from API here? - Probably a wedding cake option
              progress={0}
            />
          )}
          {currentView === "review" && (
            <ImportReview
              data={data || {}}
              onBack={this.onGoBackHome}
              onComplete={this.handleUpdateServer(true)}
              loading={importing}
            />
          )}
          {currentView === "complete" && (
            <ImportComplete onBack={this.onGoBackHome} />
          )}
        </CardBody>
      </Card>
    );
  }
}

export default BoatyardImport;

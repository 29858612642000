import React from "react";
import Fa from "~/util/fa";
import LinkDuo from "~/util/linkDuo";
import BoatImage from "~/components/dashboard/cards/BoatImage";
import moment from "moment";
import StatusPill from "~/components/StatusPill";
import { w } from "react-select/dist/index-fa0d9f94.esm";
import IconList from "~/components/dashboard/cards/IconList";
const WorkOrderCard = ({
  id,
  invoice_number,
  status,
  status_date,
  userProfile,
  link,
  kind,
  ...workOrder
}) => {
  const getPrimaryImage = () => {
    if (workOrder?.boat?.images?.length > 0) {

      // return the is_primary image
      return workOrder?.boat?.images?.find((image) => image.is_primary)?.url;
    }
    return null;
  };

  return (
    <div 
      className="justify-content-between d-flex w-100 flex-column inner-custom-card ">
      {/* First Row */}
      <div className="d-flex align-items-center justify-content-between p-2 ">
        <div>
          <h6
            // data-testid="rol-card-boat-name"
            className="title truncate-100 text-uppercase"
            style={{
              width: "92px",
              height: "18px",
              left: "0px",
              top: "0px",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: "12px",
              lineHeight: "18px",
              letterSpacing: "0.08em",
              color: "#878F9E"
            }}
          >
            Work Order
          </h6>
          <h6 className="truncate-150">
            <LinkDuo
              onClick={() => {
                // GTM-event
                window.dataLayer.push({
                  event: "searchCardClick",
                  eventProps: {
                    category: "search_actions",
                    action: "search_card_click",
                    // label will be set in GTM as page hostname
                    value: link,
                    cardType: kind,
                    userId: userProfile?.id,
                    userName: userProfile?.name,
                    facilityId: userProfile?.facility_id
                  }
                });
              }}
              to={link || "/400"}
              data-testid="search-card-invoice-link"
            >
              <span data-testid="wo-invoice">{invoice_number ||id}</span>
            </LinkDuo>
          </h6>
        </div>
        <div className="d-flex ">
          <div className="d-flex flex-column justify-content-end align-items-center">
            <h6>
              <StatusPill
                status={status}
                statusDate={status === "approved" ? status_date : null}
              />
            </h6>
            <div data-testid="wo-updated-date">{moment(status_date).format("MM/DD/YYYY")}</div>
          </div>
          <div>
            <LinkDuo
              onClick={() => {
                // GTM-event
                window.dataLayer.push({
                  event: "searchCardClick",
                  eventProps: {
                    category: "search_actions",
                    action: "search_card_click",
                    // label will be set in GTM as page hostname
                    value: link,
                    cardType: kind,
                    userId: userProfile?.id,
                    userName: userProfile?.name,
                    facilityId: userProfile?.facility_id
                  }
                });
              }}
              to={link || "/400"}
              data-testid={`rol-card-${kind}`}
              style={{
                width: "27px",
                height: "29px",
                border: "1px solid #D3DAE0",
                borderRadius: "4px"
              }}
              className="rounded border d-flex justify-content-center align-items-center ml-1"
            >
              <Fa className="text-primary" icon="angle-right" />
            </LinkDuo>
          </div>
        </div>
      </div>
      {/* image */}
      {/* <div className="bg-dark text-light">img here</div> */}
      <BoatImage
        boatName={workOrder?.boat?.name}
        getPrimaryImage={getPrimaryImage}
      />
      {/* details row */}
      <div className="d-flex justify-content-between p-2 ">
        <div>
          <h6
            // data-testid="rol-card-boat-name"
            className="title truncate-150 text-uppercase"
          >
            {workOrder?.boat?.make}
          </h6>
          <div data-testid="wo-boat-address" className="">
            {" "}
            {workOrder?.boat?.address || "36 Pine Street, Rockaway NJ 07866"}
          </div>
          <div data-testid="wo-boat-year">{workOrder?.boat?.year}</div>
        </div>
        <div className="d-flex">
          <div>
            <div>
              {" "}
              <IconList
                qbInvoiced={workOrder?.qb_invoiced}
                parent={workOrder?.parent}
                hasUnreadNote={workOrder?.hasUnreadNote}
              />
            </div>
          </div>
        </div>
      </div>
      <hr className="mt-1 mb-1 w-100" />

      {/* contact row */}
      <div className="d-flex flex-wrap justify-content-between p-2 ">
        <div className="d-flex w-100 flex-wrap">
          <h6 data-testid="wo-owner-name" className="truncate w-50">{workOrder?.owner_profile?.name}</h6>
          <div className="truncate w-50">
            <a data-testid="wo-owner-phone" href={`tel:${workOrder?.owner_profile?.phone}`}>
              {workOrder?.owner_profile?.phone}
            </a>
          </div>
        </div>{" "}
        <div className="d-flex w-100 flex-wrap">
          <div className="truncate w-50">
            <a data-testid="wo-owner-email"
              href={`mailto:${workOrder?.owner_profile?.email}`}>
              {workOrder?.owner_profile?.email}
            </a>
          </div>
        </div>
      </div>
      <hr className="mt-1 mb-1 w-100" />

      {/* Buttons Row */}
      <div className="d-flex w-100 justify-content-between p-2 my-auto">
        <div className="d-flex w-100">
          <div className="d-flex w-100">
            <div className="d-flex w-100">{workOrder.children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkOrderCard;
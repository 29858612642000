import React, { Component } from "react";
import { Container } from "reactstrap";
import images from "~/images";

export class PrintLayout extends Component {
  componentDidMount() {
    document.body.style.backgroundColor = "white";
  }

  componentWillUnmount() {
    document.body.style.backgroundColor = null;
  }
  render() {
    const { children } = this.props;
    return (
      <Container id="print-view">
        <div className="header d-flex align-items-center justify-content-between mt-3 mb-4">
          <div className="d-flex align-items-center">
            <img
              src={images[WL.logo]}
              alt="Boatyard Logo"
              style={{ maxWidth: "250px", height: "auto" }}
            />
          </div>

          <img
            src={require("~/images/logo-pettit.png")}
            alt="Binnacle app"
            id="logo"
            style={{ width: "130px", height: "auto" }}
          />
        </div>
        {children}
      </Container>
    );
  }
}

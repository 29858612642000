import React from "react";
// import { Badge } from "reactstrap";
// import kinds from "~/data/kinds";
// import classnames from "classnames";
import QuickBooksIcon from "~/components/boatyard-admin/QuickBooksIcon";

export default function QuickBooksStatusIcon(props) {
  return (
    <>
      <div data-testid="icon-quickbooks-exported" className=" d-flex justify-content-center align-items-center">
        <QuickBooksIcon size="24" bg="#9FA9B4" color="#FFFFFF" />
        <div
          className="bg-white rounded-circle d-flex justify-content-center align-items-center"
          style={{
            position: "relative",
            bottom: "9px",
            right: "9px",
            height: "7px",
            width: "7px"
          }}
        >
          <i
            style={{ color: "#6BAA60", fontSize: "10px" }}
            className="fas fa-check-circle"
          ></i>
        </div>
      </div>
    </>
  );
}

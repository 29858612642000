import React, { useState, useEffect } from "react";
import { useDashboardStore } from "~/store";
import { shallow } from "zustand";
const KindsButton = props => {
  const {
    selectedKinds,
    setSelectedKinds,
    setWorkOrderStatuses,
    qbSelectionActive
  } = useDashboardStore(
    state => ({
      selectedKinds: state.selectedKinds,
      setSelectedKinds: state.setSelectedKinds,
      isKindSelected: state.isKindSelected,
      setWorkOrderStatuses: state.setWorkOrderStatuses,
      qbSelectionActive: state.qbSelectionActive
    }),
    shallow
  );
  const selected = selectedKinds.includes(props.id);

  const onClick = () => {
    setWorkOrderStatuses([]);

    setSelectedKinds([props.id]);
  };
  return (
    <>
      <button
        data-testid={`filter-btn-${props.id}`}
        onClick={() => {
          onClick();
        }}
        disabled={qbSelectionActive}
        className={`filter-item d-flex align-items-center px-1 rounded mx-1 px-2 py-1 ${
          selected ? "selected" : ""
        }`}
      >
        {props.children || props.name}
      </button>
    </>
  );
};

export default KindsButton;

import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Button,
  Alert,
  Toast,
  ToastHeader,
  ToastBody
} from "reactstrap";
// import { binnacleClient, boatsClient } from "~/util/api";
import quickbooksLogo from "~/images/item_icons/qb-logo-hf.svg";
import QuickBooksSignInModal from "~/components/dashboard/QuickBooksSignInModal";

const QuickBooksExportButton = props => {
  const isAdmin = () => {
    return props.profileIsType(["service_writer", "facility_manager", "admin"]);
  };
  // set the facility id in case you need to use it later
  const [facilityId, setFacilityId] = useState();
  const [qbSettings, setQbSettings] = useState();
  const [qbSignInModal, setQbSignInModal] = useState(false);
  const [showQbSyncMessage, setShowQbSyncMessage] = useState(false);
  const [qbSyncMessage, setQbSyncMessage] = useState();
  const [qbSyncMessageColors, setQbSyncMessageColors] = useState([]);
  const [qbSyncMessageHeader, setQbSyncMessageHeader] = useState();
  const [qbSyncStatus, setQbSyncStatus] = useState();
  const [qbSyncActive, setQbSyncActive] = useState();
  const [qbSyncErrors, setQbSyncErrors] = useState([]);

  const toggle = () => setQbSignInModal(!qbSignInModal);
  const getFacilityId = () => {
    // console.log("get facility id called");
    return binnacleClient.get("/profiles/my_profile/");
  };

  const getQbSettings = facility_id => {
    // console.log("qb settings called");

    return binnacleClient.get(`/facilities/${facility_id}/config/`);
  };

  const downloadCsvFile = (data, fileName) => {
    const element = document.createElement("a");
    const file = new Blob([data], { type: "text/csv" });
    element.href = URL.createObjectURL(file);
    element.download = fileName;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const exportQuickBooks = async () => {
    try {
      const response = await binnacleClient.post(`/work-orders/qb-export/`, {
        work_orders: props.selectedItems
      });
      const { data, headers } = response;

      if (headers && headers["content-type"] === "text/csv") {
        const fileName = headers["content-disposition"].split("=")[1];
        console.log("csv file should download");
        downloadCsvFile(data, fileName);

        //  refresh page and clear selected items
        props.load();
        props.clearSelectedItems();
      }

      // GTM
      window.dataLayer.push({
        event: "quickBooksExportDesktop",
        eventProps: {
          action: "qb_desktop_export",
          category: "search_actions",
          userId: props.profile?.id,
          userName: props.profile?.name,
          facilityId: props.profile?.facility_id
        }
      });
      // console.log(window.dataLayer)
    } catch (error) {
      alert("Download to QuickBooks failed", error);
      //  refresh page and clear selected items
      props.load();
      props.clearSelectedItems();
    }
  };

  const signInOrSync = async () => {
    if (qbSettings && qbSettings.qb_logged_in) {
      syncToQuickBooks();
    } else {
      setQbSignInModal(true);
    }
  };

  const syncToQuickBooks = async () => {
    setQbSyncActive(true);
    try {
      const response = await binnacleClient.post(`/work-orders/qb-sync/`, {
        work_orders: props.selectedItems
      });
      const { data, status } = response;
      // Use the code below to test the sync toaster but first comment out the code above
      // const { data, status } = {
      //   data: {
      //     // List of work orders that failed to sync
      //     errors: [{ id: 1, exception: "This is an error" }],
      //     invoiced: ["123",]
      //   },
      //   status: 200
      // };
      if (data?.invoiced?.length > 0 && data?.errors?.length === 0) {
        // Set message to show success
        setShowQbSyncMessage(true);
        setQbSyncMessage(
          "Your selected work orders were exported to your QuickBooks Online account."
        );
        setQbSyncErrors([]);

        setQbSyncMessageColors(["#DEF5B4", "#505E37"]);
        setQbSyncMessageHeader("Success!");
        setQbSyncStatus("success");
        // reload the results if successful but not if there are errors
        props.load();
        props.clearSelectedItems();
      } else if (
        data &&
        data?.invoiced?.length > 0 &&
        data?.errors?.length > 0
      ) {
        // Set message to show success with errors
        setShowQbSyncMessage(true);
        setQbSyncMessage(
          "There was a problem syncing some of your selected work orders to your QuickBooks Online account."
        );
        setQbSyncErrors(data?.errors || []);

        setQbSyncMessageColors(["#fef08a", "#92400e"]);
        setQbSyncMessageHeader("Partial Success!");
        setQbSyncStatus("problems");
      } else if (data?.invoiced?.length === 0 && data?.errors?.length > 0) {
        // set message to show error

        setShowQbSyncMessage(true);
        setQbSyncMessage(
          "There was a problem syncing all your selected work orders to your QuickBooks Online account."
        );
        setQbSyncErrors(data?.errors || []);
        setQbSyncMessageColors(["#fecaca", "#7f1d1d"]);
        setQbSyncMessageHeader("Error!");
        setQbSyncStatus("error");
      } else {
        throw new Error("Sync to QuickBooks may have failed");
      }
      // GTM
      window.dataLayer.push({
        event: "quickBooksExportOnline",
        eventProps: {
          action: "qb_online_export",
          category: "search_actions",
          userId: props.profile?.id,
          userName: props.profile?.name,
          facilityId: props.profile?.facility_id
        }
      });
      // console.log(window.dataLayer);
    } catch (error) {
      alert("Sync to QuickBooks failed", error);
      // set message to show error
      setShowQbSyncMessage(true);
      setQbSyncMessage(
        "There was a problem syncing all your selected work orders to your QuickBooks Online account."
      );
      setQbSyncErrors([error] || []);
      setQbSyncMessageColors(["pink", "maroon"]);
      setQbSyncMessageHeader("Error!");
      setQbSyncStatus("error");
    }
    setQbSyncActive(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      // get facility id from the api
      const getFacilityResponse = await getFacilityId();
      console.log(getFacilityResponse);
      // set facility id to local variable
      const facility_id = await getFacilityResponse.data.facility_id;
      // set facility id to state for use in other functions
      setFacilityId(facility_id);

      // get qb settings from the api
      const getQbSettingsResponse = await getQbSettings(facility_id);
      // set qb settings to local variable
      const qb_settings = await getQbSettingsResponse.data;
      // set qb settings to state for use in other functions
      setQbSettings(qb_settings);
      // console.log(qb_settings);
    };

    fetchData()
      // make sure to catch any error
      .catch(error => {
        console.log(error);
      });
  }, []);
  // console.log(props.qbSelectActive);
  return (
    <>
      <Col md="auto" className="d-flex pr-0" style={{ minHeight: "33px" }}>
        {/* Set min height here for less jenkyness when qb button is hidden */}
        <div
          style={{
            position: "absolute",
            top: "0",
            right: "0"
          }}
        >
          <Toast
            isOpen={showQbSyncMessage}
            style={{
              minWidth: "300px"
            }}
          >
            <ToastHeader
              toggle={() => {
                setShowQbSyncMessage(false);
              }}
              style={{
                background: qbSyncMessageColors[0],
                color: qbSyncMessageColors[1]
              }}
            >
              {qbSyncMessageHeader}
            </ToastHeader>
            <ToastBody
              style={{
                background: qbSyncMessageColors[0],
                color: qbSyncMessageColors[1]
              }}
            >
              {qbSyncMessage}
              {qbSyncStatus === "success" && (
                <p className="my-2">
                  {/* link to QuickBook online's website */}
                  <a
                    style={{
                      color: qbSyncMessageColors[1],
                      textDecoration: "underline"
                    }}
                    className="font-weight-bold"
                    target="_blank"
                    href="https://quickbooks.intuit.com/"
                  >
                    QuickBooks Online
                  </a>
                </p>
              )}
              {qbSyncErrors.length > 0 && (
                <p className="my-2">
                  <ul>
                    {/* map qbSyncErrors  */}
                    {qbSyncErrors.map((workOrder, index) => (
                      <li>
                        <a
                          style={{
                            color: qbSyncMessageColors[1],
                            textDecoration: "underline"
                          }}
                          target="_blank"
                          href={`/work-orders/${workOrder?.id}`}
                          key={index}
                        >
                          {workOrder?.id}
                        </a>{" "}
                        <br /> {workOrder?.exception}
                      </li>
                    ))}
                  </ul>
                </p>
              )}
            </ToastBody>
          </Toast>
        </div>
        <QuickBooksSignInModal
          qbSettings={qbSettings}
          facilityId={facilityId}
          isOpen={qbSignInModal}
          toggle={toggle}
        />
        {(isAdmin() && props.itemsHaveWorkorders()) ||
          (props.workOrdersFilterSelected === true &&
            (!props.qbSelectActive ? (
              <Button
                size="sm"
                href="#"
                className="qb-button rounded qb-button d-flex align-items-center"
                onClick={() => {
                  if (
                    (qbSettings?.qb_online && qbSettings?.qb_logged_in) ||
                    qbSettings?.qb_desktop
                  ) {
                    props.qbSelectToggle();
                  } else {
                    setQbSignInModal(true);
                  }
                }}
              >
                <img style={{ maxHeight: "20px" }} src={quickbooksLogo} />
                <span className=" ml-2">QuickBooks</span>
              </Button>
            ) : (
              <div className="d-flex align-items-center">
                <img
                  style={{ maxHeight: "30px" }}
                  className="mr-2"
                  src={quickbooksLogo}
                />
                {qbSettings?.qb_online ? (
                  <Button
                    data-testid="btn-quickbooks-online"
                    size="sm"
                    href="#"
                    onClick={() => {
                      signInOrSync();
                    }}
                    disabled={
                      !props.selectedItemsGreaterThanZero() || qbSyncActive
                    }
                    className="qb-button rounded d-flex"
                  >
                    <div className={qbSyncActive && "spin"}>
                      <i className="fa fa-sync" aria-hidden="true" />
                    </div>
                    <span className=" ml-1">QuickBooks Online</span>
                  </Button>
                ) : (
                  <Button
                    data-testid="btn-quickbooks-desktop"
                    size="sm"
                    href="#"
                    onClick={() => {
                      exportQuickBooks();
                    }}
                    disabled={!props.selectedItemsGreaterThanZero()}
                    className="qb-button rounded "
                  >
                    <i className="fa fa-download" aria-hidden="true" />
                    <span className=" ml-1">QuickBooks Desktop</span>
                  </Button>
                )}

                <Button
                  data-testid="btn-quickbooks-done"
                  size="sm"
                  color="primary"
                  outline
                  className=" ml-2"
                  onClick={props.qbSelectToggle}
                >
                  Done
                </Button>
              </div>
            )))}
      </Col>
    </>
  );
};

export default QuickBooksExportButton;

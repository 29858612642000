import React from "react";
import { Col } from "reactstrap";
import { Text, useFormApi } from "informed";

import SearchCreateSelect from "~/components/SearchCreateSelector";
import { BootstrapText, BootstrapRadio } from "~/util/autoform/bootstrap";

const scopes = ["owner_profile", "representative_profile", "boat"];
const empty_person = {
  name: "",
  id: "",
  email: "",
  phone: "",
  is_primary_contact: false
};
const baseField = { validateOnChange: true, allowEmptyString: true };

// get the validation function for name field. DON'T CALL THIS INSIDE A component render function
const getValidateNameId = scopeKey => (value, values) => {
  const scope = values[scopeKey] || {};
  if (_.every(_.values(_.pick(values, scopes)), _.isEmpty))
    return "Create or choose an owner or representative";
  if (scope.id || scope.name) return undefined;
};

// get the validation function for email and phone field. DON'T CALL THIS INSIDE A component render function
const getValidateEmailPhone = scopeKey => (value, values) => {
  const scope = values[scopeKey] || {};
  if (_.every([scope.id, scope.name], _.isEmpty)) return undefined; // not required if (id|name)
  return value ? undefined : "This field is required";
};

// get the validation function for key contact field. DON'T CALL THIS INSIDE A component render function
const getValidateKeyContact = scopeKey => (value, values) => {
  const scope = values[scopeKey] || {};
  if (_.every([scope.id, scope.name], _.isEmpty)) return undefined; // not required if (id|name)
  return value || value === false ? undefined : "This field is required";
};

const validateNameIdCached = {};
const validateEmailPhoneCached = {};
const validateKeyContactCached = {};

const SelectEditCreateForm = ({ label, scopeKey, showLabels, onSetBoat }) => {
  // scopeKey is passed from top formState owner_profile or representative_profile
  const formApi = useFormApi();
  const name = formApi.getValue("name");
  const formState = formApi.getValues();

  const getOtherScope = (currentScope = scopeKey) => {
    return currentScope === "owner_profile"
      ? "representative_profile"
      : "owner_profile";
  };

  const isEmpty = _.isEmpty(formState[scopeKey]);
  const isPrimary = _.get(formState, `${scopeKey}.is_primary_contact`, false);

  const onChange = v => {
    formApi.setError("name", undefined);

    const nextPersonState = {
      ...empty_person,
      ...v
    };

    // This ensures a sole profile is marked as the primary contact in the UI
    if (!_.get(formState, `${[getOtherScope(scopeKey)]}.is_primary_contact`)) {
      nextPersonState.is_primary_contact = true;
    }

    formApi.setValues({ [scopeKey]: nextPersonState });
    // Attempt to auto-populate the latest boat (most recent boat by status is first)
    // Don't change boat if a person has already been chosen
    // TODO: change this validation to check for "representative"
    if (!formState.boat || !formState.boat.name) {
      onSetBoat(v);
    }

    // Validate all fields
    _.each(scopes, key => formApi.validate(key));
  };

  // radio button status for owner or representative
  // need to pass profile UUID of selected primary back to core form state as 'primary_contact_id'
  // then map the active status for the form when reopening if the contact id is a match to primar_contact_id value

  // TODO - setting representative as primary is setting formState invalid: true, which is not intended
  // radio toggle
  const onChangeRep = repScope => event => {
    const otherScope = getOtherScope(repScope);
    formApi.setValues({
      [repScope]: { ...formState[repScope], is_primary_contact: true },
      [otherScope]: { ...formState[otherScope], is_primary_contact: false }
    });
  };

  if (!_.has(validateNameIdCached, scopeKey)) {
    validateNameIdCached[scopeKey] = getValidateNameId(scopeKey);
  }

  if (!_.has(validateEmailPhoneCached, scopeKey)) {
    validateEmailPhoneCached[scopeKey] = getValidateEmailPhone(scopeKey);
  }

  if (!_.has(validateKeyContactCached, scopeKey)) {
    validateKeyContactCached[scopeKey] = getValidateKeyContact(scopeKey);
  }

  return (
    <>
      <Col sm={2}>
        <div style={{ marginTop: showLabels ? 35 : 5 }}>
          <strong>{label}</strong>
        </div>
      </Col>
      <Col sm={3}>
        <SearchCreateSelect
          url="/profiles/"
          field="id"
          label="Name"
          id={`input-name-${scopeKey}`}
          showLabel={showLabels}
          displayValue={name}
          params={{ profile_type: "customer" }}
          onChange={onChange}
          validateOnChange={true}
          validate={validateNameIdCached[scopeKey]}
        />
        <Text field="name" type="hidden" />
      </Col>
      <Col sm={3}>
        <BootstrapText
          {...baseField}
          showLabel={showLabels}
          field="email"
          label="Email"
          data-testid={`input-email-${scopeKey}`}
          validate={validateEmailPhoneCached[scopeKey]}
        />
      </Col>
      <Col sm={2}>
        <BootstrapText
          {...baseField}
          showLabel={showLabels}
          field="phone"
          label="Phone"
          data-testid={`input-phone-${scopeKey}`}
          validate={validateEmailPhoneCached[scopeKey]}
        />
      </Col>
      <Col sm={2} className="flex-column align-items-start">
        <BootstrapRadio
          {...baseField}
          disabled={isEmpty}
          showLabel={showLabels}
          field="is_primary_contact"
          label="Primary Contact"
          required={false}
          checked={isPrimary}
          data-testid={`input-radio-primary-${scopeKey}`}
          onChange={onChangeRep(scopeKey)}
          validate={validateKeyContactCached[scopeKey]}
        />
      </Col>
    </>
  );
};

export default SelectEditCreateForm;

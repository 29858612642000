import React, { Component } from "react";
import LoadingButton from "~/util/loadingButton";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import TermsAndConditions from "~/views/TermsAndConditions";

export default class AgreeTermsSubmit extends Component {
  constructor(props) {
    super(props);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.agree = this.agree.bind(this);
    this.state = {
      agreed: false,
      submitTarget: null,
      modal: false
    };
  }

  agree() {
    let target = this.state.submitTarget;

    this.setState(
      {
        modal: false,
        submitTarget: null,
        agreed: true
      },
      function() {
        target.click();
      }
    );
  }

  openModal(e) {
    if (this.props.skipModal) {
      return;
    }

    if (this.state.agreed) {
      this.setState({
        agreed: false
      });

      return;
    }

    e.preventDefault();

    this.setState({
      modal: true,
      submitTarget: e.target,
      agreed: false
    });
  }

  closeModal() {
    this.setState({
      modal: false,
      submitTarget: null,
      agreed: false
    });
  }

  renderModal() {
    return (
      <Modal isOpen={this.state.modal} toggle={this.closeModal} size="lg">
        <ModalHeader toggle={this.closeModal}>Terms and Conditions</ModalHeader>
        <ModalBody>
          <div style={{ maxHeight: 400, overflow: "scroll" }}>
            <TermsAndConditions />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.agree}>
            Agree
          </Button>{" "}
          <Button color="secondary" onClick={this.closeModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  render() {
    return (
      <>
        <LoadingButton
          {...this.props}
          onClick={this.openModal}
          className="btn-primary"
        />
        {this.renderModal()}
      </>
    );
  }
}

import React from "react";
import { Col, Card, CardBody, Container, Row } from "reactstrap";

import KindIcon from "~/components/dashboard/KindIcon";
import { Badge } from "reactstrap";
import DownloadFileButton from "~/util/DownloadFileButton";
import FileUploadButton from "~/util/FileUploadButton";
import classnames from "classnames";
import QuickBooksAdminCard from "~/components/boatyard-admin/QuickBooksAdminCard";
const templateURL = "/profiles/dockmaster-template/";

const ImportHome = props => {
  const iconCircleClasses = classnames(
    "d-flex",
    "align-items-center",
    "justify-content-center",
    "m-1 mr-2",
    "bg-primary"
  );

  return (
    <div id="ImportHome">
      <h1
        style={{
          fontWeight: "400",
          display: "flex",
          alignItems: "center"
        }}
      >
        <Badge
          pill
          className={iconCircleClasses}
          style={{ width: "60px", height: "60px" }}
        >
          <img
            src={require("~/images/item_icons/boatAdminLogo.svg")}
            alt="Admin logo"
            id="logo"
          />
        </Badge>
        Boatyard Admin
      </h1>
      <hr />
      <Container>
        <Row className="d-flex">
          <Col
            xs="12"
            lg="6"
            className="text-left p-0 flex-shrink-1 flex-grow-1 d-flex"
          >
            <Card className="p-1 my-2 flex-shrink-1 flex-grow-1">
              <CardBody>
                <h2 style={{ fontWeight: "400" }}>Boatyard Data</h2>
                <FileUploadButton
                  id="importDataButton"
                  onUpload={props.onUpload}
                  className="btn btn-primary mr-3 mb-1"
                  iconClasses="mr-2"
                >
                  Import Data
                </FileUploadButton>
                <DownloadFileButton
                  url={templateURL}
                  icon="file-download"
                  className="btn btn-outline-primary mb-1"
                  filename="dockmaster-template.csv"
                >
                  Download template
                </DownloadFileButton>

                <p className="pt-2">
                  <small style={{ color: "#6c757d" }}>
                    You may import boatyard data in .xls or .csv format. Your
                    data should be exported with boat and customer data combined
                    into one row, ideally with a last seen date field as well.
                  </small>
                </p>
                <p className="pt-2">
                  <small style={{ color: "#6c757d" }}>
                    Rows containing both a boat, profile data, and a last seen
                    date will also generate a closed $0 work order to establish
                    a relationship.
                  </small>
                </p>
              </CardBody>
            </Card>
          </Col>
          <Col
            xs="12"
            lg="6"
            className="pr-0 pl-0 pl-lg-3 flex-shrink-1 flex-grow-1 d-flex"
          >
            <QuickBooksAdminCard />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ImportHome;

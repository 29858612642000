import React, { Component } from "react";
import { withAPIData } from "./withAPIData";

export function testTypes(profile_types, test_types) {
  // force the test_types into an array, and clean the array to prevent false empty positives
  let test_types_arr = _.compact(_.flatten([test_types]));
  // detect any commonalities between profile_types and the test_types
  // if any type is held in common, return true. Else false.
  return !_.isEmpty(_.intersection(profile_types, test_types_arr));
}

/**
 * This is a higher order component that simply inserts the profile object into the props
 */
export function withProfile(WrappedComponent) {
  return withAPIData(
    class extends Component {
      render() {
        let { data: profile, ...rest } = this.props;

        const profileTypes = _.get(profile, "profile_types", []);

        const profileIsType = type => {
          // test the logged in user's profileTypes against argument array of one or more types
          return testTypes(profileTypes, type);
        };

        const props = {
          ...rest,
          profileIsType,
          profile
        };

        return (
          <>
            <WrappedComponent {...props} />
          </>
        );
      }
    },
    "/profiles/my_profile/",
    null,
    true,
    true
  );
}

export const ForProfileType = withProfile(function (props) {
  let { children, profileType } = props;

  const userTypes = _.get(props, "profile.profile_types", []);

  // Detect overlap between allowTypes/prohibitTypes and userTypes
  // If there is overlap, user is allowed/prohibited
  let isAllowed = testTypes(userTypes, profileType);

  if (isAllowed) {
    if (_.isFunction(children)) {
      return children();
    } else {
      return children;
    }
  }

  return null;
});

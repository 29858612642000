import React from "react";
import { useFormApi } from "informed";

import Fa from "~/util/fa";
import { Btn, FormBtn } from "./Form";
import EmailPreview from "../emailPreview/EmailPreview";
import DeleteBtnModal from "~/components/workOrders/DeleteBtnModal";

export const EmailPreviewButton = ({
  location = "top",
  workOrder: upstreamWO,
  type = "quote_approval",
  modalLabel = "Save and Send",
  submitLabel = "Submit"
}) => {
  const formApi = useFormApi();
  const formState = formApi.getState();
  const phases = _.get(formState, "values.phases");
  const boatPrimaryImage = upstreamWO?.boat?.images?.find((image) => image.is_primary)

  let workOrder = {
    ...upstreamWO,
    ...formState.values
  };

  if (_.isEmpty(upstreamWO) && !_.isEmpty(formState.values)) {
    workOrder = { ...formState.values };
  }

  return (
    // Function to trigger email preview gets triggered in the parent in Form.jsx, see below comments
    <EmailPreview
      type={type}
      currentPhaseSchedule={phases}
      workOrder={workOrder}
      boatPrimaryImage={boatPrimaryImage}
      // data is for email request generated by child with getMessageData
      renderSubmitButton={({ disabled, id, data, sendEmail, closeModal }) => (
        <FormBtn
          disabled={disabled}
          id={id || `${location}-save-send`}
          value={type === "work_update" ? "resend" : "saveAndSend"}
          submitOnClick={false}
          className="d-block w-100 w-md-auto d-md-inline-block mb-2 mb-md-1"
          onClick={async event => {
            if (type === "work_update") {
              // On pending wo's, simply resend the quote
              const emailResult = await sendEmail();
              closeModal();
              return;
            }
            if (type === "quote_approval") {
              formApi.setValues({ email: { ...data }, button: "saveAndSend" });
            }

            // type change order comes because it has a parent id
            // needs to handle: draft CO needing to be saved and transitioned to pending
            // and resend on an already pending change order
            if (type === "change_order") {
              // send quote for change order
              formApi.setValues({ email: { ...data }, button: "saveAndSend" });
            }

            if (type === "work_order_approval") {
              formApi.setValues({ email: { ...data }, button: "approveWO" });
            }

            // Use WO form submission
            formApi.submitForm();
          }}
        >
          <Fa icon="paper-plane" className="mr-2" />
          {submitLabel}
        </FormBtn>
      )}
      renderModalButton={({ onShowModal, disabled }) => (
        <FormBtn
          onClick={onShowModal}
          disabled={disabled}
          id={`${location}-toggle-preview`}
          value="toggleEmailPreview"
          submitOnClick={false}
          className="mr-0 mr-2 mb-2 mb-md-0"
        >
          <Fa icon="paper-plane" className="mr-2" />
          {modalLabel}
        </FormBtn>
      )}
    />
  );
};

const FormActionButtons = ({
  workOrder,
  profile,
  workOrder: { status = "draft" },
  location = "top"
}) => {
  const emailType = workOrder.parent ? "change_order" : "quote_approval";
  const statusesAllowingSoftDelete = ['draft', 'pending', 'approved', 'rejected', 'replaced']
  return (
    // email preview actions are dispatched in the parent Form.jsx under this.buttons
    // Each formBtn value maps to a key in the this.buttons map
    <div className="d-flex w-100 justify-content-center flex-column flex-md-row justify-content-sm-start justify-content-lg-end">
      {/* any Work Order not already dismissed or complete can be soft deleted with a PUT status of 'dismissed' */}
      { statusesAllowingSoftDelete.includes(status) && workOrder.id && (
        <DeleteBtnModal workOrderId={workOrder?.id} />
      )}
      {status === "draft" && (
        <>
          <FormBtn
            data-testid="btn-save-draft"
            id={`${location}-save-draft`}
            outline
            color="primary"
            value="saveAsDraft"
            className="mr-0 mr-md-2 mb-2 mb-md-0 d-block w-100 w-md-auto d-md-inline-block"
          >
            <Fa icon="save" className="mr-2" />
            Save as Draft
          </FormBtn>
          <EmailPreviewButton
            data-testid="btn-save-send-estimate"
            type={emailType}
            location={location}
            workOrder={workOrder}
            className="mr-0 mr-md-2 mb-2 mb-md-0 d-block w-100 w-md-auto d-md-inline-block"  
            // pass along form state for phases to email preview
            modalLabel="Preview and Send Estimate"
            submitLabel="Save and Send Estimate"
          />
        </>
      )}
      {status === "pending" && (
        <>
          <EmailPreviewButton
            data-testid="btn-resend-estimate"
            type={emailType}
            location={location}
            workOrder={workOrder}
            modalLabel="Resend"
            submitLabel="Resend Estimate"
          />
          <Btn
            data-testid="btn-reject-wo"
            color="danger" outline 
            value="rejectWO"
            className="mr-0 mr-md-2 mb-2 mb-md-0 d-block w-100 w-md-auto d-md-inline-block"
            >
            <Fa icon="ban" className="mr-2" />
            Decline
          </Btn>
          <Btn
            data-testid="btn-change-request"
            outline
            color="primary"
            value="createChangeRequest"
            // onMouseDown={ gtmEventChangeRequest }
            id={`create_change_request_button_${location}`}
            className="mr-0 mb-2 mb-md-0 mr-md-2 d-block w-100 w-md-auto d-md-inline-block"
          >
            <Fa icon="copy" className="mr-2" />
            Change
          </Btn>
          {/* <EmailPreviewButton
          type="work_order_approval"
          location={location}
          workOrder={workOrder}
          modalLabel="Approve"
          submitLabel="Send and Approve"
        /> */}
          <Btn color="primary" value="approveWO" data-testid="btn-approve">
            <Fa icon="check" className="mr-2" />
            Approve
          </Btn>
        </>
      )}
      {status === "approved" && (
        <>
          <Btn
            data-testid="btn-change-request"
            outline
            color="primary"
            value="createChangeRequest"
            id={`create_change_request_button_${location}`}
            className="mr-0 mb-2 mb-md-0 mr-md-2 d-block w-100 w-md-auto d-md-inline-block"
            // onClick={() => gtmEventChangeRequest()}
          >
            <Fa icon="copy" className="mr-2" />
            Change Request
          </Btn>
          <Btn 
            data-testid="btn-deliver"
            color="success" 
            value="markComplete"
            className="mr-0 mr-md-2 mb-2 mb-md-0 d-block w-100 w-md-auto d-md-inline-block"  
          >
            <Fa icon="check" className="mr-2" />
            Deliver Job
          </Btn>
          <FormBtn
            data-testid="btn-save"
            id={`${location}-save-draft`}
            outline
            color="primary"
            value="saveApproved" // was saveAsDraft
            className="mr-0 mr-md-2 mb-2 mb-md-0 d-block w-100 w-md-auto d-md-inline-block"  
          >
            <Fa icon="save" className="mr-2" />
            Save
          </FormBtn>
        </>
      )}
      {(status === "rejected" || status === "dismissed") && (
        <Btn
          data-testid="btn-change-request"
          color="primary"
          value="createChangeRequest"
          id={`create_change_request_button_${location}`}
          className="mr-0 mr-md-2 mb-2 mb-md-0 d-block w-100 w-md-auto d-md-inline-block"  
          // onClick={() => gtmEventChangeRequest()}
        >
          Change Request
        </Btn>
      )}
    </div>
  );
};

export default FormActionButtons;

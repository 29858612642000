import React from "react";
import { Badge } from "reactstrap";
import statuses from "~/data/statuses";
import moment from "moment";

export default function StatusPill({
  status = defaultStatus,
  statusDate,
  className = ""
}) {
  const { label, id } = _.get(statuses, status, {});
  const formattedDate = moment(statusDate).format("L");

  return (
    <div
      className={`card-status text-center d-flex align-items-center flex-column justify-content-center flex-wrap ${className}`}
    >
      <Badge
        pill
        data-testid="wo-status-pill"
        style={{ borderRadius: "4px" }}
        className={`status status-${id} status-pill`}
      >
        <div className="status-pill-contents">
          <span className="status-dot" />
          <strong style={{ fontWeight: "bold" }}>
            {label}
            {/* {statusDate ? ` ${formattedDate}` : null} */}
          </strong>
        </div>
      </Badge>
      {/* {statusDate ? <p className="date-created">{formattedDate}</p> : null} */}
    </div>
  );
}

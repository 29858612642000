import classNames from "classnames";
import React, { Component } from "react";
import {
  Form,
  BasicText,
  asField,
  BasicSelect,
  BasicCheckbox,
  BasicTextArea
} from "informed";
import { Input, FormGroup, Label } from "reactstrap";
import { uniqueId } from "lodash";
import PropTypes from "prop-types";
import { ChoiceBasicSelect } from "./fields";
import DateInputBasic from "./date_input";
import Fa from "~/util/fa";
import AsyncReactSelect from "./asyncReactSelect";

const BootstrapFieldWrapper = WrappedComponent => {
  class BootstrapWrapper extends Component {
    constructor(props) {
      super(props);

      this.state = { id: this.props.id || uniqueId("bootstrap-field-") };
    }

    render() {
      const {
        fieldState,
        showLabel,
        label,
        helpText,
        field,
        required,
        labelClassName,
        prependText,
        appendText,
        groupProps = {},
        isPlaceholder,
        ...rest
      } = this.props;

      const newProps = {
        className: classNames("form-control", {
          "is-invalid": fieldState.error
        }),
        id: this.state.id,
        fieldState: fieldState
      };

      return (
        <div
          className={classNames(
            "form-group",
            {
              required: required,
              "is-invalid": fieldState.error != null,
              "is-placeholder": isPlaceholder
            },
            labelClassName
          )}
          style={{ position: "relative" }}
        >
          {showLabel && <label htmlFor={this.state.id}>{label || field}</label>}

          {prependText || appendText ? (
            <div className="input-group" {...groupProps}>
              {prependText && (
                <div className="input-group-prepend">
                  <span className="input-group-text">{prependText}</span>
                </div>
              )}
              <WrappedComponent {...newProps} {...rest} />
              {appendText && (
                <div className="input-group-append">
                  <span className="input-group-text">{appendText}</span>
                </div>
              )}
            </div>
          ) : (
            <WrappedComponent {...newProps} {...groupProps} {...rest} />
          )}

          {helpText && (
            <small className="form-text text-muted">
              <i>{helpText}</i>
            </small>
          )}

          {fieldState.error !== null && (
            <div className="invalid-feedback">{fieldState.error}</div>
          )}
        </div>
      );
    }
  }

  BootstrapWrapper.defaultProps = {
    showLabel: true,
    prependText: null,
    appendText: null
  };

  BootstrapWrapper.propTypes = {
    showLabel: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    helpText: PropTypes.string,
    prependText: PropTypes.string,
    appendText: PropTypes.string,
    groupProps: PropTypes.object,
    inputProps: PropTypes.object,
    "data-testid": PropTypes.string
  };

  return asField(BootstrapWrapper);
};

// const BasicBootstrapCheckbox = ( props ) => {
//     let { className, ...rest } = props;
//     className = (className || "") + " custom-control-input";
//     console.log(props);
//
//     return (
//         <div className="checkbox">
//             <div className="custom-control custom-checkbox">
//                 <BasicCheckbox className={className} {...rest} />
//                 <label className="custom-control-label" htmlFor={props.id}>{props.label}</label>
//             </div>
//         </div>
//     )
// };

const Checkbox = ({ fieldApi, fieldState, ...props }) => {
  const { value } = fieldState;
  const { setValue, setTouched } = fieldApi;
  const {
    onChange,
    onBlur,
    field,
    initialValue,
    debug,
    forwardedRef,
    ...rest
  } = props;
  return (
    <input
      {...rest}
      name={field}
      ref={forwardedRef}
      checked={!!value}
      onChange={e => {
        setValue(e.target.checked);
        if (onChange) {
          onChange(e);
        }
      }}
      onBlur={e => {
        setTouched(true);
        if (onBlur) {
          onBlur(e);
        }
      }}
      type="checkbox"
    />
  );
};

export class BaseBootstrapCheckbox extends Component {
  constructor(props) {
    super(props);
    this.state = { id: this.props.id || uniqueId("bootstrap-field-") };
  }

  render() {
    const {
      fieldState,
      showLabel,
      label,
      helpText,
      field,
      required,
      labelClassName,
      style: propStyle,
      ...rest
    } = this.props;

    const newProps = {
      className: classNames("custom-control-input form-check-input", {
        "is-invalid": fieldState.error
      }),
      id: this.state.id,
      fieldState: fieldState
    };

    return (
      <div
        className={classNames(
          "form-group",
          { required: required, "is-invalid": fieldState.error },
          labelClassName
        )}
        style={propStyle}
      >
        <div className="checkbox">
          <div className="custom-control custom-checkbox">
            <Checkbox {...newProps} {...rest} />
            {showLabel ? (
              <label
                className={classNames("custom-control-label", labelClassName)}
                htmlFor={this.state.id}
              >
                {label}
              </label>
            ) : null}
          </div>
        </div>

        {helpText && (
          <small className="form-text text-muted">
            <i>{helpText}</i>
          </small>
        )}

        {fieldState.error && (
          <div className="invalid-feedback">{fieldState.error}</div>
        )}
      </div>
    );
  }
}
export class BaseBootstrapRadio extends Component {
  constructor(props) {
    super(props);
    this.state = { id: this.props.id || uniqueId("bootstrap-field-") };
  }

  render() {
    const {
      fieldState,
      fieldApi,
      showLabel,
      label,
      helpText,
      field,
      required,
      labelClassName,
      FormGroupClassName,
      style: propStyle,
      forwardedRef,
      ...rest
    } = this.props;

    const newProps = {
      className: classNames("custom-control-input form-check-input", {
        "is-invalid": fieldState.error
      }),
      id: this.state.id,
      fieldState: fieldState
    };

    return (
      <div
        className={classNames(
          "form-group",
          { required: required, "is-invalid": fieldState.error },
          labelClassName
        )}
        style={propStyle}
      >
        <div className="radio">
          <div className="custom-radio">
            <FormGroup className={FormGroupClassName || ""}>
              {showLabel ? (
                <Label
                  className={classNames("mb-1", labelClassName)}
                  htmlFor={this.state.id}
                  check
                >
                  {label}
                </Label>
              ) : null}
              <div
                style={{ height: "calc(1.5em + 0.75rem + 2px)" }}
                className="d-flex flex-column justify-content-center"
              >
                <Input
                  {...newProps}
                  {...rest}
                  style={{
                    position: "static",
                    margin: "0",
                    height: "18px",
                    width: "18px"
                  }}
                  className="d-block"
                  type="radio"
                  name={field}
                  // value={fieldState.value}
                  // onChange={onChangeRep(scopeKey)}
                />
              </div>
            </FormGroup>
          </div>
        </div>
      </div>
    );
  }
}

class BasicNumber extends Component {
  getValue() {
    const fieldApi = this.props.fieldApi;
    let value = fieldApi.getValue();
    value = parseInt(value);
    if (isNaN(value)) {
      value = 0;
    }
    return value;
  }

  increase() {
    const fieldApi = this.props.fieldApi;
    fieldApi.setValue(this.getValue() + 1);
  }

  decrease() {
    const fieldApi = this.props.fieldApi;
    fieldApi.setValue(this.getValue() - 1);
  }

  render() {
    return (
      <span className="number-input">
        <BasicText {...this.props} type="number" />
        <span className="number-input-minus" onClick={this.decrease.bind(this)}>
          <Fa icon="minus" />
        </span>
        <span className="number-input-plus" onClick={this.increase.bind(this)}>
          <Fa icon="plus" />
        </span>
      </span>
    );
  }
}

export const BootstrapSelect = BootstrapFieldWrapper(BasicSelect);
export const BootstrapText = BootstrapFieldWrapper(BasicText);
export const BootstrapNumber = BootstrapFieldWrapper(BasicNumber);
export const BootstrapTextArea = BootstrapFieldWrapper(BasicTextArea);
export const BootstrapChoiceSelect = BootstrapFieldWrapper(ChoiceBasicSelect);
export const BootstrapReactSelect = BootstrapFieldWrapper(AsyncReactSelect);
export const BootstrapCheckbox = asField(BaseBootstrapCheckbox);
export const BootstrapRadio = asField(BaseBootstrapRadio);
export const BootstrapDateInput = BootstrapFieldWrapper(DateInputBasic);

import React, { useState } from "react";
import classnames from "classnames";

function FloatingAttribution(props) {
  const attributionClasses = classnames(
    "card",
    "shadow",
    "p-2",
    "footer-attribution",
    props.className
  );
  const attributionStyles = {
    left: "1.5rem",
    right: "auto",
    width: "11em",
    height: "auto"
  };

  return (
    <div className={attributionClasses} style={attributionStyles}>
      <img
        src={require("~/images/logo-pettit.png")}
        alt="Pettit Pro Services"
      />
    </div>
  );
}

export default FloatingAttribution;

import React, { useState, useEffect } from "react";
import { FormGroup, Label, Input } from "reactstrap";
import { useDashboardStore } from "~/store";
import { shallow } from "zustand";

const StatusSelect = ({ key, indices, children }) => {
  const statuses = [
    { label: "Draft", id: "draft", key: "status" },
    { label: "Pending", id: "pending", key: "status" },
    { label: "Approved", id: "approved", key: "status" },
    { label: "Declined", id: "rejected", key: "status" },
    { label: "Dismissed", id: "dismissed", key: "status" },
    { label: "Replaced", id: "replaced", key: "status" },
    { label: "Complete", id: "complete", key: "status" }
  ];

  const something = "";

  const { workOrderStatuses, setWorkOrderStatuses } = useDashboardStore(
    state => ({
      workOrderStatuses: state.workOrderStatuses,
      setWorkOrderStatuses: state.setWorkOrderStatuses
    }),
    shallow
  );

  return (
    <>
      <FormGroup className="mb-0">
        <Input
          data-testid="select-status"
          id="exampleSelect"
          className="form-control select"
          name="select"
          type="select"
          value={
            workOrderStatuses.length === 1 ? workOrderStatuses[0] : something
          }
          onChange={e => {
            console.log("e", e);
            // console.log(
            //   "e.target.options[e.target.selectedIndex]",
            //   statuses[e.target.selectedIndex]?.id
            // );
            setWorkOrderStatuses([
              statuses?.[e?.target?.selectedIndex - 1]?.id
            ]);
            // e.targetvalue = statuses?.[e?.target?.selectedIndex - 1]?.id;
          }}
        >
          <option key={""} value={""}>
            {workOrderStatuses.length < 2 ? "Status" : "Multiple"}
          </option>
          {statuses.map(({ label, key, id }) => (
            <option key={id} value={id}>
              {label}
            </option>
          ))}
        </Input>
      </FormGroup>
    </>
  );
};

export default StatusSelect;

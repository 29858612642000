import React, { useState, useEffect } from "react";
import sampleBoatIMG from "~/images/no-image.svg";
import { Button } from "reactstrap";
import "./ImageUploadCarousel.scss";
import { binnacleClient, boatsClient } from "~/util/api";
import loadingImage from "~/images/image-loading.svg";
import BoatModal from "~/components/boat/BoatModal";
import ToastApiStatus from "~/components/ToastApiStatus";
import Dropzone from "~/components/boat/DropZone";
import BoatImageUploadButton from "~/components/boat/BoatImageUploadButton";
function ImageUploadCarousel(props) {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [modal, setModal] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastColors, setToastColors] = useState(["#fecaca", "#7f1d1d"]);
  const [toastHeaderText, setToastHeaderText] = useState("Error!");
  const [toastBodyText, setToastBodyText] = useState(
    "There was an error with your upload.  Please try an image less than 1MB"
  );
  // console.log(props);

  const changeHandler = event => {
    // set files to state here. One is for selected files and one is dropped files
    const files = event?.target?.files || event?.dataTransfer?.files;
    setSelectedFiles(Object.values(files));
    const submitImage = document.getElementById("submitImage");
    // Add delay to prevent error
    setTimeout(() => {
      submitImage.click();
    });
  };

  const changeCarouselImageHandler = (image, index) => {
    // console.log(event.target.src);
    props.setCarouselImage(image);
    props.setImageIndex(index);
  };

  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);

  useEffect(() => {
    // Add event listener to the hidden file input element
    const carouselImageSelect = document.getElementById("carouselImageSelect");
    const hiddenFileInput = document.getElementById("hiddenFileInput");
    carouselImageSelect.addEventListener(
      "click",
      e => {
        if (hiddenFileInput) {
          handleSubmission();
        }
      },
      false
    );
  });

  const handleSubmission = async () => {
    // set uploading here
    setUploading(true);
    const formData = new FormData();
    // This is the form Field name. Change this to match backend
    const formFieldName = "images";

    selectedFiles.forEach(selectedFile => {
      formData.append(formFieldName, selectedFile);
    });
    await new Promise(resolve => setTimeout(resolve, 1000));

    try {
      const response = await boatsClient.post(
        `/boats/${props.boat.id}/image/`,
        formData,
        {
          method: "POST",
          headers: {
            "Content-Type": `multipart/form-data;`
          }
        }
      );

      if (response.status !== 200) {
        throw new Error(`HTTP error: ${response.status}`);
      }
      await props.refreshImages();
    } catch (error) {
      setShowToast(true);
      console.error("Error:", error);
    }
    setUploading(false);
  };

  const handleImageDelete = async image => {
    try {
      const response = await boatsClient.delete(
        `/boats/${props.boat.id}/image/${image.id}/`
      );
      if (response.status !== 204) {
        throw new Error(`HTTP error: ${response.status}`);
      }
      await props.refreshImages();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleImageMakePrimary = async image => {
    try {
      const response = await boatsClient.put(
        `/boats/${props.boat.id}/image/${image.id}/`,
        {
          is_primary: true
        }
      );
      // should be 200 but BE returns 204
      if (response.status !== (204 || 200)) {
        throw new Error(`HTTP error: ${response.status}`);
      }
      await props.refreshImages();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleUpload = event => {
    hiddenFileInput.current.click();
  };

  const handleOpenModal = () => {
    if (props.images.length > 0) {
      setModal(true);
    }
  };

  return (
    <>
      <ToastApiStatus
        showToast={showToast}
        setShowToast={setShowToast}
        toastColors={toastColors}
        setToastColors={setToastColors}
        toastHeaderText={toastHeaderText}
        setToastHeaderText={setToastHeaderText}
        toastBodyText={toastBodyText}
        setToastBodyText={setToastBodyText}
      />
      {props.images.length > 0 ? (
        <BoatModal
          modal={modal}
          setModal={setModal}
          setImageIndex={props.setImageIndex}
          imageIndex={props.imageIndex}
          images={props.images}
        />
      ) : null}
      <div className="d-flex flex-wrap carousel__container">
        <BoatImageUploadButton
          changeHandler={changeHandler}
          handleUpload={handleUpload}
          hiddenFileInput={hiddenFileInput}
        />
        <Dropzone handleUpload={changeHandler}>
          <div className="d-flex justify-content-center w-100 ">
            <div className="img__carousel_wrapper">
              {props.imagesLoading ? (
                <img
                  id="carouselImageSelect"
                  className="img__carousel rounded"
                  style={{ objectFit: "contain" }}
                  src={loadingImage}
                  alt="loading"
                />
              ) : (
                <img
                  id="carouselImageSelect"
                  className="img__carousel rounded"
                  style={{
                    objectFit: props?.carouselImage ? "cover" : "contain",
                    display: props?.carouselImage ? "block" : "none"
                  }}
                  src={props?.carouselImage?.url || sampleBoatIMG}
                  alt=""
                />
              )}
              {/* Show drag and drop when no image */}
              {props?.carouselImage || props.imagesLoading ? (
                ""
              ) : (
                <div
                  role="button"
                  onClick={handleUpload}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    borderWidth: 2,
                    borderRadius: 2,
                    borderColor: "#eeeeee",
                    borderStyle: "dashed",
                    color: "#bdbdbd",
                    outline: "none",
                    transition: "border .24s ease-in-out"
                  }}
                >
                  {uploading
                    ? "Uploading..."
                    : "Drop files here or click to select files."}
                </div>
              )}

              {/* Show buttons if carouselImage array exists */}
              {props?.carouselImage && (
                <div
                  className="img__carousel_description_layer d-flex justify-content-between rounded"
                  role={props?.images.length > 0 ? "button" : "div"}
                  onClick={event => {
                    event.stopPropagation();
                    handleOpenModal();
                  }}
                >
                  <Button
                    size="sm"
                    className="m-3"
                    cssModule={{ "btn-secondary": "btn-grey-100" }}
                    color="secondary"
                    onClick={event => {
                      event.stopPropagation();
                      handleImageDelete(props.carouselImage);
                    }}
                  >
                    <i className="fas fa-trash-alt"></i>
                  </Button>
                  {props.carouselImage.is_primary === false ? (
                    <Button
                      size="sm"
                      className="m-3"
                      color="secondary"
                      cssModule={{ "btn-secondary": "btn-grey-100" }}
                      onClick={event => {
                        event.stopPropagation();
                        handleImageMakePrimary(props.carouselImage);
                      }}
                    >
                      Make Profile
                    </Button>
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </div>
          </div>
        </Dropzone>

        <button
          style={{ display: "none" }}
          id="submitImage"
          onClick={handleSubmission}
        >
          Submit
        </button>
        <div
          className="d-flex w-100 mb-5 mt-3 pb-3"
          style={{ overflow: "scroll" }}
        >
          {props?.images &&
            props?.images?.map((image, index) => (
              <div
                className="img__thumbnail_wrapper rounded"
                key={`image-${index}`}
              >
                <img
                  onClick={() => {
                    changeCarouselImageHandler(image, index);
                  }}
                  className="img__thumbnail rounded"
                  src={image.thumbnail}
                  alt=""
                />
              </div>
            ))}
        </div>
      </div>
    </>
  );
}

export default ImageUploadCarousel;

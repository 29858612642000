import React from "react";
import Fa from "~/util/fa";
import { WithAPIData, withAPIData } from "~/util/api/withAPIData";
import moment from "moment";
import withBoatyard from "~/util/api/withBoatyard";

const Comment = ({ comment, boatyard }) => {
  return (
    <div className="mb-2">
      {boatyard.id === comment.boatyard_id ? (
        <WithAPIData
          cache={true}
          url={`/profiles/${comment.profile_id}/`}
          ignore404={true}
        >
          {(data, loading) => (
            <>
              {loading ? (
                <Fa icon="spinner" spin />
              ) : (
                <strong className="d-block">
                  {data != null ? data.name : <i>Someone from your boatyard</i>}
                </strong>
              )}
            </>
          )}
        </WithAPIData>
      ) : (
        <strong className="d-block">
          <i>Someone from another boatyard</i>
        </strong>
      )}
      <small className="text-muted">
        {moment(comment.date_created).format("l LT")}
      </small>{" "}
      <br />
      {comment.comment}
      <hr />
    </div>
  );
};

export default withBoatyard(Comment);

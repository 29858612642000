import React from "react";
import { Container, Row, Col } from "reactstrap";
import { withProfile } from "~/util/api/withProfile";
import styled from "styled-components";
import moment from "moment";

import StatusPill from "~/components/StatusPill";
import FormActionButtons from "./FormActionButtons";
import WOUpdatedContactInfo from "./WOUpdatedContactInfo";
import IconWithTooltip from "~/components/IconWithTooltip";

const StyledDate = styled.span`
  font-style: italic;
  font-size: 0.9em;
  color: #585858;
`;

const TitleBar = ({ workOrder, profile }) => {
  const getDate = () => {
    const date = workOrder.status_date
      ? moment(workOrder.status_date)
      : moment();
    return date.format("MM/DD/YY");
  };

  return (
    <div id="title-bar" className="py-3">
      <Container>
        <Row className="d-flex justify-content-between flex-wrap align-items-center">
          <Col className="d-flex align-items-center" lg={6} xs={12}>
            <h1 className="mr-3 mb-0">Work Order</h1>
            <StatusPill className="mr-3" status={workOrder.status || "draft"} />

            { workOrder.parent ? <IconWithTooltip
              icon="copy"
              tooltipText="Change Order"
              size="20"
              /> : <></> }

            <StyledDate data-testid="wo-edited-date">{getDate()}</StyledDate>
            {workOrder.invoice_number && (
              <strong data-cy="wo-invoice-number" className="gray-600 ml-3">
                {workOrder.invoice_number}
              </strong>
            )}
          </Col>
          <Col
            className="d-flex align-items-center mt-2 mt-lg-0 justify-content-lg-end"
            lg={6}
            xs={12}
          >
            <FormActionButtons workOrder={workOrder} profile={profile} />
          </Col>
        </Row>

        {workOrder.updated_profile && (
          <WOUpdatedContactInfo updatedProfile={workOrder.updated_profile} />
        )}
      </Container>
    </div>
  );
};

export default withProfile(TitleBar);

import React from "react";
import styled from "styled-components";

import { CustomInput } from "reactstrap";

const StyledCheckboxRow = styled.div`
  .custom-control.custom-checkbox {
    display: flex;
    justify-content: flex-end;
  }

  .custom-control-label {
    padding-left: 0;
    padding-right: 1.7rem;
    font-style: italic;
    color: rgba(0, 0, 0, 0.4);

    &::before {
      left: auto;
      right: -8px;
      font-style: normal;
    }
  }
`;

const EnableSchedule = ({ checked, onChange, id }) => (
  <StyledCheckboxRow className="form-group">
    <div className="checkbox">
      <div
        onClick={event => event.stopPropagation()}
        className="custom-control custom-checkbox"
      >
        <CustomInput
          id={`includeScheduleInEmail-${id}`}
          type="checkbox"
          checked={checked}
          label={"Include calendar in email"}
          onChange={onChange}
        />
      </div>
    </div>
  </StyledCheckboxRow>
);

export default EnableSchedule;

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ProgressBar from "react-progress-bar-plus";

import Fa from "~/util/fa";

const ImportUploadingWrapper = styled.div`
  .react-progress-bar {
    position: static !important;
  }

  .react-progress-bar-percent {
    position: fixed !important;
    left: 0;
    top: 0;
  }

  .react-progress-bar-spinner {
    display: flex;
    justify-content: center;
    background: transparent;
    position: static !important;
    border-radius: 0 !important;
    box-shadow: none !important;
  }
`;

const ImportUploading = ({ progress }) => {
  return (
    <ImportUploadingWrapper id="ImportUploading">
      <h1
        style={{
          fontWeight: "400",
          display: "flex",
          alignItems: "center"
        }}
      >
        Profile Data Import
      </h1>
      <hr />
      {/* TODO: increment via api updates? */}
      <Fa icon="spinner" spin />
      {/* <ProgressBar percent={progress} autoIncrement /> */}
      <p style={{ textAlign: "center", marginTop: "1rem", color: "#6c757d" }}>
        Analyzing your data, preparing check...
      </p>
    </ImportUploadingWrapper>
  );
};

ImportUploading.propTypes = {
  progress: PropTypes.number
};

export default ImportUploading;

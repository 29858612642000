import React, { useState, useEffect } from "react";
import KindIcon from "~/components/dashboard/KindIcon";
import classnames from "classnames";
import BoatModal from "~/components/boat/BoatModal";

function BoatRoundedImage({
  images,
  primaryImg,
  color = "primary",
  iconColor = "text-light",
  size = 44,
  iconSize,
  enableModal,
  moveRight = "0px",
  kind = "boat",
  iconBorder = false,
  outerIconStyles = {},
  innerIconStyles = {}
}) {
  const [modal, setModal] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const [primaryImage, setPrimaryImage] = useState(0);
  const styles = {
    width: `${size}px`,
    height: `${size}px`,
    border: iconBorder === true ? "2px solid #D3DAE0" : "none"
  };
  const mergedStyles = {
    ...styles,
    ...outerIconStyles
  };

  /* function to set primary image if images array prop exists
  or use primaryImg if single image prop exists */
  const setImages = () => {
    if (images) {
      const PrimaryImage = images?.find(image => image.is_primary);
      const primaryImageIndex = images?.indexOf(PrimaryImage);
      setImageIndex(primaryImageIndex);
      // Set primary image from images array prop
      setPrimaryImage(PrimaryImage);
    } else if (primaryImg) {
      // Set primary image from single image prop
      setPrimaryImage(primaryImg);
    }
  };
  useEffect(() => {
    setImages();
  }, [images, primaryImg]);

  const iconCircleClasses = classnames(
    "d-flex",
    "align-items-center",
    "justify-content-center",
    "m-1"
  );

  const handleOpenModal = () => {
    if (enableModal) {
      // open modal
      setModal(true);
    }
  };

  const hasImagesAndEnableModal = () => {
    return images && enableModal;
  };

  return (
    <>
      {hasImagesAndEnableModal() ? (
        <BoatModal
          modal={modal}
          imageIndex={imageIndex}
          setImageIndex={setImageIndex}
          images={images}
          setModal={setModal}
        />
      ) : (
        <></>
      )}
      <div style={{ position: "relative", right: moveRight }}>
        {primaryImage ? (
          <span
            role={hasImagesAndEnableModal() ? "button" : "span"}
            onClick={() => handleOpenModal()}
          >
            <div
              className="d-flex bg-secondary rounded-circle justify-content-center align-items-center m-1 p-1 card-image-outer"
              style={{ height: `${size + 2}px`, width: `${size + 2}px` }}
            >
              <img
                src={primaryImage.thumbnail}
                // alt={name}
                className="rounded-circle img-fluid card-image-inner"
                style={{ height: `${size}px`, width: `${size}px` }}
              />
            </div>
          </span>
        ) : (
          <>
            <KindIcon
              kind={kind}
              iconBgColor={`bg-${color}`}
              iconColor={iconColor}
              className={iconCircleClasses}
              fontSize={iconSize}
              border={true}
              style={mergedStyles}
              innerIconStyles={innerIconStyles}
            />
          </>
        )}
      </div>
    </>
  );
}
export default BoatRoundedImage;

import React from "react";
import PropTypes from "prop-types";

import Fa from "~/util/fa";

const ImportComplete = ({ onBack }) => {
  const onClick = event => {
    event.preventDefault();
    onBack();
  };

  return (
    <div id="ImportComplete">
      <h1
        style={{
          fontWeight: "400",
          display: "flex",
          alignItems: "center"
        }}
      >
        Profile Data Import
      </h1>
      <hr />
      {/* Progress reporting is a wedding cake option */}
      {/* TODO: increment via api updates */}
      <div style={{ textAlign: "center" }}>
        <Fa icon="check-circle" size={4} style={{ color: "#4398e7" }} />
      </div>
      <h2 style={{ textAlign: "center", fontWeight: "400", marginTop: "1rem" }}>
        Import complete
      </h2>
      <div style={{ textAlign: "center" }}>
        <button onClick={onClick} className="btn btn-outline-primary">
          Back to Admin
        </button>
      </div>
    </div>
  );
};

ImportComplete.propTypes = {
  onGoBackHome: PropTypes.func
};

export default ImportComplete;

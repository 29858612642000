import React, { useState, useEffect } from "react";
import { Alert } from "reactstrap";

import { boatsClient } from "~/util/api";
import { LoadingIndicator } from "~/util/loadingWrapper";

export default WrappedComponent => {
  return props => {
    const id = _.get(props, "match.params.id");
    if (!id) return null;

    const [loading, setLoading] = useState(true);
    const [boat, setBoat] = useState({});
    const [error, setError] = useState("");
    useEffect(() => {
      boatsClient
        .get(`/boats/${id}/`)
        .then(({ data }) => {
          setBoat(data);
          setLoading(false);
        })
        .catch(() => {
          setError("Error loading boat");
          setLoading(false);
        });
    }, [id]);
    if (loading) return <LoadingIndicator />;
    if (!_.isEmpty(error)) return <Alert color="warning">{error}</Alert>;
    return <WrappedComponent {...props} boat={boat} />;
  };
};

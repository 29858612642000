import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Fa from "~/util/fa";
import { binnacleClient } from "~util/api";

export default function DeleteBtnModal({ workOrderId, ...props }) {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const deleteWorkOrder = async () => {
    await binnacleClient
      .patch(`/work-orders/${workOrderId}/`, { status: 'dismissed' })
      .then(() => {
        window.location = '/'
      })
      .catch(err => {
        const { data, status } = _.get(err, "response", {});
        console.log("Error deleting work order", data, status)
      });
  }

  return (
    <>
      <Button color="danger" className="mr-2" outline onClick={toggle}>
        <Fa icon="trash" className="mr-2" />
        Delete
      </Button>
      <Modal isOpen={modal} toggle={toggle} {...props}>
      <ModalHeader toggle={toggle}>Delete Work Order</ModalHeader>
      <ModalBody>
        <p><strong>Are you sure you want to delete this work order?</strong></p>
        <p>This cannot be undone.</p>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" outline onClick={toggle}>
          Cancel
        </Button>
        <Button color="danger" onClick={deleteWorkOrder}>
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  </>
  )
}


import React, { useState } from "react";
import { Modal, ModalHeader, Button } from "reactstrap";

function BoatModal({ setModal, modal, images, imageIndex, setImageIndex }) {
  const toggle = () => setModal(!modal);
  // const [activeImage, setActiveImage] = useState(0);

  const handleChangeImage = nextImage => {
    const totalImages = images.length;
    if (nextImage === totalImages) {
      setImageIndex(0);
    } else if (nextImage < 0) {
      setImageIndex(totalImages - 1);
    } else {
      setImageIndex(nextImage);
    }
  };
  const currentImage = () => {
    try {
      return images?.[imageIndex]?.url;
    } catch (error) {
      console.error("Error:", error);
      return "";
    }
  };
  return (
    <>
      <Modal size="xl" isOpen={modal} toggle={toggle}>
        <ModalHeader
          cssModule={{
            "modal-header":
              "pt-1 px-3 d-flex justify-content-end align-items center modal-z-index-fix"
          }}
          toggle={toggle}
        ></ModalHeader>
        <div className="modal-body pt-0 pb-3 px-3">
          <div className="d-flex flex-row">
            <img
              style={{
                width: "100%",
                maxHeight: "100vh",
                margin: "-113px 0",
                padding: "113px 0",
                objectFit: "scale-down"
              }}
              src={currentImage()}
              className="rounded"
            />
          </div>
          {images.length > 1 ? (
            <div className="boat-image-modal-arrow-overlay">
              <Button
                size="sm"
                className="m-3 rounded-circle"
                cssModule={{ "btn-secondary": "btn-grey-100" }}
                color="secondary"
                onClick={event => {
                  event.stopPropagation();
                  handleChangeImage(imageIndex - 1);
                }}
              >
                <i className="fas fa-arrow-left"></i>{" "}
              </Button>
              <Button
                size="sm"
                className="m-3 rounded-circle"
                cssModule={{ "btn-secondary": "btn-grey-100" }}
                color="secondary"
                onClick={event => {
                  event.stopPropagation();
                  handleChangeImage(imageIndex + 1);
                }}
              >
                <i className="fas fa-arrow-right"></i>{" "}
              </Button>
            </div>
          ) : null}
        </div>
      </Modal>
    </>
  );
}

export default BoatModal;

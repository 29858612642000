import React, { useLayoutEffect, useState, useEffect } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const DateInputBasic = ({
  fieldState: { value },
  fieldApi: { setTouched, setValue },
  ...props
}) => {
  const selected = _.isEmpty(value) ? null : moment(value).toDate();
  const [index, setIndex] = useState(props?.index || 0);
  const [estimatedOrActual, setEstimatedOrActual] = useState(
    props?.estimatedOrActual || ""
  );
  const [closestDate, setClosestDate] = useState("");
  const openToDate = date => {
    if (date) {
      return new Date(date);
    } else {
      return new Date();
    }
  };

  const datePickerProps = {
    onFocus: () => setTouched(true),
    onChange: date => setValue(moment(date).toISOString()),

    selected,
    openToDate: openToDate(closestDate),
    ...props,
    // replace className with classNameOverride below to avoid conflict with other form-control classes
    ...{ className: `form-control datepicker-${estimatedOrActual}` }
  };

  /* 
  This function checks to see if the fields above it are empty or not
  If any of the fields above it are not empty, it sets the date to the value of the field above it.
  This is done so the user can select a date close to the value in the fields above it.
  */
  const determineClosestDate = () => {
    // loop backwards through the indices until index 0 or until a value is found
    let i = index;
    while (i >= 0) {
      // get the value of the field at current value of the i in the loop
      const tempDate = document
        ?.getElementById(`phase-row-${i}`)
        ?.querySelector(`.datepicker-${estimatedOrActual}`)?.value;

      // if date is found, set the date and break the loop
      if (tempDate) {
        const isoDate = moment(tempDate).toISOString();
        setClosestDate(isoDate);
        break;
      }
      i--;
    }
  };
  // Use layout effect to call the function to determine the closest date.
  // Used this because it is called when the DOM is updated.
  useLayoutEffect(() => {
    determineClosestDate();
  });

  return (
    <>
      <DatePicker {...datePickerProps} />
    </>
  );
};

DateInputBasic.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  options: PropTypes.object,
  dateFormat: PropTypes.string
};

DateInputBasic.defaultProps = {
  label: "",
  type: "text",
  options: {},
  fieldState: {},
  id: null,
  dateFormat: "yyyy-MM-dd"
};

export default DateInputBasic;

import axios from "axios";
import { binnacleClient, boatsClient } from "./index";
import { attachRequestCallback, attachResponseCallback } from "./interceptors";
import { binnacleStore } from "~/util/store";

const calculatePercentage = (loaded, total, e) =>
  Math.floor(loaded * 1.0) / total;

let requestsCounter = 0;

const incrementCounter = () => {
  requestsCounter++;
  binnacleStore.requestProgress = 20;
};

const update = e =>
  (binnacleStore.requestProgress = calculatePercentage(e.loaded, e.total, e));

const responseFunc = response => {
  if (--requestsCounter === 0) {
    binnacleStore.requestProgress = 100;
  }
  return response;
};

const errorFunc = error => {
  if (--requestsCounter === 0) {
    binnacleStore.requestProgress = 100;
  }

  if (axios.isCancel(error)) {
    return new Promise(() => {});
  }

  return Promise.reject(error);
};

_.each([binnacleClient, boatsClient], client => {
  attachRequestCallback(client, incrementCounter);
  client.defaults.onDownloadProgress = update;
  client.defaults.onUploadProgress = update;
  attachResponseCallback(client, responseFunc, errorFunc);
});

import React from 'react';
import { Container, Row, Col } from 'reactstrap';

// This should only display on active work orders in Draft status

const priceDigestAttribution = ({ workOrderStatus }) =>
  <Container className="py-3">
    <Row>
      <Col>
        <a href="https://pricedigests.com/" target="_blank">
          {workOrderStatus === "draft" && (
            <img
              src={require("~/images/logo_price_digest.svg")}
              alt="Price Digest"
              className="float-right mt-3"
              style={{ maxWidth: "150px" }}
            />
          )}
        </a>
      </Col>
    </Row>
  </Container>

  export default priceDigestAttribution;
import React from "react";
import styled from "styled-components";
import { Badge, Button } from "reactstrap";
import { binnacleClient } from "~/util/api";

import withFacility from "~/util/api/withFacility";
import LinkDuo from "~/util/linkDuo";
import StatusPill from "~/components/StatusPill";
import KindIcon from "~/components/dashboard/KindIcon";
import CardPhaseBox from "~/components/dashboard/CardPhaseBox";
import Fa from "~/util/fa";
import ExternalLink from "~/util/ExternalLink";
import EmailPreview from "~/components/emailPreview/EmailPreview";
import ApproveButton from "~/components/approve/ApproveButton";
import QuickBooksStatusIcon from "~/components/dashboard/QuickBooksStatusIcon";
import BoatCard from "~/components/dashboard/cards/BoatCard";
import FacilityCard from "~/components/dashboard/cards/FacilityCard";
import ProfileCard from "~/components/dashboard/cards/ProfileCard";
import WorkOrderCard from "~/components/dashboard/cards/WorkOrderCard";
import { useDashboardStore } from "~/store";
import { shallow } from "zustand";

// import getDifferenceInDays from '~/util/dateCalcFunctions.js';

const StyledEmailButton = styled.button`
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin-left: 1rem;

  i {
    color: rgba(0, 0, 0, 0.4);
    position: relative;

    ${p =>
      p.hasBadge
        ? `
          &::after {
            content: "";
            height: 10px;
            width: 10px;
            background-color: #c9241a;
            position: absolute;
            display: block;
            right: -5px;
            top: -3px;
            border-radius: 50%;
            border: 1px solid #fff;
          }
        `
        : ``}
  }
`;

export const ViewButton = ({ link }) => (
  <LinkDuo to={link} className="w-100">
    <Button
      data-testid="search-card-view-button"
      className={`item-card__action-button w-100`}
      color="primary"
      size="sm"
    >
      View
    </Button>
  </LinkDuo>
);

export const CompleteButton = ({
  updateItem,
  id,
  className,
  workOrder,
  userProfile,
  link
}) => {
  const onClick = e => {
    e.preventDefault();
    e.stopPropagation();

    // GTM-event
    window.dataLayer.push({
      event: "searchDeliverJob",
      eventProps: {
        category: "search_actions",
        action: "deliver_job",
        // label will be set in GTM as page hostname
        workOrderId: id,
        userId: userProfile?.id,
        userName: userProfile?.name,
        facilityId: userProfile?.facility_id,
        boatId: workOrder?.boat?.id,
        boatName: workOrder?.boat?.name,
        ownerId: workOrder?.owner_profile?.id,
        ownerName: workOrder?.owner_profile?.name
      }
    });

    console.log(window.dataLayer);

    binnacleClient
      .patch(`/work-orders/${id}/`, { status: "complete" })
      .then(({ data }) => updateItem(data));
  };
  return (
    <Button className={className} color="success" size="md" onClick={onClick}>
      <Fa icon="check" /> Deliver Job
    </Button>
  );
};

export const CheckButton = ({ updateItem, id, className, link }) => {
  const onCheckChange = e => {
    e.preventDefault();
    e.stopPropagation();
    // binnacleClient
    //   .patch(`/work-orders/${id}/`, { status: "complete" })
    //   .then(({ data }) => updateItem(data));
  };
  return (
    <Button className={className} color="success" size="md" onClick={onClick}>
      <Fa icon="check" /> Deliver Job
    </Button>
  );
};

export const DismissButton = ({ updateItem, id }) => {
  const onClick = e => {
    e.preventDefault();
    e.stopPropagation();
    binnacleClient
      .patch(`/work-orders/${id}/`, { status: "dismissed" })
      .then(({ data }) => updateItem(data));
  };
  return (
    <Button
      className={`item-card__action-button btn btn-outline-primary`}
      color="primary"
      outline
      size="sm"
      onClick={onClick}
    >
      <Fa icon="check" /> Dismiss
    </Button>
  );
};

const phaseDisplay = (
  id,
  status,
  currentPhase,
  phasesData,
  updateItem,
  link,
  workOrder,
  facility,
  userProfile
) => {
  // if (status === 'complete') return <Badge pill color="success" className="mt-2"><Fa icon="check" /> Work Complete</Badge>;
  if (
    status === "approved" &&
    _.every(phasesData, phase => !phase.date_estimated || phase.date_completed)
  ) {
    return (
      <CompleteButton
        className="w-100"
        {...{ updateItem, userProfile, workOrder, id, link }}
      />
    );
  } else if (status === "approved" && currentPhase) {
    return (
      <CardPhaseBox
        {...{
          currentPhase,
          id,
          phasesData,
          updateItem,
          workOrderId: workOrder.id,
          boat: workOrder.boat,
          owner: workOrder.owner_profile,
          userProfile,
          link
        }}
        key={`phase-box-${id}`}
      />
    );
  } else if (status === "draft") {
    const handleSubmitQuote = (e, workOrder, userProfile, data) => {
      const { id } = workOrder;

      const eventName =
        workOrder?.parent && !!workOrder?.parent
          ? "searchSubmitChangeOrder"
          : "searchSubmitEstimate";
      const actionName =
        workOrder?.parent && !!workOrder?.parent
          ? "submit_change_order"
          : "submit_estimate";

      // GTM-event
      window.dataLayer.push({
        event: eventName,
        eventProps: {
          category: "search_actions",
          action: actionName,
          // label will be set in GTM as page hostname
          workOrderId: id,
          userId: userProfile?.id,
          userName: userProfile?.name,
          facilityId: userProfile?.facility_id,
          boatId: workOrder?.boat?.id,
          boatName: workOrder?.boat?.name,
          ownerId: workOrder?.owner_profile?.id,
          ownerName: workOrder?.owner_profile?.name
        }
      });

      console.log(window.dataLayer);

      e.preventDefault();
      e.stopPropagation();
      binnacleClient
        .patch(`/work-orders/${id}/`, { status: "pending", email: { ...data } })
        .then(({ data }) => updateItem(data));
    };

    return (
      <>
        <EmailPreview
          type={"quote_approval"}
          workOrder={workOrder}
          boatPrimaryImage={workOrder?.boat?.images?.find(
            image => image?.is_primary
          )}
          currentPhaseSchedule={workOrder.phases}
          // dont pass sendEmail prop from here because that executes email send in EmailPreview for work updates
          renderSubmitButton={({ disabled, id, getRef, data, closeModal }) => {
            return (
              <Button
                disabled={disabled}
                id={`item-submit-draft-${id}`}
                className="ml-1 w-100 d-block d-md-auto w-md-auto"
                color="primary"
                ref={ref => getRef(ref)}
                onClick={e =>
                  handleSubmitQuote(e, workOrder, userProfile, data)
                }
              >
                <Fa icon="paper-plane" className="mr-2" />
                Preview and Send Estimate
              </Button>
            );
          }}
          renderModalButton={({ onShowModal }) => (
            <Button
              className="item-card__action-button primary w-100"
              color="primary"
              size="sm"
              onClick={onShowModal}
            >
              <Fa className="mr-2" icon="paper-plane" />
              Preview and Send Estimate
            </Button>
          )}
        />
      </>
    );
  } else if (status === "rejected") {
    return (
      <>
        <LinkDuo to={link} className="w-100">
          <button
            className={`item-card__action-button btn btn-primary mr-2`}
            // color="primary"
            size="sm"
          >
            Review
          </button>
        </LinkDuo>
        <LinkDuo to={link}>
          <DismissButton updateItem={updateItem} id={id} />
        </LinkDuo>
      </>
    );
  } else if (status === "pending") {
    return (
      <ApproveButton
        id={workOrder.id}
        noun="Estimate"
        isPublic={false}
        icon="check"
        onCompleteRequest={data => {
          if (data) {
            updateItem(data);
          } else {
            window.location = `/`;
          }
        }}
        url={`/work-orders/${workOrder.id}/`}
        data={{
          phases: phasesData,
          facility_id: facility.id,
          status: "approved"
        }}
        method="PATCH"
        label="Mark Approved"
        workOrder={workOrder}
        userProfile={userProfile}
        className="w-100"
        eventName="searchWorkOrderApprove"
      />
    );
  } else {
    return <ViewButton link={link} />;
  }
};

const cardVariants = {
  workorder: ({
    facility,
    extra: workOrder,
    link,
    updateItem,
    hasUnreadNote,
    userProfile,
    ...props
  }) => {
    const {
      id,
      status,
      current_phase,
      status_date,
      phases,
      boat: { name, make, model, year },
      owner_profile,
      representative_profile
    } = workOrder;
    const woNotes = _.get(workOrder, "work_order_notes") || [];
    // const unreadNotes = woNotes.filter(note => !note.read); // changing this to single flag on wo object

    const unreadEmailLink = {
      // https://stackoverflow.com/questions/30115324/pass-props-in-link-react-router
      // remove trailing slash and add card id for anchor when clicking email icon
      pathname: `${link.slice(0, -1)}#project-activity-card`,
      projectActivityOpen: true
    };
    const updatedProps = {
      link,
      ...workOrder,
      ...props,
      woNotes,
      unreadEmailLink,
      hasUnreadNote
    };

    return (
      <>
        <WorkOrderCard {...updatedProps}>
          <div
            data-testid="work-order-card-secondary-content"
            className="d-flex flex-even justify-content-lg-end align-items-center w-100"
          >
            {phaseDisplay(
              id,
              status,
              current_phase,
              phases,
              updateItem,
              link,
              workOrder,
              facility,
              userProfile
            )}
          </div>
        </WorkOrderCard>
      </>
    );
  },
  boat: props => (
    <>
      <BoatCard {...props} />
    </>
  ),

  profile: props => (
    <>
      <ProfileCard {...props} />
    </>
  ),

  facility: props => (
    <>
      <FacilityCard {...props} />
    </>
  ),

  default: ({ body = "" }) => <>{body}</>
};

const NewItemCard = props => {
  const {
    kind,
    extra,
    link,
    updateItem,
    has_unread_note: hasUnreadNote,
    userProfile,
    facility
  } = props;

  // State
  const {
    selectedItems,
    addSelectedItem,
    removeSelectedItem,
    qbSelectionActive: qbSelectActive
  } = useDashboardStore(
    state => ({
      selectedItems: state.selectedItems,
      addSelectedItem: state.addSelectedItem,
      removeSelectedItem: state.removeSelectedItem,
      qbSelectionActive: state.qbSelectionActive
    }),
    shallow
  );

  const CardBody = _.get(cardVariants, kind, cardVariants.default);
  // Add ? to make sure search doesn't break
  const itemId = link?.split("/")[2] || null;
  // console.log("itemId", itemId, props);
  if (!kind || !link) return null;

  const onCheckChange = e => {
    if (e.target.checked) {
      addSelectedItem(itemId);
    } else {
      removeSelectedItem(itemId);
    }
  };

  const invoiceIsReady = () => {
    const { status } = extra;
    const billableStatuses = ["approved", "complete", "replaced"];
    return billableStatuses.includes(status);
  };

  const boatImage = extra => {
    console.log("extra", extra);
    // filter primary image
    const primaryImage = extra.images.find(image => image.is_primary === true);
    console.log("primaryImage", primaryImage);
    return (
      <div
        data-testid="card-thumbnail"
        className={`d-flex bg-secondary rounded-circle justify-content-center align-items-center p-1 card-image-outer`}
        style={{ height: "44px", width: "44px" }}
      >
        <img
          src={primaryImage.thumbnail}
          alt={extra.name}
          data-testid="card-thumbnail-image"
          className="rounded-circle img-fluid card-image-inner"
          style={{ height: "44px", width: "44px" }}
        />
      </div>
    );
  };

  return (
    <div
      className={`d-flex mb-4 ${
        kind === "workorder" ? "custom-card-workorder" : "custom-card"
      }`}
    >
      {qbSelectActive && kind === "workorder" && invoiceIsReady() && (
        <div className="d-flex flex-wrap align-items-start mt-3">
          <div className="custom-control custom-checkbox">
            <input
              onChange={onCheckChange}
              type="checkbox"
              className="custom-control-input"
              checked={selectedItems.includes(itemId)}
              id={`item${itemId}`}
            />
            <label
              className="custom-control-label"
              htmlFor={`item${itemId}`}
            ></label>
          </div>
        </div>
      )}
      <div className="new-item-card mb-0 py-1 px-0 media w-100 align-items-start">
        {/* checkbox */}

        <div
          data-testid={`search-card-${kind}`}
          className={`d-flex align-items-start w-100 ${
            kind === "workorder" ? "align-self-stretch" : ""
          }`}
        >
          <CardBody
            extra={extra}
            link={link}
            updateItem={updateItem}
            hasUnreadNote={hasUnreadNote}
            facility={facility}
            userProfile={userProfile}
          />
        </div>
      </div>
    </div>
  );
};

// possibly refactor this to remove the need for the withFacility HOC
export default withFacility(NewItemCard);

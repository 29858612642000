import React from "react";
import { Table } from "reactstrap";

import ExternalLink from "~/util/ExternalLink";

export const ProfileDisplay = ({ workOrder }) => {
  // const { owner_profile: owner, representative_profile: rep } = workOrder;
  const { owner_profile, representative_profile } = workOrder;

  // if (_.isEmpty(created_profile) && _.isEmpty(updated_profile)) {
  //   return null;
  // }

  // I beg forgiveness for the putrid nastiness that is the below nested ternary operators -- Greg

  const badgeStyles = {
    borderRadius: "7px",
    color: "#004E2C",
    backgroundColor: "#EBFFEB",
    fontSize: "13px",
    marginLeft: "1rem",
    display: "inline-block",
    padding: "4px 5px"
  };

  const PrimaryBadge = () => {
    return (
      <div style={badgeStyles} data-testid="badge-primary-contact">
        Primary Contact
      </div>
    );
  };

  const isPrimaryContact = contact => {
    if (contact && contact.is_primary_contact === true) {
      return true;
    }
  };

  const fallback = <p style={{ marginBottom: 0 }}>&nbsp;</p>;

  return (
    <div className="mb-5 mt-4">
      <Table striped>
        <thead>
          <tr>
            <th style={{ border: "none" }}>
              Owner {isPrimaryContact(owner_profile) ? <PrimaryBadge /> : ""}
            </th>
            <th style={{ border: "none" }}>
              Representative{" "}
              {isPrimaryContact(representative_profile) ? <PrimaryBadge /> : ""}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="py-2" data-testid="owner-name">
              {owner_profile
                ? owner_profile.name
                  ? owner_profile.name
                  : fallback
                : fallback}
            </td>
            <td className="py-2" data-testid="rep-name">
              {representative_profile && representative_profile.name
                ? representative_profile.name
                : fallback}
            </td>
          </tr>
          <tr>
            <td className="py-2" data-testid="owner-email">
              {owner_profile ? (
                <ExternalLink
                  type="email"
                  useIcon={false}
                  link={owner_profile.email ? owner_profile.email : undefined}
                />
              ) : (
                fallback
              )}
            </td>
            <td data-testid="rep-email">
              {representative_profile ? (
                <ExternalLink
                  type="email"
                  useIcon={false}
                  link={
                    representative_profile.email
                      ? representative_profile.email
                      : undefined
                  }
                />
              ) : (
                fallback
              )}
            </td>
          </tr>
          <tr>
            <td className="py-2" data-testid="owner-phone">
              {owner_profile ? (
                owner_profile.phone ? (
                  <ExternalLink
                    style={{ textDecoration: "underline" }}
                    type="phone"
                    useIcon={false}
                    link={owner_profile.phone}
                  />
                ) : (
                  ""
                )
              ) : (
                fallback
              )}
            </td>
            <td className="py-2" data-testid="rep-phone">
              {representative_profile ? (
                representative_profile.phone ? (
                  <ExternalLink
                    style={{ textDecoration: "underline" }}
                    type="phone"
                    useIcon={false}
                    link={representative_profile.phone}
                  />
                ) : (
                  ""
                )
              ) : (
                fallback
              )}
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

ProfileDisplay.defaultProps = {
  owner_profile: {},
  representative_profile: {}
};

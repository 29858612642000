import axios from "axios";
import { binnacleStore } from "~/util/store";
import { each } from "lodash";
import attachInterceptors, { getAccessToken } from "./interceptors";

/**
 * Calls the onComplete callback with a boolean indicating whether or not we are logged in.
 */

export const isLoggedIn = async () => !_.isEmpty(await getAccessToken());

const axiosApiClient = axios.create({ baseURL: "/api/" });
attachInterceptors(axiosApiClient);
export const binnacleClient = axiosApiClient;
export const publicApiClient = axios.create({ baseURL: "/api/" });

const axiosBoatsClient = axios.create({ baseURL: "/boats/" });
attachInterceptors(axiosBoatsClient);
export const boatsClient = axiosBoatsClient;

window.binnacleClient = binnacleClient;
window.boatsClient = boatsClient;

import React from "react";
import { Form } from "informed";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

import { boatsClient } from "~/util/api";
import SelectEditCreateForm from "./SelectEditCreateForm";
import Fa from "~/util/fa";
import { FormStatePre } from "~/util/DevPre";
import withFacility from "~/util/api/withFacility";
import withBoatyard from "~/util/api/withBoatyard";

export default withBoatyard(
  withFacility(({ boat = {}, isNew = true, facility, boatyard }) => {
    const backLink = boat.id ? `/boat/${boat.id}/` : "/";

    const formProps = {
      onSubmit: boat => {
        let request;

        if (boat.id) {
          request = boatsClient.put(`/boats/${boat.id}/`, boat);
        } else {
          let data = {
            ...boat,
            facility_ids: [facility.id],
            company_ids: [boatyard.id]
          };
          request = boatsClient.post("/boats/", data);
        }

        request
          .then(() => {
            window.location = "/";
          })
          .catch(console.log);
      }
    };

    return (
      <Form {...formProps} initialValues={boat}>
        <h2>{isNew ? "New" : "Edit"} Boat</h2>
        <SelectEditCreateForm nameIsSelectable={false} scope={false} />
        <Button color="primary" type="submit">
          <Fa icon="save" className="mr-2" /> Save
        </Button>
        <Link to="/" className="btn btn-outline-secondary ml-3">
          Cancel
        </Link>
        <FormStatePre />
      </Form>
    );
  })
);

import "./config";

import ReactDOM from "react-dom";
import React from "react";

import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-MVGTB7J"
};

TagManager.initialize(tagManagerArgs);

require("~/styles/index.scss");

import App from "./Routes";

ReactDOM.render(<App />, document.getElementById("app"));

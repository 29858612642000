/// have to do this weird thing here because `require()` cannot be used with dynamic variables

const images = {
  worthwhile_logo: require("~/images/boatyard_logo_worthwhile.png"),
  dys_logo: require("~/images/boatyard_logo_diversified.png"),
  truenorth_logo: require("~/images/boatyard_logo_true_north.png"),
  rossmarine_logo: require("~/images/boatyard_logo_rossmarine.png"),
  annapolis_logo: require("~/images/boatyard_logo_portannapolis.png"),
  pettit_logo: require("~/images/boatyard_logo_pettit.png"),
  gasparilla_logo: require("~/images/boatyard_logo_gasparilla.png")
};

export default images;

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Row, Button } from "reactstrap";
import moment from "moment";

import { BootstrapTextArea } from "~/util/autoform/bootstrap";
import Fa from "~/util/fa";
import EmailPreview from "~/components/emailPreview/EmailPreview";
import phases from "~/data/phases";
import { LoadingIndicator } from "~/util/loadingWrapper";

export const StyledActivityItem = styled.div`
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 1rem;
  margin-bottom: 1rem;
`;

const StyledRow = styled(Row)`
  margin-bottom: 0.5rem;
  margin-left: 0;
  margin-right: 0;
`;

const StyledActivitySection = styled.span`
  margin-right: 1.5rem;
`;

class ProjectActivity extends React.Component {
  state = {
    currentMessage: "",
    activityItems: []
  };

  componentDidMount() {
    this.setState({ activityItems: this.props.activityItems || [] });
  }

  componentDidUpdate(prevProps) {
    const lastActivity = prevProps.activityItems || [];
    const latestActivity = this.props.activityItems || [];
    if (lastActivity.length !== latestActivity.length) {
      this.setState({ activityItems: latestActivity });
    }
  }

  handleSetNote = currentMessage => {
    this.setState({ currentMessage });
  };

  handleResetNote = () => {
    this.setState({ currentMessage: "" }); // reset
    this.props.formApi.setValue("woComment", "");
  };

  handleGenerateActivity = (noteType = "internal") => {
    const { profile, workOrder } = this.props;

    const nextActivity = {
      work_order_id: workOrder.id,
      creator_profile_id: profile.id,
      note_type: noteType,
      note: this.state.currentMessage,
      date_created: new Date().toJSON()
    };

    // optimistically add the note to the notes list
    this.setState({
      activityItems: [
        ...this.state.activityItems,
        {
          ...nextActivity,
          creator_profile: profile
        }
      ]
    });
    this.handleResetNote();

    return nextActivity;
  };

  getLabelForType = type => {
    const labelMap = {
      internal: "Internal",
      external: "Work Update",
      phase_complete: "Phase Complete",
      status_change: "Status Change",
      customer_reply: "Customer Reply"
    };

    return labelMap[type] || "Update";
  };

  getLabelForPhase = finished_phase => {
    if (!finished_phase) return null;

    return phases[finished_phase] && phases[finished_phase].label;
  };

  render() {
    const { onAddNote, workOrder, currentPhaseSchedule } = this.props;
    const { currentMessage, activityItems } = this.state;

    if (!activityItems) return <LoadingIndicator />;

    return (
      <>
        {activityItems
          // Sort: oldest at the top
          .sort((a, b) => moment(a.date_created).diff(moment(b.date_created)))
          .map(activity => {
            const {
              creator_profile,
              date_created,
              finished_phase,
              note,
              note_type,
              work_order_id // should always match current work order
            } = activity;

            return (
              <StyledActivityItem
                key={`project-activity-${work_order_id}-${date_created}`}
              >
                <StyledRow>
                  <StyledActivitySection>
                    {moment(date_created).format("MM/DD/YY")}
                  </StyledActivitySection>
                  {creator_profile ? (
                    <StyledActivitySection>
                      <strong>{creator_profile.name}</strong>
                    </StyledActivitySection>
                  ) : null}
                  <StyledActivitySection>
                    Type: <strong>{this.getLabelForType(note_type)}</strong>
                  </StyledActivitySection>
                  {finished_phase && this.getLabelForPhase(finished_phase) ? (
                    <StyledActivitySection>
                      Finished Phase:{" "}
                      <strong>{this.getLabelForPhase(finished_phase)}</strong>
                    </StyledActivitySection>
                  ) : null}
                </StyledRow>
                <StyledRow>{note}</StyledRow>
              </StyledActivityItem>
            );
          })}
        <div>
          <BootstrapTextArea
            className="col-lg-8 form-control"
            rows={4}
            field="woComment"
            showLabel={false}
            onChange={event => {
              event.preventDefault();
              event.stopPropagation();
              this.handleSetNote(event.target.value);
            }}
          />
          <div className="d-flex justify-content-end col-lg-8 pr-0">
            <Button
              disabled={!currentMessage}
              className="btn-outline-primary mr-3"
              value="addProjectActivityNote"
              onClick={async event => {
                event.preventDefault();
                event.stopPropagation();

                await onAddNote(this.handleGenerateActivity("internal"));
              }}
              id={`activity-save-note`}
              color=""
            >
              <Fa className="mr-2" icon="plus" />
              Add Note
            </Button>
            <EmailPreview
              type="work_update"
              message={{ defaultMessage: currentMessage }}
              // onUpdateMessage here keeps the text input note in sync with the modal note
              onUpdateMessage={nextNote => {
                this.setState({ currentMessage: nextNote }, () => {
                  this.props.formApi.setValue("woComment", nextNote);
                });
              }}
              renderSubmitButton={({
                disabled,
                id,
                getRef,
                sendEmail,
                closeModal
              }) => (
                <Button
                  disabled={disabled}
                  id={id || `activity-save-send`}
                  className="ml-1"
                  color="primary"
                  ref={ref => getRef(ref)}
                  onClick={async () => {
                    await onAddNote(this.handleGenerateActivity("external"));
                    await sendEmail();
                    closeModal();
                  }}
                >
                  <Fa icon="paper-plane" className="mr-2" />
                  Send Update
                </Button>
              )}
              renderModalButton={({ onShowModal }) => (
                <Button
                  onClick={onShowModal}
                  disabled={!currentMessage}
                  id={`activity-toggle-preview`}
                  color="primary"
                  className="ml-1"
                >
                  <Fa icon="paper-plane" className="mr-2" />
                  Add Note and Update Customer
                </Button>
              )}
              workOrder={workOrder}
              currentPhaseSchedule={currentPhaseSchedule}
            />
            {/* end email preview button and modal */}
          </div>
        </div>
      </>
    );
  }
}

ProjectActivity.propTypes = {
  onAddNote: PropTypes.func,
  activityItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      date_created: PropTypes.string,
      phase_complete: PropTypes.string,
      note_type: PropTypes.string,
      creator_profile: PropTypes.object,
      note: PropTypes.string
    })
  ),
  formApi: PropTypes.object.isRequired
};

export default ProjectActivity;

import axios from "axios";
import React, { Component } from "react";
import { binnacleClient, isLoggedIn } from ".";
import { binnacleStore } from "~/util/store";
import { withAuth } from "./withAuth";
import { get, set, has, autorun } from "mobx";

@withAuth
class WithAPIDataBase extends Component {
  static defaultProps = {
    dummyData: null,
    cache: false,
    skipUnAuth: false,
    ignore404: false
  };

  constructor(props) {
    super(props);
    this.state = {
      data: null,
      loading: true
    };
    this.source = null;
    this.timout = null;
    this.dummyData = this.props.dummyData;
    this.cache = this.props.cache;
    this.checkCache = this.checkCache.bind(this);
    this.checkDummyData = this.checkDummyData.bind(this);
    this.getData = this.getData.bind(this);
  }

  getUrl() {
    let compiled = _.template(this.props.url);
    return compiled(this.props.componentProps || {});
  }

  checkCache() {
    let component = this;
    let url = this.getUrl();

    if (this.cache) {
      autorun(reaction => {
        if (has(binnacleStore.cacheApiData, url)) {
          component.setState({
            loading: false,
            data: get(binnacleStore.cacheApiData, url)
          });
        } else {
          component.getData();
        }
        reaction.dispose();
      });
    } else {
      component.getData();
    }
  }

  checkDummyData() {
    let component = this;

    if (this.dummyData != null) {
      // fake a loading time
      this.timout = setTimeout(function() {
        component.setState({
          loading: false,
          data: component.dummyData
        });
      }, 750);
    } else {
      component.checkCache();
    }
  }

  getData() {
    let component = this;
    let url = this.getUrl();

    const CancelToken = axios.CancelToken;
    this.source = CancelToken.source();

    let requestConfig = {
      cancelToken: this.source.token,
      url: url,
      ignore404: this.props.ignore404
    };

    binnacleClient(requestConfig)
      .then(response => {
        if (this.cache) {
          set(binnacleStore.cacheApiData, url, response.data);
        }

        component.setState({
          loading: false,
          data: response.data
        });
      })
      .catch(error => {
        if (error.response.status === 404) {
          component.setState({
            loading: false,
            data: null
          });
        }
      });
  }

  checkUnAuth() {
    let component = this;

    if (this.props.skipUnAuth) {
      isLoggedIn().then(function(loggedIn) {
        if (!loggedIn) {
          component.setState({
            loading: false,
            data: null
          });
        } else {
          component.checkDummyData();
        }
      });
    } else {
      component.checkDummyData();
    }
  }

  componentDidMount() {
    this.checkUnAuth();
  }

  componentWillUnmount() {
    if (this.source != null) {
      this.source.cancel();
    } else if (this.timeout != null) {
      clearTimeout(this.timeout);
    }
  }

  render() {
    return this.props.children(this.state.data, this.state.loading);
  }
}

export const WithAPIData = WithAPIDataBase;

export function withAPIData(
  WrappedComponent,
  url,
  dummyData = null,
  cache = false,
  skipUnAuth = false
) {
  return @withAuth
  class extends Component {
    render() {
      return (
        <WithAPIData
          url={url}
          dummyData={dummyData}
          componentProps={this.props}
          cache={cache}
          skipUnAuth={skipUnAuth}
        >
          {(data, loading) => (
            <WrappedComponent data={data} loading={loading} {...this.props} />
          )}
        </WithAPIData>
      );
    }
  };
}

import React from "react";
import { Table } from "reactstrap";
import moment from "moment";

import phases from "~/data/phases";
import { shouldShowActualCompletionDates } from "~/util/util";

const PhaseRow = ({ phase, estimate, completed, status }) => {
  return (
    <tr>
      <th>{phase.label}</th>
      <td>{estimate && estimate.format("l")}</td>
      <td>{completed && completed.format("l")}</td>
    </tr>
  );
};

// Used on Approval views, Email previews (from Email/PreviewSchedule)
// Print views, and completed/changed Work Orders

// needs to return one column for estimated dates and two columns if in approved, completed, or changed status

export function ScheduleDisplay({
  phases: schedule = [],
  renderTableHeader,
  status
}) {
  const rowProps = ({ phase, date_estimated, date_completed }) => ({
    key: `phase-row-${phase}`,
    phase: phases[phase] || {},
    estimate: date_estimated ? moment(date_estimated) : false,
    completed: date_completed ? moment(date_completed) : false
  });
  return (
    <div className="mb-3">
      {renderTableHeader ? null : (
        <h3 style={{ marginLeft: "12px" }}>Schedule</h3>
      )}
      <Table striped>
        {renderTableHeader ? renderTableHeader() : null}
        <tbody>
          {schedule.map(phase => (
            <PhaseRow {...rowProps(phase)} status={status} />
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export function DataSheetsDisplay({ data_sheets: data_sheets }) {
  return (
    <div className="mb-3">
      <h3 style={{ marginLeft: "12px" }}>Data Sheets</h3>
      <Table striped>
        <tbody>
          {data_sheets.map((data_sheet, index) => (
            <tr key={`data_sheet-row-${index}`}>
              <td>
                <a href={data_sheet[0]}>{data_sheet[1]}</a>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

const eqlChng = obj =>
  _.has(obj, "equal__") ? [obj.equal__, undefined] : _.get(obj, "changed__");
const PhaseRowDiff = ({ phase, e1, e2 }) => {
  const firstTDStyle = e2
    ? { textDecoration: "line-through", color: "#999" }
    : {};
  return (
    <tr>
      <th>{phase.label}</th>
      <td style={firstTDStyle}>{e1 && e1.format("YYYY-MM-DD")}</td>
      <td>{e2 && e2.format("YYYY-MM-DD")}</td>
    </tr>
  );
};

export function ScheduleDisplayDiff({ phases: schedule }) {
  if (!schedule) return null;

  const rowProps = ({ phase, date_estimated }) => {
    const [e1, e2] = eqlChng(date_estimated || {});
    return {
      key: `phase-row-${phase}`,
      phase: phases[phase] || {},
      e1: e1 ? moment(e1) : false,
      e2: e2 ? moment(e2) : false
    };
  };

  return (
    <div className="mb-3">
      <h3 style={{ marginLeft: "12px" }}>Schedule</h3>
      <Table striped>
        <tbody>
          {schedule.map(({ phase, date_estimated }) => {
            // the diff view expects the date_estimated field to be an object that looks like this:
            //   { "__equal": ["date1", "date2"] }
            if (typeof date_estimated !== "object" || date_estimated === null)
              return null;

            return <PhaseRowDiff {...rowProps({ phase, date_estimated })} />;
          })}
        </tbody>
      </Table>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { useDashboardStore } from "~/store";
import { shallow } from "zustand";

// There is a bug where when it can't scroll to the bottom of the page sometimes to the last or second to last element items.
// Need to figure out workaround for this like detecting this and in these scenarios just scroll to the top of the page.

const NavigateIds = () => {
  const ids = ["workorder", "boat", "profile", "facility"];

  const headerElement = document.getElementById("header");
  const [atTheBottomOfPage, setAtTheBottomOfPage] = useState(false);
  const [atTheTopOfPage, setAtTheTopOfPage] = useState(true);
  const [currentId, setCurrentId] = useState(ids[0]);

  const { selectedKinds } = useDashboardStore(
    state => ({
      selectedKinds: state.selectedKinds
    }),
    shallow
  );
  // first selected kind
  const firstSelectedKind = selectedKinds[0];
  // last selected kind
  const lastSelectedKind = selectedKinds[selectedKinds.length - 1];

  useEffect(() => {
    console.log("set current id", currentId);

    const handleScroll = () => {
      const y = window.scrollY;
      const closestId = selectedKinds.reduce(
        (acc, id) => {
          const element = document.getElementById(id);
          if (!element) {
            return acc;
          }
          const rect = element.getBoundingClientRect();
          if (rect.top >= 0 && rect.top < acc.distance) {
            return { id, distance: rect.top };
          }
          if (rect.bottom <= 0 && Math.abs(rect.bottom) < acc.distance) {
            return { id, distance: Math.abs(rect.bottom) };
          }
          return acc;
        },
        { id: null, distance: Infinity }
      );
      setCurrentId(closestId.id);

      // detect if at the top of the page
      if (y < 100) {
        setAtTheTopOfPage(true);
      } else {
        setAtTheTopOfPage(false);
      }

      // detect if at the bottom of the page
      const body = document.body;
      const html = document.documentElement;
      const height = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );
      if (y + window.innerHeight >= height - 100) {
        setAtTheBottomOfPage(true);
      } else {
        setAtTheBottomOfPage(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClick = direction => {
    const currentIndex = ids.indexOf(currentId);
    let nextIndex;
    if (direction === "up") {
      nextIndex = currentIndex - 1;
      if (nextIndex < 0) {
        nextIndex = 0;
      }
    } else {
      nextIndex = currentIndex + 1;
      if (nextIndex >= ids.length) {
        nextIndex = 0;
      }
    }
    const element = document.getElementById(ids[nextIndex]);
    if (element) {
      const headerHeight = headerElement ? headerElement.offsetHeight : 0;
      const elementTop = element.offsetTop - headerHeight;
      window.scrollTo({
        top: elementTop,
        left: 0,
        behavior: "smooth"
      });
    }
  };

  return (
    <>
      <div
        style={{
          position: "fixed",
          left: "1%",
          bottom: "1%",
          display: "flex",
          flexDirection: "column"
        }}
      >
        <button
          onClick={() => handleClick("up")}
          disabled={currentId === firstSelectedKind && atTheTopOfPage}
          className="btn shadow rounded-circle btn-primary my-2 border-0"
          style={{ backgroundColor: "#DCDCDC" }}
        >
          <i className="fa fa-arrow-up"></i>
        </button>
        <button
          onClick={() => handleClick("down")}
          disabled={currentId === lastSelectedKind || atTheBottomOfPage}
          className="btn shadow rounded-circle btn-primary my-2 border-0"
          style={{ backgroundColor: "#DCDCDC" }}
        >
          <i className="fa fa-arrow-down"></i>
        </button>
      </div>
    </>
  );
};

export default NavigateIds;

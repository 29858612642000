import React from "react";
import PropTypes from "prop-types";
import { Container, Card, Alert } from "reactstrap";
import { parse as qsParse } from "query-string";

import { WorkOrderBase } from "~/views/WorkOrder";
import { LoadingIndicator } from "~/util/loadingWrapper";
import withFacility from "~/util/api/withFacility";
import {
  ApproveMain,
  ApproveDecline,
  ApproveSuccess,
  ApproveChange,
  nounLabels
} from "~/components/approve";
import { prettyAddress } from "~/components/emailPreview/utils";
import images from "~/images";
import { publicApiClient } from "~/util/api";

export class Approve extends WorkOrderBase {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      loading: true,
      workOrder: {},
      view: "main",
      viewOptions: {}
    };
  }

  handleSetView = (nextView, viewOptions = {}) => () => {
    this.setState({ view: nextView || "main", viewOptions });
  };

  fetchWO = () => {
    const { customUrl, url } = this.props;

    const id = _.get(this.props, "match.params.id");
    const requestUrl = (customUrl || url).replace("${ id }", id);

    // to test this view visit localhost:3000/approve/quote/{workOrderId}/?access_token={bearerToken}
    this.token = qsParse(_.get(this.props, "location.search")).access_token;
    publicApiClient
      .get(requestUrl, {
        headers: { Authorization: `Bearer ${this.token}` }
      })
      .then(({ data }) => this.setState({ workOrder: data, loading: false }))
      .catch(error => {
        if (error.response.status === 403) {
          window.location = "/";
        }
        this.setState({ loading: false, error: true });
      });
    return null;
  };

  // b/c this component extends WorkOrder
  renderWO = () => {
    return null;
  };

  renderMain = () => {
    const { workOrder } = this.state;
    const ownerOrRep = this.getOwnerOrRep();

    return (
      <ApproveMain
        noun={this.props.noun}
        token={this.token}
        handleSetView={this.handleSetView}
        workOrder={workOrder}
        facility={this.props.facility}
        ownerOrRep={ownerOrRep}
      />
    );
  };

  renderDecline = () => {
    return (
      <ApproveDecline
        noun={this.props.noun}
        token={this.token}
        handleSetView={this.handleSetView}
        workOrder={this.state.workOrder}
      />
    );
  };

  renderRequestChange = () => {
    return (
      <ApproveChange
        noun={this.props.noun}
        token={this.token}
        handleSetView={this.handleSetView}
        workOrder={this.state.workOrder}
      />
    );
  };

  renderSuccess = () => {
    return (
      <ApproveSuccess
        viewOptions={this.state.viewOptions}
        noun={this.props.noun}
        facility={this.props.facility}
      />
    );
  };

  render() {
    const { facility, skipLoad } = this.props;
    const { loading, error, view } = this.state;

    document.title = "Pettit Pro Portal | Approve";

    if (loading && !skipLoad) return <LoadingIndicator />;
    if (error)
      return (
        <Alert color="danger">
          There was an error loading the{" "}
          {_.lowerCase(nounLabels[this.props.noun])}. Please contact your
          representative.
        </Alert>
      );

    const content =
      view === "decline"
        ? this.renderDecline
        : view === "requestChange"
        ? this.renderRequestChange
        : view === "success"
        ? this.renderSuccess
        : this.renderMain;

    return (
      <Container
        data-testid="card-approve-container"
        fluid
        className="pt-5 pb-3" 
        style={{
          maxWidth: '1200px'
        }}
        >
        <Card
          className="p-2 p-lg-5"
          style={{
            width: "100%",
            // border: "none",
            boxShadow: "0 1rem 3rem rgba(0, 0, 0, 0.175)"
          }}
        >
          <div data-testid="approve-page-header" className="page-header">
            {/*<NavBar className="bg-white" />*/}
            <div className="d-flex pt-2 align-items-center flex-column flex-md-row justify-content-center justify-content-md-between flex-wrap">
              <div className="d-flex align-items-center">
                <img 
                  data-testid="facility-logo"
                  src={images[WL.logo]}
                  className="m-lg-0 mb-3"
                  alt={WL.title} 
                  style={{
                    width: '100%', 
                    maxWidth: '200px', 
                    height: 'auto' 
                  }} />
                {/* <h3 className="ml-3">{WL.title}</h3> */}
              </div>
              <img
                style={{ height: "35px" }}
                src={require("~/images/logo-pettit.png")}
                alt="Binnacle app"
                id="logo"
              />
            </div>
            <div className="py-2 w-100 text-center text-md-left">
              {/* {prettyAddress(_.get(facility, 'address'))} */}
              {prettyAddress(facility.address)}
            </div>
          </div>
          <hr className="py-2" />
          {content()}
        </Card>
      </Container>
    );
  }
}

Approve.propTypes = {
  noun: PropTypes.string,
  customUrl: PropTypes.string,
  skipLoad: PropTypes.bool, // For testing,
  publicApi: PropTypes.bool
};

Approve.defaultProps = {
  noun: "estimate",
  customUrl: "/work-orders/${ id }/"
};

const ConnectedApprove = withFacility(Approve);
// const ConnectedApprove = Approve;

export const ApproveQuote = props => (
  <ConnectedApprove
    // publicApi={true}
    {...props}
    noun="estimate"
  />
);

export const ApproveChangeRequest = props => (
  <ConnectedApprove
    // publicApi={true}
    {...props}
    noun="change"
    customUrl="/work-orders/${ id }/diff/"
  />
);

import React, { useState } from "react";
import { Button, Alert } from "reactstrap";

import { publicApiClient, binnacleClient } from "~/util/api";
import Fa from "~/util/fa";

const ApproveButton = ({
  id,
  noun = "Estimate",
  token,
  icon = "file-signature",
  hideNoun = false,
  onCompleteRequest,
  url = `/work-orders/${id}/approve/`,
  data = null,
  method = "POST",
  btnStyle = "primary",
  className = "",
  disabled = false,
  label = "Approve",
  eventName = "workOrderApprove",
  workOrder,
  userProfile,
  isPublic
}) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState();

  const request = {
    url,
    data,
    method
  };

  if (isPublic) request.headers = { Authorization: `Bearer ${token}` };
  const client = isPublic ? publicApiClient : binnacleClient;

  const gtmEventPush = async () => {
    // GTM-event
    window.dataLayer.push({
      event: eventName,
      eventProps: {
        category: "search_actions",
        action: "mark_approved",
        // label will be set in GTM as page hostname
        workOrderId: workOrder?.id,
        userId: userProfile?.id,
        userName: userProfile?.name,
        facilityId: userProfile?.facility_id,
        boatId: workOrder?.boat?.id,
        boatName: workOrder?.boat?.name,
        ownerId: workOrder?.owner_profile?.id,
        ownerName: workOrder?.owner_profile?.name
      }
    });
    console.log(window.dataLayer);
  };

  const submit = async () => {
    setLoading(true);
    client
      .request(request)
      .catch(() =>
        setErrors(
          `There was an error handling this ${noun}, please contact your representative.`
        )
      )
      .finally(response => {
        setLoading(false);
        gtmEventPush();

        if (response && response.data) {
          return onCompleteRequest(response.data);
        }
        onCompleteRequest();
      });
  };
  if (errors) return <Alert color="warning">{errors}</Alert>;
  return (
    <Button
      data-testid="btn-approve"
      onClick={event => {
        event.preventDefault();
        event.stopPropagation();
        submit();
      }}
      color={btnStyle}
      style={{ height: "100%" }}
      className={className}
      // size="lg"
      disabled={loading || disabled ? true : undefined}
    >
      <Fa
        icon={loading ? `spinner` : icon}
        className={`color-white mr-2${loading ? " fa-spin" : ""}`}
      />
      {label || `Approve${hideNoun ? `` : ` ${noun}`}`}
    </Button>
  );
};

ApproveButton.defaultProps = {
  isPublic: true
};

export default ApproveButton;

import React from "react";
import PropTypes from "prop-types";
import { CardBody, Col, Card, Row } from "reactstrap";

import LoadingButton from "~/util/loadingButton";
import Fa from "~/util/fa";

// const fakeData = {
//   profiles: {
//     created_count: 32,
//     merged_count: 12,
//     error_count: 4,
//     rows: [
//       { num: 3, content: "column [email] empty" },
//       { num: 5, content: "column [name] and [email] empty" },
//       { num: 12, content: "column [phone] empty" },
//       { num: 14, content: "column [name] empty" }
//     ]
//   },
//   boats: {
//     created_count: 18,
//     merged_count: 8,
//     error_count: 2,
//     rows: [
//       { num: 5, content: "column [boat name] empty" },
//       { num: 12, content: "column [boat name] empty" }
//     ]
//   },
//   work_orders: {
//     created_count: 12,
//     merged_count: 0,
//     error_count: 0,
//     rows: []
//   }
// };

const StatsColumn = ({ stats = {}, title, className }) => {
  return (
    <Col md="4" className={className} id="ImportReview">
      <Card style={{ height: "100%" }}>
        <CardBody>
          <h2
            style={{ fontSize: "1.4em", fontWeight: "400", marginTop: "1rem" }}
          >
            {title}
          </h2>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "0.5rem"
            }}
          >
            <Fa icon="check-circle" className="text-success mr-2" size={3} />
            <span className="text-success">
              Created: {stats.created_count || 0}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "0.5rem"
            }}
          >
            <Fa icon="object-group" className="text-warning mr-2" size={3} />
            <span className="text-warning">
              Merged: {stats.merged_count || 0}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1rem"
            }}
          >
            <Fa
              icon="exclamation-triangle"
              className="text-danger mr-2"
              size={3}
            />
            <span className="text-danger">Error: {stats.error_count || 0}</span>
          </div>
          {stats.rows && stats.rows.length ? (
            <div>
              {stats.rows.map((row, i) => (
                <p
                  key={i}
                  className="text-danger"
                  style={{ fontSize: "0.9em" }}
                >
                  Row {row.num}: {row.content}
                </p>
              ))}
            </div>
          ) : null}
        </CardBody>
      </Card>
    </Col>
  );
};

const ImportReview = ({ data, onBack, onComplete, loading }) => {
  return (
    <>
      <h1
        style={{
          fontWeight: "400",
          display: "flex",
          alignItems: "center"
        }}
      >
        Profile Data Import
      </h1>
      <Row style={{ marginBottom: "1rem" }}>
        <StatsColumn stats={data["profiles"]} title="Profiles" />
        <StatsColumn stats={data["boats"]} title="Boats" />
        <StatsColumn
          stats={data["work_orders"]}
          title="Work Orders"
          className="pr-0"
        />
      </Row>
      <Row style={{ justifyContent: "flex-end" }}>
        <button
          className="btn btn-outline-primary"
          style={{ marginRight: "1rem" }}
          onClick={onBack}
        >
          Back
        </button>

        <LoadingButton
          className="btn btn-primary"
          loading={loading}
          onClick={onComplete}
        >
          Proceed with Import
        </LoadingButton>
      </Row>
    </>
  );
};

ImportReview.propTypes = {
  data: PropTypes.object,
  onBack: PropTypes.func,
  onComplete: PropTypes.func,
  loading: PropTypes.bool
};

export default ImportReview;

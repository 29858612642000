import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardTitle } from "reactstrap";
import { Form } from "informed";
import { BootstrapText } from "~/util/autoform/bootstrap";
import { getValidators } from "~/util/autoform/validators";
import { binnacleStore } from "~/util/store";
import { observer } from "mobx-react";
import { withAPIForm } from "~/util/api/withAPIForm";
import LoadingButton from "~/util/loadingButton";
import { withAuth } from "~/util/api/withAuth";

const validators_required = getValidators(["required"]);

export default
@withAuth
@withAPIForm
class PasswordResetConfirmScreen extends Component {
  constructor(props) {
    super(props);
    this.hasToken =
      _.has(props, "match.params.uuid") && _.has(props, "match.params.token");
    if (this.hasToken) {
      const { uuid, token } = props.match.params;
      this.token = `${uuid}/${token}`;
    }
    this.onPasswordResetEmail = this.onPasswordResetEmail.bind(this);
    this.onPasswordChangedConfirmed = this.onPasswordChangedConfirmed.bind(
      this
    );
    this.state = {};
  }

  onPasswordResetEmail(data) {
    this.setState({ emailSent: true });
  }

  onPasswordChangedConfirmed() {
    this.setState({ passwordResetConfirmed: true, emailSent: false });
  }

  renderForm() {
    const addData = this.hasToken ? { token: this.token } : {};

    let onSubmit = this.props.bindFormSubmit(
      this.hasToken
        ? this.onPasswordChangedConfirmed
        : this.onPasswordResetEmail,
      "/users/password/reset/",
      { transformApiData: data => ({ ...data, ...addData }) }
    );

    return (
      <Form onSubmit={onSubmit} getApi={this.props.getFormApi}>
        {this.hasToken ? (
          <div>
            <BootstrapText
              type="password"
              field="password"
              label="New Password"
              validate={validators_required}
              required={true}
            />

            <BootstrapText
              type="password"
              field="password2"
              label="Confirm Password"
              validate={validators_required}
              required={true}
            />
          </div>
        ) : (
          <BootstrapText
            field="email"
            label="Email"
            validate={validators_required}
            required={true}
          />
        )}

        <LoadingButton
          loading={this.props.sendingRequest}
          className="btn-lg btn-primary"
        >
          Submit
        </LoadingButton>
      </Form>
    );
  }

  renderEmailConfirmation() {
    return (
      <div>
        <CardTitle className="h1">Reset your Password</CardTitle>
        <p>
          Please confirm your email address, and we we will send you an email.
        </p>
        {this.renderForm()}
      </div>
    );
  }

  renderEmailSent() {
    return (
      <div>
        <CardTitle className="h1">Reset your Password</CardTitle>
        <p>An email has been sent to you with a link to reset your password.</p>
      </div>
    );
  }

  renderPasswordReset() {
    return (
      <div>
        <CardTitle className="h1">Password Reset</CardTitle>
        {this.renderForm()}
      </div>
    );
  }

  renderResetPasswordConfirmation() {
    return (
      <div>
        <CardTitle className="h1">Password Reset</CardTitle>
        <p>
          Your password has been reset. You can log in with your new password.
        </p>
        <Link className="btn btn-primary btn-lg" to="/login/">
          Return to login
        </Link>
      </div>
    );
  }

  render() {
    const { emailSent, passwordResetConfirmed } = this.state;
    const emailConfirmation = !this.hasToken && !emailSent;
    const resetPassword = this.hasToken && !passwordResetConfirmed;
    return (
      <Container>
        <Row>
          <Col xs="12" sm={{ size: 8, offset: 2 }} md={{ size: 6, offset: 3 }}>
            <Card className="p-5">
              {emailConfirmation ? this.renderEmailConfirmation() : null}
              {emailSent ? this.renderEmailSent() : null}
              {resetPassword ? this.renderPasswordReset() : null}
              {passwordResetConfirmed
                ? this.renderResetPasswordConfirmation()
                : null}
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

import React, { useEffect, useState } from "react";
import {
  Input,
  FormGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { useHistory } from "react-router-dom";

import Fa from "~/util/fa";
import withFacility from "~/util/api/withFacility";
import withAuthConditional from "~/util/api/withAuthConditional";
import { withProfile } from "~/util/api/withProfile";

// const multiCreateOptions = [
//   {
//     id: "workorder",
//     label: "Work Order",
//     path: "work-orders/create/"
//   },
//   {
//     id: "boat",
//     label: "Boat",
//     path: "boat/new/"
//   },
//   {
//     id: "profile",
//     label: "Profile",
//     path: "profile/create/"
//   }
// ];

const MultiAdd = ({ facility, profileIsType }) => {
  const history = useHistory();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  if (!profileIsType(["facility_manager", "admin", "service_writer"])) {
    return <></>;
  }
  return (
    <div id="multi-add" className="ml-auto mr-2">
      <div className="d-flex">
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle
            data-testid="btn-add-item"
            className="btn btn-primary rounded-pill text-white d-flex align-items-center"
            caret
          >
            <Fa className="mr-1" icon="plus" />
            Add
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              data-testid="add-workorder"
              onClick={() => history.push("/1/work-orders/create/")}
            >
              Work Order
            </DropdownItem>
            <DropdownItem 
              data-testid="add-boat"
              onClick={() => history.push("/1/boat/new/")}>
              Boat
            </DropdownItem>
            <DropdownItem 
              data-testid="add-profile"
              onClick={() => history.push("/1/profile/create/")}>
              Profile
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
  );
};

export default withFacility(withAuthConditional(withProfile(MultiAdd)));

import React from "react";
import { shallow } from "zustand";
import { useDashboardStore } from "~/store";
import CardSection from "~/components/dashboard/CardSection";
import NavigateIds from "~/components/dashboard/NavigateIds";
function advancedSearch(props) {
  const { selectedKinds } = useDashboardStore(
    state => ({
      selectedKinds: state.selectedKinds
    }),
    shallow
  );
  const selectedKindsDictionary = {
    workorder: "Work Orders",
    boat: "Boats",
    profile: "Profiles",
    facility: "Facilities"
  };

  return (
    <div className="my-2">
      {/* map selectedKinds */}
      {selectedKinds.map((kind, index) => {
        return (
          <div key={kind}>
            <h6
              id={kind}
              // bootstrap class for color red
              className="my-3"
              style={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: "16px",
                lineHeight: "18px",
                letterSpacing: "0.08em",
                color: "#585E69",
                textTransform: "uppercase"
              }}
            >
              {selectedKindsDictionary[kind]}
            </h6>
            <CardSection kind={kind} {...props} />
            {selectedKinds.length > 1 ? <NavigateIds /> : null}
          </div>
        );
      })}
    </div>
  );
}

export default advancedSearch;

import React, { Component } from "react";
import { Container, Card, CardBody, Row, Col, Input } from "reactstrap";
import NavBar from "../components/navbar";
import images from "~/images";
import { LoadingIndicator } from "~/util/loadingWrapper";
import { binnacleClient, publicApiClient } from "~/util/api";
import DevPre from "~/util/DevPre";
import WorkOrderForm from "~/components/workOrders/Form";

const errorRetrievingMsg =
  "An error occured and the Service Request information couldn't be retrieved.";

export class WorkOrderBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workOrder: {},
      loading: true,
      // below 2 used by WorkUpdateReply component
      replyMessage: "",
      showThankYou: false,
      boatPrimaryImage: ""
      // passed from card if opening a new message
      // projectActivityOpen: false
    };
    this.id = null;
  }

  componentDidMount() {
    this.fetchWO();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      _.get(this.props, "match.params.id") !==
      _.get(prevProps, "match.params.id")
    ) {
      this.fetchWO();
    }
  }

  getBoatPrimaryImage = () => {
    const workOrder = this.state.workOrder;
    console.log(workOrder);
    const primaryImage = workOrder?.boat?.images?.find(
      image => image?.is_primary === true
    );
    if (primaryImage) {
      this.setState({ boatPrimaryImage: primaryImage });
    }
  };

  fetchWO = () => {
    const { publicApi, customUrl } = this.props;
    const id = _.get(this.props, "match.params.id");

    const client = publicApi ? publicApiClient : binnacleClient;

    this.id = id;
    if (id) {
      this.setState({ loading: true });
      client
        .get(
          customUrl ? customUrl.replace("${ id }", id) : `/work-orders/${id}/`
        )
        .then(({ status, data: workOrder }) => {
          if (status >= 300) {
            this.setState({ errorMessage: errorRetrievingMsg, loading: false });
          } else {
            this.setState({ workOrder, loading: false });
            this.getBoatPrimaryImage();
            console.log(this.state);
          }
        })
        .catch(() =>
          this.setState({ errorMessage: errorRetrievingMsg, loading: false })
        );
    } else {
      this.setState({ loading: false });
    }
  };

  getOwnerOrRep() {
    const { workOrder } = this.state;
    return _.isEmpty(workOrder.owner_profile)
      ? { label: "Representative", profile: workOrder.representative_profile }
      : { label: "Owner", profile: workOrder.owner_profile };
  }

  renderWO() {
    const { workOrder } = this.state;
    return <DevPre v={workOrder} />;
  }

  render() {
    if (this.state.loading) return <LoadingIndicator />;
    return this.renderWO();
  }
}

export class EditWorkOrder extends WorkOrderBase {
  renderWO() {
    const {
      workOrder,
      workOrder: { status = "draft" }
    } = this.state;
    const workOrderType = workOrder.parent ? "Change Order" : "Work Order";

    document.title = `Pettit Pro Portal | ${workOrderType}`;
    return (
      <>
        <NavBar className="bg-white" details={true}></NavBar>
        <WorkOrderForm workOrder={workOrder} includeTitleBar={true} />
      </>
    );
  }
}

const MIN_REPLY_MESSAGE_LENGTH = 8;

export class WorkUpdateReply extends WorkOrderBase {
  handleUpdateMessage = event => {
    event.preventDefault();

    this.setState({ replyMessage: event.target.value });
  };

  handleSubmitMessage = async event => {
    event.preventDefault();
    event.stopPropagation();

    const { replyMessage, workOrder } = this.state;
    if (!workOrder || !workOrder.id || !replyMessage.length) return;

    this.setState({ replyMessage: "" });

    const result = await binnacleClient.post(`work-order-notes/`, {
      note: replyMessage,
      note_type: "customer_reply",
      date_created: new Date().toJSON(),
      creator_profile_id: _.get(workOrder, "owner_profile.id"),
      work_order_id: workOrder.id
    });

    this.setState({ showThankYou: true });

    console.log({ result });
  };

  renderWO = () => {
    return (
      <>
        <Container>
          <Row>
            <Col xs="12" md={{ size: 10, offset: 1 }}>
              <Card className="pt-2 pb-3 my-5">
                <CardBody>
                  <Row>
                    <Col xs="12" sm={{ size: 10, offset: 1 }}>
                      <div className="w-100 d-flex justify-content-between align-items-center">
                        <img
                          className="boatyard-logo"
                          src={images[WL.logo]}
                          alt={WL.title}
                          style={{ maxWidth: "150px", height: "auto" }}
                        />
                        <img
                          src={images["pettit_logo"]}
                          alt="Pettit Pro Services"
                          style={{ maxWidth: "150px", height: "auto" }}
                        />
                      </div>
                      <hr />
                      {this.state.showThankYou ? (
                        <>
                          <h2
                            style={{
                              textAlign: "center",
                              fontWeight: "400",
                              marginTop: "1rem"
                            }}
                          >
                            Thanks for the update!
                          </h2>
                          <div
                            style={{ textAlign: "center", marginTop: "2rem" }}
                          >
                            <p>
                              We've received your message and will be in touch
                              soon!
                            </p>
                          </div>
                        </>
                      ) : (
                        <>
                          <h2
                            style={{
                              textAlign: "center",
                              fontWeight: "600",
                              marginTop: "1rem",
                              letterSpacing: "-0.1rem"
                            }}
                          >
                            Send us a message
                          </h2>
                          <h4
                            style={{
                              textAlign: "center",
                              fontWeight: "100",
                              marginTop: "1rem",
                              marginBottom: "1rem",
                              color: "rgba(120,120,120)"
                            }}
                          >
                            Want to communicate with us about your Work Order?
                          </h4>
                          <div style={{ textAlign: "center" }}>
                            <p>Enter your message below.</p>
                          </div>
                          <div
                            className="col-12 col-lg-10 offset-lg-1"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginBottom: "1.5rem"
                            }}
                          >
                            <Input
                              value={this.state.replyMessage}
                              rows={4}
                              type="textarea"
                              onChange={this.handleUpdateMessage}
                            />
                          </div>
                          <div style={{ textAlign: "center" }}>
                            <button
                              disabled={
                                this.state.replyMessage.length <
                                MIN_REPLY_MESSAGE_LENGTH
                              }
                              onClick={this.handleSubmitMessage}
                              className="btn btn-primary"
                            >
                              Send us a message
                            </button>
                          </div>
                        </>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  };
}

import React from "react";
import PropTypes from "prop-types";
import { Row } from "reactstrap";

import LinkDuo from "~/util/linkDuo";
import Fa from "~/util/fa";
import { facilityProps } from "~/data/props";

const ApproveSuccess = ({ noun, facility = {}, viewOptions }) => {
  const label = noun === "estimate" ? "Work Order" : "Revised Estimate";
  const {
    showSuccessIcon = false,
    title = "Thank you!",
    message = `We've confirmed your ${label} and will be in touch.`,
    button = { url: facility.url || "/", label: `Visit ${facility.name || ""}` }
  } = viewOptions;

  return (
    <div className="justify-content-center d-flex flex-column align-items-center">
      {showSuccessIcon ? (
        <div className="my-3 dot dot-huge bg-success text-white">
          <Fa icon="check" className="p-2" />
        </div>
      ) : null}
      <h1 className="my-2">{title}</h1>
      <h3 className="my-3 text-center text-secondary">{message}</h3>
      <LinkDuo className="btn btn-outline-primary my-3" to={button.url}>
        {button.label}
      </LinkDuo>
      <LinkDuo
        className="my-3"
        // TODO: where should "close window" go?
        to={"/"}
        style={{ color: "gray", textDecoration: "underline" }}
      >
        Close Window
      </LinkDuo>
    </div>
  );
};

ApproveSuccess.propTypes = {
  noun: PropTypes.oneOf(["estimate", "change"]),
  facility: facilityProps,
  viewOptions: PropTypes.shape({
    showSuccessIcon: PropTypes.bool,
    title: PropTypes.string,
    message: PropTypes.string,
    button: PropTypes.shape({ url: PropTypes.string, label: PropTypes.string })
  })
};

export default ApproveSuccess;

import React, { useState, useEffect, useEffect as useEffectClone } from "react";
import { Row, Col, Container } from "reactstrap";
import NavBar from "~/components/navbar";
import { withProfile } from "~/util/api/withProfile";
import { binnacleClient } from "~/util/api";
import { useDebounce } from "~/util/nolodash";
import AdvancedModal from "~/components/dashboard/AdvancedModal";
import SearchBar from "~/components/dashboard/SearchBar";
import HeaderSecondRow from "~/components/dashboard/HeaderSecondRow";
import { useDashboardStore } from "~/store";
import { shallow } from "zustand";
import NewItemCard from "~/components/dashboard/NewItemCard";
import { NewLoadingIndicator } from "~/util/loadingWrapper";
import { useHistory, useLocation } from "react-router";
import AdvancedSearch from "~/components/dashboard/AdvancedSearch";
import buildSearchQuery from "~/util/buildSearchQuery";
const DashboardFunctionalComponent = props => {
  const history = useHistory();
  const location = useLocation();
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const {
    selectedKinds,
    query,
    fireSearch,
    workOrderStatuses,
    qbSelectionActive,
    advancedSearchActive,
    cursor,
    setNextCursor,
    selectedItems,
    addSelectedItem,
    removeSelectedItem
  } = useDashboardStore(
    state => ({
      selectedKinds: state.selectedKinds,
      query: state.query,
      fireSearch: state.fireSearch,
      workOrderStatuses: state.workOrderStatuses,
      cursor: state.cursor,
      setNextCursor: state.setNextCursor,
      qbSelectionActive: state.qbSelectionActive,
      advancedSearchActive: state.advancedSearchActive,
      selectedItems: state.selectedItems,
      addSelectedItem: state.addSelectedItem,
      removeSelectedItem: state.removeSelectedItem
    }),
    shallow
  );

  // Using debounce to prevent multiple calls to the API
  const debounceDefaultTime = 250;

  const debouncedValues = useDebounce(
    { query, workOrderStatuses, selectedKinds, qbSelectionActive },
    debounceDefaultTime
  );

  // This is used to update any item that is changed from an api 'mutation' call.
  const updateItem = item => {
    const newItems = items.map(i => {
      if (i?.extra?.id === item.id) {
        i.extra = item;
        return i;
      }
      return i;
    });
    setItems(newItems);
  };

  const loadItems = async (loadMore = false) => {
    let selectedFilterQueryParam;
    const params = new URLSearchParams(location.search);
    selectedFilterQueryParam = params.get("filter");
    if (selectedFilterQueryParam) {
      selectedKinds[0] = selectedFilterQueryParam;
      params.delete("filter");
      history.replace({ search: params.toString() });
    }
    /*
     * These are the number of items to load for each kind in simple search.
     * Client may want to change this in the future.
     */
    const resultsPerPageLookup = {
      workorder: 12,
      boat: 12,
      profile: 12,
      readyforinvoice: 12
    };
    const resultsPerPage = resultsPerPageLookup[selectedKinds[0]];

    const { data } = await binnacleClient.get(
      `/options/?${buildSearchQuery(
        query,
        workOrderStatuses,
        selectedKinds,
        loadMore ? cursor : null,
        resultsPerPage
      )}`
    );
    console.log("loadingItems", data);
    if (loadMore) {
      setItems([...items, ...data.results]);
    } else {
      setItems(data.results);
    }
    setNextCursor(data.next_cursor);
    setLoading(false);
  };

  const isAdmin = () => {
    return props.profileIsType(["service_writer", "facility_manager", "admin"]);
  };

  useEffect(() => {
    if (!advancedSearchActive) {
      loadItems();
    }

    return () => {
      // clean up function to cancel any pending debounced values
    };
  }, [
    debouncedValues.query,
    debouncedValues.workOrderStatuses,
    debouncedValues.selectedKinds,
    debouncedValues.qbSelectionActive,
    selectedItems.length < 1
  ]);

  return (
    <div>
      <div id="header" className="sticky">
        <NavBar>
          <SearchBar />
          <AdvancedModal />
        </NavBar>
        {/* Second Row goes here */}

        <HeaderSecondRow isAdmin={isAdmin} {...props} />
      </div>

      <div className="dashboard">
        <Container className="px-1">
          {/* Simple Search */}
          {!advancedSearchActive ? (
            <>
              <Row>
                <Col xs="12" md="12">
                  <div className="dashboard__content mt-3 d-flex flex-wrap cards-grid">
                    <NewLoadingIndicator loading={loading} />
                    {!loading && _.isEmpty(items) && <em>No results found</em>}
                    {/* ROL Items */}

                    {items.map((item, index) => (
                      <div key={index} className="d-flex card-container">
                        <NewItemCard
                          {...item}
                          userProfile={props.profile}
                          updateItem={updateItem}
                          key={`option-item-${index}`}
                          className={"mb-2 d-flex"}
                        />
                      </div>
                    ))}
                  </div>
                </Col>
              </Row>
              <Row className="d-flex justify-content-center py-2">
                {cursor && items.length > 0 ? (
                  <button
                    onClick={() => {
                      loadItems(true);
                    }}
                    className="btn btn-primary"
                  >
                    Load More
                  </button>
                ) : (
                  ""
                )}
                {items.length > 0 && !cursor ? (
                  <div className="text-center">No more items to load</div>
                ) : (
                  ""
                )}
              </Row>
            </>
          ) : (
            <AdvancedSearch
              userProfile={props.profile}
              updateItem={updateItem}
            />
          )}
        </Container>
      </div>
    </div>
  );
};

export default withProfile(DashboardFunctionalComponent);

import React, { Component } from "react";
import { BasicSelect, Option, asField } from "informed";

export class ChoiceBasicSelect extends Component {
  renderOption(opt) {
    let id = null;
    let name = null;

    if (opt.constructor === Array) {
      id = opt[0];
      name = opt[1];
    } else {
      id = opt.id;
      name = opt.name;
    }

    return (
      <option key={id} value={id}>
        {name}
      </option>
    );
  }

  render() {
    let { choices, allowBlank, ...rest } = this.props;

    return (
      <BasicSelect {...rest}>
        {allowBlank && <Option value="">--------</Option>}

        {choices.map(option => this.renderOption(option))}
      </BasicSelect>
    );
  }
}

import React, { useState } from "react";
import { useFormState } from "informed";
import { Container } from 'reactstrap';

const DevPre = ({ v }) => {
  const [open, setOpen] = useState(false);
  const props = {
    onClick: () => setOpen(!open),
    className: "p-3",
    style: {
      overflow: "hidden",
      cursor: "pointer",
      maxHeight: open ? "none" : "5em"
    }
  };
  return (
    <Container className="rounded my-3" style={{ backgroundColor: "#d9f0f0" }}>
      <pre {...props}>{JSON.stringify(v, null, 2)}</pre>
    </Container>
  );
};

const ProdPre = () => null;

const ConditionalExport =
  process.env.NODE_ENV === "development" ? DevPre : ProdPre;

export default ConditionalExport;

export const FormStatePre = () => {
    const formState = useFormState();
    return <ConditionalExport v={formState} />
};

import React from "react";
import { Container } from "reactstrap";
import NavBar from "~/components/navbar";

export default ({ showNav }) => (
  <div>
    {showNav && <NavBar />}
    <Container>
      <h1>404</h1>
      <h2>Not Found</h2>
      <p>
        We are sorry, but it looks like the page you tried to access does not
        exist.
      </p>
    </Container>
  </div>
);

import React, { useState, useEffect } from "react";
import { useFormState, useFormApi, asField, Text } from "informed";
import { Table } from "reactstrap";
import WorkItem from "~/components/workOrders/WorkItem";
import WorkItemsModal from "~/components/services/SelectModal";
import { calculateTax } from "~/components/workOrders/calculations";
import CollapsibleCard from "~/components/generic/CollapsibleCard";
import { currency } from "~/util/currency";
import Fa from "~/util/fa";
import { deleteIn, setIn } from "immutable-setter";
import classnames from "classnames";

import MarginOrDiscountRow from "./WorkOrderMarginDiscount";

import {
  calcItemPiece,
  calculateItemSubtotal,
  recalculatePriceAndUnits
} from "./calculations";

// in Form, "field" of work items gets passed through as
// value, error, with onChangeItems (?)
const WorkItemListBase = ({
  value,
  error,
  onChangeItems,
  canEdit,
  boat,
  isOpen
}) => {
  let formState = useFormState();
  const formApi = useFormApi();
  const items = value || [];
  const [currentBoat, setCurrentBoat] = useState(boat);
  useEffect(() => {
    //If boat is not currently defined,
    // pull the boat from form state.
    if (boat === undefined) {
      let the_boat = _.get(formState, "values.boat", 0);
      setCurrentBoat(the_boat);
    }
  }, [boat, formState]);

  // 1. Get calculated and display values from form state
  //   (form values are used when posting to server)
  const surfaceArea =
    _.get(formState, "values.boat.specs.surface_area") ||
    _.get(boat, "specs.surface_area") ||
    0;
  const length =
    _.get(formState, "values.boat.specs.length", 0) ||
    _.get(boat, "specs.length") ||
    0;
  var margin = parseFloat(_.get(formState, "values.margin", 0));
  var discount = parseFloat(_.get(formState, "values.discount", 0));
  var envFee = parseFloat(_.get(formState, "values.environmental_fee", 0));

  const basePrice = items.reduce((t, i) => t + parseFloat(i.price), 0);
  // envPrice is % on basePrice of all line items before tax and margin
  // the API sums basePrice + (basePrice * envPrice)
  const envPrice = basePrice * (Math.max(envFee, 0) / 100);
  // get calculated result of sales tax from individual line items -- not tax applied to total
  const taxTotal = _.get(formState, "values.sales_tax", 0);
  // calculate margin on the subtotal of basePrice + envPrice + tax
  // this appears to have formerly incorrectly been also adding taxTotal before the %
  const marginPrice =
    (basePrice + envPrice + taxTotal) * (Math.max(margin, 0) / 100);
  // total_price in API is now sum of basePrice, envPrice, tax, and margin
  const discountPrice =
    ((basePrice + envPrice + taxTotal + marginPrice) * Math.max(discount, 0)) /
    100;

  // resulting total after envPrice, margin and dicount + line item tax
  const totalWithTax = _.get(formState, "values.total_price", 0); // const basePrice = _.sum(parseFloat(_.map(items, "price")));

  // 2. UI stuff
  const [forceOpenState, setForceOpenState] = useState();

  const forceOpenCard = () => {
    setForceOpenState(true);
  };

  // cleanup callback for forced state after forcing open
  const onForcedOpenReset = () => {
    setForceOpenState(false);
  };

  const tableClasses = classnames("form-group", {
    "is-invalid": _.some(error, item => !_.isEmpty(item))
  });

  // 3. Item change handlers (tax and total calculations)

  // apply unit-level changes (ex: materials, labor) to items and update
  const onUnitsChange = i => {
    return (key, value) => {
      let newItems = setIn(items, [i, key], value);
      newItems = setIn(
        newItems,
        [i, "price"],
        calculateItemSubtotal(newItems[i], surfaceArea, length)
      );
      onChangeItems(newItems);
      handleUpdateTotalWithTax(newItems);
    };
  };

  // add items and update state
  const handleAddItems = newItems => {
    newItems = _.map(newItems, item =>
      recalculatePriceAndUnits(item, surfaceArea, length)
    );
    const sortedItems = _.sortBy([...items, ...newItems], "ordering");
    onChangeItems(sortedItems);
  };

  // add items and update state
  const removeItem = index => {
    const nextItems = deleteIn(items, [index]);
    onChangeItems(nextItems);
    handleUpdateTotalWithTax(nextItems);
  };

  // gets called by recalculateItems whenever items or key inputs change
  // This calculates tax and total based on the current work_items
  //  and updates it in the Informed state
  const handleUpdateTotalWithTax = workItems => {
    // returns sum of labor and material tax from line items
    // that include them
    const totalTax = calculateTax({
      items: workItems,
      surfaceArea,
      length
    });

    // set tax on form state first because total calculation
    // items below pull the latest value from form state
    // (incorporated in marginPrice and envPrice)
    formApi.setValues({ sales_tax: totalTax });

    // Calculated values, used in later calculations and labels
    // console.log("------------------------");
    // console.log(`basePrice = ${basePrice}`);
    // console.log(`marginPrice = ${marginPrice}`);
    // console.log(`envPrice = ${envPrice}`);
    // console.log(`discountPrice = ${discountPrice}`);

    let total = basePrice + marginPrice + envPrice;
    total = total - discountPrice;
    total = total + taxTotal;

    formApi.setValues({ total_price: total });
  };

  // gets called whenever items or key inputs change
  // This is the Informed state "reset" function:
  //   - Updates the "work_items" field (via parent component)
  //   - Update the "total" and "sales_tax" fields
  const recalculateItems = initial => {
    const updatedItems = _.map(items, item =>
      recalculatePriceAndUnits(item, surfaceArea, length, initial)
    );
    onChangeItems(updatedItems);
    // handleUpdateTotalWithTax(updatedItems);
  };

  // Update total and tax on first load
  useEffect(() => {
    let initial = true;
    recalculateItems(initial);
    initial = false;
    handleUpdateTotalWithTax(items);
  }, []);
  useEffect(() => {
    handleUpdateTotalWithTax(items);
  }, [basePrice, marginPrice, envFee, discountPrice, items]);

  // This is the "check for updates" function to ensure
  //  that displayed totals sync up with work_item data

  useEffect(() => {
    let initial = true;
    recalculateItems(initial);
    initial = false;
  }, [
    surfaceArea,
    length,
    margin,
    basePrice,
    discount,
    envFee,
    items.length // adding/removing work_items
  ]);

  return (
    <>
      <CollapsibleCard
        header={
          <h2>
            Work Items{" "}
            {canEdit && (
              <WorkItemsModal
                add={handleAddItems}
                openCardCallback={forceOpenCard}
              />
            )}
          </h2>
        }
        isOpen={isOpen}
        forceOpen={forceOpenState}
        onForcedOpen={onForcedOpenReset}
        body={
          _.isEmpty(items) ? (
            <em>No Current Work Items. Add some above.</em>
          ) : (
            <Table responsive striped className={tableClasses}>
              <thead>
                <tr>
                  <th style={{ width: "50%" }}>Item</th>
                  <th>
                    <span
                      style={{ display: "inline-block", minWidth: "130px" }}
                    >
                      Labor Units
                    </span>
                  </th>
                  <th>
                    <span
                      style={{ display: "inline-block", minWidth: "130px" }}
                    >
                      Material Units
                    </span>
                  </th>
                  <th className="text-right" style={{ minWidth: "90px" }}>
                    Price
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {items.map((item, i) => (
                  <tr key={`item-row-${item.id}`}>
                    <WorkItem
                      {...{
                        canEdit,
                        item,
                        onUnitsChange: onUnitsChange(i),
                        boatSpecs: currentBoat.specs
                      }}
                      errors={error && error[i]}
                    />
                    <td className="align-middle">
                      {canEdit && (
                        <a
                          className="text-danger clickable"
                          onClick={() => removeItem(i)}
                        >
                          <Fa icon="trash" />
                        </a>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <MarginOrDiscountRow
                  field="environmental_fee"
                  canEdit={canEdit}
                  label="Environmental Fee"
                  price={envPrice}
                />
                <MarginOrDiscountRow
                  field="margin"
                  canEdit={canEdit}
                  label="Margin"
                  price={marginPrice}
                />
                <MarginOrDiscountRow
                  field="discount"
                  canEdit={canEdit}
                  label="Discount"
                  price={discountPrice}
                />
                <tr>
                  <td colSpan="4">
                    <h4 className="text-right" id="tax_label">
                      {/* previously were using getItemsTax(items) to calculate 
                      TODO: change this back so we're seeing sales_tax change in the preview
                      */}
                      Tax: {currency(taxTotal)}
                    </h4>
                  </td>
                  <td />
                </tr>
                <tr>
                  <td colSpan="4">
                    <h4 className="text-right" id="total_label">
                      {/* TODO: temporary fix until we can reevaluate all price calculations on the frontend to match parity with the backend, and calculate dynamic sales tax on the frontend */}
                      Total: {currency(totalWithTax)}
                    </h4>
                  </td>
                  <td />
                </tr>
              </tfoot>
            </Table>
          )
        }
      />
      {/* <DevPre v={items} /> */}
    </>
  );
};

const WorkItemList = asField(
  ({ fieldState, fieldApi, sales_tax, total_price, ...rest }) => {
    const onChangeItems = items => {
      fieldApi.setValue(items);
      fieldApi.validate();
    };

    return (
      <>
        <Text field="sales_tax" type="hidden" initialValue={sales_tax || 0} />
        <Text
          field="total_price"
          type="hidden"
          initialValue={total_price || 0}
        />
        <WorkItemListBase
          onChangeItems={onChangeItems}
          value={fieldState.value}
          error={fieldState.error}
          {...rest}
        />
      </>
    );
  }
);

export default props => {
  const validate = items => {};
  // {
  //   const errors = _.map(items, validateWorkItem);
  //   return _.every(errors, _.isEmpty) ? undefined : errors;
  // }
  return <WorkItemList validate={validate} {...props} />;
};

import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import quickbooksLogo from "~/images/item_icons/qb-logo-hf.svg";

export default function QuickBooksSignInModal(args) {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const qbRedirectUrl = `/api/facilities/${args?.facilityId}/qb-redirect/?qb_auth_key=${args?.qbSettings?.qb_auth_key}`;

  return (
    <Modal {...args}>
      {/* use cssModule prop to modify modal-title class */}
      <ModalHeader
        cssModule={{ "modal-title": "modal-title w-100 text-center" }}
        toggle={args.toggle}
      >
        QuickBooks Authentication
      </ModalHeader>
      <ModalBody>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div
            className="mt-0 mb-2"
            style={{ height: "3.25rem", width: "3.25rem" }}
          >
            {" "}
            <img style={{ height: "3.25rem" }} src={quickbooksLogo} />
          </div>
          <p className="text-center mt-2 mb-3">
            Please sign in to your QuickBooks Online account to reauthenticate
            and sync work orders.
          </p>
          <Button href={qbRedirectUrl} className="mb-2 mt-1" color="primary" onClick={args.toggle}>
            Sign In to QuickBooks Online
          </Button>{" "}
        </div>
      </ModalBody>
    </Modal>
  );
}

import React from "react";
import { Row, Col } from "reactstrap";

const WOUpdatedContactInfo = ({ updatedProfile, className = "" }) => {
  const rowStyles = `d-flex justify-content-between flex-wrap align-items-center mt-2 ${className}`;

  return (
    updatedProfile && (
      <Row className={rowStyles}>
        <Col className="d-flex align-items-center">
          <strong className="mr-2">Service Writer</strong>
          {updatedProfile.name && (
            <span data-testid="wo-servicewriter-name" className="mr-2">
              {updatedProfile.name}
            </span>
          )}
          {updatedProfile.email && (
            <a
              href={`mailto:${updatedProfile.email}`}
              data-testid="wo-updated-profile-email"
              className="mr-2"
            >
              {updatedProfile.email}
            </a>
          )}
          {updatedProfile.phone && (
            <span data-testid="wo-updated-profile-phone" className="mr-2">
              {updatedProfile.phone}
            </span>
          )}
        </Col>
      </Row>
    )
  );
};

export default WOUpdatedContactInfo;

import React from "react";
import { Badge } from "reactstrap";
import kinds from "~/data/kinds";
import classnames from "classnames";

export default function KindIcon({
  kind,
  className,
  style,
  iconColor = "gray-500",
  iconBgColor = "bg-secondary",
  fontSize = 30,
  innerIconStyles = {},
  ...imgProps
}) {
  if (!_.has(kinds, kind)) return null;
  const { icon } = kinds[kind];
  const iconClass = classnames(`${icon}`, `${iconColor}`);
  const defaultInnerIconStyles = {
    fontSize: `${fontSize}px`
  };
  const mergedInnerIconStyles = {
    ...defaultInnerIconStyles,
    ...innerIconStyles
  };
  return (
    <>
      <Badge
        pill
        data-testid={`icon-${kind}`}
        className={
          className
            ? `${className} ${iconBgColor}`
            : `text-center p-1 ${iconBgColor}`
        }
        style={{ ...style }}
      >
        <i style={mergedInnerIconStyles} className={iconClass}></i>
        {/* <img {...imgProps} /> */}
      </Badge>
      {/* <div className={`icon-label text-${color}`}>{kind}</div> */}
    </>
  );
}

import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Alert, Button } from "reactstrap";

import { facilityProps, profileProps, workOrderProps } from "~/data/props";
import { ApproveButton, nounLabels } from "~/components/approve";
import Fa from "~/util/fa";
import ExternalLink from "~/util/ExternalLink";
import {
  ScheduleDisplay,
  ScheduleDisplayDiff
} from "~/components/workOrders/ScheduleDisplay";
import {
  WorkItemsDisplay,
  WorkItemsDisplayDiff
} from "~/components/workOrders/WorkItemsDisplay";
import { lineBreaksHtml } from "~/util/alpacaLegacyUtils";

/* url path = 
${baseurl}/approve/quote/${workorder-id}
${baseurl}/approve/change-order/${workorder-id}
*/

const ApproveMain = ({
  handleSetView,
  facility,
  workOrder,
  ownerOrRep,
  noun,
  token
}) => {
  const profile =
    _.get(workOrder, "created_profile") ||
    _.get(workOrder, "updated_profile") ||
    {};

  const {
    name: creatorName = "",
    // name: creatorName = "Stan Woodward",
    email: creatorEmail = "",
    // email: creatorEmail = "stan@lockwoodboatworks.com",
    phone: creatorPhone = "",
    // phone: creatorPhone = "(355) 311-5222",
    // TODO: Need to add title field to user on backend
    title = "Service Writer"
  } = profile;

  const boatPrimaryImage = workOrder.boat?.images?.find(image => image.is_primary);

  const Schedule = noun === "estimate" ? ScheduleDisplay : ScheduleDisplayDiff;
  const WorkItems =
    noun === "estimate" ? WorkItemsDisplay : WorkItemsDisplayDiff;

  const approveProps = {
    id: workOrder.id,
    noun: nounLabels[noun],
    hideNoun: true,
    icon: "check",
    token,
    onCompleteRequest: handleSetView("success")
  };

  return (
    <>
      <Row className="py-2 pl-3">
        <h1 style={{ color: "#181818" }}>Approve {nounLabels[noun]}</h1>
      </Row>

      <Row>
          <Col md="5">
        <table data-testid="estimate-overview-info">
        {creatorName && (
          <tr data-testid="servicewriter-contact-info">
          <th
            className="d-none d-lg-table-cell"
            style={{ minWidth: "150px", "verticalAlign": "top" }}>Service Writer</th>
          <td className="d-flex flex-column" data-testid="service-writer-info">
            <label class="d-block d-lg-none" data-testid="mobile-label">Service Writer</label>
            {creatorName && (
              <span data-testid="wo-servicewriter-name" className="mr-2">
                <strong>{creatorName}</strong>
              </span>
            )}
            {creatorEmail && (
              <a
                href={`mailto:${creatorEmail}`}
                data-testid="wo-updated-profile-email"
                className="mr-2"
              >
                {creatorEmail}
              </a>
            )}
            {creatorPhone && (
              <span data-testid="wo-updated-profile-phone" className="mr-2">
                {creatorPhone}
              </span>
            )}
            </td>
          </tr>
        )}
        <tr className="approve-boat__owner">
          <th className="d-none d-lg-table-cell pt-4">{_.get(ownerOrRep, "label")}</th>
          <td>
            <label class="d-block d-lg-none" data-testid="mobile-label">{_.get(ownerOrRep, "label")}</label>
            {workOrder.owner_profile ? workOrder.owner_profile.name : ""}
          </td>
        </tr>
        <tr className="mb-0 approve-boat__name">
          <th className="d-none d-lg-table-cell pt-4">Boat</th>
          <td>
            <label class="d-block d-lg-none" data-testid="mobile-label">Boat</label>
            {workOrder.boat && workOrder.boat.name}
          </td>
        </tr>
        </table>
          </Col>
          <Col md="7">
       
          {
            boatPrimaryImage ? 
            <img 
                data-testid="approvequote-boat-primaryimage" 
                src={boatPrimaryImage.url} 
                alt={workOrder.boat.name} 
                className="rounded"
                style={{ "maxHeight": "350px", "maxWidth": "100%" }}
                /> : 
                ""
          }
          </Col>
        </Row>

      {workOrder.status === "draft" ? (
        <Alert color="info">
          This {_.lowerCase(nounLabels[noun])} is not ready for approval
        </Alert>
      ) : null}
      <Row className="my-4">
        <Col sm="5">
          <Schedule {...workOrder} />
        </Col>
        <Col sm="7">
          {/* regular or change order diff */}
          <WorkItems {...workOrder} showDiscount={noun === "estimate"} />
        </Col>
      </Row>
      <div className="d-flex justify-content-end flex-wrap">
        {workOrder.status === "pending" ? (
          <>
            <Button
              data-testid="btn-decline"
              onClick={handleSetView("decline")}
              className="approve__decline-button btn-outline-danger d-block w-100 w-md-auto d-md-inline-block w-md-auto mb-2 mb-md-0 mx-0 mr-md-2 mt-2 mt-md-0"
              color="danger"
            >
              <Fa icon="ban" className="mr-2" />
              Decline
            </Button>
            <Button
              data-testid="btn-request-change"
              onClick={handleSetView("requestChange")}
              className="approve__change-button color-white btn-outline-primary d-block w-100 w-md-auto d-md-inline-block w-md-auto mb-2 mb-md-0 mx-0 mr-md-2 mt-2 mt-md-0"
              color=""
            >
              <Fa icon="recycle" className="mr-2" />
              Request Change
            </Button>
            <ApproveButton
              data-testid="btn-approve"
              className="approve__approve-button d-block w-100 d-md-inline-block w-md-auto mt-2 mt-md-0"
              {...approveProps}
            />
          </>
        ) : (
          <Alert color="info">
            This {_.lowerCase(nounLabels[noun])} is invalid or has already been
            approved.
          </Alert>
        )}
      </div>
      {facility.terms_and_conditions && (
        <>
          <div className="pt-4 my-2">
            <h6 id="terms_and_conditions_header">Terms and Conditions</h6>
            <p
              style={{ fontSize: "0.8em", color: "lightgray" }}
              id="terms_and_conditions_content"
            >
              {lineBreaksHtml(facility.terms_and_conditions)}
            </p>
          </div>
        </>
      )}
    </>
  );
};

ApproveMain.propTypes = {
  handleSetView: PropTypes.func,
  facility: facilityProps,
  workOrder: workOrderProps,
  ownerOrRep: profileProps,
  noun: PropTypes.string,
  token: PropTypes.string
};

export default ApproveMain;

import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { binnacleClient } from "~/util/api";
import Fa from "~/util/fa";

export default class VerifyPhoneScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      success: false
    };
  }

  componentDidMount() {
    let token = this.props.match.params.token;
    let component = this;

    binnacleClient
      .post("/profiles/verify_phone/", { token: token })
      .then(response => {
        component.setState({ loading: false, success: true });
      })
      .catch(function(error) {
        component.setState({ loading: false, success: false });
      });
  }

  render() {
    return (
      <Container className="mb-4">
        <h1>Verify Phone Number</h1>
        {this.state.loading ? (
          <span>
            <Fa icon="spinner" spin /> &#160;&#160;Verifying...
          </span>
        ) : this.state.success ? (
          <p className="lead">
            <Fa icon="check" className="text-success" /> &#160;&#160;Your phone
            number has been verified!
          </p>
        ) : (
          <p className="lead">
            <Fa icon="times" className="text-danger" /> &#160;&#160;Unable to
            verify your phone number. The link may have expired.
          </p>
        )}
      </Container>
    );
  }
}

import qs from "qs";

function buildSearchQuery(
  query,
  workOrderStatuses,
  selectedKinds,
  cursor,
  page_size = 4
) {
  let searchQuery = {};
  searchQuery.search = "";

  searchQuery.page_size = page_size || 4;

  if (selectedKinds.length > 0) {
    searchQuery.search += `type:${selectedKinds?.join(" type:")}`;
  }
  if (workOrderStatuses.length > 0 && workOrderStatuses[0] !== undefined) {
    searchQuery.search += ` status:${workOrderStatuses?.join(" status:")}`;
  }

  if (query) {
    searchQuery.search += ` ${query}`;
  }

  if (cursor) {
    searchQuery.cursor = cursor;
  }

  return qs.stringify(searchQuery);
}

export default buildSearchQuery;

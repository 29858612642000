import React from "react";
import styled from "styled-components";
import { Row } from "reactstrap";

import images from "~/images";
import { prettyAddress } from "./utils";
import previewProps from "./props";

const StyledPreviewHeader = styled.div``;

const PreviewHeader = ({ address }) => {
  return (
    <StyledPreviewHeader>
      <Row className="justify-content-center">
        <img
          className="boatyard-logo"
          src={images[WL.logo]}
          alt={WL.title}
          style={{
            maxWidth: '100%',
            height: 'auto',
            maxHeight: '80px'
          }}
        />
        {/* {WL.title} */}
      </Row>
      {address ? (
        <Row className="justify-content-center">{prettyAddress(address)}</Row>
      ) : null}
    </StyledPreviewHeader>
  );
};

const { facility: address } = previewProps;

PreviewHeader.propTypes = {
  address
};

export default PreviewHeader;

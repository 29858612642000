import React from "react";
import PropTypes from "prop-types";

export const LoadingIndicator = ({ loading }) => {
  return (
    <div>
      <i className="fas fa-spinner fa-spin" />
    </div>
  );
};

export const NewLoadingIndicator = ({ loading }) => {
  if (!loading) return null;

  return (
    <div>
      <i className="fas fa-spinner fa-spin" />
    </div>
  );
};

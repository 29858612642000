import { Route, Redirect } from "react-router-dom";
import React, { Component } from "react";
import { withAuth } from "~/util/api/withAuth";
import { observer } from "mobx-react";
import { binnacleStore } from "~/util/store";
import { observe, action, autorun } from "mobx";

let PrivateRouteComponent = withAuth(function(props) {
  let { component: Component, render, componentProps } = props;

  if (props.authLoading) {
    return null;
  }

  if (props.isAuthenticated) {
    if (Component) {
      return <Component {...componentProps} />;
    } else if (render) {
      return render(componentProps);
    }
  } else {
    return (
      <Redirect
        to={{
          pathname: `/login/`,
          from: componentProps.location
            ? componentProps.location.pathname
            : undefined
        }}
      />
    );
  }

  return null;
});

export default class PrivateRoute extends Component {
  constructor(props) {
    super(props);
    this.renderComponent = this.renderComponent.bind(this);
  }

  renderComponent(props) {
    let { component, render, ...rest } = this.props;
    return (
      <PrivateRouteComponent
        component={component}
        render={render}
        componentProps={props}
      />
    );
  }

  render() {
    let { component, render, ...rest } = this.props;
    return <Route {...rest} render={this.renderComponent} />;
  }
}

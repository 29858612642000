import React from "react";
import { PropTypes } from "prop-types";
import { currency, smartRound } from "~/util/currency";
import { CALC_TYPES } from "~/components/workOrders/calculations";
import { getUnitName } from "~/components/workOrders/util";

const DisplayField = ({ units, unitName, boatSpecs = {}, coverage }) => {
  if (unitName === "SFXF") {
    return (
      <span className="text-nowrap">
        {(boatSpecs.surface_area / coverage) * units} SFXFS
      </span>
    );
  }
  if (unitName !== "SFXF") {
    return (
      <span className="text-nowrap">
        {_.round(units, 1)} {unitName && getUnitName(unitName, units)}
      </span>
    );
  }
};

const ItemUnitField = ({ appendText, onChange, type, value, error }) => (
  <div className="form-group mb-0">
    <div className="input-group" style={{ flexWrap: "nowrap" }}>
      <input
        className="form-control"
        id="bootstrap-field-107"
        type={type}
        min="0"
        placeholder=""
        value={value || ""}
        style={{ minWidth: "75px" }}
        onChange={onChange}
        step="any"
        onBlur={e => {
          onChange({ target: { value: e.target.value } });
        }}
      />

      {appendText && (
        <div className="input-group-append">
          <span className="input-group-text">{appendText}</span>
        </div>
      )}
    </div>
    {/* {error && <div className="invalid-feedback">{error}</div>} */}
  </div>
);

const unitPrefixMap = {
  labor: {
    labor_uom: "uom",
    labor_ppu: "ppu",
    labor_units: "units",
    labor_coverage: "coverage",
    labor_unit_name: "unit_name"
  },
  materials: {
    materials_uom: "uom",
    materials_ppu: "ppu",
    materials_units: "units",
    materials_coverage: "coverage",
    materials_unit_name: "unit_name"
  }
};

export function unitPrefixMapper(item, prefix) {
  const prefixMap = unitPrefixMap[prefix];
  const {
    uom,
    units,
    coverage,
    unit_name: unitName
  } = _.mapKeys(_.pick(item, _.keys(prefixMap)), (v, k) => prefixMap[k]);
  const fieldName = `${prefix}_units`;
  return { uom, units, coverage, unitName, fieldName };
}

export function validateWorkItem(item) {
  let isValid = {};
  for (let prefix in unitPrefixMap) {
    const { uom, units, coverage, fieldName } = unitPrefixMapper(item, prefix);
    if (_.includes(["unit", "linearx"], uom) && _.isEmpty(units))
      isValid[fieldName] = "This field is required";
  }
  return isValid;
}

const WorkItem = ({
  canEdit = true,
  item,
  onUnitsChange,
  boatSpecs = {},
  errors = {}
}) => {
  const getBSProps = (field, type = "number") => ({
    field,
    type,
    onChange: e => onUnitsChange(field, parseFloat(e.target.value))
  });

  const unitFieldOrDisplay = prefix => {
    const { uom, units, coverage, unitName, fieldName } = unitPrefixMapper(
      item,
      prefix
    );
    const error = errors[fieldName];
    let calc = CALC_TYPES[uom];

    if (calc === undefined) {
      return null;
    }

    let unit_type = calc.units;
    if (unit_type === "hidden") return null; // not showing units
    if (!canEdit && isNaN(parseFloat(units))) return null; // 0 units doesn't need to be shown
    if (!canEdit || (unit_type === "display" && coverage))
      return <DisplayField {...{ units, unitName, boatSpecs, coverage }} />;
    if (unit_type === "editable") {
      return (
        <ItemUnitField
          {...getBSProps(fieldName)}
          value={parseFloat(units)}
          error={error}
          appendText={unitName ? getUnitName(unitName, units) : undefined}
        />
      );
    }
    return null;
  };

  return (
    <>
      <td className="align-middle">{item.title}</td>
      <td className="align-middle">{unitFieldOrDisplay("labor")}</td>
      <td className="align-middle">{unitFieldOrDisplay("materials")}</td>
      <td className="text-right align-middle">{currency(item.price)}</td>
    </>
  );
};

WorkItem.propTypes = {
  canEdit: PropTypes.bool
};

export default WorkItem;

import React, { Component } from "react";
import LoadingButton from "./loadingButton";
import { binnacleClient } from "~/util/api";
import fileDownload from "js-file-download";

export default class DownloadFileButton extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.state = {
      downloading: false
    };
  }

  onClick = () => {
    let component = this;
    this.setState({ downloading: true });

    binnacleClient
      .get(this.props.url, { responseType: "arraybuffer" })
      .then((response) => {
        let filename =
          this.props.filename ||
          _.split(response.headers["content-disposition"], "=")[1];
        let mime = response.headers["content-type"];
        fileDownload(response.data, filename, mime);
        component.setState({ downloading: false });
      });
  };

  render() {
    let { url, ...rest } = this.props;

    return (
      <LoadingButton
        loading={this.state.downloading}
        {...rest}
        onClick={this.onClick}
        type="button"
      />
    );
  }
}

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Button, Input } from "reactstrap";

import Fa from "~/util/fa";

const Wrapper = styled.div`
  position: relative;
  margin-bottom: 2rem;

  ::placeholder,
  ::-moz-placeholder,
  ::-ms-input-placeholder,
  ::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.25);
  }
`;

const StyledToggleButton = styled(Button)`
  position: absolute;
  top: 10px;
  right: 0;
`;

const StyledFa = styled(Fa)``;

const TextEditorHeader = styled.div`
  text-align: center;
  padding: 0.5rem;
`;

const StyledInput = styled(Input)`
  min-height: 150px;
`;

const TextBox = styled.div`
  min-height: 150px;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border: 1px solid transparent;
  color: ${p => (p.isPlaceholder ? "#aaa" : "")};
`;

class PreviewEditor extends React.Component {
  state = {
    currentMessage: "",
    mode: "edit"
  };

  componentDidMount() {
    this.setState({ currentMessage: this.props.message });
  }

  handleToggleEditing = () => {
    const { mode } = this.state;
    this.setState({ mode: mode === "edit" ? "view" : "edit" }, () => {
      if (mode === "edit") {
        this.props.onSave(this.state.currentMessage);
      }
    });
  };

  handleChangeText = event => {
    const { value } = event.target;
    this.setState({ currentMessage: value });
  };

  render() {
    const { title, placeholder } = this.props;
    const { mode, currentMessage } = this.state;

    const editing = mode === "edit";

    return (
      <Wrapper>
        <StyledToggleButton
          className="PreviewEditor__toggle-mode"
          data-testid="btn-toggle-email-preview"
          color="primary"
          onClick={this.handleToggleEditing}
        >
          <StyledFa color="primary" icon={editing ? "check" : "edit"} />
        </StyledToggleButton>
        <TextEditorHeader>
          <h2>{title}</h2>
        </TextEditorHeader>
        {editing ? (
          <StyledInput
            type="textarea"
            name="message"
            placeholder={placeholder}
            onChange={this.handleChangeText}
            defaultValue={currentMessage}
          />
        ) : (
          <TextBox isPlaceholder={!currentMessage}>
            {currentMessage || placeholder}
          </TextBox>
        )}
      </Wrapper>
    );
  }
}

PreviewEditor.propTypes = {
  message: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onSave: PropTypes.func,
  title: PropTypes.string
};

PreviewEditor.defaultProps = {
  message: ""
};

export default PreviewEditor;

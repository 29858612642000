import React, { Component } from "react";

export const BoatyardContext = React.createContext({});
BoatyardContext.Provider.displayName = "BoatyardContext.Provider";
BoatyardContext.Consumer.displayName = "BoatyardContext.Consumer";
/**
 * This is a higher order component that simply inserts the facility object into props
 */
export default function withBoatyard(WrappedComponent) {
  return class extends Component {
    render() {
      return (
        <BoatyardContext.Consumer>
          {value => <WrappedComponent {...this.props} boatyard={value} />}
        </BoatyardContext.Consumer>
      );
    }
  };
}

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Label, Input, FormGroup } from "reactstrap";

import { workOrderProps } from "~/data/props";
import {
  nounLabels,
  ApproveButton,
  containerStyles
} from "~/components/approve";
import Fa from "~/util/fa";
// import { useStateWithPromise } from "~/util/util";

export const checkboxes = [
  {
    value: "timing",
    label: "The proposed service does not fit my time schedule",
    checked: false
  },
  {
    value: "call",
    label: "Please call me to discuss the work in more detail",
    checked: false
  },
  {
    value: "later",
    label: "I plan to schedule this service at a later date",
    checked: false
  },
  {
    value: "expensive",
    label: "The price is higher than anticipated for outlined service",
    checked: false
  },
  {
    value: "someone_else",
    label: "I plan to have the service completed by someone else",
    checked: false
  },
  { value: "other", label: "Other", checked: false }
];

const ApproveDecline = ({ workOrder, noun, handleSetView, token }) => {
  // const ApproveDecline = ({ workOrder, noun, handleSetView }) => {
  const [checkboxState, setCheckboxes] = useState(checkboxes);
  const [reason, setReason] = useState("");

  const toggleCheckbox = value => {
    const next = checkboxState.reduce((nextCheckboxes, checkbox) => {
      if (value === checkbox.value) {
        checkbox.checked = !checkbox.checked;
      }
      nextCheckboxes.push(checkbox);
      return nextCheckboxes;
    }, []);
    return next;
  };

  const data = {
    status: "rejected",
    rejection_reason: [
      ...checkboxState.filter(c => c.checked).map(c => c.label),
      reason
    ].join(". "),
    work_order_note_type: "customer_reply"
  };

  const approveProps = {
    id: workOrder.id,
    noun: nounLabels[noun],
    hideNoun: true,
    icon: "ban",
    token,
    onCompleteRequest: handleSetView("success", {
      title: `${noun === "change" ? "Revised " : ""} Estimate Declined`,
      message: "We've received your message and will be in touch."
    }),
    data,
    method: "POST",
    url: `/work-orders/${workOrder.id}/reject/`,
    btnStyle: "danger",
    disabled: reason.length < 5 || !checkboxState.filter(c => c.checked).length,
    className: "btn-outline-danger d-block w-100 w-md-auto d-md-inline-block",
    label: "Confirm Decline"
  };

  return (
    <div data-testid="wo-approvedecline-wrapper" className="py-2 w-100 d-flex flex-column align-items-center">
      <h1 className="mb-4" style={{ fontSize: "2em", color: "#181818" }}>
        Decline {noun === "change" ? "Revised " : ""}Estimate
      </h1>
      <FormGroup className="mb-3" style={containerStyles}>
        {checkboxState.map(({ value, label, checked }) => {
          return (
            <div
              key={`approve-decline-${value}`}
              className={`approve-decline__checkbox approve-decline-${value} custom-control custom-checkbox mb-2"`}
            >
              <Input
                data-testid={`checkbox-${value}`}
                id={`approve-decline-${value}`}
                name={`approve-decline-${value}`}
                checked={checked}
                onChange={e => setCheckboxes(toggleCheckbox(value))}
                type="checkbox"
                className="custom-control-input form-check-input"
              />
              <Label
                className="custom-control-label"
                htmlFor={`approve-decline-${value}`}
              >
                {label}
              </Label>
            </div>
          );
        })}
      </FormGroup>
      <div className="mb-3 approve-decline__reason" style={containerStyles}>
        <label for="input-decline-reason" className="mb-2">Reason</label>
        <Input
          id="input-decline-reason"
          data-testid="input-decline-reason"
          defaultValue={reason}
          type="textarea"
          name="approve-decline-reason"
          placeholder="Please select a checkbox above and give us a brief explanation of why you are declining this estimate..."
          onChange={e => setReason(e.target.value)}
          style={{ minHeight: "140px" }}
        />
      </div>
      <div
        style={containerStyles}
        className="py-1 d-flex justify-content-center flex-wrap"
      >
        <Button
          data-testid="btn-back"
          className="btn-outline-secondary mb-3 mb-md-0 mr-md-2 w-100 w-md-auto d-md-inline-block"
          onClick={handleSetView("main")}
          color=""
        >
          <Fa icon="arrow-left" className="text-black mr-2" />
          Back
        </Button>
        <ApproveButton {...approveProps} />
      </div>
    </div>
  );
};

ApproveDecline.propTypes = {
  noun: PropTypes.oneOf(["estimate", "change"]),
  token: PropTypes.string,
  handleSetView: PropTypes.func,
  workOrder: workOrderProps
};

export default ApproveDecline;

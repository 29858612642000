export const kindList = [
  {
    id: "workorder",
    label: "Work Orders",
    icon: "far fa-file-alt"
  },
  {
    id: "promotion",
    label: "Promotions",
    icon: "fas fa-star"
  },
  {
    id: "boat",
    label: "Boats",
    icon: "fas fa-ship"
  },
  {
    id: "profile",
    label: "Profiles",
    icon: "far fa-user"
  },
  {
    id: "facility",
    label: "Facilities",
    icon: "fas fa-map-marker-alt"
  },
  {
    id: "copy",
    label: "Copy",
    icon: "far fa-clone"
  }
];
export default _.keyBy(kindList, "id");

import { useState, useEffect } from "react";
/*
 * nolodash.js - is a file that contains functions that are similar to lodash functions
 * which couldn't be simplified into simple functions with the help of es6 features like destructuring, spread, etc.
 * It's so that we can remove lodash from our project.  It's part of a push to get leaner and meaner.
 */

/*
 *  debounce - is a function that returns a function,
 *  that, as long as it continues to be invoked, will not be triggered.
 */
export const debounce = function debounce(fn, delay) {
  let timer = null;
  return function () {
    const context = this,
      args = arguments;
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(context, args);
    }, delay);
  };
};

/*
 * Hook version of debounce
 */
export const useDebounce = function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

/*
 *  isEmpty - is a function that returns true if the value is empty.
 */
export const isEmpty = function isEmpty(value) {
  return (
    value === undefined ||
    value === null ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0)
  );
};

/*
 * keyBy - is a function that returns an object composed of keys generated from the results of running each element of collection thru iteratee.
 */
export const keyBy = function keyBy(collection, iteratee) {
  return collection.reduce((acc, item) => {
    acc[item[iteratee]] = item;
    return acc;
  }, {});
};

export const range = function range(start, end) {
  return Array.from({ length: end - start }, (v, k) => k + start);
};

// alternative to above

// const range = (start, end) => Array.from(
//   Array(Math.abs(end - start) + 1),
//   (_, i) => start + i
// );

export const statusList = [
  {
    id: "rejected",
    label: "Declined"
    // color: "#C9241A",
  },
  {
    id: "draft",
    label: "Draft"
    // color: "#D19F3D",
  },
  {
    id: "pending",
    label: "Pending"
    // color: "#D1723D",
  },
  {
    id: "approved",
    label: "Approved"
    // color: "#3D96D1",
  },
  {
    id: "complete",
    label: "Completed"
    // color: "#6EC866",
  },
  {
    id: "dismissed",
    label: "Dismissed"
    // color: "#C9241A",
  },
  {
    id: "replaced",
    label: "Changed"
    // color: "#7743E7",
  }
];
export default _.keyBy(statusList, "id");

import axios from "axios";
import { binnacleStore } from "~/util/store";
import { binnacleClient } from "~/util/api";

async function refreshUserToken(refreshToken) {
  let data = { refresh: refreshToken };
  let headers = { headers: { Authorization: null } }; // clear any header that was set

  try {
    let response = await binnacleClient.post("/users/refresh/", data, headers);
    binnacleStore.updateUserTokenInfo(response.data);
    return response.data.access;
  } catch (error) {
    if (error.response.status === 401) {
      binnacleStore.clearUserTokenInfo();
    }
    return null;
  }
}
let test = 0;
/**
 * Attempts to retrieve the JWT access token.
 * Calls onComplete when token or no token is available.
 */
export async function getAccessToken() {
  if (binnacleStore.hydrated === 0) {
    return new Promise(resolve => {
      setTimeout(resolve, 10);
    }).then(function() {
      return getAccessToken();
    });
  }
  let tokenInfo = binnacleStore.userTokenInfo;

  if (_.some([tokenInfo, tokenInfo.access, tokenInfo.refresh], _.isEmpty)) {
    binnacleStore.cacheApiData = {};
    return null;
  }

  let currentTimeStamp = Math.floor(Date.now() / 1000);

  if (currentTimeStamp >= tokenInfo.access_expires) {
    if (currentTimeStamp < tokenInfo.refresh_expires) {
      // We need to refresh
      return refreshUserToken(tokenInfo.refresh);
    } else {
      // the access token has expired, and the refresh token has expired. Need to login manually again.
      binnacleStore.cacheApiData = {};
      return null;
    }
  } else {
    // the access token has not expired yet. We're good.
    return tokenInfo.access;
  }
}

function authInterceptor(config) {
  let originalConfig = config;
  if (!_.has(originalConfig.headers, "Authorization")) {
    return getAccessToken()
      .then(token => {
        if (!_.isEmpty(token)) {
          originalConfig.headers["Authorization"] = "Bearer " + token;
        }
        return Promise.resolve(originalConfig);
      })
      .catch(function(error) {
        console.log(error);
      });
  } else {
    return config;
  }
}

const attachAuth = client => client.interceptors.request.use(authInterceptor);

const attachLogs = client =>
  client.interceptors.response.use(undefined, error => {
    if (axios.isCancel(error)) {
      return Promise.reject(error);
    } else if (error.response === undefined) {
      if (error.message !== undefined) {
        setTimeout(() => {
          console.log(error);
        }, 1000);
      }
    } else if (
      (error.response.status === 403 || error.response.status === 401) &&
      error.config.url !== "/api/users/login/"
    ) {
      document.location = "/login/?next=" + document.location.pathname;
    } else if (error.response.status === 404) {
      if (error.config.ignore404 === true) {
        return Promise.reject(error);
      }

      document.location = "/404/";
    } else {
      return Promise.reject(error);
    }

    return new Promise(() => {});
  });

export const attachRequestCallback = (client, callback) =>
  client.interceptors.request.use(config => {
    callback();
    return config;
  });

export const attachResponseCallback = (client, res, err) =>
  client.interceptors.response.use(res, err);

// Attach All default interceptors
export default function(client) {
  attachAuth(client);
  attachLogs(client);
}

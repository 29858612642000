import React, { Component, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { withProfile } from "~/util/api/withProfile";
import { Link } from "react-router-dom";
import DownloadFileButton from "~/util/DownloadFileButton";
import { binnacleClient } from "~/util/api";
import DeleteProfileButton from "./DeleteProfileButton";
import Fa from "~/util/fa";
import LoadingButton from "~/util/loadingButton";
import NavBar from "~/components/navbar";
import ExternalLink from "~/util/ExternalLink";

const bgImage = require("~/images/bg_boatyard_img.jpg");

const DeleteButton = ({ profile: { id, name } }) => {
  const [isOpen, setOpen] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const [deleted, setDeleted] = useState(false);
  function deleteProfile() {
    setDeleting(true);
    binnacleClient.delete(`/profiles/${id}/`).then(function () {
      setDeleting(false);
      setDeleted(true);
      setTimeout(() => {
        window.location = "/?filter=profile";
      }, 1000);
    });
  }
  return (
    <>
      <Button
        className="btn-outline-danger ProfileDetail__delete_button"
        onClick={() => setOpen(true)}
      >
        <Fa icon="ban" /> Delete
      </Button>
      <Modal isOpen={isOpen} toggle={() => setOpen(!isOpen)}>
        <ModalHeader>Delete {name}'s account</ModalHeader>
        <ModalBody>
          <p>
            Are you sure you want to delete {name}'s account? This will
            permanently delete {name}'s data, and they will no longer have
            access to their account.
          </p>
        </ModalBody>
        <ModalFooter>
          {deleted ? (
            <span>
              <Fa icon="check" className="mr-2" />
              Account Deleted! Redirecting to homepage...
            </span>
          ) : (
            <LoadingButton
              className="btn-danger"
              onClick={deleteProfile}
              loading={isDeleting}
            >
              {isDeleting ? "Deleting..." : "Yes, I'm sure. Delete"}
            </LoadingButton>
          )}
          <Button color="secondary" onClick={() => setOpen(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export class ProfileDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: null,
      deleting: false
    };
  }

  getId() {
    return _.get(this.props, "match.params.id");
  }

  canEditProfile() {
    const { profileIsType, profile } = this.props;
    return (
      (profile && profile.id === this.getId()) ||
      profileIsType(["facility_manager", "admin", "service_writer"])
    );
  }

  getProfile() {
    if (this.getId()) {
      return this.state.profile;
    } else {
      return this.props.profile;
    }
  }

  componentDidMount() {
    if (this.getId()) {
      const component = this;
      binnacleClient.get(`/profiles/${this.getId()}/`).then(
        function (response) {
          if (response.status !== 200) {
            component.setState({
              error_msg:
                "An error occurred and the Service Request information couldn't be retrieved."
            });
          }
          component.setState({ profile: response.data });
        },
        function (error) {
          component.setState({
            error_msg:
              "An error occurred and the Service Request information couldn't be retrieved."
          });
        }
      );
    }
  }

  // Hide QB fields if profile_types contains "service_worker", "service_writer", "facility_manager", or "admin"
  renderQuickbooksFields(qb_customer_id, qb_customer_name) {
    const { profile_types } = this.getProfile();

    if (
      profile_types.includes("service_worker") ||
      profile_types.includes("service_writer") ||
      profile_types.includes("facility_manager") ||
      profile_types.includes("admin")
    ) {
      return null;
    } else {
      return (
        <>
          {qb_customer_id ? (
            <Row className="mb-3 ProfileDetail__qb_customer_id">
              <Col xs="6" className="px-0">
                {" "}
                <span
                  style={{ display: "inline-block", minWidth: "70px" }}
                  className="mr-5"
                >
                  QuickBooks ID:
                </span>{" "}
              </Col>
              <Col xs="6" className="px-0">
                {" "}
                <span>{qb_customer_id}</span>
              </Col>
            </Row>
          ) : null}
          {qb_customer_name ? (
            <Row className="mb-3 ProfileDetail__qb_customer_name">
              <Col xs="6" className="px-0">
                {" "}
                <span
                  style={{ display: "inline-block", minWidth: "70px" }}
                  className="mr-5"
                >
                  QuickBooks Customer Name:
                </span>{" "}
              </Col>
              <Col xs="6" className="px-0">
                {" "}
                <span>{qb_customer_name}</span>
              </Col>
            </Row>
          ) : null}
        </>
      );
    }
  }

  renderProfileInfo() {
    if (!this.props.profile && this.state.profile) return null;

    const { name, email, phone, qb_customer_id, qb_customer_name } =
      this.getProfile();
    return (
      <>
        <h2 className="ProfileDetail__name" style={{ fontWeight: "600" }}>
          {name ? name : "Account Information"}
        </h2>
        <hr />

        <Container>
          {email ? (
            <Row className="mb-3 ProfileDetail__email">
              <Col xs="6" className="px-0">
                {" "}
                <span
                  style={{ display: "inline-block", minWidth: "70px" }}
                  className="mr-5"
                >
                  Email:
                </span>{" "}
              </Col>
              <Col xs="6" className="px-0">
                {" "}
                <ExternalLink
                  style={{ textDecoration: "underline", fontStyle: "italic" }}
                  type="email"
                  link={email}
                />
              </Col>
            </Row>
          ) : null}

          {phone ? (
            <Row className="mb-3 ProfileDetail__phone">
              <Col xs="6" className="px-0">
                {" "}
                <span
                  style={{ display: "inline-block", minWidth: "70px" }}
                  className="mr-5"
                >
                  Phone:
                </span>{" "}
              </Col>
              <Col xs="6" className="px-0">
                {" "}
                <ExternalLink
                  style={{ textDecoration: "underline", fontStyle: "italic" }}
                  type="phone"
                  link={phone}
                />
              </Col>
            </Row>
          ) : null}
          {this.renderQuickbooksFields(qb_customer_id, qb_customer_name)}
        </Container>
      </>
    );
  }

  renderDownloadData() {
    if (this.getId()) return null;
    const profile = this.getProfile();
    return (
      <DownloadFileButton
        url={`/profiles/${profile.id}/download/`}
        className="btn-link p-0"
      >
        Download my data
      </DownloadFileButton>
    );
  }

  renderActions() {
    const profile = this.getProfile();
    const isAdmin = this.props.profileIsType([
      "service_writer",
      "facility_manager",
      "admin"
    ]);
    return (
      <div className="d-flex justify-content-between">
        <div className="ProfileDetail__edit_button order-2">
          {this.canEditProfile() ? (
            <Link
              className="btn btn-outline-primary mr-2"
              to={`/profile/${
                this.getId() && profile ? `${profile.id}/` : ""
              }edit/`}
            >
              <Fa icon="pencil-alt" className="mr-2" />
              Edit
            </Link>
          ) : null}

          {isAdmin && this.getId() && <DeleteButton profile={profile} />}
        </div>

        {this.getId() ? null : (
          <div className="d-flex flex-column align-items-start order-1">
            <h6>Account Settings</h6>
            <Link className="btn-link" to="/password-change/">
              Change my password
            </Link>
            {this.renderDownloadData()}
            <DeleteProfileButton profileId={profile.id} />
          </div>
        )}
      </div>
    );
  }

  render() {
    if (!this.getProfile()) return null;
    document.title = `Pettit Pro Portal | Profile`;

    return (
      <>
        <NavBar details={true} className="bg-white" />
        <Container>
          <Row>
            <Col
              xs="12"
              sm={{ size: 10, offset: 1 }}
              md={{ size: 8, offset: 2 }}
            >
              <Card className="p-5 my-3">
                <CardBody>
                  {this.renderProfileInfo()}
                  <hr />
                  {this.renderActions()}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* <div
          className="bg-image-full bg-img-light-blur"
          style={{ backgroundImage: `url('${bgImage}')` }}
        ></div>  */}
      </>
    );
  }
}

export default withProfile(ProfileDetail);

import pluralize from "pluralize";

export function getUnitName(unit_name, units) {
  if (unit_name) {
    // if the unit name contains spaces, then use this logic to determine the unit name
    if (unit_name !== "%" && unit_name.includes(" ")) {
      // split the string, throw the last element into a variable and then join the rest of the string back together
      let [lastPartOfUnitName, ...restOfUnitName] = unit_name
        .split(" ")
        .reverse();
      lastPartOfUnitName = pluralize(lastPartOfUnitName, _.round(units, 1));
      // put it back together and then reverse it back to the original order and then return it
      return restOfUnitName.reverse().join(" ") + " " + lastPartOfUnitName;
    } else if (unit_name !== "%" && !unit_name.includes(" ")) {
      return pluralize(unit_name, _.round(units, 1));
    }
    return unit_name;
  } else {
    return "";
  }
}

import React from "react";
import LinkDuo from "~/util/linkDuo";
import Fa from "~/util/alpacaLegacyUtils";
import BoatRoundedImage from "~/components/boat/BoatRoundedImage";
const BoatCard = ({
  extra: { images, name, make, model, year, ...extraProps },
  link,
  facility,
  // make new property for address from facility.address.street and facility.address.city
  address = `${facility.address.street}, ${facility.address.city} ${facility.address.state} ${facility.address.zip}`,
  kind,
  userProfile,
  updateItem,
  ...props
}) => {
  return (
    <div className="justify-content-between d-flex w-100 flex-column inner-custom-card ">
      {/* First Row */}
      <div className="d-flex align-items-center justify-content-between p-2 ">
        <div>
          <h6 className="title truncate text-uppercase ml-2 item-card__header">
            Boat
          </h6>
        </div>
        <div className="d-flex">
          <div>
            <LinkDuo
              onClick={() => {
                // GTM-event
                window.dataLayer.push({
                  event: "searchCardClick",
                  eventProps: {
                    category: "search_actions",
                    action: "search_card_click",
                    // label will be set in GTM as page hostname
                    value: link,
                    cardType: kind,
                    userId: userProfile?.id,
                    userName: userProfile?.name,
                    facilityId: userProfile?.facility_id
                  }
                });
              }}
              to={link || "/400"}
              data-testid="search-card-link"
              style={{
                width: "27px",
                height: "29px",
                border: "1px solid #D3DAE0",
                borderRadius: "4px"
              }}
              className="rounded border d-flex justify-content-center align-items-center ml-1"
            >
              <Fa className="text-primary" icon="angle-right" />
            </LinkDuo>
          </div>
        </div>
      </div>
      <hr className="mt-1 mb-1 w-100" />
      <div className="d-flex justify-content-between px-2 py-0">
        <div className="d-flex align-items-center">
          <BoatRoundedImage
            color="dark"
            size={52}
            enableModal={true}
            images={images}
          />
          <h6
            data-testid="search-card-boat-name"
            className="mx-1 title truncate text-uppercase"
          >
            {name}
          </h6>
        </div>
      </div>
      <hr className="mt-1 mb-1 w-100" />

      {/* details row */}
      <div className="d-flex justify-content-between p-2 ">
        <div className="px-2">
          {" "}
          <h6 data-testid="boat-make" className="title truncate text-uppercase">
            {make}
          </h6>
          <div data-testid="boat-address" className="card-boat-address">
            {address}
          </div>
          <div data-testid="boat-year"> {year}</div>
        </div>
        <div className="d-flex">
          <div>
            <div>{/* Nothing on right side */}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoatCard;

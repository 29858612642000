import React from "react";
import { Route, Switch } from "react-router-dom";

import NotFoundPage from "~/views/notFound";
import PrivateRoute from "~/util/privateRoute";

import { EditWorkOrder, WorkUpdateReply } from "~/views/WorkOrder";
import { PrintWorkList } from "~/components/workOrders/PrintWorkList";
import { PrintEstimate } from "~/components/workOrders/PrintEstimate";

import { ApproveQuote, ApproveChangeRequest } from "~/views/Approve";
import PrintChangeOrder from "~/views/PrintChangeOrder";
import BoatyardAdmin from "~/views/BoatyardAdmin";
import LoginScreen from "~/components/auth/Login";
// import RegisterScreen from "~/components/auth/Register";
// import CompleteRegistrationScreen from "~/components/auth/CompleteRegistration";
// import VerifyPhoneScreen from "~/components/auth/VerifyPhone";
import PasswordChangeScreen from "~/components/auth/PasswordChange";
import PasswordResetConfirmScreen from "~/components/auth/PasswordReset";
import ProfileEdit from "~/components/profiles/Edit";
import ProfileDetail from "~/components/profiles/Detail";
import CompleteRegistrationScreen from "~/components/auth/CompleteRegistration";
import VerifyPhoneScreen from "~/components/auth/VerifyPhone";

// "^/work-orders/..."
export const WorkOrderRoutes = ({ match: { path } }) => (
  <Switch>
    <PrivateRoute path={`${path}/create/`} exact component={EditWorkOrder} />
    <PrivateRoute path={`${path}/:id/`} exact component={EditWorkOrder} />
    <PrivateRoute
      path={`${path}/:id/create-change-order/`}
      exact
      component={EditWorkOrder}
    />
    <Route
      path={`${path}/:id/customer-reply/`}
      exact
      component={WorkUpdateReply}
    />
    <Route component={NotFoundPage} />
  </Switch>
);

// const exact = true;
export const authRoutes = [
  { path: "/login/", component: LoginScreen },
  // {path:"/register/", component: RegisterScreen, exact},
  {
    path: "/complete-registration/:token/",
    component: CompleteRegistrationScreen,
    exact: true
  },
  { path: "/verify-phone/:token/", component: VerifyPhoneScreen, exact: true },
  { path: "/password-change/", component: PasswordChangeScreen },
  {
    path: "/password-reset/",
    component: PasswordResetConfirmScreen,
    exact: true
  },
  {
    path: "/password-reset/:uuid/:token/",
    component: PasswordResetConfirmScreen
  }
];

export const ProfileRoutes = ({ match: { path } }) => (
  <Switch>
    <PrivateRoute path={`${path}/:id/edit/`} exact component={ProfileEdit} />
    <PrivateRoute path={`${path}/create/`} component={ProfileEdit} />
    <PrivateRoute path={`${path}/edit/`} exact component={ProfileEdit} />
    <PrivateRoute path={`${path}/:id/`} component={ProfileDetail} />
    <PrivateRoute path={`${path}`} exact component={ProfileDetail} />
    <Route component={NotFoundPage} />
  </Switch>
);

export const PrintViews = ({ match: { path } }) => (
  <Switch>
    <PrivateRoute
      path={`${path}/estimate/:id`}
      exact
      component={PrintEstimate}
    />
    <PrivateRoute
      path={`${path}/work-list/:id`}
      exact
      component={PrintWorkList}
    />
    <PrivateRoute
      path={`${path}/change-order/:id`}
      exact
      component={PrintChangeOrder}
    />
  </Switch>
);

export const ApproveViews = ({ match: { path } }) => (
  <Switch>
    <Route path={`${path}/quote/:id`} exact component={ApproveQuote} />
    <Route
      path={`${path}/change-order/:id`}
      exact
      component={ApproveChangeRequest}
    />
  </Switch>
);

export const AdminViews = ({ match: { path } }) => (
  <Switch>
    <PrivateRoute path={`${path}/boatyard`} exact component={BoatyardAdmin} />
  </Switch>
);

import React, { useState, useEffect } from "react";
// import { range } from "~/util/nolodash";
import { Input } from "reactstrap";
import Fa from "~/util/fa";
import { useDashboardStore } from "~/store";
import { shallow } from "zustand";
const SearchBar = ({}) => {
  const onFullSearchChange = e => {
    console.log("onFullSearchChange", e.target.value);
    setQuery(e.target.value);
  };
  const [advancedSearchActive, setAdvancedSearchActive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  // const toggle = () => setIsOpen(!isOpen);
  const { query, setQuery, setFireSearch } = useDashboardStore(
    state => ({
      query: state.query,
      setQuery: state.setQuery,
      setFireSearch: state.setFireSearch
    }),
    shallow
  );

  useEffect(() => {
    // setIsModalOpen(isOpen);
  }, [isOpen]);

  return (
    <div className="input-group p-relative">
      <Input
        className="input-search--feature"
        onChange={onFullSearchChange}
        placeholder="Search by boat, owner, keyword"
        value={query}
      />
      <span role="button" className="input-group-icon--feature input-group-text rounded-pill">
        <Fa icon="search" />
        Search
      </span>
    </div>
  );
};

export default SearchBar;

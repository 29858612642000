import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Button,
  Alert
} from "reactstrap";
// import { binnacleClient, boatsClient } from "~/util/api";
import QuickBooksIcon from "~/components/boatyard-admin/QuickBooksIcon";

const qbImageLocation = require("~/images/item_icons/qb-logo-h-alt.svg");
const QuickBooksDesktopHelpLink = () => {
  return (
    <a
      target="_blank"
      href="https://quickbooks.intuit.com/learn-support/en-us/help-article/import-export-data-files/import-export-data-quickbooks-desktop/L9KS42UxP_US_en_US"
      className={"ml-4 mb-1"}
    >
      Learn More
    </a>
  );
};

const QuickBooksOnlineHelpLink = () => {
  return (
    <a
      target="_blank"
      href="https://quickbooks.intuit.com/learn-support/en-us/help-article/business-profile/learn-quickbooks-online-videos/L20eVgTOt_US_en_US?uid=l8kg1u70"
      className={"ml-4 mb-1"}
    >
      Learn More
    </a>
  );
};

const QuickBooksAdminCard = props => {
  const [OnlineCheckboxStatus, setOnlineCheckboxStatus] = useState(false);

  const [isQuickBooksConnected, setIsQuickBooksConnected] = useState(false);
  const [facilityId, setFacilityId] = useState();
  const [qbSettings, setQbSettings] = useState();
  const [quickBooksConnectLoading, setQuickBooksConnectLoading] =
    useState(false);
  const [QbId, setQbId] = useState("");

  const [QbUsername, setQbUsername] = useState("");

  const onOnlineCheckboxChange = async e => {
    let response;
    if (e.target.checked) {
      response = await UpdateQbSettings({ qb_desktop: false, qb_online: true });
    } else {
      response = await UpdateQbSettings({
        qb_desktop: false,
        qb_online: false
      });
    }
    console.log(response);
    console.log({ ...qbSettings, ...response.data });
    // set and merge the new settings with the previous settings
    setQbSettings({ ...qbSettings, ...response.data });
  };

  const onDesktopCheckboxChange = async e => {
    let response;
    if (e.target.checked) {
      response = await UpdateQbSettings({ qb_desktop: true, qb_online: false });
    } else {
      response = await UpdateQbSettings({
        qb_desktop: false,
        qb_online: false
      });
    }
    console.log(response);
    console.log({ ...qbSettings, ...response.data });
    // set and merge the new settings with the previous settings
    setQbSettings({ ...qbSettings, ...response.data });
  };

  const signOut = async () => {
    setQuickBooksConnectLoading(true);
    try {
      const response = await binnacleClient.post(
        `/facilities/${facilityId}/qb-logout/`
      );
      console.log(response);
      setQbSettings({ ...qbSettings, ...response.data });
      setQuickBooksConnectLoading(false);
    } catch (error) {
      console.log(error);
      setQuickBooksConnectLoading(false);
    }
  };

  // const onQbIdChange = e => {
  //   setQbId(e.target.value);
  // };

  // const onQbUsernameChange = e => {
  //   setQbUsername(e.target.value);
  // };

  // const isQbDataNotFilledOut = () => {
  //   if (QbId === "" || QbUsername === "") {
  //     return true;
  //   }

  //   return false;
  // };

  const getFacilityId = () => {
    return binnacleClient.get("/profiles/my_profile/");
  };

  const getQbSettings = facility_id => {
    return binnacleClient.get(`/facilities/${facility_id}/config/`);
  };

  const qbRedirect = () => {
    return binnacleClient.get("/facilities/qb-redirect/");
  };

  const UpdateQbSettings = qb_settings => {
    const { qb_desktop, qb_online } = qb_settings;
    return binnacleClient.post(`/facilities/${facilityId}/config/`, {
      qb_desktop,
      qb_online
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      // get facility id from the api
      const getFacilityResponse = await getFacilityId();
      // set facility id to local variable
      const facility_id = await getFacilityResponse.data.facility_id;
      console.log("getFacilityResponse", getFacilityResponse);
      // set facility id to state for use in other functions
      setFacilityId(facility_id);

      // get qb settings from the api
      const getQbSettingsResponse = await getQbSettings(facility_id);
      // set qb settings to local variable
      const qb_settings = await getQbSettingsResponse.data;
      // set qb settings to state for use in other functions
      setQbSettings(qb_settings);
      console.log(qb_settings);
    };

    fetchData()
      // make sure to catch any error
      .catch(error => {
        console.log(error);
      });
  }, []);

  const handleConnectToQuickBooks = async () => {
    const qbRedirectResponse = await qbRedirect();
    const qbRedirectUrl = await qbRedirectResponse.data.url;
    setQuickBooksConnectLoading(true);
    await setTimeout(() => {
      setQbSettings({ ...qbSettings, ...{ qb_logged_in: true } });

      setQuickBooksConnectLoading(false);
    }, 1400);
    // setIsQuickBooksConnected(true);
    // setIsEditing(false);
  };

  const DynamicQuickBooksConnectButton = () => {
    // Return a button based on the state of the component

    // Returns a loading button if the component is loading
    if (qbSettings.qb_logged_in) {
      return (
        <div className="d-flex align-items-center justify-content-center">
          <Alert className="d-flex align-items-center p-2 my-2 mr-3">
            {" "}
            <i
              style={{
                color: "#7DB419",
                fontSize: "1.25rem",
                paddingRight: ".7rem"
              }}
              className="fas fa-check-circle"
              aria-hidden="true"
            ></i>{" "}
            Account Connected!
          </Alert>
          <button
            onClick={() => {
              signOut();
            }}
            className="btn btn-outline-primary  my-2 "
          >
            Sign Out of QuickBooks
          </button>
        </div>
      );
    } else if (quickBooksConnectLoading) {
      return (
        <Button
          color="primary"
          className="btn btn-primary d-inline-flex align-items-center my-2 qb-connect ml-3"
          disabled={true}
          onClick={() => {}}
          style={{ cursor: "not-allowed" }}
        >
          <QuickBooksIcon bg="#FFFFFF" color="default" />
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          Connecting...
        </Button>
      );
    } else {
      const qbRedirectUrl = `/api/facilities/${facilityId}/qb-redirect/?qb_auth_key=${qbSettings.qb_auth_key}`;
      return (
        <>
          <Button
            href={qbRedirectUrl}
            color="primary"
            className="btn btn-primary d-inline-flex align-items-center my-2 qb-connect"
            disabled={false}
          >
            <QuickBooksIcon />
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Connect to QuickBooks Online{" "}
          </Button>{" "}
        </>
      );
    }
  };

  return (
    <Card className="p-1 my-2 flex-shrink-1 flex-grow-1 ">
      <CardBody>
        <h2 style={{ fontWeight: "400" }}>QuickBooks Integration</h2>
        <Form>
          <div className="d-flex justify-content-between">
            <div
              key={`quickbooks-online-connect`}
              className={`quickbooks-online_connect_checkbox quickbooks-online-connect custom-control custom-checkbox mb-2"`}
            >
              <Input
                id={`quickbooks-online-connect`}
                name={`quickbooks-online-connect`}
                checked={qbSettings && qbSettings.qb_online}
                onChange={e => onOnlineCheckboxChange(e)}
                type="checkbox"
                className="custom-control-input form-check-input"
              />
              <Label
                className="custom-control-label font-weight-bold"
                htmlFor={`quickbooks-online-connect`}
              >
                {"QuickBooks Online"}
              </Label>
            </div>
            {/* show success icon for qb online */}
            {false && (
              <div className="d-flex">
                <i
                  className={`fas fa-check-circle ${
                    true ? "text-success" : "text-muted"
                  }`}
                ></i>
              </div>
            )}
            {/* show error icon for qb online */}
            {false && (
              <div className="d-flex">
                <i
                  onClick={() => {}}
                  className={`fa fa-times ${
                    true ? "text-danger" : "text-muted"
                  }`}
                  aria-hidden="true"
                ></i>
              </div>
            )}
          </div>
          {qbSettings && qbSettings.qb_online && (
            <>
              <div className="qb-button-container ml-4">
                <DynamicQuickBooksConnectButton />
              </div>
            </>
          )}
          <div
            key={`quickbooks-desktop-connect`}
            className={`quickbooks-desktop_connect_checkbox quickbooks-desktop-connect custom-control custom-checkbox mb-2"`}
          >
            <Input
              id={`quickbooks-desktop-connect`}
              name={`quickbooks-desktop-connect`}
              checked={qbSettings && qbSettings.qb_desktop}
              onChange={e => onDesktopCheckboxChange(e)}
              type="checkbox"
              className="custom-control-input form-check-input"
            />
            <Label
              className="custom-control-label font-weight-bold"
              htmlFor={`quickbooks-desktop-connect`}
            >
              {"QuickBooks Desktop"}
            </Label>
          </div>
          {/* Hide help link for now */}
          {qbSettings?.qb_desktop && (
            <div className="mt-1 py-2 px-4">
              <p>
                To import work order invoice downloads with your QuickBooks
                Desktop instance, you'll need to use an additional third-party
                tool such as{" "}
                <a
                  style={{ textDecoration: "underline", fontWeight: "bold"}}
                  href="https://www.zed-systems.com/axis-import.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Zed Axis
                </a>
                . See their{" "}
                <a
                  style={{ textDecoration: "underline", fontWeight: "bold"}}
                  href="https://www.zed-systems.com/axis-demo.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  demos
                </a>{" "}
                for more.
              </p>
            </div>
          )}
        </Form>
      </CardBody>
    </Card>
  );
};

export default QuickBooksAdminCard;

import { observable, action } from "mobx";
import { create, persist } from "mobx-persist";

class UserTokenInfo {
  @persist @observable access = "";
  @persist @observable access_expires = 0;
  @persist @observable refresh = "";
  @persist @observable refresh_expires = 0;
}

class BinnacleStore {
  @persist("object", UserTokenInfo)
  @observable
  userTokenInfo = new UserTokenInfo();
  @observable hydrated = 0;
  @observable cacheApiData = observable.map();
  @observable requestProgress = 100;

  @action("UPDATE_USER_TOKEN_INFO")
  updateUserTokenInfo(tokenInfo) {
    this.userTokenInfo = _.assign(this.userTokenInfo, tokenInfo);
  }

  @action("CLEAR_USER_TOKEN_INFO")
  clearUserTokenInfo() {
    this.userTokenInfo = new UserTokenInfo();
  }
}

const hydrate = create({
  storage: localStorage
});

// create the state
export let binnacleStore = new BinnacleStore();
window.binnacleStore = binnacleStore;
hydrate("binnacleStore", binnacleStore).then(function(store) {
  binnacleStore.hydrated = 1;
});

import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import Fa from "~/util/fa";

export const StyledLink = styled.a`
  text-decoration: ${p => (p.linkType === "phone" ? "underline" : "")};

  i {
    color: gray;
  }
`;

const formatURL = (link, type) => {
  if (type == "email") return `mailto:${link}`;
  if (type == "phone") return `tel:${link}`;

  return link;
};

const renderFAIcon = (type, propsIcon) => {
  let icon = propsIcon;

  switch (type) {
    case "email":
      icon = "envelope";
      break;
    case "phone":
      icon = "phone";
      break;
    default:
  }

  if (!icon) return null;

  return <Fa icon={icon} spacing={1} />;
};

export const ExternalLink = ({
  type,
  link,
  icon,
  useIcon,
  className,
  style,
  children,
  onClick,
  target
}) => {
  const formattedURL = formatURL(link, type);

  return (
    <StyledLink
      target={target}
      onClick={onClick}
      className={`ExternalLink${className ? ` ${className}` : ""}`}
      href={formattedURL}
      style={style}
      linkType={type}
    >
      {useIcon && renderFAIcon(type, icon)}
      {children ? children : link}
    </StyledLink>
  );
};

ExternalLink.propTypes = {
  useIcon: PropTypes.bool,
  link: PropTypes.string.isRequired,
  icon: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.oneOf(["phone", "email", "website"]).isRequired,
  style: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  onClick: PropTypes.func,
  target: PropTypes.oneOf(["_blank", "_self", "_top", "_parent"])
};

ExternalLink.defaultProps = {
  type: "website",
  target: "_blank",
  style: {},
  useIcon: true,
  onClick: event => {}
};

export default ExternalLink;

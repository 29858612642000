import React from "react";
import { Row, Col } from "reactstrap";
import moment from "moment";
import { PrintLayout } from "~/components/print/PrintLayout";
import { LoadingIndicator } from "~/util/loadingWrapper";
import DevPre from "~/util/DevPre";
import withFacility from "~/util/api/withFacility";
import { withAPIData } from "~/util/alpacaLegacyAPI/";
import { ScheduleDisplayDiff } from "~/components/workOrders/ScheduleDisplay";
import { WorkItemsDisplayDiff } from "~/components/workOrders/WorkItemsDisplay";
import { lineBreaksHtml } from  "~/util/alpacaLegacyUtils";

const getPrimaryImage = boat => {
  try {
    const PrimaryImage = boat?.images?.find(image => image?.is_primary);
    return PrimaryImage.url;
  } catch (error) {
    console.error(error);
    return null;
  }
};

const PrintChangeOrder = withFacility(
  withAPIData(
    ({ loading, data: workOrder, facility }) => {
      if (loading) return <LoadingIndicator />;
      const statusDate = moment(workOrder.status_date).format("l");
      const ownerOrRep = _.isEmpty(workOrder.owner_profile)
        ? { label: "Representative", profile: workOrder.representative_profile }
        : { label: "Owner", profile: workOrder.owner_profile };

      return (
        <PrintLayout>
          <h2>Estimate</h2>
          <div className="my-4">{statusDate}</div>

          <Row className="my-4">
            <Col sm="4">
              <table>
                <tbody>
                  {ownerOrRep.profile != null && (
                    <tr>
                      <td style={{ minWidth: "8em" }}>
                        <strong>{ownerOrRep.label}</strong>
                      </td>
                      <td>{ownerOrRep.profile.name}</td>
                    </tr>
                  )}
                  <tr>
                    <td>
                      <strong>Boat:</strong>
                    </td>
                    <td>{workOrder.boat.name}</td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <Col sm="8">
              {/* {JSON.stringify(workOrder?.boat?.images)} */}
              {getPrimaryImage(workOrder.boat) ? (
                <img src={getPrimaryImage(workOrder.boat)} style={{
                  width: "100%",
                  height: "auto",
                  maxHeight: "400px",
                  objectFit: "contain",
                  objectPosition: "left"
                    
                }} />
              ) : null}
            </Col>
          </Row>

          <Row className="my-4">
            <Col sm="4">
              <ScheduleDisplayDiff {...workOrder} />
            </Col>
            <Col sm="8">
              <WorkItemsDisplayDiff {...workOrder} />
            </Col>
          </Row>

          {facility.terms_and_conditions && (
            <>
              <h3 id="terms_and_conditions_header">Terms and Conditions</h3>
              <p id="terms_and_conditions_content">
                {lineBreaksHtml(facility.terms_and_conditions)}
              </p>
            </>
          )}

          {/* <DevPre v={workOrder} /> */}
        </PrintLayout>
      );
    },
    "/work-orders/${match.params.id}/diff/",
    null,
    binnacleClient
  )
);

export default PrintChangeOrder;

import React from "react";
import styled from "styled-components";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Tooltip
} from "reactstrap";

import { binnacleClient } from "~/util/api";
import Fa from "~/util/fa";
import withFacility from "~/util/api/withFacility";
import { withTypeProps } from "./utils";
import { withProfile } from "~/util/api/withProfile";
import previewProps from "./props";
import { fakeSender, fakeFacility, fakeWorkOrder } from "./fakeData";
import PreviewHeader from "./PreviewHeader";
import PreviewSender from "./PreviewSender";
import PreviewEditor from "./PreviewEditor";
import PreviewSchedule from "./PreviewSchedule";
import PreviewEstimate from "./PreviewEstimate";
import EnableSchedule from "./EnableSchedule";

const StyledModal = styled(Modal)`
  @media (min-width: 576px) {
    max-width: 1000px;
  }

  /* full viewport height minus margin for spacing */
  height: calc(100vh - 3.5rem);

  .modal-content {
    /* Fill modal-dialog parent */
    height: 100%;
  }

  .modal-body {
    background-color: lightgray;
    padding-top: 2rem;
    height: 100%;
    overflow-y: auto;
  }
`;

const PreviewContentContainer = styled(Container)`
  @media (min-width: 576px) {
    max-width: 600px;
  }
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  padding: 1.5rem;
`;

export class EmailPreview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      canSendEmail: false,
      showSchedule: true,
      currentMessage: "",
      submitTooltipOpen: false
    };
  }

  componentDidMount() {
    if (this.props.message && !!this.props.message.defaultMessage) {
      this.setState({
        currentMessage: this.props.message.defaultMessage
      });
    }

    if (this.props.alwaysShowSchedule) {
      this.setState({ showSchedule: true });
    }
  }

  handleToggleModal = toggle => event => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }
    this.setState({ modalOpen: toggle });
  };

  // This object shape is server-ready
  //  and will populate the work_order.email field
  getMessageData = () => {
    const { showSchedule, currentMessage } = this.state;
    const { type } = this.props;

    return {
      // The server doesn't understand the "comment" type - frontend use only
      email_type: type,
      show_schedule: showSchedule,
      comment: currentMessage
    };
  };

  handleSubmit = async () => {
    this.props.onSubmit(this.getMessageData());
    this.handleToggleModal(false)();

    if (!this.state.canSendEmail) return;
    await this.handleSendEmail();
  };

  // needs to handle 
  handleSendEmail = async () => {
    const { workOrder } = this.props;

    if (!workOrder || !workOrder.id)
      return new Error("I can't send this message without a work order.");

    return await binnacleClient.post(
      `email-templates/${workOrder.id}/send-email/`,
      {
        ...this.getMessageData()
      }
    );
  };

  handleClose = () => {
    this.handleToggleModal(false)();
    this.props.onClose();
  };

  handleSaveMessage = nextMessage => {
    this.setState(
      {
        currentMessage: nextMessage,
        canSendEmail: true
      },
      () => {
        if (this.props.onUpdateMessage) {
          this.props.onUpdateMessage(nextMessage);
        }
      }
    );
  };

  handleToggleSchedule = () => {
    this.setState({ showSchedule: !this.state.showSchedule });
  };

  handleToggleSubmitTooltip = () => {
    this.setState({ submitTooltipOpen: !this.state.submitTooltipOpen });
  };


  render() {
    const {
      renderModalButton,
      renderSubmitButton,
      buttonLabel,
      type,
      workOrder: { boat, phases, work_items, total_price, margin, id, sales_tax, status },
      facility: { address },
      currentPhaseSchedule = [],
      boatPrimaryImage,
      onReviewEstimate,
      modalTitle,
      submitButtonLabel,
      profile: sender,
      message: {
        placeholder = "Please add a Message",
        title = "Your Message",
        defaultMessage
      },
      modalButtonDisabled = false
    } = this.props;

    return (
      <>
        {renderModalButton ? (
          renderModalButton({
            onShowModal: this.handleToggleModal(true),
            disabled: modalButtonDisabled
          })
        ) : (
          <button
            type="button"
            className="EmailPreview__open-modal-button btn btn-primary"
            aria-label="Toggle Email Preview"
            onClick={this.handleToggleModal(true)}
          >
            <Fa className="mr-2" icon="paper-plane" />
            {buttonLabel}
          </button>
        )}
        <StyledModal isOpen={this.state.modalOpen} toggle={this.handleClose} data-testid="modal-email-preview">
          <ModalHeader toggle={this.handleClose} data-testid="modal-email-preview-title">{modalTitle}</ModalHeader>
          <ModalBody data-testid="modal-email-preview-body">
            <PreviewContentContainer>
              <PreviewHeader address={address} />
              {sender ? <PreviewSender sender={sender} /> : null}
              <PreviewEditor
                message={defaultMessage}
                title={title}
                placeholder={placeholder}
                onSave={this.handleSaveMessage}
              />

              {
                boatPrimaryImage.url ? 
                <img src={boatPrimaryImage.url} alt={boat.name} className="w-100 mb-3 rounded" />
                : ''
              }

              {currentPhaseSchedule ? (
                <EnableSchedule
                  id={id}
                  checked={this.state.showSchedule}
                  onChange={this.handleToggleSchedule}
                />
              ) : null}
              {currentPhaseSchedule && this.state.showSchedule ? (
                <PreviewSchedule phases={currentPhaseSchedule} status={status} />
              ) : null}
              {work_items &&
              ["quote_approval", "change_order"].includes(type) ? (
                <PreviewEstimate
                  workItems={work_items}
                  sales_tax={sales_tax}
                  totalPrice={total_price}
                  onReviewEstimate={onReviewEstimate}
                  margin={margin}
                />
              ) : null}
            </PreviewContentContainer>
          </ModalBody>
          <ModalFooter className="flex-column flex-md-row">
            <button
              className="EmailPreview__close-modal-button btn btn-outline-secondary d-block w-100 d-md-inline-block w-md-auto"
              data-testid="btn-back"
              onClick={this.handleClose}
            >
              Back
            </button>
            {renderSubmitButton ? (
              // if passed as callback prop execute in:
              // FormActionButtons with informed submission on WO detail
              // ItemCard on ROL with manual request for draft to pending
              renderSubmitButton({
                disabled: !this.state.canSendEmail,
                id: "email-preview-submit-button",
                getRef: ref => (this.submitRef = ref),
                data: this.getMessageData(),
                sendEmail: this.handleSendEmail,
                closeModal: this.handleToggleModal(false),
              })
            ) : (
              <button
                data-testid="btn-save-send-estimate"
                className={`btn btn-primary d-block EmailPreview__send-message-button ${
                  !this.state.canSendEmail ? "disabled" : ""
                }`}
                id="email-preview-submit-button"
                aria-disabled={!this.state.canSendEmail}
                disabled={!this.state.canSendEmail}
                onClick={this.handleSubmit}
                tabIndex={!this.state.canSendEmail ? "-1" : ""}
              >
                {this.state.canSendEmail ? (
                  <>
                    <Fa className="mr-2" icon="paper-plane" />
                    {submitButtonLabel}
                  </>
                ) : (
                  "Please Add a Message "
                )}
              </button>
            )}
            {/* commenting out the below because there's no need for  
            {!this.state.canSendEmail && !!this.submitRef ? (
              <Tooltip
                placement="bottom"
                isOpen={true}
                target={"email-preview-submit-button"}
                toggle={this.handleToggleSubmitTooltip}
                style={{ whiteSpace: "pre-wrap" }}
                dangerouslySetInnerHTML={{
                  __html: `Please save a message that is at least ${MIN_MESSAGE_LENGTH} characters.`
                }}
                trigger="click hover focus"
                autohide={true}
              ></Tooltip>
            ) : null} */}
          </ModalFooter>
        </StyledModal>
      </>
    );
  }
}

EmailPreview.propTypes = {
  ...previewProps
};

EmailPreview.defaultProps = {
  type: "work_update",
  workOrder: {},
  message: {},
  facility: {},
  profile: {},
  boatPrimaryImage: {},
  onClose: () => console.log("Closing"),
  onReviewEstimate: () => console.log("Let's review this estimate!")
};

export default withProfile(withFacility(withTypeProps(EmailPreview)));

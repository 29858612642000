import React, { useRef, useState, useEffect } from "react";
import { binnacleClient, boatsClient } from "~/util/api";
// import BoatModal from "~/components/boat/BoatModal";
import ToastApiStatus from "~/components/ToastApiStatus";
import BoatImageUploadButton from "~/components/boat/BoatImageUploadButton";
import BoatRoundedImage from "~/components/boat/BoatRoundedImage";
import { useDebounce } from "~/util/nolodash";
import { Tooltip } from "reactstrap";

function WorkOrderBoatImageWrapper({
  formApi,
  id: workOrderId,
  boat: initialBoat
}) {
  const props = formApi.getValues();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [images, setImages] = useState([]);
  const [initialLoadFinished, setInitialLoadFinished] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastColors, setToastColors] = useState(["#fecaca", "#7f1d1d"]);
  const [toastHeaderText, setToastHeaderText] = useState("Error!");
  const [toastBodyText, setToastBodyText] = useState(
    "There was an error with your upload."
  );
  const toolTipRef = useRef(null);
  const [toolTipOpen, setToolTipOpen] = useState(false);
  const toggleToolTip = () => setToolTipOpen(!toolTipOpen);

  const changeHandler = event => {
    // set files to state here. One is for selected files and one is dropped files
    const files = event?.target?.files || event?.dataTransfer?.files;
    setSelectedFiles(Object.values(files));
    const submitImage = document.getElementById("submitImage");
    // Add delay to prevent error
    setTimeout(() => {
      submitImage.click();
    });
  };

  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);

  // create debounced values for better ux and performance
  const debouncedInitialBoat = useDebounce(initialBoat?.images, 100);
  const debouncedBoatId = useDebounce(props?.boat, 100);
  const debouncedBoatName = useDebounce(props?.boat?.name, 100);
  // Debounce the initial load finished so to prevent the wrong boat images from loading
  const deboundedinitialLoadFinished = useDebounce(initialLoadFinished, 250);

  // useEffect1 will get triggered when the initial boat images are loaded
  useEffect(() => {
    if (initialBoat?.id && initialBoat?.images?.length && !deboundedinitialLoadFinished) {
      setImages([...initialBoat.images]);
      setInitialLoadFinished(true);
    }
    // clean up function
    return () => {};
  }, [debouncedInitialBoat]);

  // useEffect2 will get triggered when the boat id changes or when you create a new boat from the WO create page
  useEffect(() => {
    if (deboundedinitialLoadFinished) {
      getNewBoatImages();
    } else if (!initialBoat?.id && !deboundedinitialLoadFinished) {
      setImages([]);
      setInitialLoadFinished(true);
    }

    // clean up function
    return () => {};
  }, [debouncedBoatId, debouncedBoatName]);

  const getNewBoatImages = async () => {
    let response;
    /*
     * When user is on WO create page images come from boat api
     * When user is on WO edit page boat images come from work order api
     * The reason for needing to switch between the two is because
     * the WO caches the boat images when the WO is created.  This
     * is to prevent the boat images from changing while the WO is
     * being worked on.
     */
    try {
      if (props.boat?.id) {
        response = await boatsClient.get(`/boats/${props.boat?.id}/`);
      }

      if (response?.data?.images) {
        setImages([...response.data.images]);
      } else if (response?.data?.boat?.images) {
        setImages([...response.data.boat.images]);
      } else {
        setImages([]);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    if (!deboundedinitialLoadFinished) {
      setInitialLoadFinished(true);
    }
  };
  const handleSubmission = async () => {
    // set uploading here
    setUploading(true);
    const formData = new FormData();
    // This is the form Field name. Change this to match backend

    try {
      // This is when in draft and workOrderId exists
      let endPoint = `/work-orders/${workOrderId}/image/`;
      let contentType = "image/*;";
      let formFieldName = "image";
      let client = binnacleClient;
      // This is when on create wo page and workOrderId does not exist
      if (!workOrderId || initialBoat?.id !== props?.boat?.id) {
        endPoint = `/boats/${props.boat?.id}/image/`;
        contentType = "multipart/form-data";
        formFieldName = "images";
        client = boatsClient;
      }

      selectedFiles.forEach(selectedFile => {
        formData.append(formFieldName, selectedFile);
      });

      const response = await client.post(endPoint, formData, {
        method: "POST",
        headers: {
          "Content-Type": contentType
        }
      });
      if (response.status !== 200) {
        throw new Error(`HTTP error: ${response.status}`);
      }

      if (response.data?.boat?.images) {
        setImages([...response.data.boat.images]);
      } else if (response.data?.images) {
        setImages([...response.data.images]);
      } else {
        await getNewBoatImages();
      }
    } catch (error) {
      setShowToast(true);
      const errorText = error?.response?.data || error?.message || error;
      // If the error response is an html page then parse it and get the error message
      if (error?.response?.data && error?.response?.data?.includes("<html>")) {
        const errorTextParsed = parseHTML(errorText);
        if (errorTextParsed === "413 Request Entity Too Large") {
          setToastBodyText(
            "Oops! Your file is too big to upload. Please try with a smaller file."
          );
        } else {
          setToastBodyText(errorTextParsed);
        }
      } else if (typeof error?.response?.data === "string") {
        setToastBodyText(errorText);
      } else {
        setToastBodyText(
          "Oops! Something went wrong. Please try again or contact support."
        );
      }

      console.error("Error:", errorText, error);
    }
    setUploading(false);
  };

  const parseHTML = html => {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(html, "text/html");

    return htmlDoc.querySelector("h1").innerHTML;
  };

  const handleUpload = event => {
    hiddenFileInput.current.click();
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <ToastApiStatus
        showToast={showToast}
        setShowToast={setShowToast}
        toastColors={toastColors}
        setToastColors={setToastColors}
        toastHeaderText={toastHeaderText}
        setToastHeaderText={setToastHeaderText}
        toastBodyText={toastBodyText}
        setToastBodyText={setToastBodyText}
      />
      <button
        style={{ display: "none" }}
        id="submitImage"
        onClick={e => {
          e.preventDefault();
          handleSubmission();
        }}
      >
        Submit
      </button>
      <div className="mx-2">
        <BoatRoundedImage
          iconSize="18"
          size={52}
          enableModal={true}
          images={images}
          color="none"
          border={true}
          iconColor="none"
          outerIconStyles={{
            border: "2px solid #D3DAE0",
            color: "#9FA9B4",
            background: "#E3EBED"
          }}
          innerIconStyles={{
            color: "#838D97"
          }}
        />
      </div>

      {images.length === 0 && deboundedinitialLoadFinished && (
        <>
          <div ref={toolTipRef}>
            <BoatImageUploadButton
              disabled={!props?.boat?.id}
              title={
                !props?.boat?.id
                  ? "Save your Work Order as draft to upload"
                  : "Upload Image"
              }
              changeHandler={changeHandler}
              handleUpload={handleUpload}
              hiddenFileInput={hiddenFileInput}
              showLine={false}
              showHeader={false}
            />
          </div>
          {!props?.boat?.id && (
            <Tooltip
              placement="right"
              isOpen={toolTipOpen}
              target={toolTipRef}
              toggle={toggleToolTip}
            >
              Save your Work Order as draft to upload
            </Tooltip>
          )}
        </>
      )}
    </div>
  );
}
export default WorkOrderBoatImageWrapper;

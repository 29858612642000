import React, { useRef, useState } from "react";

function Dropzone({ children, handleUpload }) {
  const dropzoneRef = useRef(null);
  const [dragCounter, setDragCounter] = useState(0);

  const handleDragEnter = event => {
    event.preventDefault();
    event.stopPropagation();
    setDragCounter(prevDragCounter => prevDragCounter + 1);
  };

  const handleDragLeave = event => {
    event.preventDefault();
    event.stopPropagation();
    setDragCounter(prevDragCounter => prevDragCounter - 1);
  };

  const handleDragOver = event => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = event => {
    event.preventDefault();
    event.stopPropagation();
    setDragCounter(0);
    // Do something with the dropped files
    const files = event.dataTransfer.files;
    console.log(files);
    handleUpload(event);
  };

  return (
    <div
      ref={dropzoneRef}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: dragCounter > 0 ? "#fafafa" : "white"

      }}
    >
      {children}
    </div>
  );
}
export default Dropzone;

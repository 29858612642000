import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Container, Row, Col, Card, CardTitle } from "reactstrap";
import { Form } from "informed";
import { BootstrapText } from "~/util/autoform/bootstrap";
import { getValidators } from "~/util/autoform/validators";
import { withAPIForm } from "~/util/api/withAPIForm";
import LoadingButton from "~/util/loadingButton";
import { withAuth } from "~/util/api/withAuth";

const validators_required = getValidators(["required"]);

export class BaseLoginScreen extends Component {
  constructor(props) {
    super(props);
    this.onLoginSuccess = this.onLoginSuccess.bind(this);
    this.getNextUrl = this.getNextUrl.bind(this);
  }

  getNextUrl() {
    if (this.props.location.from !== undefined) {
      return this.props.location.from;
    } else {
      let nextParam = new URLSearchParams(window.location.search).get("next");

      if (nextParam != null) {
        return nextParam;
      }
    }

    return "/";
  }

  onLoginSuccess(data) {
    this.props.login(data);
    // redirect to wherever we were last

    document.location = this.getNextUrl();
    // this.props.history.replace(next);
  }

  render() {
    if (this.props.isAuthenticated) {
      return <Redirect to={{ pathname: this.getNextUrl() }} />;
    }

    let onSubmit = this.props.bindFormSubmit(
      this.onLoginSuccess,
      "/users/login/",
      { nonFieldErrorsField: "email" }
    );

    const bgImage = require("~/images/bg_boatyard_img.jpg");

    return (
      <div className="wrapper-page align-items-center justify-content-center">
        <Container>
          <Row>
            <Col
              xs="12"
              sm={{ size: 8, offset: 2 }}
              md={{ size: 6, offset: 3 }}
              lg={{ size: 4, offset: 4 }}
            >
              <Card className="card-login">
                <Form onSubmit={onSubmit} getApi={this.props.getFormApi}>
                  <BootstrapText
                    field="email"
                    label="Email"
                    validate={validators_required}
                    required={true}
                    data-testid="login-email"
                  />

                  <BootstrapText
                    type="password"
                    field="password"
                    label="Password"
                    validate={validators_required}
                    required={true}
                    data-testid="login-password"
                  />

                  <div className="text-center">
                    <LoadingButton
                      loading={this.props.sendingRequest}
                      className="btn-lg btn-primary mb-3"
                      data-testid="login-button"
                    >
                      Log In
                    </LoadingButton>
                    <br />
                    <Link 
                      className="py-3" 
                      to="/password-reset"
                      data-testid="login-forgot-password-link"
                      >
                      Forgot&nbsp;Password
                    </Link>
                  </div>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="bg-image-full d-flex align-items-center justify-content-center"
          style={{ backgroundImage: `url('${bgImage}')` }}
        />
      </div>
    );
  }
}

const LoginScreen = withAuth(withAPIForm(BaseLoginScreen));
export default LoginScreen;

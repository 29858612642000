import React, { useRef, useState } from "react";
import { Button } from "reactstrap";
function BoatImageUploadButton({
  changeHandler,
  handleUpload,
  hiddenFileInput,
  showLine = true,
  showHeader = true,
  disabled = false,
  title = "Upload Image"
}) {
  return (
    <>
      <input
        id="hiddenFileInput"
        ref={hiddenFileInput}
        style={{ display: "none" }}
        type="file"
        name="file"
        accept="image/jpg, image/jpeg, image/png"
        multiple
        onChange={changeHandler}
      />

      <div
        className="carousel__container_header-row d-flex align-items-center w-100"
        style={{ marginTop: "1px" }}
      >
        {showHeader && (
          <h4
            className="carousel__container_header mt-2 mb-0"
            style={{ position: "relative", top: "-4px", paddingLeft: "-9px" }}
          >
            Photos
          </h4>
        )}
        <Button
          disabled={disabled}
          style={{ pointerEvents: disabled ? "none" : "auto" }}
          outline
          color="primary"
          className="ml-2 fw-bold py-1"
          onClick={handleUpload}
          title={title}
        >
          <i className="fas fa-file-upload mx-1"></i> Upload
        </Button>
      </div>
      {showLine && (
        <hr
          className="w-100 my-3"
          style={{ borderTop: "1px solid color(--secondary)" }}
        />
      )}
    </>
  );
}
export default BoatImageUploadButton;

import { ftInStrToDecimal } from "~/components/boat/ftInFormat";
import { currency, currencyFloat } from "~/util/currency";

const calcSqft = (ppu, coverage, surfaceArea) =>
  _.ceil(surfaceArea / coverage, 1) * ppu;

export const CALC_TYPES = {
  sqft: {
    func: ({ ppu, coverage, surfaceArea }) =>
      calcSqft(ppu, coverage, surfaceArea),
    getUnits: ({ coverage, surfaceArea }) =>
      coverage ? _.ceil(surfaceArea / coverage, 1) : null,
    units: "editable"
  },
  sqftx: {
    func: ({ ppu, units, coverage, surfaceArea }) =>
      calcSqft(ppu, coverage, surfaceArea) * units,
    getRangeUnits: ({ surfaceArea, coverage }) =>
      _.ceil(surfaceArea / coverage, 1),
    units: "editable"
  },
  sqftxfixed: {
    func: ({ ppu, coverage, surfaceArea }) =>
      calcSqft(ppu, coverage, surfaceArea),
    getRangeUnits: ({ surfaceArea, coverage }) =>
      _.ceil(surfaceArea / coverage, 1),
    // getUnits: ({ coverage, surfaceArea }) => _.ceil(surfaceArea / coverage, 1),
    units: "editable"
  },
  unit: {
    // func: ({ ppu, units }) => parseFloat(ppu) * parseFloat(units),
    units: "editable"
  },
  fixed: {
    func: ({ ppu }) => ppu,
    getUnits: () => 1,
    units: "editable"
  },
  linear: {
    func: ({ ppu, length }) => ppu * length,
    getUnits: ({ length }) => _.ceil(length, 1),
    units: "editable"
  },
  linearx: {
    func: ({ ppu, units, length }) => ppu * length * units,
    getRangeUnits: ({ length }) => length,
    units: "editable"
  }
};
const getRange = (surfaceArea, coverage) => {
  return coverage ? _.ceil(surfaceArea / coverage, 1) : null;
};

const getCoverage = item => {
  if (item.labor_units) {
    return parseFloat(item.labor_coverage);
  } else {
    return parseFloat(item.materials_coverage);
  }
};

export function calcItemPpu(ppu, ranges, units) {
  if (!_.isEmpty(ranges)) {
    for (let index in ranges) {
      let bottom = parseFloat(ranges[index].bottom);
      let top = parseFloat(ranges[index].top);
      let price = ranges[index].ppu;

      if (units >= bottom && (units < top || _.isNaN(top))) {
        ppu = price;
        break;
      }
    }
  }

  ppu = parseFloat(ppu);

  return ppu || 0;
}

// handles item calculation for either labor or materials depending on inputs
// piece item labor or piece item material
// piece = line item

export function calcItemPiece(piece, item, surfaceArea, length) {
  let units = parseFloat(calcItemPieceUnit(item, piece, surfaceArea, length));
  // let rangeUnits = calcItemPieceRangeUnit(
  //   item,
  //   piece,
  //   surfaceArea,
  //   length,
  //   units
  // );
  let coverage = getCoverage(item);
  let rangeUnits = getRange(surfaceArea, coverage);

  if (!item.rangeUnits) {
    item.rangeUnits = rangeUnits;
  }

  let ppu = calcItemPpu(
    item[`${piece}_ppu`],
    item[`${piece}_ranges`],
    rangeUnits
  );

  let unit_name = item[`${piece}_unit_name`];
  let price = 0;

  if (unit_name === "%") {
    // special case for percent units
    units = units / 100.0;
  }
  if (!isNaN(units) && units !== Infinity) {
    if (item.labor_units === null) {
      price = parseFloat(ppu * item[`materials_units`]);
    } else if (item.materials_units === null) {
      price = parseFloat(ppu * item[`labor_units`]);
    } else {
      price = parseFloat(ppu * units);
    }
  } else {
    price = 0;
  }

  // first round to 3 decimal places, then to 2
  // because normally it doesn't work the same as python and we need the values to match
  return !_.isFinite(price) ? 0 : _.round(price, 2);
}

function calcItemPieceRangeUnit(item, piece, surfaceArea, length, units) {
  let uom = item[`${piece}_uom`];
  let coverage = item[`${piece}_coverage`];
  let func = CALC_TYPES[uom] ? CALC_TYPES[uom].getRangeUnits : undefined;
  if (func !== undefined) {
    return func({ surfaceArea, length, coverage, units });
  } else {
    return units;
  }
}

function calcItemPieceUnit(item, piece, surfaceArea, length, units) {
  let uom = item[`${piece}_uom`];
  let coverage = item[`${piece}_coverage`];
  let func = CALC_TYPES[uom] ? CALC_TYPES[uom].getUnits : undefined;

  if (func !== undefined) {
    return getRange(surfaceArea, coverage);
  } else {
    return item[`${piece}_units`];
  }
}

// uses units in calculations
export function calculateItemSubtotal(item, surfaceArea, length) {
  length = _.ceil(ftInStrToDecimal(length), 1);
  if (_.isNaN(parseFloat(surfaceArea))) surfaceArea = 0;
  let labor_price;
  let materials_price;
  labor_price = calcItemPiece("labor", item, surfaceArea, length);
  materials_price = calcItemPiece("materials", item, surfaceArea, length);

  return _.round(materials_price + labor_price, 2);
}

// gets called on useEffect whenever form state changes
export function recalculatePriceAndUnits(item, surfaceArea, length, initial) {
  let coverage = getCoverage(item);
  let rangeUnits = getRange(surfaceArea, coverage);

  if (initial) {
    item.rangeUnits = 1;
  } else {
    item.rangeUnits = rangeUnits;
  }
  let labor_units = calcItemPieceUnit(item, "labor", surfaceArea, length);
  // console.log("LABOR UNITS", labor_units);
  let materials_units = calcItemPieceUnit(
    item,
    "materials",
    surfaceArea,
    length
  );

  if (item.labor_units === null && item.materials_units) {
    materials_units = (parseFloat(materials_units) * item.rangeUnits).toFixed(
      1
    );

    item.materials_units = materials_units;
    item.materials_uom = "unit";
    // item.rangeUnits = 1;
  }
  if (item.materials_units === null && item.labor_units) {
    labor_units = (parseFloat(labor_units) * item.rangeUnits).toFixed(1);
    item.labor_units = labor_units;
    item.labor_uom = "unit";
    // item.rangeUnits = 1;
  }
  if (item.materials_units && item.labor_units) {
    labor_units = (parseFloat(labor_units) * item.rangeUnits).toFixed(1);
    // item.labor_units = labor_units;
    if (!initial) {
      materials_units = (surfaceArea / item.materials_coverage).toFixed(1);
    } else {
      materials_units = (parseFloat(materials_units) * item.rangeUnits).toFixed(
        1
      );
    }

    // item.materials_units = materials_units;
    item.materials_uom = "unit";
    item.labor_uom = "unit";
  }

  let price = calculateItemSubtotal(item, surfaceArea, length);

  return { ...item, ...{ price, labor_units, materials_units } };
}

// TODO - tax in isolation is correct but subtotal per item and total is not
export const calculateTax = ({ items, surfaceArea, length }) =>
  _.sum(
    _.map(items, item => {
      const laborTax =
        item.labor_sales_tax_rate &&
        calcItemPiece("labor", item, surfaceArea, length) > 0
          ? calcItemPiece("labor", item, surfaceArea, length) *
            item.labor_sales_tax_rate
          : 0;

      const materialsTax =
        item.materials_sales_tax_rate &&
        calcItemPiece("materials", item, surfaceArea, length) > 0
          ? calcItemPiece("materials", item, surfaceArea, length) *
            item.materials_sales_tax_rate
          : 0;

      return laborTax + materialsTax;
    })
  );

// Given the total cost of a work order after adding margin and subtracting discount and the margin and discount percentages,
// Find the original sub total, and actual margin and discount prices
export function getSubtotalMarginDiscount(
  total,
  margin,
  discount,
  environmental_fee,
  items,
  sales_tax
) {
  margin = Math.max(margin, 0) / 100 || 0;
  discount = Math.max(discount, 0) / 100 || 0;
  environmental_fee = Math.max(environmental_fee, 0) / 100 || 0;

  const basePrice = items.reduce((total, item) => {
    // total up items for change orders
    // if item is in a change order, instead of just listing a series of objects for line itmes
    // we'll receive a list of objects with their own k, v pairs that denote the line item type
    // a change order's new subtotal will be the sum of equal__ and added__ items
    Object.entries(item).forEach(([key, value]) => {
      if (key === "equal__" || key === "added__") {
        total += parseFloat(value.price);
      }
    });
    // if a standard W.O. item (if price is on item directly)
    if (Object.keys(item).includes("price")) {
      total += parseFloat(item.price);
      console.log(total);
    }
    return total;
  }, 0);

  const envPrice = basePrice * environmental_fee;
  const marginPrice = (basePrice + envPrice + sales_tax) * margin;
  const discountPrice =
    (basePrice + envPrice + sales_tax + marginPrice) * discount;

  return {
    subtotal: currencyFloat(basePrice),
    margin: currencyFloat(marginPrice),
    discount: currencyFloat(discountPrice),
    envPrice: currencyFloat(envPrice)
  };
}

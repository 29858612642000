import React from "react";

const DisplayMarginOrDiscount = ({ value }) => {
  if (!value) {
    return null;
  }
  return <span className="text-nowrap">{value}%</span>;
};

export default DisplayMarginOrDiscount;

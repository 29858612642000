import React from "react";
import { isLoggedIn } from "./index";
import { LoadingIndicator } from "~/util/loadingWrapper";

export default function withAuthRequired(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true,
        loggedIn: false
      };
    }

    componentDidMount() {
      isLoggedIn().then(loggedIn => {
        this.setState({
          loading: false,
          loggedIn
        });
      });
    }

    render() {
      const { loggedIn, loading } = this.state;
      if (loading) return <LoadingIndicator />;
      return loggedIn ? <WrappedComponent {...this.props} /> : null;
    }
  };
}

import React from "react";
import { Route, Switch } from "react-router-dom";
import { Container, Card, Button } from "reactstrap";
import { Form } from "informed";
import NavBar from "../navbar";

import NotFoundPage from "~/views/notFound";
import { BootstrapTextArea } from "~/util/autoform/bootstrap";
import { boatsClient } from "~/util/api";
import BoatDetail from "./Detail";
import BoatForm from "./Form";
import withBoat from "./withBoat";
import BoatHistory from "./History";
import BoatComments from "./Comments";
import { withProfile } from "~/util/api/withProfile";
import withFacility from "~/util/api/withFacility";
import withBoatyard from "~/util/api/withBoatyard";
import PrivateRoute from "~/util/privateRoute";

@withProfile
@withFacility
@withBoatyard
class BoatCommentForm extends React.Component {
  render() {
    document.title = `Pettit Pro Portal | Boat`;
    const { id, profile, facility, boatyard } = this.props;
    if (!_.every([id, profile, facility, boatyard])) return null;
    const baseData = {
      profile_id: profile.id,
      facility_id: facility.id,
      boatyard_id: boatyard.id
    };
    const onSubmit = ({ comment }) => {
      boatsClient
        .post(`/boats/${id}/comments/`, { comment, ...baseData })
        .then(() => window.location.reload(false));
    };
    return (
      <Form onSubmit={onSubmit}>
        <BootstrapTextArea field="comment" label="Add Comment" />
        <Button color="primary" type="submit">
          Add Comment
        </Button>
      </Form>
    );
  }
}

const Layout = ({ children }) => (
  <>
    <NavBar details={true} className="bg-white" />
    <div className="pt-3 pb-5">
      <Container>
        <Card className="p-4 mt-3">{children}</Card>
      </Container>
    </div>
  </>
);

const LayoutWithHistory = ({ boat, children }) => (
  <>
    <Layout>{children}</Layout>
    <Container>
      <Card className="shadow py-3 px-4 my-4">
        <BoatHistory boat={boat} id={boat.id} />
      </Card>

      <Card className="shadow py-3 px-4 my-4">
        <h2>Comments</h2>
        <BoatComments boat={boat} id={boat.id} />
        <BoatCommentForm id={boat.id} />
      </Card>
    </Container>
  </>
);

const New = () => (
  <Layout>
    <BoatForm />
  </Layout>
);

const Edit = withBoat(({ boat }) => (
  <LayoutWithHistory boat={boat}>
    <BoatForm boat={boat} isNew={false} />
  </LayoutWithHistory>
));

const Detail = withBoat(({ boat }) => (
  <LayoutWithHistory boat={boat}>
    <BoatDetail boat={boat} />
  </LayoutWithHistory>
));

export default ({ match }) => (
  <>
    <Switch>
      <PrivateRoute path={`${match.path}/new/`} component={New} />
      <PrivateRoute path={`${match.path}/:id/edit/`} exact component={Edit} />
      <PrivateRoute path={`${match.path}/:id/`} exact component={Detail} />
      <NotFoundPage />
    </Switch>
  </>
);

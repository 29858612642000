import React, { useState, useEffect } from "react";
import { useDashboardStore } from "~/store";
import { shallow } from "zustand";
import Fa from "~/util/fa";
import { Row, Col, Button } from "reactstrap";
const ClearAdvancedSearch = props => {
  // useEffect(() => {}, []);

  const {
    setSelectedKinds,
    setWorkOrderStatuses,
    advancedSearchActive,
    setAdvancedSearchActive,
    workOrderStatuses,
    selectedKinds,
    query,
    setQuery
  } = useDashboardStore(
    state => ({
      setSelectedKinds: state.setSelectedKinds,
      setWorkOrderStatuses: state.setWorkOrderStatuses,
      advancedSearchActive: state.advancedSearchActive,
      setAdvancedSearchActive: state.setAdvancedSearchActive,
      workOrderStatuses: state.workOrderStatuses,
      selectedKinds: state.selectedKinds,
      query: state.query,
      setQuery: state.setQuery
    }),
    shallow
  );
  const notReadyForInvoice = selectedKinds[0] !== "readyforinvoice";

  const simpleSearchActive = () => {
    const isQuery = query.length > 0;
    const hasWorkOrderStatus = workOrderStatuses.length > 0;
    return (isQuery || hasWorkOrderStatus) && !advancedSearchActive;
  };
  // const isDefaultSearchActive = () => {
  //   const isOnlyWorkOrder = selectedKinds.toString() === "workorder";
  //   const queryStringIsEmpty = query.length === 0 || query === undefined;
  //   const statusesIsEmpty =
  //     workOrderStatuses.length === 0 || workOrderStatuses === undefined;
  //   return isOnlyWorkOrder && queryStringIsEmpty && statusesIsEmpty;
  // };

  const resetSearch = () => {
    setAdvancedSearchActive(false);
    setSelectedKinds(["workorder"]);
    setWorkOrderStatuses([]);
    setQuery("");
  };
  return (
    <>
      {/* Show  Clear Search button if search is not default, not ready for invoice, or quickbooks sync mode is active */}
      {(simpleSearchActive() && notReadyForInvoice) ||
      (advancedSearchActive && notReadyForInvoice) ? (
        <Row>
          <Col md={12}>
            <Button
              color="outline-secondary"
              className="btn btn-light d-flex align-items-center"
              onClick={() => {
                resetSearch();
              }}
            >
              <Fa icon="times" className="mr-2" />
              <span className="d-flex d-md-none">Clear</span>
              <span className="d-none d-md-flex">
                {simpleSearchActive()
                  ? "Clear Search"
                  : "Clear Advanced Search"}
              </span>
            </Button>
          </Col>
        </Row>
      ) : null}
    </>
  );
};

export default ClearAdvancedSearch;

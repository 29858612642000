import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { Scope, Text, useFormApi, useFormState } from "informed";

import SearchCreateSelect from "~/components/SearchCreateSelector";
import { BootstrapText, BootstrapCheckbox } from "~/util/autoform/bootstrap";
import { getValidators } from "~/util/autoform/validators";
import { boatsClient } from "~/util/api";
import DevPre from "~/util/DevPre";
import { decimalToFtInStr } from "./ftInFormat";
import withBoatyard from "~/util/api/withBoatyard";
import withFacility from "~/util/api/withFacility";

const validators_required = getValidators(["required"]);

const formatOptionLabel = (
  { name, make, model, year, label, ...other },
  { context, ...more }
) => {
  if (context === "value") {
    return label;
  } else if (name === undefined) {
    return label;
  }

  return (
    <Row>
      <Col md="3" style={{ fontWeight: "bold", fontStyle: "italic" }}>
        {name}
      </Col>
      <Col md="3">{make}</Col>
      <Col md="3">{model}</Col>
      <Col md="3">{year}</Col>
    </Row>
  );
};

const flexibleFtInStr = value => {
  if (/^[\d\.]+$/.test(value)) return decimalToFtInStr(value);
  return value;
};

const hiddenProps = {
  type: "hidden",
  keepState: true
};

const emptyValues = {
  name: null,
  make: null,
  model: null,
  year: null,
  specs: {
    length: null,
    beam: null,
    draft: null,
    surface_area: null,
    hull_type: null
  }
};
const getRequireNameOrId = scope => (v, vs) =>
  _.get(vs, _.compact([scope, "id"])) || _.get(vs, _.compact([scope, "name"]))
    ? undefined
    : "This field is required";
const requireNameOrId = {
  boat: getRequireNameOrId("boat"),
  false: getRequireNameOrId(false)
};

const Selector = ({ cols, field, url, label, ...otherProps }) => (
  <Col sm={cols}>
    <SearchCreateSelect
      {...{
        field,
        url,
        label,
        client: boatsClient,
        searchParamName: "name",
        required: true,
        validateOnChange: true,
        allowEmptyStrings: true,
        keepState: true,
        ...otherProps
      }}
    />
  </Col>
);

const BSF = ({ cols, field, label = _.capitalize(field), ...otherProps }) => {
  return (
    <Col sm={cols}>
      <BootstrapText {...{ field, label, ...otherProps }} />
    </Col>
  );
};

const useFieldDependsOn = (values, required = true) => {
  const [disabled, setDisabled] = useState(true);
  const [key, setKey] = useState(_.kebabCase(values.join("-")));
  useEffect(() => {
    setDisabled(!_.every(values));
    setKey(_.kebabCase(values.join("-")));
  }, values);

  let validate = undefined;

  if (required && _.every(values)) {
    validate = validators_required;
  }

  const updateKey = (...values) =>
    new Promise(resolve => {
      setKey(_.kebabCase(values.join("-")));
      setTimeout(() => resolve(), 2);
    });
  return { disabled, key, validate, updateKey };
};

export const BaseBoatSelector = ({
  nameIsSelectable = true,
  scope = "boat",
  facility,
  boatyard
}) => {
  // State and Functions:
  const formState = useFormState();
  const formApi = useFormApi();
  const profile_id = _.get(formState, "values.owner_profile.id");
  const formValueKey = _.isEmpty(scope) ? "values" : `values.${scope}`;
  const setFormValue = v => {
    if (_.isEmpty(scope)) formApi.setValues(v);
    else formApi.setValues({ [scope]: v });
  };

  // STATE
  const formStateValues = _.get(formState, formValueKey, {});
  const { name, make, model, year, specs } = formStateValues;
  const [specsLoading, setSpecsLoading] = useState(false);
  const [saLoading, setSALoading] = useState(false); // SURFACE_AREA
  const {
    disabled: makeIsDisabled,
    key: makeKey,
    makeValidate,
    updateKey: updateMakeKey
  } = useFieldDependsOn([name]); // MAKE
  const {
    disabled: modelIsDisabled,
    key: modelKey,
    modelValidate,
    updateKey: updateModelKey
  } = useFieldDependsOn([make]); // MODEL
  const {
    disabled: yearIsDisabled,
    key: yearKey,
    validate: yearValidate,
    updateKey: updateYearKey
  } = useFieldDependsOn([make, model]); // YEAR
  const {
    disabled: specsIsDisabled,
    key: specsKey,
    validate: specsValidate
  } = useFieldDependsOn([make, model, year, specs], false); // SPECS

  // PROPS
  let nameParams = {
    sort_facility_id: facility.id,
    sort_company_id: boatyard.id
  };
  if (!_.isEmpty(profile_id)) {
    nameParams.sort_profile_id = profile_id;
  }

  const nameProps = {
    searchParamName: "search",
    params: nameParams,
    validate: requireNameOrId[scope],
    displayValue: name || "Find a boat by name",
    isPlaceholder: !name,
    creatable: true,
    formatOptionLabel,
    mapOpt: ({ name, make, model, year, id }) => ({
      name,
      make,
      model,
      year,
      value: id,
      label: _.capitalize(name)
    }),
    onChange: v => {
      const boat = _.merge({}, emptyValues, v, {
        specs: { surface_area: _.get(v, "specs.surface_area_calculations.0.1") }
      });
      setFormValue(boat);
      // Promise.all([updateMakeKey(name), updateModelKey(make), updateYearKey(make, model)])
      //     .then(()=>setFormValue(boat));// TODO - figure out how to do this without satanic assistance
    }
  };

  const makeProps = {
    // key: makeKey,
    isDisabled: makeIsDisabled,
    validate: makeValidate,
    mapOpt: ({ name }) => ({ label: _.capitalize(name), value: name }),
    cacheBy: "name",
    onChange: () => {
      setFormValue({ model: null, year: null });
    }
  };

  const modelProps = {
    // key: modelKey,
    isDisabled: modelIsDisabled,
    validate: modelValidate,
    mapOpt: ({ name }) => ({ label: _.capitalize(name), value: name }),
    cacheBy: "name",
    params: { make },
    cacheUniq: modelKey,
    onChange: () => {
      setFormValue({ year: null });
    }
  };

  const yearProps = {
    // key: yearKey,
    isDisabled: yearIsDisabled,
    validate: yearValidate,
    mapOpt: ({ year, name }) => ({ label: year || name, value: year || name }),
    cacheBy: "year",
    cacheUniq: yearKey,
    params: { make, model },
    onChange: ({ year, name }) => {
      setFormValue({ specs: emptyValues.specs });
      if (year) {
        setSpecsLoading(true);
        boatsClient
          .get("/specs/", { params: { make, model, year } })
          .then(({ data: specs }) => {
            setSpecsLoading(false);
            setFormValue({ specs });
          })
          .catch(() => setSpecsLoading(false));
      }
      setFormValue({ year: year || name });
    }
  };
  const setHullType = () => {
    let newSpecs = { ...formState.values.boat.specs };

    if (newSpecs.hull_type === "catamaran") {
      newSpecs.hull_type = null;
      newSpecs.surface_area = newSpecs.surface_area * 2;
    } else {
      newSpecs.hull_type = "catamaran";
      newSpecs.surface_area = newSpecs.surface_area / 2;
    }
    setFormValue({ specs: newSpecs });
  };
  const specsBaseProps = {
    disabled: specsLoading || specsIsDisabled,
    validate: specsValidate,
    allowEmptyString: true,
    mask: flexibleFtInStr,
    maskOnBlur: true,
    keepState: true,
    onBlur: () => {
      const { length, beam, draft, hull_type } = _.get(
        formStateValues,
        "specs"
      );
      if (
        _.every([length, beam, draft, hull_type] && _.isEmpty(formState.errors))
      ) {
        setSALoading(true);
        boatsClient
          .get("/specs/surface_area/", {
            params: { length, beam, draft, hull_type }
          })
          .then(({ data: v }) => {
            const specs = {
              surface_area_calculations: v,
              surface_area: _.last(_.first(v)) || null
            };
            setFormValue({ specs });
            setSALoading(false);
          })
          .catch(() => setSALoading(false));
      }
    }
  };

  const { disabled: saIsDisabled, validate: saValidate } = useFieldDependsOn([
    make,
    model,
    year
  ]);
  const surfaceAreaProps = {
    allowEmptyString: true,
    validateOnChange: true,
    disabled: specsLoading || saLoading || saIsDisabled,
    validate: saValidate,
    keepState: true
  };

  const keys = { makeKey, modelKey, yearKey, formStateValues };
  return (
    <Row>
      {nameIsSelectable ? (
        <>
          <Selector
            cols={12}
            field="id"
            url="/boats/"
            label="Name"
            id="select-find-boat-by-name"
            {...nameProps}
          />
          <Text field="name" {...hiddenProps} />
        </>
      ) : (
        <Col xs="12">
          <BootstrapText field="name" label="Boat Name" />
          <Text field="id" {...hiddenProps} />
        </Col>
      )}
      <Selector
        cols={4}
        field="make"
        url="/specs/makes/"
        label="Make"
        id="input-boat-make"
        {...makeProps}
      />
      <Selector
        cols={4}
        field="model"
        url="/specs/models/"
        label="Model"
        id="input-boat-model"
        {...modelProps}
      />
      <Selector
        cols={4}
        field="year"
        url="/specs/years/"
        label="Year"
        id="input-boat-year"
        {...yearProps}
      />
      <Scope scope="specs">
        <BSF
          cols={2}
          field="length"
          id="input-boat-length"
          {...specsBaseProps}
          placeholder={`ft'in"`}
        />
        <BSF cols={2} 
          field="beam" 
          id="input-boat-beam" 
          {...specsBaseProps} 
          placeholder={`ft'in"`} />
        <BSF
          cols={2}
          field="draft"
          id="input-boat-draft"
          {...specsBaseProps}
          placeholder={`ft'in"`}
        />
        <BSF
          cols={2}
          field="surface_area"
          id="input-boat-surface-area"
          {...{
            ...surfaceAreaProps,
            label: "Surface Area",
            appendText: "sqft"
          }}
        />
        <Col className="pt-3">
          <br />
          <div
            className="catamaran-checkbox-container"
            style={{
              display: "flex",
              flexDirection: "column",
              transform: "translateY(-.8rem)"
            }}
          >
            <label
              htmlFor="catamaran"
              style={{
                transform: "translateY(-1.6rem)"
              }}
            >
              Catamaran
            </label>
            <input
              type="checkbox"
              name=""
              id="catamaran"
              onChange={setHullType}
              style={{
                height: "1.4rem",
                width: "1.4rem",
                transform: "translateY(-.8rem)"
              }}
            />
          </div>
        </Col>
        <BootstrapCheckbox
          label="Catamaran"
          field="hull_type"
          // format={value => value === "catamaran"}
          // parse={value => (value ? "catamaran" : null)}
          // disabled={specsBaseProps.disabled}
          // onChange={specsBaseProps.onBlur}
          id="catamaran_checkbox"
          style={{ transform: "translateY(-.3rem)" }}
        />

        <Text field="surface_area_calculations" {...hiddenProps} />
      </Scope>
    </Row>
  );
};

export default withFacility(withBoatyard(BaseBoatSelector));

import React, { Component, Fragment } from "react";
import { hot } from "react-hot-loader/root";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";

import Dashboard from "./views/Dashboard";
import DashboardFunctionalComponent from "./views/DashboardFunctionalComponent";

import NotFoundPage from "./views/notFound";

import {
  WorkOrderRoutes,
  authRoutes,
  ProfileRoutes,
  PrintViews,
  ApproveViews,
  AdminViews
} from "~/views/ViewRoutes";
import { binnacleClient } from "~/util/api";
import { FacilityContext } from "~/util/api/withFacility";
import { BoatyardContext } from "~/util/api/withBoatyard";
import ProgressBar from "react-progress-bar-plus";
import { observer } from "mobx-react";
import { binnacleStore } from "~/util/store";
import BoatRouter from "./components/boat/Router";
import { WithAPIData } from "~/util/api/withAPIData";
import PrivateRoute from "~/util/privateRoute";

// TODO: scrollto hook not working yet
import ScrollHandler from "~/util/ScrollHandler";

// Initialize Progress Bar
require("~/util/api/progress");
const bgImage = require("~/images/bg_boatyard_img.jpg");

class FacilityRouter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      facility: null,
      loading: true
    };
  }

  componentDidMount() {
    this.downloadFacility();
  }

  downloadFacility() {
    const { url, params } = this.props.match;
    const { facilityNumber = 0 } = params;
    const { history } = this.props;

    const setFacility = ({ data: facility }) =>
      this.setState({ facility, loading: false });
    const catchErr = ({ response }) => {
      this.setState({ loading: false });
      if (_.includes([404, 401], response.status)) {
        const newURL = _.replace(
          history.location.pathname,
          new RegExp(`^${url}`),
          ""
        );
        history.push(newURL);
      }
    };

    binnacleClient
      .get(`/facilities/${facilityNumber}/`)
      .then(setFacility)
      .catch(catchErr);
  }

  render() {
    let path = _.get(this.props, "match.path");

    if (this.state.loading) return null;
    return (
      <FacilityContext.Provider value={this.state.facility}>
        <Switch>
          {/* What is this path for.  In local env it reads "/:facilityNumber(\\d+)?" */}

          <PrivateRoute
            path={`${path}/`}
            exact
            component={DashboardFunctionalComponent}
          />

        {/* old version of dashboard until we decide to remove */}
          <PrivateRoute path={`${path}/dashboard`} exact component={Dashboard} />

   
          <Route strict path={`${path}/print`} component={PrintViews} />
          <Route strict path={`${path}/approve`} component={ApproveViews} />
          <Route path={`${path}/work-orders`} component={WorkOrderRoutes} />
          <Route path={`${path}/profile`} component={ProfileRoutes} />
          <Route path={`${path}/boat`} component={BoatRouter} />
          <Route path={`${path}/admin`} component={AdminViews} />
          {authRoutes.map((props, i) => (
            <Route key={`auth-route-${i}`} {...props} />
          ))}
          <Route component={NotFoundPage} />
        </Switch>
      </FacilityContext.Provider>
    );
  }
}

@observer
class App extends Component {
  render() {
    return (
      <Router>
        <Fragment>
          <ProgressBar percent={binnacleStore.requestProgress} />
          <WithAPIData url="/boatyard/">
            {data =>
              data !== null && (
                <BoatyardContext.Provider value={data}>
                  <Switch>
                    <Route
                      exact
                      strict
                      path="/:url*"
                      render={props => (
                        <Redirect to={`${props.location.pathname}/`} />
                      )}
                    />

                    <Route path="/404/">
                      {() => <NotFoundPage showNav={true} />}
                    </Route>

                    <Route
                      path="/:facilityNumber(\d+)?"
                      component={FacilityRouter}
                    />

                    <Route>{() => <NotFoundPage showNav={true} />}</Route>
                  </Switch>
                  {/* Render a background image everywhere except for the print views */}
                  <Route
                    // Console throws error b/c of Regex, but it still works 🤷‍♂️
                    path={/^((?!\/print\/).)*$/}
                    render={() => (
                      <div
                        className="bg-image-full bg-img-light-blur"
                        style={{ backgroundImage: `url('${bgImage}')` }}
                      ></div>
                    )}
                  ></Route>
                </BoatyardContext.Provider>
              )
            }
          </WithAPIData>
        </Fragment>
      </Router>
    );
  }
}

export default hot(App);

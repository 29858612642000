import { round } from "lodash";

export const decimalToFtInStr = num => {
  const feet = Math.floor(num);
  const inches = Math.round((num - feet) * 12);
  let result = `${feet}'`;
  if (inches) {
    result += ` ${inches}"`;
  }
  return result;
};

export const ftInStrToDecimal = str => {
  if (!(typeof str === "string" || str instanceof String)) {
    return 0;
  }

  const match = str.match(/((?<feet>[0-9]+)')? ?((?<inches>[0-9]+)")?/);
  if (match == null) {
    return 0;
  }
  const feet = match.groups.feet || 0;
  const inches = match.groups.inches || 0;
  return round(parseInt(feet) + parseInt(inches) / 12, 4);
};

import React from "react";
import styled from "styled-components";
import { Button } from "reactstrap";

import { WorkItemsDisplay } from "~/components/workOrders/WorkItemsDisplay";
import previewProps from "./props";

const EstimateContainer = styled.div`
  .WorkItemsDisplay {
    h3 {
      margin-left: 0 !important;
    }
  }
`;

const ReviewEstimateContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const PreviewEstimate = ({
  workItems,
  sales_tax,
  margin,
  totalPrice,
  onReviewEstimate,
}) => {
  return (
    <EstimateContainer>
      <WorkItemsDisplay
        work_items={workItems}
        sales_tax={sales_tax}
        total_price={totalPrice}
        margin={margin}
        discount={0}
        // columns={[0]}
        showTotal={true}
        showDiscount={true}
      />
      {onReviewEstimate && (
        <ReviewEstimateContainer>
          <Button className="btn btn-secondary" onClick={onReviewEstimate}>
            Review Estimate
          </Button>
        </ReviewEstimateContainer>
      )}
    </EstimateContainer>
  );
};

const { workItems, onReviewEstimate, totalPrice, margin } = previewProps;

PreviewEstimate.propTypes = {
  workItems,
  onReviewEstimate,
  margin,
  totalPrice
};

export default PreviewEstimate;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardTitle } from "reactstrap";
import { Form } from "informed";
import { BootstrapText } from "~/util/autoform/bootstrap";
import { getValidators } from "~/util/autoform/validators";
import { binnacleStore } from "~/util/store";
import { observer } from "mobx-react";
import { withAPIForm } from "~/util/api/withAPIForm";
import LoadingButton from "~/util/loadingButton";
import { withAuth } from "~/util/api/withAuth";

const validators_required = getValidators(["required"]);

export default
@withAuth
@withAPIForm
class PasswordChangeScreen extends Component {
  constructor(props) {
    super(props);

    this.onPasswordChangeSuccess = this.onPasswordChangeSuccess.bind(this);
  }

  onPasswordChangeSuccess(data) {
    this.props.login(data);
    // redirect to wherever we were last

    let next = "/";

    if (this.props.location.from !== undefined) {
      next = this.props.location.from;
    } else {
      let nextParam = new URLSearchParams(window.location.search).get("next");

      if (nextParam != null) {
        next = nextParam;
      }
    }

    document.location = next;
    // this.props.history.replace(next);
  }

  render() {
    if (this.state && this.state.type === "success" && this.state.message) {
      return (
        <Container>
          <h1>Password Reset</h1>
          <h2>Success</h2>
          <p>{this.state.message}</p>
        </Container>
      );
    } else {
      let onSubmit = this.props.bindFormSubmit(
        this.onPasswordChangeSuccess,
        "/users/password/"
      );

      return (
        <Container>
          <Row>
            <Col xs="12" sm="8" md={{ size: 6, offset: 3 }}>
              <Card className="p-5">
                <CardTitle className="h1">Change Password</CardTitle>

                <Form onSubmit={onSubmit} getApi={this.props.getFormApi}>
                  <BootstrapText
                    type="password"
                    field="old_password"
                    label="Old Password"
                    validate={validators_required}
                    required={true}
                  />

                  <BootstrapText
                    type="password"
                    field="password"
                    label="New Password"
                    validate={validators_required}
                    required={true}
                  />

                  <BootstrapText
                    type="password"
                    field="password2"
                    label="Confirm New Password"
                    validate={validators_required}
                    required={true}
                  />

                  <LoadingButton
                    loading={this.props.sendingRequest}
                    className="btn-lg btn-primary"
                  >
                    Submit
                  </LoadingButton>
                  <Link to="/password-reset" />
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      );
    }
  }
}

/*
 *  toggleArrayValue - returns a new array with the <key> added/removed
 *  based on the previous state of the array.
 *  if <force> is defined and true, <key> is added regardless of previous state
 *  if <force> is defined and false, <key> is removed regardless of previous state
 */
export const toggleArrayValue = (inArr, key, force) => {
  let outArr = _.clone(inArr);
  if (!_.isUndefined(force)) {
    if (force && !_.includes(outArr, key)) {
      outArr.push(key);
    }
    if (!force) {
      _.pull(outArr, key);
    }
  } else {
    if (_.includes(outArr, key)) {
      _.pull(outArr, key);
    } else {
      outArr.push(key);
    }
  }
  return outArr;
};

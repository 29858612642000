import React, { useState, useEffect } from "react";
import { Col } from "reactstrap";
import KindsButton from "./KindButton";
const KindsButtonList = ({ key, indices, children }) => {
  // useEffect(() => {}, []);
  const kinds = [
    {
      id: "workorder",
      name: "Work Orders"
    },
    {
      id: "boat",
      name: "Boat"
    },
    {
      id: "profile",
      name: "Profile"
    },
    {
      id: "facility",
      name: "facility"
    }
  ];
  return (
    <>
      <Col
        xs="auto"
        md="auto"
        className="d-inline-flex align-items-center pl-0 ml-0"
        style={{ minHeight: "40px" }}
      >
        <div className="d-flex">
          {kinds.map((kind, index) => {
            return <KindsButton key={kind.id} {...kind} />;
          })}
        </div>
      </Col>
    </>
  );
};

export default KindsButtonList;

import PropTypes from "prop-types";

// Is there a consistent "work item" shape? It seems to vary wildly
const workItem = PropTypes.shape({
  title: PropTypes.string,
  price: PropTypes.bool,
  ordering: PropTypes.bool,
  id: PropTypes.string
});

export const workOrderProps = PropTypes.shape({
  phases: PropTypes.arrayOf(
    PropTypes.shape({
      date_completed: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          equal__: PropTypes.string,
          changed__: PropTypes.string
        })
      ]),
      date_estimated: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          equal__: PropTypes.string,
          changed__: PropTypes.string
        })
      ]),
      phase: PropTypes.string,
      phase_label: PropTypes.string
    })
  ),
  work_items: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        equal__: workItem,
        changed__: workItem
      }),
      workItem
    ])
  ),
  total_price: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      changed__: PropTypes.arrayOf(PropTypes.number),
      equal__: PropTypes.arrayOf(PropTypes.number)
    })
  ]),
  owner_profile: PropTypes.shape({
    email: PropTypes.string
  }),
  margin: PropTypes.number
});

export const facilityProps = PropTypes.shape({
  name: PropTypes.string,
  address: PropTypes.shape({
    city: PropTypes.string,
    state: PropTypes.string,
    street: PropTypes.string,
    zip: PropTypes.string
  }),
  description: PropTypes.string,
  id: PropTypes.string,
  number: PropTypes.number,
  phone: PropTypes.string,
  status: PropTypes.string,
  status_date: PropTypes.string,
  terms_and_conditions: PropTypes.string,
  url: PropTypes.string,
  work_items: PropTypes.arrayOf(workItem),
  menu_items: PropTypes.arrayOf(workItem)
});

export const profileProps = PropTypes.shape({
  name: PropTypes.string,
  title: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string
});

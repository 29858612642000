import React, { Component } from "react";
import {
  Row,
  Col,
  Container,
  CustomInput,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalBody
} from "reactstrap";
import NavBar from "../components/navbar";
import { withProfile } from "~/util/api/withProfile";
import { Link } from "react-router-dom";
import Fa from "~/util/fa";
import { binnacleClient } from "~/util/api";
import { LoadingIndicator } from "~/util/loadingWrapper";
import { toggleArrayValue } from "~/util/lang";
import { kindList } from "~/data/kinds";
import { statusList } from "~/data/statuses";
import FloatingAttribution from "~/components/FloatingAttribution";
import MultiAdd from "~/components/dashboard/MultiAdd";
import ItemCard from "~/components/dashboard/ItemCard";
import QuickBooksExportButton from "~/components/dashboard/QuickBooksExportButton";

import FilterControlItem from "~/components/dashboard/FilterControlItem";

const parseKeys = ["type", "status"];
const parseReg = new RegExp(
  `^(?: *)(${parseKeys.join("|")})(?::)([a-zA-Z]+)(?: *)`
);
const filterKinds = _.filter(
  kindList,
  ({ id }) => (id !== "promotion" && id !== "copy")
);
const filterStatuses = statusList;
export function fullSearchStrToObj(strIn) {
  let str = _.clone(strIn);
  let out = { fullSearch: strIn };
  while (parseReg.test(str)) {
    const [throwaway, key, value] = str.match(parseReg);
    out[key] = out[key] || [];
    out[key].push(value);
    str = str.replace(parseReg, "");
  }
  if (!_.has(out, "type")) out.type = _.map(filterKinds, "id");
  out.search = str;
  return out;
}
export function objToFullSearchStr({ search, fullSearch, ...filters }) {
  // Deliberately throw away fullSearch
  const filterStrs = [];
  for (let key of parseKeys) {
    _.each(filters[key], value => filterStrs.push(`${key}:${value}`));
  }
  return _.compact([...filterStrs, search]).join(" ");
}

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      advancedModalOpen: false,
      advancedSearchActive: false,
      items: [],
      fullSearch: "type:workorder",
      search: "",
      loading: true,
      loadingMore: false,
      status: [],
      type: ["workorder"],
      cursor: undefined,
      noMore: false,
      qbSelectActive: false,
      qbOnline: false,
      selectedItems: []
    };

    this.scrollTrigger = React.createRef();
    this.filterMenuRef = React.createRef();
    this.handleScroll = this.handleScroll.bind(this);
    this.updateItem = this.updateItem.bind(this);
    this.load = _.debounce(this.load.bind(this), 300);
    this.toggleFilter = this.toggleFilter.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onFullSearchChange = this.onFullSearchChange.bind(this);
    this.toggleAdvancedModal = this.toggleAdvancedModal.bind(this);
    this.qbSelectToggle = this.qbSelectToggle.bind(this);
    this.addSelectedItem = this.addSelectedItem.bind(this);
    this.removeSelectedItem = this.removeSelectedItem.bind(this);
    this.itemsHaveWorkorders = this.itemsHaveWorkorders.bind(this);
    this.getDashboardState = this.getDashboardState.bind(this);
    this.selectedItemsGreaterThanZero =
      this.selectedItemsGreaterThanZero.bind(this);
    this.clearSelectedItems = this.clearSelectedItems.bind(this);
  }

  componentDidMount() {
    this.load();
    window.addEventListener("scroll", this.handleScroll, false);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll, false);
  }

  qbSelectToggle(eventName = "quickBooksDesktopExport") {
    this.setState({ qbSelectActive: !this.state.qbSelectActive });
    // empty QuickBooks selected items list when done with selection
    if (!this.state.qbSelectActive) {
      // update search filter to show these items
      this.setState({ fullSearch: "type:readyforinvoice" });
      this.load();

      // clear selected items after toggling qbSelectActive state
      this.clearSelectedItems();
    } else {
      // change filter back to original state
      this.setState({ fullSearch: "type:workorder" });
      this.load();

      // clear selected items after toggling qbSelectActive state
      this.clearSelectedItems();
    }
  }
  clearSelectedItems() {
    console.log("This should clear the selected items");
    this.setState({ selectedItems: [] });
  }

  handleScroll() {
    if (!this.scrollTrigger.current) return;

    const triggerOffset =
      this.scrollTrigger.current.offsetTop +
      this.scrollTrigger.current.clientHeight;
    const pageOffset = window.pageYOffset + window.innerHeight;
    if (pageOffset > triggerOffset) {
      this.loadMore();
    }
  }

  load() {
    const { fullSearch } = this.state;
    binnacleClient
      .get("/options/", { params: { search: fullSearch } })
      .then(({ data }) => {
        this.setState({
          items: data.results,
          cursor: data.next_cursor,
          loading: false,
          noMore: false
        });
      });
  }

  loadMore() {
    const { fullSearch, cursor, items: prevItems, noMore } = this.state;
    if (noMore) return;
    this.setState({ loadingMore: true });
    binnacleClient
      .get("/options/", { params: { search: fullSearch, cursor } })
      .then(({ data }) => {
        if (_.isEmpty(data.results))
          this.setState({ cursor: false, loadingMore: false, noMore: true });
        const items = _.concat(prevItems, data.results);
        this.setState({ items, cursor: data.next_cursor });
      });
  }

  onAdvSearchChange(e) {
    this.setState({ search: e.target.value });
  }

  onSearchChange(e) {
    this.setState({ search: e.target.value }, this.updateFiltersInFullSearch);
  }

  onFullSearchChange(e) {
    this.setState(fullSearchStrToObj(e.target.value, true), this.load);
  }

  updateItem(item) {
    const { items } = this.state;
    const index = _.findIndex(items, { extra: { id: item.id } });
    _.merge(items[index].extra, item);
    this.setState({ items });
  }

  toggleFilter(key, id, forceOnly = false) {
    console.log(key, id, forceOnly);

    const stateArray = forceOnly ? [id] : toggleArrayValue(this.state[key], id);
    const newState = { [key]: stateArray };
    // If changing {type} and new value doesn't include 'workorder' reset {status}
    if (key === "type" && !_.includes(this.stateArray, "workorder"))
      newState.status = [];
    // If changing {status} and new value is not empty, make sure {type: workorder} is checked
    if (key === "status" && !_.isEmpty(stateArray))
      newState.type = toggleArrayValue(this.state.type, "workorder", true);
    this.setState(newState, this.updateFiltersInFullSearch);
  }

  toggleFilterArray(key, ids, forceOnly = false) {
    console.log("key", key, "ids", ids, "forceOnly", forceOnly);
    console.log("state");
    this.setState({ [key]: ids }, this.updateFiltersInFullSearch);
  }

  updateFiltersInFullSearch() {
    this.setState(
      {
        fullSearch: objToFullSearchStr(
          _.pick(this.state, ["search", "type", "status"])
        )
      },
      this.load
    );
  }

  toggleAdvancedModal(value) {
    const advancedModalOpen = value || !this.state.advancedModalOpen;
    const newState = { advancedModalOpen };
    if (value === false) {
      // submit button was pressed
      newState.fullSearch = objToFullSearchStr(
        _.pick(this.state, ["search", "type", "status"])
      );
    }
    this.setState(newState, value === false ? this.load : () => {});
  }

  isAdmin() {
    return this.props.profileIsType([
      "service_writer",
      "facility_manager",
      "admin"
    ]);
  }

  handleToggleAdvancedSearch = toggle => () => {
    const { search, type } = this.state;

    const nextState = {
      advancedSearchActive: toggle
    };

    const nextFullSearch = objToFullSearchStr(
      _.pick(this.state, ["search", "type", "status"])
    );

    if (!toggle) {
      nextState.fullSearch = type ? nextFullSearch : search;
    }

    this.setState(nextState, !toggle ? this.load : null);
  };

  renderLiCheckboxFilter(key, indeces) {
    const constValues = key === "type" ? filterKinds : filterStatuses;
    const indecesToDisplay = indeces || _.range(constValues.length);
    const items = _.map(indecesToDisplay, i => constValues[i]);
    const stateValues = this.state[key];
    return items.map(({ label, id }) => (
      <li key={`filter-li-${key}-${id}`}>
        <CustomInput
          id={`filter-li-${key}-${id}`}
          type="checkbox"
          checked={_.includes(stateValues, id)}
          label={label}
          onChange={() => this.toggleFilter(key, id)}
        />
      </li>
    ));
  }

  addSelectedItem(item) {
    const { selectedItems } = this.state;
    this.setState({ selectedItems: [...selectedItems, item] });
  }

  removeSelectedItem(item) {
    const { selectedItems } = this.state;
    console.log("removeSelectedItem", item, selectedItems);
    this.setState({
      selectedItems: selectedItems.filter(i => i !== item)
    });
  }

  renderAdvancedModal() {
    const { advancedModalOpen, search } = this.state;
    return (
      <>
        {/* Button for Advanced Filter Modal */}
        <Button
          disabled={this.state.qbSelectActive}
          className="btn btn-light-feature rounded-pill mb-md-0 mr-3 ml-1 text-dark"
          style={{ flexShrink: 0 }}
          onClick={() => this.toggleAdvancedModal(true)}
        >
          <Fa icon="search" />
          <span className="ml-1 font-weight-normal">Advanced</span>
        </Button>
        {/* Modal Contents */}
        <Modal
          isOpen={advancedModalOpen}
          toggle={() => this.toggleAdvancedModal()}
        >
          <ModalBody>
            <h4 className="text-center mb-3">Advanced Filter</h4>
            <div className="input-group">
              <span className="input-group-prepend">
                <span className="filter-feature-icon input-group-text">
                  <Fa icon="search" />
                </span>
              </span>
              <Input
                onChange={this.onSearchChange}
                placeholder="Filter"
                value={search}
              />
            </div>
            <ul className="list-unstyled my-3 ml-2">
              {this.renderLiCheckboxFilter("type", [0])}
              <ul className="list-unstyled" style={{ paddingLeft: "2rem" }}>
                {this.renderLiCheckboxFilter("status")}
              </ul>
              {this.renderLiCheckboxFilter(
                "type",
                _.range(1, filterKinds.length)
              )}
            </ul>
            <div className="text-center">
              <Button
                color="primary"
                onClick={() => {
                  this.handleToggleAdvancedSearch(true)();
                  this.toggleAdvancedModal(false);
                }}
              >
                Filter
              </Button>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }

  // SIDEBAR FILTER MENU
  renderIconFilters() {
    const { type } = this.state;
    return (
      <div className="wrapper-sidebar">
        <div className="sidebar-filter-list" ref={this.filterMenuRef}>
          {_.map(filterKinds, kind => (
            <FilterControlItem
              key={`filter-${kind.id}`}
              data-testid={`filter-btn-${kind}`}
              onClick={() => this.toggleFilter("type", kind.id, true)}
              selected={_.includes(type, kind.id)}
              {...kind}
            />
          ))}
        </div>
        <Link to="/" className="logo-pettit d-none d-md-block">
          <img
            src={require("~/images/logo-pettit.png")}
            alt="Binnacle app"
            id="logo"
          />
        </Link>
      </div>
    );
  }

  invoiceIsReady(status) {
    const billableStatuses = ["approved", "complete", "replaced"];
    return billableStatuses.includes(status) || false;
  }

  itemsHaveWorkorders() {
    return _.some(this.state.items, item => {
      return item.kind === "workorder";
    });
  }

  getDashboardState() {
    return this.state;
  }

  selectedItemsGreaterThanZero() {
    return this.state.selectedItems.length > 0;
  }

  dashboardSecondRowButtons() {
    const { type } = this.state;
    return (
      <div className="d-flex" ref={this.filterMenuRef}>
        {_.map(filterKinds, kind => (
          <FilterControlItem
            key={`filter-${kind.id}`}
            qbSelectActive={this.state.qbSelectActive}
            onClick={() =>
              !this.state.qbSelectActive
                ? this.toggleFilter("type", kind.id, true)
                : () => {}
            }
            selected={_.includes(type, kind.id)}
            {...kind}
          />
        ))}
      </div>
    );
  }
  render(props) {
    document.title = "Pettit Pro Portal | Dashboard";
    const {
      items,
      loading,
      fullSearch,
      loadingMore,
      noMore,
      search,
      advancedSearchActive
    } = this.state;
    const bgImage = require("~/images/bg_boatyard_img.jpg");
    return (
      <div className="wrapper-page dashboard">
        {/* SIDEBAR */}
        {/* {this.renderIconFilters()} */}
        {/* <FloatingAttribution left className="d-md-none" /> */}
        {/* /SIDEBAR */}
        <div className="wrapper-main-content ">
          <div className="wrapper-header-sticky sticky">
            <NavBar>
              <div className="input-group p-relative">
                <Input
                  className="input-search--feature"
                  onChange={this.onFullSearchChange}
                  placeholder="Search by boat, owner, keyword"
                  value={advancedSearchActive ? fullSearch : search}
                />
                <span className="input-group-icon--feature input-group-text rounded-pill">
                  <Fa icon="search" />
                  Search
                </span>
              </div>
              {this.renderAdvancedModal()}
            </NavBar>
            {/* Second Row */}
            <div className="bg-white nav-shadow py-2">
              <Container>
                <Row className=" d-flex align-items-center justify-content-between">
                  <Col xs="12" md="auto" className="d-flex  mb-md-0">
                    {this.dashboardSecondRowButtons()}
                  </Col>
                  {/* Need to move this to its own Quickbooks component */}
                  <QuickBooksExportButton
                    selectedItemsGreaterThanZero={
                      this.selectedItemsGreaterThanZero
                    }
                    load={this.load}
                    clearSelectedItems={this.clearSelectedItems}
                    selectedItems={this.state.selectedItems}
                    qbSelectActive={this.state.qbSelectActive}
                    getDashboardState={this.getDashboardState}
                    profile={this.props.profile}
                    qbSelectToggle={this.qbSelectToggle}
                    itemsHaveWorkorders={this.itemsHaveWorkorders}
                    {...this.props}
                  />
                </Row>
              </Container>
            </div>
          </div>

          <Container>
            {/* selected: {this.state.selectedItems.join(", ")} */}
            {advancedSearchActive ? (
              <Row>
                <Col md={12}>
                  <Button
                    id="btn-clear-advanced-search"
                    color=""
                    className="btn-outline-secondary mt-3"
                    onClick={this.handleToggleAdvancedSearch(false)}
                  >
                    <Fa icon="times" className="mr-2" />
                    Clear Advanced Search
                  </Button>
                </Col>
              </Row>
            ) : null}
            <Row className="mt-4">
              <Col md={12} data-testid="rol-list">
                {loading && <LoadingIndicator />}
                {!loading && _.isEmpty(items) && <em>No results found</em>}
                {/* ROL Items */}
                {items
                  .filter(item => {
                    if (this.state.qbSelectActive) {
                      return (
                        item.kind === "workorder" &&
                        item.extra.qb_invoiced === false &&
                        this.invoiceIsReady(item.extra.status)
                      );
                    }
                    return item;
                  })
                  .map((item, index) => (
                    <div>
                      <ItemCard
                        {...item}
                        userProfile={this.props.profile}
                        qbSelectActive={this.state.qbSelectActive}
                        updateItem={this.updateItem}
                        key={`option-item-${index}`}
                        className={"mb-2 d-flex"}
                        selectedItems={this.state.selectedItems}
                        addSelectedItem={this.addSelectedItem}
                        removeSelectedItem={this.removeSelectedItem}
                      />
                    </div>
                  ))}
                <div ref={this.scrollTrigger} />
                {loadingMore && <LoadingIndicator />}
                {noMore && <em>No more items</em>}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

// API OPTIONS LOAD OFF FOR FRONTEND DESIGN

export default withProfile(Dashboard);

/*
if draft, calc. days since creation, action: send (goes to pending)
if pending, calc. days since quoted, action: mark approved
if approved, mark phase, due date for phase, calc. days till deadline, action: complete phase
*/

// export default withFacility(({facility}) => {
//     sampleData
//     return <Dashboard data={sampleData} facility={facility} />
// });

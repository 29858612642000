import React, { Component } from "react";

export const FacilityContext = React.createContext({});
FacilityContext.Provider.displayName = "FacilityContext.Provider";
FacilityContext.Consumer.displayName = "FacilityContext.Consumer";

/**
 * This is a higher order component that simply inserts the facility object into props
 */
export default function withFacility(WrappedComponent) {
  return class extends Component {
    render() {
      return (
        <FacilityContext.Consumer>
          {value => <WrappedComponent {...this.props} facility={value} />}
        </FacilityContext.Consumer>
      );
    }
  };
}

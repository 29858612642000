import React from "react";
import styled from "styled-components";
import { Row, Col } from "reactstrap";
import { useFormApi } from "informed";

import SearchCreateSelect from "~/components/SearchCreateSelector";
import Fa from "~/util/fa";

const StyledRow = styled(Row)`
  margin-top: 1rem;
  margin-bottom: 1rem;

  .form-group {
    margin-bottom: 0;
  }

  .col-sm-4 {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const CustomerSearch = ({ onSetBoat }) => {
  const formApi = useFormApi();
  const formState = formApi.getValues();
  const { owner_profile, representative_profile, boat, customer_search } =
    formState;

  const onChange = value => {
    if (!boat || !boat.name) {
      onSetBoat(value);
    }

    if (!owner_profile || !owner_profile.name) {
      formApi.setValues({
        owner_profile: { ...value, is_primary_contact: true }
      });
    } else if (!representative_profile || !representative_profile.name) {
      formApi.setValues({
        representative_profile: { ...value, is_primary_contact: true }
      });
    }
  };

  return (
    <StyledRow>
      <Col sm="3" lg="3">
        <div
          style={{
            display: "flex",
            alignItems: "baseline"
          }}
        >
          <Fa icon="search" className="mt-2" />
          <strong>Customer Search</strong>
        </div>
      </Col>
      <Col
        sm="9"
        lg="9"
        className=" w-100 px-0 flex justify-content-start ml-0"
      >
        <div
          style={{
            width: "85%",
            marginLeft: "-75px"
          }}
        >
          <SearchCreateSelect
            url="/profiles/"
            field="customer_search"
            label="Customer Search"
            showLabel={false}
            id="input-customer-search"
            creatable={false}
            isPlaceholder={!customer_search}
            params={{ profile_type: "customer" }}
            displayValue={
              !customer_search
                ? "Search by owner or representative name, email, or phone number to lookup boat information"
                : ""
            }
            onChange={onChange}
            validateOnChange={false}
            // validate={validateNameIdCached[scopeKey]}
          />
        </div>
      </Col>
    </StyledRow>
  );
};

export default CustomerSearch;

import React from "react";
import { Container, Row, Col } from "reactstrap";

import BoatyardImport from "~/components/boatyard-admin/BoatyardImport";
import NavBar from "../components/navbar";
const bgImage = require("~/images/bg_boatyard_img.jpg");

const BoatyardAdmin = () => {
  document.title = `Pettit Pro Portal | Admin`;
  return (
    <>
      <NavBar details={true} className="bg-white" shadow={true} />
      <Container>
        <Row>
          <Col xs="12">
            <BoatyardImport />
          </Col>
        </Row>
      </Container>
      <div
        className="bg-image-full bg-img-light-blur"
        style={{ backgroundImage: `url('${bgImage}')` }}
      ></div>
    </>
  );
};

export default BoatyardAdmin;

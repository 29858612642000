import React, { useState, useEffect } from "react";
// import { range } from "~/util/nolodash";
import { Modal, ModalBody, Button, Input } from "reactstrap";
import Fa from "~/util/fa";
import LiCheckboxFilter from "./LiCheckboxFilter";

import { useDashboardStore } from "~/store";
import { shallow } from "zustand";

const AdvancedModal = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(isOpen);
  const [qbSelectActive, setQbSelectActive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const { query, setQuery, setFireSearch, setAdvancedSearchActive, qbSelectionActive } =
    useDashboardStore(
      state => ({
        query: state.query,
        setQuery: state.setQuery,
        setFireSearch: state.setFireSearch,
        setAdvancedSearchActive: state.setAdvancedSearchActive,
        qbSelectionActive: state.qbSelectionActive
      }),
      shallow
    );

  const onSearchChange = e => {
    setQuery(e.target.value);
    setAdvancedSearchActive(true);
  };
  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  return (
    <>
      {/* Button for Advanced Filter Modal */}
      <Button
        disabled={qbSelectionActive}
        className="btn btn-light-feature rounded-pill mb-md-0 mr-3 ml-1 text-dark"
        style={{ flexShrink: 0 }}
        onClick={() => toggle(true)}
      >
        <Fa icon="search" />
        <span className="ml-1 font-weight-normal">Advanced</span>
      </Button>
      {/* Modal Contents */}
      <Modal isOpen={isModalOpen} toggle={() => toggle()}>
        <ModalBody>
          <h4 className="text-center mb-3">Advanced Filter</h4>
          <div className="input-group">
            <span className="input-group-prepend">
              <span className="filter-feature-icon input-group-text">
                <Fa icon="search" />
              </span>
            </span>
            <Input
              onChange={onSearchChange}
              placeholder="Filter"
              value={query}
            />
          </div>
          <ul className="list-unstyled my-3 ml-2">
            <LiCheckboxFilter />
          </ul>
          <div className="text-center">
            <Button
              color="primary"
              onClick={() => {
                setAdvancedSearchActive(true);
                toggle(false);
              }}
            >
              Filter
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default AdvancedModal;

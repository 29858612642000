import React from "react";
import IconWithTooltip from "~/components/IconWithTooltip";
import QuickBooksStatusIcon from "../QuickBooksStatusIcon";
const IconList = ({ qbInvoiced, parent, hasUnreadNote }) => {
  return (
    <>
      <div className="d-flex align-items-center">
        {/* hasParent show purple icon */}
        {parent ? (
          <IconWithTooltip />
        ) : null}

        {/* WO was invoiced in Quickbooks, show qb icon */}
        {qbInvoiced ? <QuickBooksStatusIcon /> : null}

        {hasUnreadNote ? ( // show note icon
          <div className="d-flex align-items-center">
            <div
              // needs to be position absolute and on top of the envelope icon and be red and circular and small

              style={{
                width: `${9}px`,
                height: `${9}px`,
                backgroundColor: "#C9241A",
                borderRadius: "50%",
                position: "relative",
                top: "-6px",
                left: "22px",
                border: "1.5px solid #FFF"
                
              }}
            ></div>
            <i className="fas fa-envelope" style={{ fontSize: "18px", color: "#9FA9B4" }}></i>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default IconList;

import React, { useState, useEffect } from "react";
import { range } from "~/util/nolodash";
import { CustomInput } from "reactstrap";
import { useDashboardStore } from "~/store";
import { shallow } from "zustand";
import { kindList } from "~/data/kinds";
const LiCheckboxFilter = ({ key, indices, children }) => {
  const kinds = [
    { label: "Work Orders", id: "workorder", key: "kinds" },
    { label: "Boats", id: "boat", key: "kinds" },
    { label: "Profiles", id: "profile", key: "kinds" },
    { label: "Facilities", id: "facility", key: "kinds" }
  ];

  const statuses = [
    {
      id: "rejected",
      label: "Declined",
      key: "status"
    },
    {
      id: "draft",
      label: "Draft",
      key: "status"
    },
    {
      id: "pending",
      label: "Pending",
      key: "status"
    },
    {
      id: "approved",
      label: "Approved",
      key: "status"
    },
    {
      id: "complete",
      label: "Completed",
      key: "status"
    },
    {
      id: "dismissed",
      label: "Dismissed",
      key: "status"
    },
    {
      id: "replaced",
      label: "Changed",
      key: "status"
    }
  ];

  const {
    selectedKinds,
    workOrderStatuses,
    setSelectedKinds,
    setAdvancedSearchActive,
    setWorkOrderStatuses
  } = useDashboardStore(
    state => ({
      selectedKinds: state.selectedKinds,
      workOrderStatuses: state.workOrderStatuses,
      setSelectedKinds: state.setSelectedKinds,
      setAdvancedSearchActive: state.setAdvancedSearchActive,
      setWorkOrderStatuses: state.setWorkOrderStatuses
    }),
    shallow
  );

  const toggleFilter = (key, id) => {
    setAdvancedSearchActive(true);
    console.log("toggleFilter", key, id);
    console.log("selectedKinds", selectedKinds);
    if (key === "kinds") {
      if (selectedKinds.includes(id)) {
        setSelectedKinds(selectedKinds.filter(item => item !== id));
      } else {
        setSelectedKinds([...selectedKinds, id]);
      }
    }

    if (key === "status") {
      if (workOrderStatuses.includes(id)) {
        setWorkOrderStatuses(workOrderStatuses.filter(item => item !== id));
      } else {
        setWorkOrderStatuses([...workOrderStatuses, id]);
      }
    }

    console.log("selectedKinds second call", selectedKinds);

    // logic to clear all statuses when workorders is cleared
    if (id === "workorder" && selectedKinds.includes(id)) {
      setWorkOrderStatuses([]);
    }

    // logic to select workorders when status goes from empty to not empty
    if (key === "status" && !selectedKinds.includes("workorder")) {
      setSelectedKinds([...selectedKinds, "workorder"]);
    }
  };
  useEffect(() => {}, []);
  // Loop for kinds ["workorder", "boat", "profile", "facility"]
  return kinds.map(({ label, key, id }) => (
    <li key={`filter-li-${key}-${id}`}>
      <CustomInput
        id={`filter-li-${key}-${id}`}
        type="checkbox"
        checked={selectedKinds.includes(id)}
        label={label}
        onChange={() => toggleFilter(key, id)}
      />
      {/*  loop for statuses ["draft", "pending", "approved", "declined", "dismissed", "replaced", "complete"] */}
      {id === "workorder" ? (
        <ul>
          {statuses.map(({ label, key, id }) => (
            <li style={{ listStyle: "none" }} key={`filter-li-${key}-${id}`}>
              <CustomInput
                id={`filter-li-${key}-${id}`}
                type="checkbox"
                checked={workOrderStatuses.includes(id)}
                label={label}
                onChange={() => toggleFilter(key, id)}
              />
            </li>
          ))}
        </ul>
      ) : null}
    </li>
  ));
};

export default LiCheckboxFilter;

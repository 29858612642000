import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { Form, Text } from "informed";
import { BootstrapText } from "~/util/autoform/bootstrap";
import { getValidators } from "~/util/autoform/validators";
import { withAPIForm } from "~/util/api/withAPIForm";
import { withAuth } from "~/util/api/withAuth";
import AgreeTermsSubmit from "./AgreeTermsSubmit";

const validators_required = getValidators(["required"]);
const validators_email = getValidators(["required", "email"]);

export default
@withAuth
@withAPIForm
class CompleteRegistrationScreen extends Component {
  constructor(props) {
    super(props);
    this.onCompleteRegistration = this.onCompleteRegistration.bind(this);
    this.onRegistrationError = this.onRegistrationError.bind(this);
    this.state = {
      complete: false
    };
  }

  onCompleteRegistration(data) {
    this.props.login(data);
    document.location = "/";
    // this.props.history.push('/');
  }

  onRegistrationError(error) {
    if (
      error.response.status === 400 &&
      _.hasIn(error.response.data, "token")
    ) {
      alert(
        "Invalid link. Your registration link is either invalid, or has expired."
      );
      this.props.history.push("/");
    }
  }

  render() {
    let config = {
      onError: this.onRegistrationError,
      extraData: { agree_terms: true }
    };
    let onSubmit = this.props.bindFormSubmit(
      this.onCompleteRegistration,
      "/profiles/signup/",
      config
    );

    return (
      <Container className="mb-4">
        <Row>
          <Col xs="12" sm={{ size: 8, offset: 2 }}>
            <Card className="p-5">
              <CardBody>
                <h1>Complete Registration</h1>

                <Form onSubmit={onSubmit} getApi={this.props.getFormApi}>
                  <BootstrapText
                    field="email"
                    label="Email"
                    validate={validators_email}
                    required={true}
                  />

                  <BootstrapText
                    field="password"
                    label="Password"
                    type="password"
                    validate={validators_required}
                    required={true}
                  />

                  <BootstrapText
                    field="password2"
                    label="Password, again"
                    type="password"
                    validate={validators_required}
                    required={true}
                  />

                  <Text
                    field="token"
                    type="hidden"
                    initialValue={this.props.match.params.token}
                  />

                  <AgreeTermsSubmit
                    loading={this.props.sendingRequest}
                    className="btn-lg"
                    skipModal={true}
                  >
                    Complete Registration
                  </AgreeTermsSubmit>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

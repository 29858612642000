import React from 'react';
import {useFormState} from 'informed'
import {BootstrapText} from '~/util/autoform/bootstrap';
import {currency, smartRound} from "~/util/currency";
import DisplayMarginOrDiscount from './DisplayMarginDiscount';

const MarginOrDiscountRow = ({ field, label, price, canEdit }) => {
    const formState = useFormState();
    const fieldValue = _.get(formState, `values.${field}`);
  
    return (
      <tr>
        <td />
        <td className="align-middle">
          <strong>{label}</strong>
        </td>
        <td>
          <>
            <BootstrapText
              labelClassName="mb-0"
              min={0}
              groupProps={{ style: { maxWidth: "130px" } }}
              field={field}
              type={canEdit ? "number" : "hidden"}
              placeholder={label}
              showLabel={false}
              allowEmptyString={true}
              validateOnChange={true}
              appendText={canEdit ? "%" : null}
              step="any"
              maskOnBlur={true}
              mask={value => smartRound(value)}
              id={`${field}_input`}
            />
            {!canEdit ? <DisplayMarginOrDiscount value={fieldValue} /> : null}
          </>
        </td>
        <td className="align-middle text-right">{currency(price)}</td>
        <td />
      </tr>
    );
  };
  
  export default MarginOrDiscountRow;
  
import React from "react";
import { boatsClient as client } from "~/util/api";
import { LoadingIndicator } from "~/util/loadingWrapper";
import { withAPIData } from "~/util/alpacaLegacyAPI/";

import Comment from "~/components/comments/Comment";

const boatComments = ({ loading, data }) => {
  if (loading) return <LoadingIndicator />;

  return (
    <div className="d-flex flex-column">
      {_.isEmpty(data.results) ? (
        <em>No notes on this boat yet</em>
      ) : (
        (data.results || []).map((comment, i) => (
          <Comment comment={comment} key={`comment-${i}`} />
        ))
      )}
    </div>
  );
};

export default withAPIData(
  boatComments,
  "/boats/${id}/comments/",
  null,
  client
);

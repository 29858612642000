import React, {
  Component,
  useState,
  createContext,
  useContext
} from "react";

import {
  Container,
  Card,
  Row,
  Col,
  Button,
  Tooltip,
} from "reactstrap";
import { Form, Scope, useFormApi, useFormState, Text } from "informed";
import flat from "flat";
import classnames from "classnames";
import { Link } from "react-router-dom";
import WorkOrderBoatImageWrapper from "~/components/boat/WorkOrderBoatImageWrapper";
import { binnacleClient, boatsClient } from "~util/api";
import WorkItemsList from "~/components/workOrders/workItemsList";
import ProjectSchedule from "~/components/workOrders/ProjectSchedule";
import { BoatDisplay } from "~/components/workOrders/BoatDisplay";
import { ProfileDisplay } from "~/components/workOrders/ProfileDisplay";
import SelectEditCreatePerson from "~/components/profiles/SelectEditCreateForm";
import SelectEditCreateBoat from "~/components/boat/SelectEditCreateForm";
import FloatingAttribution from "~/components/FloatingAttribution";
import { FormStatePre } from "~/util/DevPre";
import BoatComments from "~/components/boat/Comments";
import CustomerSearch from "~/components/workOrders/CustomerSearch";
import { withProfile } from "~/util/api/withProfile";
import withFacility from "~/util/api/withFacility";
import withBoatyard from "~/util/api/withBoatyard";
import { addFormErrors } from "~/util/autoform/util";
import WOTitleBar from "./WOTitleBar";
import ProjectActivity from "./ProjectActivity";
import FormActionButtons from "./FormActionButtons";
import Fa from "~/util/fa";
import CollapsibleCard from "~/components/generic/CollapsibleCard";
import PriceDigestAttribution from "~/components/workOrders/PriceDigestAttribution";

const ScopePersonFields = ({ scope, ...props }) => (
  <Scope scope={scope}>
    <SelectEditCreatePerson {...props} scopeKey={scope} />
  </Scope>
);

const ProfileForms = ({ onSetBoat }) => (
  <Row>
    <ScopePersonFields
      onSetBoat={onSetBoat}
      scope="owner_profile"
      label="Owner"
      showLabels={true}
    />
    <ScopePersonFields
      onSetBoat={onSetBoat}
      scope="representative_profile"
      label="Representative"
      showLabels={false}
    />
  </Row>
);

const phasesEmpty = phases => {
  return _.every(phases, ({ date_estimated }) => _.isEmpty(date_estimated));
};

const profileEmpty = owner_profile => {
  return _.every(_.values(owner_profile), value => _.isEmpty(value));
};

const buttonMessages = {
  boat: "Select or create a boat",
  work_items: "Add some work items"
  // phases: "Add at least one estimate date",
};

export const Btn = ({
  type = "submit",
  name = "button",
  className = "px-3 mr-2",
  submitOnClick = false,
  children,
  ...otherProps
}) => {
  const formApi = useFormApi();
  const onClick = e => {
    formApi.setValue("button", e.target.value);
    if (submitOnClick) {
      formApi.submitForm();
    }
  };
  const props = {
    onClick,
    type,
    name,
    className,
    ...otherProps
  };
  return <Button {...props}>{children}</Button>;
};

export const FormBtn = props => {
  const [isOpen, setOpen] = useState(false);
  const formState = useFormState();
  const toggle = () => setOpen(!isOpen);
  const status = useContext(StatusContext);

  const {
    keys = _.keys(buttonMessages), // logic props
    color = "primary",
    outline = false,
    className: passedClassName = "px-3 mx-3",
    id,
    size,
    submitOnClick,
    children,
    ...otherProps
  } = props; // display props

  if (status !== "draft")
    return (
      <Btn
        {...{ color, size, outline, className: passedClassName }}
        {...otherProps}
      >
        {children}
      </Btn>
    );

  let incomplete = [];
  for (let k of keys) {
    if (k === "phases" && phasesEmpty(formState.values.phases)) {
      incomplete.push(buttonMessages.phases);
    } else if (_.isEmpty(formState.values[k])) {
      incomplete.push(buttonMessages[k]);
    }
  }
  const className = classnames("btn", "disabled", passedClassName, {
    [`btn-${size}`]: size,
    [`btn-outline-${color}`]: outline,
    [`btn-${color}`]: !outline
  });

  if (!_.isEmpty(incomplete))
    return (
      <>
        <button
          {...{ id, className }}
          {...otherProps}
          // onClick={null}
          type="button"
        >
          {children}
        </button>
        <Tooltip
          placement="bottom"
          isOpen={isOpen}
          target={id}
          toggle={toggle}
          style={{ whiteSpace: "pre-wrap" }}
          dangerouslySetInnerHTML={{
            __html: ["Still Required:", ...incomplete].join("\n")
          }}
        />
      </>
    );

  const formErrors = _.map(flat(formState.errors), (v, k) => `${k}: ${v}`);
  if (!_.isEmpty(formErrors))
    return (
      <>
        <button {...{ id, className }} {...otherProps} type="button">
          {children}
        </button>
        <Tooltip placement="bottom" isOpen={isOpen} target={id} toggle={toggle}>
          There were some form errors. Please correct them to continue
        </Tooltip>
      </>
    );

  return (
    <Btn
      {...{ id, color, size, outline, className: passedClassName }}
      {...otherProps}
    >
      {children}
    </Btn>
  );
};

export const FormLayout = ({
  formState,
  workOrder = {},
  includeTitleBar,
  profile,
  ...props
}) => {
  const boatId =
    _.get(formState, "values.boat.id") || _.get(workOrder, "boat.id");
  const projectActivity = _.get(workOrder, "work_order_notes");
  const workOrderId = workOrder.id;
  const workOrderStatus = workOrder.status || "draft";
  const formApi = useFormApi();

  var defaultOpenStatus;
  if (workOrderStatus === "draft") {
    defaultOpenStatus = true;
  } else if (workOrderStatus === "pending") {
    defaultOpenStatus = true;
  } else {
    defaultOpenStatus = false;
  }

  const handleGetBoatForProfile = profile => {
    if (!profile || !profile.id) return;
    boatsClient
      .get(`/boats/?profile_id=${profile.id}`)
      .then(({ data }) => {
        const boats = _.get(data, "results", []);
        const boat = boats.filter(b => b.customer_ids.includes(profile.id))[0];
        if (boat) formApi.setValues({ boat });
        formApi.validate();
      })
      .catch(err => {
        console.error("error: ", { err });
      });
  };

  const handlePostInternalProjectActivityNote = async activityItem => {
    const formState = formApi.getState();

    // Add the new activity item to current state optimistically
    const work_order_notes = _.get(formState, "values.work_order_notes");
    const nextNotes = work_order_notes
      ? [...work_order_notes, activityItem]
      : [activityItem];
    formApi.setValues({
      work_order_notes: nextNotes
    });

    const result = await binnacleClient.post(`work-order-notes/`, {
      ...activityItem
    });
  };

  return (
    <>
      <Text field="button" type="hidden" />
      <Container>
        {!includeTitleBar ? (
          <Card className="shadow pt-3 pl-4 pr-4 pb-4 my-4">
            <Row>
              <FormActionButtons
                // facility={this.props.facility}
                workOrder={workOrder}
                location="top"
              />
            </Row>
          </Card>
        ) : null}
        {workOrder.parent && (
          <Row>
            <Col md={6}>
              <Link
                to={`/work-orders/${workOrder.parent}/`}
                className="d-inline-block mt-3 btn btn-outline-primary"
              >
                <Fa icon="history" className="mr-2" />
                View Previous Version
              </Link>
            </Col>
          </Row>
        )}

        {workOrderStatus === "draft" ? (
          <>
            <CollapsibleCard
              isOpen={defaultOpenStatus}
              header={<h2 className="mb-0">Contact Info</h2>}
              body={
                <>
                  <ProfileForms onSetBoat={handleGetBoatForProfile} />
                  <CustomerSearch onSetBoat={handleGetBoatForProfile} />
                </>
              }
            />
            <CollapsibleCard
              isOpen={defaultOpenStatus}
              header={
                <div className="mb-0 d-flex align-items-center">
                  <h2 className="mb-0 mr-2">Boat</h2>{" "}
                  <WorkOrderBoatImageWrapper {...workOrder} {...props} />
                </div>
              }
              body={
                <>
                  <Scope scope="boat">
                    <SelectEditCreateBoat />
                  </Scope>
                  <h3>Boat Notes</h3>
                  {boatId && <BoatComments id={boatId} />}
                </>
              }
            />
          </>
        ) : (
          <>
            <CollapsibleCard
              isOpen={true}
              header={<h2>Boat &amp; Contact Info</h2>}
              body={
                <Row>
                  <Col lg={6}>
                    <BoatDisplay cardStyle="primary" boat={workOrder.boat} />
                  </Col>
                  <Col lg={6}>
                    <ProfileDisplay workOrder={workOrder} />
                  </Col>
                  <Col sm={12}>
                    <h3>Boat Notes</h3>
                    {boatId && <BoatComments id={boatId} />}
                  </Col>
                </Row>
              }
            />
          </>
        )}
      </Container>
      <Container>
        {/*{workOrderStatus === "draft" && <BootstrapTextArea field="bComment" label="Add Note to Boat" />}*/}

        <WorkItemsList
          field="work_items"
          canEdit={workOrderStatus === "draft"}
          boat={workOrder.boat}
          isOpen={defaultOpenStatus}
          sales_tax={workOrder.sales_tax}
          total_price={workOrder.total_price}
        />

        <CollapsibleCard
          isOpen={true}
          header={<h2>Schedule & Data Sheets</h2>}
          body={
            <Row>  
              <Col>
                <Row>
                  <Col lg={7} className="mb-3 mb-md-0">
                    <ProjectSchedule workOrderStatus={workOrderStatus} />
                  </Col>
                  <Col lg={5}>
                    <Row>
                      <ul>

                      </ul>
                    </Row>
                    <Row>
                      {!_.isEmpty(workOrder.data_sheets) ? (
                        <ul>
                          <li>
                            <a href="https://www.pettitpaint.com/media/5641/pettit-paint_2023-antifouling-bottom-paint-brochure_2-27-23_rev2_web.pdf" target="_blank">
                              Compatibility Chart
                            </a>
                          </li>
                          {_.map(workOrder.data_sheets, (dataSheet, i) => (
                            <li key={`sheet-${i}`}>
                              <a href={dataSheet[0]} target="_blank">
                                {dataSheet[1]}
                              </a>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <em style={{ color: "gray" }}>
                          <ul>
                            <li>
                              <a href="https://www.pettitpaint.com/media/5641/pettit-paint_2023-antifouling-bottom-paint-brochure_2-27-23_rev2_web.pdf" target="_blank">
                                Compatibility Chart
                              </a>
                            </li>
                            <li>
                              Data sheets will appear when related work items are added
                              above
                            </li>
                          </ul>
                        </em>
                      )}
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          }
        />
        <CollapsibleCard
          id="project-activity-card"
          isOpen={true}
          header={
            <>
              <h2 className="mb-0">Project Activity</h2>
            </>
          }
          body={
            <div className="pt-3">
              {/* posting new note needs  */}
              {workOrder.id ? (
                <>
                  <Text
                    field="work_order_notes"
                    type="hidden"
                    initialValue={projectActivity}
                  />
                  <ProjectActivity
                    id={workOrder.id}
                    workOrder={workOrder}
                    activityItems={projectActivity}
                    onAddNote={handlePostInternalProjectActivityNote}
                    profile={profile}
                    currentPhaseSchedule={formState.values.phases}
                    formApi={formApi}
                  />
                </>
              ) : (
                <div className="w-100 mt-3">
                  <em>Save your new work order to leave a note</em>
                </div>
              )}

              {/* Decided on Feb 13, 2021 to drop support for legacy WO comments */}
            </div>
          }
        />
      </Container>

      {/* larger custom bottom padding is to given room for Pettit attribution logo */}
      <div
        id="workOrderFooter"
        className="footer bg-white w-100 pt-3"
        style={{ paddingBottom: "55px" }}
      >
        <Container>
          <Row>
            <Col id="work-order-print-buttons" lg={5} className="mb-3 mb-lg-0">
              <div>
                <FormBtn
                  id="printWi"
                  outline
                  color="secondary"
                  size="md"
                  className="ml-0 mr-2"
                  value="saveAndPrintWorkItems"
                >
                  <Fa icon="print" className="mr-2" />
                  Print Work List
                </FormBtn>
                {workOrder.parent ? (
                  <FormBtn
                    id="printChg"
                    outline
                    color="secondary"
                    size="md"
                    value="saveAndPrintChangeOrder"
                    className="ml-0 mr-2"
                  >
                    <Fa icon="print" className="mr-2" />
                    Print Change Order
                  </FormBtn>
                ) : (
                  <FormBtn
                    id="printEst"
                    outline
                    color="secondary"
                    size="md"
                    value="saveAndPrintEstimate"
                    className="ml-0 mr-2"
                  >
                    <Fa icon="print" className="mr-2" />
                    Print Estimate
                  </FormBtn>
                )}
              </div>
            </Col>
            <Col id="work-order-buttons-footer" lg={7}>
              <FormActionButtons workOrder={workOrder} location="bottom" />
            </Col>
            <Text field="email" type="hidden" initialValue={null} />
          </Row>
        </Container>

        <PriceDigestAttribution workOrderStatus={workOrderStatus} />
        <FloatingAttribution left />

        <FormStatePre />
      </div>
    </>
  );
};

export const StatusContext = createContext();

export default
@withProfile
@withFacility
@withBoatyard
class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: false
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.formApi = null;
    this.buttons = {
      addProjectActivityNote: {
        request: () => ({
          method: "POST",
          url: `work-order-notes/${this.getWorkOrderID()}/`,
          data: { note: newProjectActivityNote }
        })
      },
      // resend: { data: { status: "pending" } },
      saveAsDraft: {
        data: { status: "draft" },
        after: ({ data: { id } }) => {
          document.location = `/work-orders/${id}/`;
        }
      },
      saveApproved: {
        data: { status: "approved" },
        after: ({ data: { id } }) => {
          document.location = `/work-orders/${id}/`;
        }
      },
      saveAndSend: {
        data: {
          status: "pending",
          after: ({ data: { id } }) => {
            document.location = `/work-orders/${id}/`;
          }
        }
      },
      markComplete: { data: { status: "complete" } },
      approveWO: { data: { status: "approved" } },
      rejectWO: { data: { status: "rejected" } },
      softDeleteWo: {
        request: () => ({
          method: 'PATCH',
          url: `work-orders/${this.getWorkOrderID()}}/`,
          data: { status: "dismissed" },
        }),
        after: () => {
          document.location = `/`;
        }
      },
      createChangeRequest: {
        request: () => ({
          method: "POST",
          url: `work-orders/${this.getWorkOrderID()}/change-order/`
        }),
        after: ({ data }) => {
          document.location = `/work-orders/${data.id}/`;
        }
      },
      saveAndPrintEstimate: {
        // data: { send_quote: false },
        after: ({ data: { id } }) => {
          document.location = `/print/estimate/${id}`;
          // setTimeout(()=>window.location = `/work-orders/${id}/`, 300);
        }
      },
      saveAndPrintWorkItems: {
        // data: { send_quote: false },
        after: ({ data: { id } }) => {
          document.location = `/print/work-list/${id}`;
          // setTimeout(()=>window.location = `/work-orders/${id}/`, 300);
        }
      },
      saveAndPrintChangeOrder: {
        // data: { send_quote: false },
        after: ({ data: { id } }) => {
          document.location = `/print/change-order/${id}`;
        }
      }
    };
  }

  getWorkOrderID() {
    return _.get(this.props, "workOrder.id");
  }

  getApi(formApi) {
    this.formApi = formApi;
  }

  afterSubmission({ data, status, button, nextLocation = "/" }) {
    if (status >= 400) {
      addFormErrors(data, this.formApi);
      this.setState({ errorMessage: data });
    } else {
      if (_.isFunction(button.after)) button.after({ data, status, button });
      else window.location = nextLocation;
    }
  }

  // potentially remove/refactor in favor of project activity?
  submitComments({ woComment, bComment }, woID, bID) {
    let promises = [];
    let base_data = {
      boatyard_id: this.props.boatyard.id,
      facility_id: this.props.facility.id,
      profile_id: this.props.profile.id
    };
    // if (!_.isEmpty(bComment)) promises.push(boatsClient.post(`/boats/${bID}/comments/`, {comment: bComment, ...base_data}));
    if (!_.isEmpty(woComment))
      promises.push(
        binnacleClient.post(`/work-orders/${woID}/comments/`, {
          comment: woComment,
          ...base_data
        })
      );
    return Promise.all(promises);
  }

  getRepresentativeId = data => {
    let primary_contact_id =
      _.get(data, "owner_profile.id", undefined) ||
      _.get(data, "representative_profile.id");
    if (!!_.get(data, "owner_profile.is_primary_contact"))
      primary_contact_id = _.get(data, "owner_profile.id");
    if (!!_.get(data, "representative_profile.is_primary_contact"))
      primary_contact_id = _.get(data, "representative_profile.id");

    return primary_contact_id;
  };

  // clear out owner or representative if there is nothing there
  cleanProfiles = data => {
    if (
      _.has(data, "owner_profile") &&
      _.every(data["owner_profile"], _.isEmpty)
    ) {
      data["owner_profile"] = null;
    }
    if (
      _.has(data, "representative_profile") &&
      _.every(data["representative_profile"], _.isEmpty)
    ) {
      data["representative_profile"] = null;
    }
  };

  addNotesForPhaseChanges = (data, email) => {
    const { workOrder } = this.props;

    // Fall back to an empty array if no phase data
    // never add notes if in draft state: we only want to trigger completion emails in approved
    if (!data.phases || !workOrder) return [];

    // work_order_notes (Project Activity) gets passed along with the workOrder request on save
    // rather than calling a dedicated note endpoint
    const generateWorkOrderNote = (phase, useEmail) => {
      return {
        work_order_id: this.getWorkOrderID(),
        creator_profile_id: _.get(this.props.profile, "id"),
        note_type: phase ? "phase_complete" : "external",
        note: useEmail && email && email.comment ? email.comment : "",
        date_created: new Date().toJSON(),
        finished_phase: phase || null
      };
    };

    if (!workOrder.status || workOrder.status === "draft") {
      return [generateWorkOrderNote(null, true)];
    }

    // Compares server wo's work_order_notes w/ form state
    // Adds a new note for each new actual completion date chosen in approved status
    return data.phases.reduce((woNotes, phase, index) => {
      const upstreamPhase = _.get(this.props.workOrder, `phases[${index}]`, []);
      if (phase.date_completed && !upstreamPhase.date_completed) {
        // If last one, use email content for the note
        const useEmail = index === data.phases.length - 1;
        const nextNote = generateWorkOrderNote(phase.phase, useEmail);
        woNotes.push(nextNote);
      }
      return woNotes;
    }, []);
  };

  onSubmit(formData) {
    // 1. Prep data
    const woID = this.getWorkOrderID();
    const button = _.get(this.buttons, formData.button, {});
    const { workOrder, profile } = this.props;

    // must pull some data from formData in case submitting an estimate before the work order is saved
    const gtmEvent = (eventName, actionName) => {
      window.dataLayer.push({
        event: eventName,
        eventProps: {
          action: actionName,
          category: "workorder_actions",
          workOrderId: !!workOrder ? workOrder?.id : "",
          userId: profile?.id,
          userName: profile?.name,
          facilityId: profile?.facility_id,
          boatId: formData?.boat?.id,
          boatName: formData?.boat?.name,
          ownerId: formData?.owner_profile?.id,
          ownerName: formData?.owner_profile?.name,
          workOrderStatus: !!workOrder ? workOrder?.status : "Draft"
        }
      });
      console.log(window.dataLayer);
    };

    // GTM
    if (formData.button === "createChangeRequest") {
      gtmEvent("createChangeRequest", "create_change_request");
    }
    if (formData.button === "saveApproved") {
      gtmEvent("workOrderApprove", "mark_approved");
    }
    if (formData.button === "rejectWO") {
      gtmEvent("workOrderDecline", "mark_declined");
    }
    if (formData.button === "markComplete") {
      gtmEvent("deliverJob", "deliver_job");
    }
    if (formData.button === "saveAndPrintWorkItems") {
      gtmEvent("savePrintWorkList", "print_work_list");
    }
    if (formData.button === "saveAndPrintEstimate") {
      gtmEvent("savePrintEstimate", "print_estimate");
    }
    if (formData.button === "saveAndPrintChangeOrder") {
      gtmEvent("savePrintChangeRequest", "print_change_request");
    }
    if (formData.button === "saveAndSend" && !workOrder.parent) {
      gtmEvent("submitEstimate", "submit_estimate");
    }
    if (formData.button === "saveAndSend" && !!workOrder.parent) {
      gtmEvent("submitChangeRequest", "submit_change_request");
    }

    const data = _.merge(
      { facility_id: this.props.facility.id },
      _.omit(formData, ["button", "woComment", "bComment"]),
      button.data
    );
    this.cleanProfiles(data);
    // add the representative id to the form data (server sets is_primary_contact)
    data.primary_contact_id = this.getRepresentativeId(data);

    // Clean up blank email key
    if (!data.email && data.hasOwnProperty("email")) {
      delete data.email;
    }

    // Add phase completions to project activity when saving the work order
    const phaseNotes = this.addNotesForPhaseChanges(data, data.email);
    const nextNotes = [
      ..._.get(data, "work_order_notes", []).map(n =>
        _.pickBy(n, (v, k) => k !== "creator_profile")
      ),
      ...phaseNotes
    ];

    // optimistic - unnecessary because of reload
    // this.formApi.setValues({ work_order_notes: nextNotes });
    // server
    data["work_order_notes"] = nextNotes;

    // 2. Prep server request
    let request;
    if (button.request) {
      request = button.request({ data });
    } else {
      request = {
        url: woID ? `work-orders/${woID}/` : "work-orders/",
        method: woID ? "patch" : "post",
        data
      };
    }

    // 3. Send request and handle result
    binnacleClient
      .request(request)
      .then(({ data, status }) => {
        // this.submitComments(formData, data.id, data.boat.id).then(() => {
        if (formData.button === "resend") return; // If resending, we're done
        this.afterSubmission({ data, status, button });
        // });
      })
      .catch(err => {
        console.log(err);
        const { data, status } = _.get(err, "response", {});
        this.afterSubmission({ data, status, button });
      });
  } // onSubmit

  render() {
    const { workOrder, includeTitleBar, profile } = this.props;
    return (
      <StatusContext.Provider value={workOrder.status || "draft"}>
        <Form
          initialValues={{ ...workOrder, email: null }}
          onSubmit={this.onSubmit}
          workOrder={workOrder}
          profile={profile}
          getApi={this.getApi.bind(this)}
          className="needs-validation"
        >
          {props => (
            <>
              {includeTitleBar ? <WOTitleBar workOrder={workOrder} /> : null}
              <FormLayout
                {...{
                  ...props,
                  workOrder,
                  includeTitleBar,
                  profile
                }}
              />
            </>
          )}
        </Form>
      </StatusContext.Provider>
    );
  }
}

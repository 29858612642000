import React, { Component, Fragment } from "react";
import { FormGroup, Label, Input, CustomInput } from "reactstrap";
import { deleteIn, setIn } from "immutable-setter";
import { asField } from "informed";
import classNames from "classnames";
import { styles } from "~/util/autoform/reactSelectBoostrap4";
import ReactSelect from "react-select";
import Fa from "~/util/alpacaLegacyUtils";

function findMenuItem(menu, menuValue, menuId) {
  for (let index in menu) {
    if (menu[index].id === menuId) {
      return { menu: menu[index], value: menuValue[menuId] };
    } else if (!_.isEmpty(menu[index].sub_items)) {
      let recurse_value = findMenuItem(
        menu[index].sub_items,
        _.isEmpty(menuValue) ? {} : menuValue[menu[index].id],
        menuId
      );
      if (recurse_value !== null) {
        return recurse_value;
      }
    }
  }

  return null;
}

function copyFrom(menu, menuItem, currentValue, defaultValue) {
  let result = findMenuItem(menu, currentValue, menuItem.copy_from);
  if (result == null) {
    return defaultValue;
  }

  let { menu: copyFromMenuItem, value: copyFromValue } = result;

  let getCopiedValue = (fromMenuItem, fromMenuValue, toMenuItem) => {
    if (_.isEmpty(fromMenuItem.sub_items)) {
      return fromMenuValue;
    }

    let value = {};

    for (let i in fromMenuItem.sub_items) {
      for (let x in toMenuItem.sub_items) {
        if (fromMenuItem.sub_items[i].value === toMenuItem.sub_items[x].value) {
          let tempValue = fromMenuValue[fromMenuItem.sub_items[i].id];
          if (tempValue !== undefined) {
            value[toMenuItem.sub_items[x].id] = getCopiedValue(
              fromMenuItem.sub_items[i],
              tempValue,
              toMenuItem.sub_items[x]
            );
          }
          break;
        }
      }
    }

    return value;
  };

  if (_.isEmpty(copyFromValue)) {
    return defaultValue;
  }

  return getCopiedValue(copyFromMenuItem, copyFromValue, menuItem);
}

const ColorBox = ({ item }) => {
  if (!item.color) {
    return null;
  }
  return (
    <>
      <span
        className="color-preview-square"
        style={{ background: `#${item.color}` }}
      />
      {/* &#160;&#160; */}
    </>
  );
};

export class SelectMenuItem extends Component {
  constructor(props) {
    super(props);
    this.onCheckChange = this.onCheckChange.bind(this);
  }

  onCheckChange(e) {
    let checked = e.target.checked;

    this.props.onChange(
      this.props.item.id,
      checked ? true : null,
      this.props.index
    );
  }

  render() {
    let { item, value } = this.props;
    let checked = value !== null;

    let label = (
      <span>
        <ColorBox item={item} />
        {item.caption}
      </span>
    );

    return (
      <FormGroup className="mb-3 ml-2">
        <CustomInput
          id={`menu-select-${this.props.item.id}`}
          type="checkbox"
          checked={checked}
          onChange={this.onCheckChange}
          label={label}
        />
        {/*{!item.customer_visible ? <strong>{item.caption}</strong> : item.caption}*/}
      </FormGroup>
    );
  }
}

export class SelectTextMenuItem extends Component {
  constructor(props) {
    super(props);
    this.onCheckChange = this.onCheckChange.bind(this);
    this.onTextChange = this.onTextChange.bind(this);
  }

  onCheckChange(e) {
    let checked = e.target.checked;
    let value = this.props.value;

    this.props.onChange(
      this.props.item.id,
      checked ? (value == null ? "" : value) : null,
      this.props.index
    );
  }

  onTextChange(e) {
    this.props.onChange(this.props.item.id, e.target.value, this.props.index);
  }

  render() {
    let { item, value } = this.props;
    let checked = value !== null;

    let label = (
      <span>
        <ColorBox item={item} />
        {item.caption}
      </span>
    );

    return (
      <FormGroup
        className={classNames("mb-3 ml-2", {
          "is-invalid": this.props.error !== undefined
        })}
      >
        <CustomInput
          id={`menu-select-text-${this.props.item.id}`}
          type="checkbox"
          checked={checked}
          onChange={this.onCheckChange}
          label={label}
        />

        {checked && (
          <Input
            id={`menu-select-text-input-${this.props.item.id}`}
            value={this.props.value}
            onChange={this.onTextChange}
            invalid={this.props.error !== undefined}
          />
        )}

        {this.props.error !== undefined && (
          <div className="invalid-feedback">{this.props.error}</div>
        )}
      </FormGroup>
    );
  }
}

export class OptionMenuItem extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.onItemChange = this.onItemChange.bind(this);
  }

  handleChange(newValue) {
    let value = this.props.value;

    if (newValue != null) {
      if (this.props.item.sub_items) {
        value = { value: newValue.value, sub_values: {} };
      } else {
        value = newValue.value;
      }
    }

    this.props.onChange(this.props.item.id, value, this.props.index);
  }

  onItemChange(itemId, itemValue) {
    // make a copy so the react render system knows it's a new value
    let value = {
      value: this.props.value.value,
      sub_values: this.props.value.sub_values
    };

    if (itemValue == null) {
      value.sub_values = deleteIn(value.sub_values, [itemId]);
    } else {
      value.sub_values = setIn(value.sub_values, [itemId], itemValue);
    }

    this.props.onChange(this.props.item.id, value, this.props.index);
  }

  render() {
    // value could be either a string "value"
    // or a dictionary {"value": "value", "sub_values": {}}

    let { item } = this.props;
    let options = _.map(item.options, option => ({
      value: option.value,
      label: option.name,
      color: option.color
    }));

    let value = this.props.value;
    let subValues = null;

    if (value != null) {
      if (_.isObject(value)) {
        subValues = value.sub_values;
        value = value.value;
      }

      value = _.find(options, ["value", value]);
    }

    let formatOption = (option, b) => {
      return (
        <span>
          <ColorBox item={option} />
          {option.label}
        </span>
      );
    };

    return (
      <Fragment>
        <FormGroup
          className={classNames("mb-3", {
            "is-invalid": this.props.error !== undefined
          })}
        >
          {item.caption && (
            <Label for={`option_menu_item${item.id}`}>
              <ColorBox item={item} />
              {item.caption}
              {/*{!item.customer_visible ? <strong>{item.caption}</strong> : item.caption}*/}
            </Label>
          )}

          <ReactSelect
            id={`menu-option-${item.id}`}
            styles={styles}
            options={options}
            onChange={this.handleChange}
            value={value}
            className="react-select-container"
            classNamePrefix="react-select"
            isClearable={true}
            formatOptionLabel={formatOption}
          />

          {this.props.error !== undefined && (
            <div className="invalid-feedback">{this.props.error}</div>
          )}
        </FormGroup>

        {value && item.sub_items && (
          <div
            className="ml-3 pl-3"
            style={{ borderLeft: "1px #ced4da solid" }}
          >
            {renderItems(
              item.sub_items,
              this.onItemChange,
              this.props.rootMenu,
              this.props.rootValue,
              this.props.error,
              subValues,
              this.props.showCustomerHidden
            )}
          </div>
        )}
      </Fragment>
    );
  }
}

export class GroupMenuItem extends Component {
  constructor(props) {
    super(props);
    this.onItemChange = this.onItemChange.bind(this);
    this.onCheckChange = this.onCheckChange.bind(this);
  }

  onItemChange(id, value, index) {
    if (value == null) {
      this.props.onChange(
        this.props.item.id,
        deleteIn(this.props.value, [id]),
        this.props.index
      );
    } else {
      let oldItemValue = this.props.value[id];
      if (
        _.isEmpty(oldItemValue) &&
        !_.isEmpty(this.props.item.sub_items[index].copy_from)
      ) {
        value = copyFrom(
          this.props.rootMenu,
          this.props.item.sub_items[index],
          this.props.rootValue,
          value
        );
      }

      this.props.onChange(
        this.props.item.id,
        setIn(this.props.value, [id], value),
        this.props.index
      );
    }
  }

  onCheckChange(e) {
    let checked = e.target.checked;
    let value = this.props.value;
    this.props.onChange(
      this.props.item.id,
      checked ? (value == null ? {} : value) : null,
      this.props.index
    );
  }

  render() {
    let { item, value } = this.props;
    let checked = value !== null;

    let label = (
      <span>
        <ColorBox item={item} />
        {item.caption} &#160;&#160;
        <Fa
          icon="level-down-alt"
          style={{ fontSize: "smaller" }}
          className="text-muted"
        />
      </span>
    );

    return (
      <Fragment>
        <FormGroup className="mb-3 ml-2">
          <CustomInput
            id={`menu-group-${this.props.item.id}`}
            type="checkbox"
            checked={checked}
            onChange={this.onCheckChange}
            label={label}
          />
        </FormGroup>

        {checked && (
          <div
            className="ml-3 pl-3"
            style={{ borderLeft: "1px #ced4da solid" }}
          >
            {renderItems(
              item.sub_items,
              this.onItemChange,
              this.props.rootMenu,
              this.props.rootValue,
              this.props.error,
              value,
              this.props.showCustomerHidden
            )}
          </div>
        )}
      </Fragment>
    );
  }
}

const COMPONENT_MAP = {
  select_menu_item: SelectMenuItem,
  select_text_menu_item: SelectTextMenuItem,
  option_menu_item: OptionMenuItem,
  group_menu_item: GroupMenuItem
};

function renderItems(
  data,
  onChange,
  rootMenu,
  rootValue,
  errors = {},
  value = {},
  showCustomerHidden = false
) {
  function renderItem(item, index) {
    if (!showCustomerHidden && !item.customer_visible) {
      return null;
    }

    let Component = COMPONENT_MAP[item.type];

    if (Component !== undefined) {
      let itemValue = null;
      if (value && value[item.id] !== undefined && value[item.id] != null) {
        itemValue = value[item.id];
      }

      return (
        <Component
          rootMenu={rootMenu}
          rootValue={rootValue}
          index={index}
          item={item}
          key={item.id}
          onChange={onChange}
          error={
            errors !== undefined && errors != null ? errors[item.id] : undefined
          }
          value={itemValue}
          showCustomerHidden={showCustomerHidden}
        />
      );
    }

    return null;
  }

  return _.map(data, renderItem);
}

@asField
class ServicesMenuBase extends Component {
  constructor(props) {
    super(props);
    this.onItemChange = this.onItemChange.bind(this);
  }

  onItemChange(itemId, itemValue, index) {
    let value = this.props.fieldState.value || {};

    let new_value = {};
    if (value == null) {
      new_value = deleteIn(value, [itemId]);
    } else {
      let oldItemValue = value[itemId];
      if (
        _.isEmpty(oldItemValue) &&
        !_.isEmpty(this.props.menuData[index].copy_from)
      ) {
        itemValue = copyFrom(
          this.props.menuData,
          this.props.menuData[index],
          value,
          itemValue
        );
      }
      new_value = setIn(value, [itemId], itemValue);
    }
    this.props.fieldApi.setValue(new_value);
  }

  render() {
    let value = this.props.fieldState.value || {};
    let showCustomerHidden = this.props.showCustomerHidden || false;

    return (
      <div>
        {renderItems(
          this.props.menuData,
          this.onItemChange,
          this.props.menuData,
          value,
          this.props.fieldState.error,
          value,
          showCustomerHidden
        )}
      </div>
    );
  }
}

export default class ServicesMenu extends Component {
  constructor(props) {
    super(props);
    this.validateData = this.validateData.bind(this);
  }

  validateData(value = {}, values = {}, menuData = null) {
    // if(data == null || _.keys(data).length === 0) {
    //     return {"__all__": "Please select at least one service."}
    // }

    if (menuData == null) {
      menuData = this.props.menuData;
    }

    let errors = {};

    for (let index in menuData) {
      let item = menuData[index];
      let itemValue = value[item.id];

      if (!item.customer_visible && !this.props.showCustomerHidden) {
        continue;
      }

      if (item.type === "select_menu_item") {
        // this is not required
        continue;
      } else if (item.type === "select_text_menu_item") {
        if (
          itemValue !== undefined &&
          itemValue !== null &&
          _.trim(itemValue).length === 0
        ) {
          // it was checked, but no text was entered.
          errors[item.id] = "Please enter a value.";
        }
      } else if (item.type === "option_menu_item") {
        if (itemValue === undefined || itemValue === null) {
          // this is always required.
          errors[item.id] = "Please select a value.";
        }
      } else if (item.type === "group_menu_item") {
        if (itemValue !== undefined && itemValue !== null) {
          // if it was checked, we need to do the recursive validation
          let subErrors = this.validateData(itemValue, {}, item.sub_items);
          if (subErrors != null) {
            errors[item.id] = subErrors;
          }
        }
      }
    }

    if (_.keys(errors).length === 0) {
      return undefined;
    }

    return errors;
  }

  render() {
    return <ServicesMenuBase {...this.props} validate={this.validateData} />;
  }
}

import React from "react";
import styled from "styled-components";
import { Row } from "reactstrap";

import previewProps from "./props";
import ExternalLink from "~/util/ExternalLink";

const SenderWrapper = styled.div`
  margin: 2rem 0;
  padding: 1rem;
  border: 1px solid lightgray;
  h4 {
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.6);
    span {
      padding-left: 0.7rem;
      display: inline-block;
      font-size: 0.7em;
    }
  }

  .ExternalLink {
    font-style: normal !important;
    color: rgba(0, 0, 0, 0.6);
  }
`;

const PreviewSender = ({ sender }) => {
  if (!sender || !sender.name) return null;
  const { name, title, phone, email } = sender;
  return (
    <SenderWrapper className="PreviewSender">
      <Row className="justify-content-center align-items-center flex-column">
        <h4>
          {name}
          <span>{title}</span>
        </h4>
        <p style={{ marginBottom: "0.7rem" }}>
          <ExternalLink link={phone} useIcon={false} type="phone" />
        </p>
        <p style={{ marginBottom: "0.7rem" }}>
          <ExternalLink link={email} useIcon={false} type="email" />
        </p>
      </Row>
    </SenderWrapper>
  );
};

PreviewSender.propTypes = {
  sender: previewProps.profile
};

export default PreviewSender;

import React from "react";
import AsyncPaginate from "react-select-async-paginate";
import { styles } from "./reactSelectBoostrap4";

export default class AsyncReactSelect extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.fieldApi = this.props.fieldApi;
    this.state = {
      value: null
    };
  }

  handleChange(value) {
    const { onChange } = this.props;

    this.setState({ value: value });

    if (this.fieldApi) {
      this.fieldApi.setValue(value !== null ? value.value : null);
    }

    if (onChange) {
      onChange(value);
    }
  }

  render() {
    const {
      fieldApi,
      fieldState,
      onChange,
      displayValue,
      ...props
    } = this.props;
    const { setTouched } = fieldApi;
    const { forwardedRef, className, field, initial, key, ...rest } = props;

    rest.onBlur = e => {
      setTouched();
    };

    let value = {};
    if (this.props.value) {
      value = this.props.value;
    } else if (this.props.fieldState.value) {
      if (
        this.state.value &&
        this.state.value.value === this.props.fieldState.value
      ) {
        value = this.state.value;
      } else {
        value = {
          value: this.props.fieldState.value,
          label: this.props.fieldState.value
        };
      }
    }
    if (displayValue) {
      value.label = displayValue;
    }

    return (
      <AsyncPaginate
        styles={styles}
        onChange={this.handleChange}
        {...rest}
        value={value}
        className="react-select-container"
        classNamePrefix="react-select"
      />
    );
  }
}

import React from "react";
import ItemCard from "~/components/dashboard/ItemCard";
import { withAPIData } from "~/util/alpacaLegacyAPI/";
import { LoadingIndicator } from "~/util/loadingWrapper";
import { binnacleClient } from "~/util/api";

function BoatHistory({ data, loading, boat }) {
  if (loading) return <LoadingIndicator />;
  const workOrders = data.results;

  const checkWorkHistoryExists = () => {
    if (!(workOrders.length >= 1)) {
      return (
        <p>
          <i>There is no history of work orders for this boat yet.</i>
        </p>
      );
    } else {
      return workOrders.map(item => (
        <ItemCard
          key={item.id}
          kind="workorder"
          link={`/work-orders/${item.id}/`}
          {...item}
          extra={{ boat, status: item.status, ...item }}
        />
      ));
    }
  };

  return (
    <>
      <h2>Boat Work History</h2>
      {checkWorkHistoryExists()}
    </>
  );
}

export default withAPIData(
  BoatHistory,
  "/work-orders/?boat_id=${id}&status=pending&status=draft&status=approved&status=rejected&status=replaced&status=complete",
  null,
  binnacleClient
);

import React from "react";
import styled from "styled-components";

import { shouldShowActualCompletionDates } from "~/util/util";
import { ScheduleDisplay } from "~/components/workOrders/ScheduleDisplay";
import previewProps from "./props";

const ScheduleContainer = styled.div``;

const StyledTh = styled.th`
  border-top: none !important;

  h3 {
    display: inline-block;
    margin-bottom: 0;
  }
`;

// used only by email?
const PreviewSchedule = ({ phases, status }) => {

  const showStatus = shouldShowActualCompletionDates(status);

  return (
    <ScheduleContainer>
      <ScheduleDisplay
        renderTableHeader={() => {
          return (
            <thead>
              <tr>
                <StyledTh style={{ paddingLeft: "0" }}>
                  <h3>Schedule</h3>
                </StyledTh>
                <StyledTh>Estimated</StyledTh>
                { showStatus ? <StyledTh>Actual</StyledTh> : null }
              </tr>
            </thead>
          );
        }}
        phases={phases}
        status={status}
      />
    </ScheduleContainer>
  );
};

const { scheduleData } = previewProps;

PreviewSchedule.propTypes = scheduleData;

export default PreviewSchedule;

import React, { useState } from "react";
import { binnacleClient } from "~/util/api";
import { Badge } from "reactstrap";
import { BootstrapReactSelect } from "~/util/autoform/bootstrap";
import Select from "react-select";
import Creatable from "react-select/creatable";
import Highlighter from "react-highlight-words";
import { PropTypes } from "prop-types";

const defaultMapping = {
  id: "value",
  name: "label"
};

function useCache() {
  const [cacheData, setState] = useState({});
  const cache = async items => setState(_.assign(cacheData, items));
  return { cacheData, cache };
}

function mapper(obj, { mapping = defaultMapping, forwards = true } = {}) {
  let result = _.clone(obj);
  const map = forwards ? mapping : _.invert(mapping);
  result = _.pick(result, _.keys(map));
  result = _.mapKeys(result, (v, k) => map[k]);
  return result;
}

const defaultMapToOpt = (val, args) => mapper(val, args);
const defaultMapToVal = (opt, args = {}) =>
  mapper(opt, { ...args, forwards: false });
const NewPill = (
  <Badge pill className="status-pill bg-yellow-light text-yellow-dark mx-2">
    <span className="fa fa-star text-yellow-mid mr-2" />
    New
  </Badge>
);

function getFetcher({
  url,
  params: extraParams,
  mapping,
  mapOpt,
  cache,
  cacheBy,
  searchParamName,
  client
}) {
  return async (search, prevOptions = [], prevArgs) => {
    // Which page?
    // NOTE - for some reason destructuring isn't working.
    // I tried {page=1} as the 3rd argument to this function,
    // but that would break the page load functionality.
    // This appears to be a bug in react-select-async-paginate.
    // this workaround appears to function correctly:
    const prevPage = _.get(prevArgs, "page", 1);
    const page = _.isEmpty(prevOptions) ? 1 : prevPage + 1; // increment prevPage if search is identical

    // Get that page...
    let params = {
      page,
      ...extraParams
    };

    if (!_.isEmpty(search)) params[searchParamName] = search;
    let response;
    try {
      response = await client.get(url, { params });
    } catch (e) {
      console.error(e);
      return {
        options: [],
        hasMore: false,
        additional: { page }
      };
    }

    // I don't know how this works, but it does...
    cache(_.keyBy(response.data.results, cacheBy));
    // Map the results to new options
    const options = _.map(response.data.results, obj =>
      mapOpt(obj, { mapping })
    );
    const hasMore = !_.isEmpty(response.data.next);
    return {
      options,
      hasMore,
      additional: { page }
    };
  };
}

function useChangeHandler(
  { mapVal, mapOpt, cacheData, cacheBy, setNew },
  callback
) {
  const handleChange = option => {
    if (_.isEmpty(option)) {
      setNew(false);
      callback({});
      return null;
    }
    let value;
    setNew(option.__isNew__);
    if (option.__isNew__) {
      value = { ...mapVal(option), id: "" };
    } else {
      value = _.get(cacheData, option.value, { name: null });
    }
    // Pass the value to the onChange, to save to the formData
    callback(value);
    // Return the Option for rendering
    return mapOpt(value);
  };
  return option => handleChange(option);
}

function SearchCreateSelect({
  field,
  url,
  params = {},
  mapping = defaultMapping,
  mapOpt = defaultMapToOpt,
  mapVal = defaultMapToVal,
  cacheBy = "id",
  searchParamName = "search",
  client = binnacleClient,
  creatable = true,
  onChange: passValueToOnChange = () => {},
  label: textLabel,
  ...otherProps
}) {
  const { cacheData, cache } = useCache();
  const [isNew, setNew] = useState(false);
  const onChange = useChangeHandler(
    { mapping, mapVal, mapOpt, cacheData, cacheBy, setNew },
    passValueToOnChange
  );
  const loadOptions = getFetcher({
    url,
    params,
    searchParamName,
    mapping,
    mapOpt,
    cache,
    cacheBy,
    client
  });
  const clearValue = () => {
    setNew(false);
  };

  const label =
    creatable && isNew ? (
      <span>
        {textLabel} {NewPill}
      </span>
    ) : (
      textLabel
    );
  const SelectComponent = creatable ? Creatable : Select;

  const props = {
    field,
    isClearable: true,
    debounceTimeout: 200,
    loadOptions,
    label,
    clearValue,
    SelectComponent,
    ...otherProps,
    onChange
  };
  return <BootstrapReactSelect {...props} />;
}

SearchCreateSelect.propTypes = {
  url: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  params: PropTypes.object
};

export default SearchCreateSelect;

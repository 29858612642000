import React from "react";
import { Row, Col } from "reactstrap";
import { WorkItemsDisplay } from "~/components/workOrders/WorkItemsDisplay";
import { WorkOrderBase } from "~/views/WorkOrder";
import { PrintLayout } from "~/components/print/PrintLayout";
import withFacility from "~/util/api/withFacility";
import { ScheduleDisplay } from "~/components/workOrders/ScheduleDisplay";
import moment from "moment";
import { lineBreaksHtml } from  "~/util/alpacaLegacyUtils";

export class PrintEstimateBase extends WorkOrderBase {
  renderWO() {
    document.title = "Pettit Pro Portal | Print Estimate";

    const workOrder = this.state.workOrder;
    const boatPrimaryImage = this.state.boatPrimaryImage;
    const statusDate = moment(workOrder.status_date).format("l");
    const { updated_profile: serviceWriterProfile } = this.state.workOrder;
    const ownerOrRep = this.getOwnerOrRep();
    
    return (
      <PrintLayout>
        <h2>Estimate</h2>
        
        <Row>
          <Col md="5">
        <table data-testid="estimate-overview-info">
        {serviceWriterProfile && (
          <tr data-testid="servicewriter-contact-info">
          <th style={{ minWidth: "150px", "verticalAlign": "top" }}>Service Writer</th>
          <td className="d-flex flex-column" data-testid="service-writer-info">
            {serviceWriterProfile.name && (
              <span data-testid="wo-servicewriter-name" className="mr-2">
                <strong>{serviceWriterProfile.name}</strong>
              </span>
            )}
            {serviceWriterProfile.email && (
              <a
                href={`mailto:${serviceWriterProfile.email}`}
                data-testid="wo-updated-profile-email"
                className="mr-2"
              >
                {serviceWriterProfile.email}
              </a>
            )}
            {serviceWriterProfile.phone && (
              <span data-testid="wo-updated-profile-phone" className="mr-2">
                {serviceWriterProfile.phone}
              </span>
            )}
            </td>
          </tr>
        )}

        <tr>
              <th style={{ minWidth: "150px" }}>Date</th>
              <td>{statusDate}</td>
            </tr>
            {ownerOrRep.profile != null && (
              <tr>
                <th style={{ minWidth: "150px" }}>{ownerOrRep.label}</th>
                <td>{ownerOrRep.profile.name}</td>
              </tr>
            )}
            {!_.isEmpty(workOrder.boat) && (
              <tr>
                <th style={{ minWidth: "150px" }}>Boat</th>
                <td>{workOrder.boat.name}</td>
              </tr>
            )}
        </table>
          </Col>
          <Col md="7">
          {
            boatPrimaryImage ? 
            <img 
                data-testid="printestimate-boat-primaryimage" 
                src={boatPrimaryImage.url} 
                alt={workOrder.boat.name} 
                className="rounded"
                style={{ "objectFit": "contain", "objectPosition": "left", "maxHeight": "350px", "maxWidth": "100%" }} />
              : ""
          }
          </Col>
        </Row>

        <Row className="my-4">
          {!_.isEmpty(workOrder.phases) && (
            <Col sm="5">
              <ScheduleDisplay phases={workOrder.phases} />
            </Col>
          )}
          <Col sm="7">
            <WorkItemsDisplay
              work_items={workOrder.work_items}
              {...workOrder}
              showDiscount={true}
            />
          </Col>
        </Row>

        {this.props.facility.terms_and_conditions && (
          <>
            <h3 id="terms_and_conditions_header">Terms and Conditions</h3>
            <p id="terms_and_conditions_content">
              {lineBreaksHtml(this.props.facility.terms_and_conditions)}
            </p>
          </>
        )}
        {/* <DevPre v={this.state.workOrder} /> */}
      </PrintLayout>
    );
  }
}

export const PrintEstimate = withFacility(PrintEstimateBase);
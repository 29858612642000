// Boiler code for react functional component
import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import QuickBooksExportButtonRefactor from "./QuickBooksExportButtonRefactor";
import KindsButtonList from "./KindsButtonList";
import StatusSelect from "./StatusSelect";
import { useDashboardStore } from "~/store";
import { shallow } from "zustand";
import ClearAdvancedSearch from "~/components/dashboard/ClearAdvancedSearch";

const HeaderSecondRow = ({ isAdmin, profile, ...props }) => {
  // const [selectedItemsGreaterThanZero, setSelectedItemsGreaterThanZero] =
  //   useState(false);
  const [load, setLoading] = useState(false);
  // Empty function for now mean to reload data after qb sync/download
  const reloadData = () => {};

  // const getDashboardState = () => {
  //   return {};
  // };

  const itemsHaveWorkorders = () => {
    return false;
  };

  const {
    selectedKinds,
    selectedItems,
    setSelectedKinds,
    setWorkOrderStatuses,
    qbSelectionActive,
    setQbSelectionActive,
    setSelectedItems,
    setAdvancedSearchActive,
    setQuery
  } = useDashboardStore(
    state => ({
      selectedKinds: state.selectedKinds,
      selectedItems: state.selectedItems,
      setSelectedKinds: state.setSelectedKinds,
      setWorkOrderStatuses: state.setWorkOrderStatuses,
      qbSelectionActive: state.qbSelectionActive,
      setQbSelectionActive: state.setQbSelectionActive,
      setSelectedItems: state.setSelectedItems,
      setAdvancedSearchActive: state.setAdvancedSearchActive,
      setQuery: state.setQuery
    }),
    shallow
  );

  const clearSelectedItems = () => {
    setSelectedItems([]);
  };

  const qbSelectToggle = item => {
    setQbSelectionActive(!qbSelectionActive);
    if (!qbSelectionActive) {
      setSelectedKinds(["readyforinvoice"]);
      setQuery("")
      setWorkOrderStatuses([]);
    } else {
      setSelectedKinds(["workorder"]);
      setAdvancedSearchActive(false);
      setWorkOrderStatuses([]);
    }
  };

  const selectedItemsGreaterThanZero = () => {
    return selectedItems.length > 0;
  };

  const selectedKindsIncludesWorkorders = () => {
    return (
      selectedKinds.includes("workorder") ||
      selectedKinds.includes("readyforinvoice")
    );
  };

  const showQuickbooksButton = () => {
    return isAdmin() && selectedKindsIncludesWorkorders();
  };

  return (
    <div className="bg-white nav-shadow py-2">
      <Container>
        <Row className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <KindsButtonList />
            <ClearAdvancedSearch />
          </div>

          <span className="d-flex al">
            {selectedKinds.includes("workorder") ? <StatusSelect /> : <></>}
            <div className={`${showQuickbooksButton() ? "d-flex" : "d-none"}`}>
              <QuickBooksExportButtonRefactor
                selectedItemsGreaterThanZero={selectedItemsGreaterThanZero}
                load={reloadData}
                workOrdersFilterSelected={true}
                clearSelectedItems={clearSelectedItems}
                selectedItems={selectedItems}
                qbSelectActive={qbSelectionActive}
                profile={profile}
                qbSelectToggle={qbSelectToggle}
                itemsHaveWorkorders={itemsHaveWorkorders}
                {...props}
              />
            </div>
          </span>
        </Row>
      </Container>
    </div>
  );
};

export default HeaderSecondRow;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import isExternal from "is-url-external";
import PropTypes from "prop-types";

const propTypes = {
  to: PropTypes.string.isRequired
};

/**
 * Link that also works for external URL's
 * COPIED FROM: https://github.com/ReactTraining/react-router/issues/1147#issuecomment-283684226
 */
export default class LinkDuo extends Component {
  render() {
    return isExternal(this.props.to) ? (
      <a href={this.props.to} {...this.props} />
    ) : (
      <Link {...this.props} />
    );
  }
}

LinkDuo.propTypes = propTypes;

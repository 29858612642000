import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Row, Button, Input } from "reactstrap";

import { workOrderProps } from "~/data/props";
import {
  nounLabels,
  containerStyles,
  ApproveButton
} from "~/components/approve";
import Fa from "~/util/fa";

const ApproveRequestChange = ({ workOrder, noun, handleSetView, token }) => {
  // const ApproveRequestChange = ({ workOrder, noun, handleSetView }) => {
  const [change, setChange] = useState("");

  const data = {
    status: "draft",
    change_reason: change,
    work_order_note_type: "customer_reply"
  };

  const approveProps = {
    id: workOrder.id,
    noun: nounLabels[noun],
    hideNoun: true,
    icon: "",
    token,
    onCompleteRequest: handleSetView("success", {
      title: "Change Order Requested",
      message:
        "We've received your request and will prepare a revised estimate for your review."
    }),
    data,
    method: "POST",
    url: `/work-orders/${workOrder.id}/change-order/`,
    btnStyle: "",
    disabled: change.length < 5,
    className: "btn-outline-primary mb-3 mb-md-0 mr-md-2 w-100 w-md-auto d-md-inline-block",
    label: "Submit Change Order Request"
  };

  return (
    <div data-testid="wo-change-wrapper" className="d-flex flex-column align-items-center">
      <h1 className="mb-4" style={{ fontSize: "2em", color: "#181818" }}>
        Request Change Order
      </h1>
      <div className="mb-3" style={containerStyles}>
        <label for="approve-request-change" className="mb-2" >What would you like to change?</label>
        <Input
          data-testid="input-request-change"
          id="approve-request-change"
          defaultValue={change}
          type="textarea"
          name="approve-decline-change"
          placeholder="Please give us a brief description of what you would like to change..."
          onChange={e => setChange(e.target.value)}
          style={{ minHeight: "140px" }}
        />
      </div>
      <div
        style={containerStyles}
        className="pt-1 pb-3 d-flex justify-content-center flex-wrap"
      >
        <Button
          data-testid="btn-back"
          className="btn-outline-secondary mb-3 mb-md-0 mr-md-2 w-100 w-md-auto d-md-inline-block"
          onClick={handleSetView("main")}
          color=""
        >
          <Fa icon="arrow-left" className="text-black mr-2" />
          Back
        </Button>
        <ApproveButton {...approveProps} />
      </div>
    </div>
  );
};

ApproveRequestChange.propTypes = {
  noun: PropTypes.oneOf(["estimate", "change"]),
  token: PropTypes.string,
  handleSetView: PropTypes.func,
  workOrder: workOrderProps
};

export default ApproveRequestChange;

import React from "react";
import { Badge } from "reactstrap";
import classNames from "classnames";

function FilterControlItem({
  qbSelectActive,
  label,
  icon,
  color,
  selected,
  onClick
}) {
  const pillProps = {
    className: classNames(
      "filter-item",
      "d-flex",
      "align-items-center",
      "px-1",
      "rounded",
      "mx-1",
      "px-2",
      "py-1",

      { selected: selected }
    ),
    onClick,
  };
  const imgProps = {
    src: icon,
    alt: label
  };
  return (
    <div>
      <div
        {...pillProps}
        style={{
          cursor: qbSelectActive ? "not-allowed" : "pointer"
        }}
      >
        {/* <Badge pill className={`bg-${'transparent'} mr-2`}>
          <i className={classNames([imgProps.src, "text-primary"])} title={label} />
        </Badge> */}
        {label}
      </div>
    </div>
  );
}

export default FilterControlItem;

export const nounLabels = {
  estimate: "Estimate",
  change: "Change Request"
};

export const containerStyles = {
  width: "100%",
  maxWidth: "650px",
  margin: "0 auto"
};

import React, { useRef, useState } from "react";
import PropTypes from "prop-types";

import Fa from "~/util/fa";

const FileUploadButton = props => {
  const { className, onUpload, icon, iconClasses } = props;
  const inputRef = useRef(null);

  const onClick = event => {
    event.preventDefault();
    inputRef.current.click();
  };

  const onFileChange = event => {
    const file = event.target.files[0];
    onUpload(file);
  };

  return (
    <>
      <input
        style={{
          visibility: "hidden",
          maxHeight: "0",
          fontSize: "0",
          display: "block"
        }}
        type="file"
        ref={inputRef}
        onChange={onFileChange}
      />
      <a href="#" className={className} onClick={onClick}>
        {icon ? (
          <Fa icon={icon} spacing={1} className={iconClasses || ""} />
        ) : null}
        {props.children}
      </a>
    </>
  );
};

FileUploadButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  icon: PropTypes.string,
  onUpload: PropTypes.func.isRequired,
  iconClasses: PropTypes.string
};

FileUploadButton.defaultProps = {
  icon: "file-upload"
};

export default FileUploadButton;

import React, { useContext } from "react";
import styled from "styled-components";
import { Scope, Text, useFieldState } from "informed";

import { statusList } from "~/data/statuses";
import { BootstrapDateInput } from "~/util/autoform/bootstrap";
import { StatusContext } from "~/components/workOrders/Form";
import Fa from "~/util/fa";

const statusIds = _.map(statusList, "id");
const nonEditableStatuses = _.difference(statusIds, ["draft", "approved"]);

const phases = ["Haul/Inspect", "Prepare", "Paint", "Detail", "Deliver"];

const StyledPickerContainer = styled.span`
  .react-datepicker-popper {
    /* Ensure the popup calendar appears above the calendar icon */
    z-index: 2;
  }

  .react-datepicker__input-container > input {
    padding-left: 2rem;
  }
`;

const StyledCalendarIcon = styled(Fa)`
  position: absolute;
  z-index: 1;
  left: 8px;
  top: 12px;
  pointer-events: none;
`;

const PickerWithIcon = props => (
  <StyledPickerContainer style={{ position: "relative" }}>
    <StyledCalendarIcon icon="calendar" style={{}} />
    <BootstrapDateInput {...props} />
  </StyledPickerContainer>
);

const PhaseRow = ({
  phase,
  workOrderStatus,
  showActualCompleted,
  id,
  index,
  estimatedOrActual
}) => {
  const status = useContext(StatusContext);
  const completedFS = useFieldState("date_completed");
  const isApproved = status === "approved";
  const noEdits = _.includes(nonEditableStatuses, status);
  const compFilled = completedFS.value && !completedFS.touched;
  const estProps = {
    showLabel: false,
    disabled: isApproved || noEdits || compFilled
  };
  const compProps = { showLabel: false, disabled: noEdits || compFilled };
  const key = _.snakeCase(phase);
  return (
    <tr id={`phase-row-${index}`}>
      <td className="px-2 py-0">
        <strong>{phase}</strong>
        <Text type="hidden" field="phase" initialValue={key} />
      </td>
      <td className="px-2 pt-2 pb-0">
        <PickerWithIcon
          disabled={workOrderStatus !== "draft"}
          // attempting to remove background for non-editable estimated fields on approved
          style={
            workOrderStatus !== "draft"
              ? {
                  backgroundColor: "#ffffff !important",
                  border: 0
                }
              : {}
          }
          {...estProps}
          id={id}
          estimatedOrActual="estimated"
          index={index}
          field="date_estimated"
        />
      </td>
      {showActualCompleted ? (
        <td className="px-2 pt-2 pb-0">
          <PickerWithIcon {...compProps} {...{ index, estimatedOrActual }} />
        </td>
      ) : null}
    </tr>
  );
};

const ProjectSchedule = ({ workOrderStatus }) => {
  const showActualCompleted = workOrderStatus === "approved";

  return (
    <Scope scope="phases">
      <table>
        <thead>
          <tr>
            <th>{/* Phase */}</th>
            <th className="px-2">Estimated Completion</th>
            {showActualCompleted && <th className="px-2">Actual Completion</th>}
          </tr>
        </thead>
        <tbody>
          {_.map(phases, (phase, i) => (
            <Scope scope={`[${i}]`} key={`phase-row-${i}`}>
              <PhaseRow
                workOrderStatus={workOrderStatus}
                phase={phase}
                id={`phase-${phase}`}
                estimatedOrActual="actual"
                index={i}
                showActualCompleted={showActualCompleted}
              />
            </Scope>
          ))}
        </tbody>
      </table>
    </Scope>
  );
};

export default ProjectSchedule;

import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { withAuth } from "~/util/api/withAuth";
import LoadingButton from "~/util/loadingButton";
import { binnacleClient } from "~/util/api";

export default
@withAuth
class DeleteProfileButton extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.doDelete = this.doDelete.bind(this);
    this.state = {
      modal: false,
      deleting: false
    };
  }

  toggle() {
    this.setState({ modal: !this.state.modal });
  }

  doDelete() {
    this.setState({ deleting: true });
    let component = this;

    binnacleClient
      .delete(`/profiles/${this.props.profileId}/`)
      .then(function(response) {
        component.setState({ deleting: false, modal: false });
        component.props.logout();
      });
  }

  renderModal() {
    return (
      <Modal isOpen={this.state.modal} toggle={this.toggle}>
        <ModalHeader toggle={this.toggle}>Delete your account</ModalHeader>
        <ModalBody>
          <p>
            Are you sure you want to delete your account? This will permanently
            delete your data, and you will no longer have access to your
            account.
          </p>
        </ModalBody>
        <ModalFooter>
          <LoadingButton
            className="btn-danger"
            onClick={this.doDelete}
            loading={this.state.deleting}
          >
            Yes, I'm sure. Delete
          </LoadingButton>{" "}
          <Button color="secondary" onClick={this.toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  render() {
    return (
      <>
        {this.renderModal()}
        <Button
          color="link"
          className="text-danger p-0"
          onClick={this.toggle}
          type="button"
        >
          Delete my account
        </Button>
      </>
    );
  }
}

import React, { useState, useRef } from "react";
import KindIcon from "~/components/dashboard/KindIcon";
import { Tooltip } from "reactstrap";

const IconWithTooltip = ({ icon = "copy", tooltipText = "Change Order", size="20" }) => {
  const toolTipRef = useRef(null);
  const [toolTipOpen, setToolTipOpen] = useState(false);
  const toggleToolTip = () => setToolTipOpen(!toolTipOpen);

  return(
    <>
    <div ref={toolTipRef}>
    <KindIcon
        kind={icon}
        iconBgColor={`bg-${icon}-icon`}
        iconColor={`text-${icon}-icon`}
        style={{ width: `${size}px`, height: `${size}px`, marginRight: '10px' }}
        fontSize={10}
        
      />
      </div>
      <Tooltip
        placement="bottom"
        isOpen={toolTipOpen}
        target={toolTipRef}
        toggle={toggleToolTip}
      >
      { tooltipText }
    </Tooltip> 
    </>)
}

export default IconWithTooltip;
import React, { Component } from "react";
import PropTypes from "prop-types";
import Fa from "./fa";
import classNames from "classnames";

export default class LoadingButton extends Component {
  render() {
    let { loading, children, disabled, className, icon, ...rest } = this.props;

    className = classNames(className, "btn");

    return (
      <button className={className} {...rest} disabled={loading || disabled}>
        {!loading && icon && <Fa icon={icon} className="mr-2" />}
        {loading && <Fa icon="spinner" spin className="mr-2" />}
        {children ? children : "Continue"}
      </button>
    );
  }
}

LoadingButton.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.string
};

import React, { useState, useEffect } from "react";
import classnames from "classnames";
import { Button, Col, Row } from "reactstrap";
import moment from "moment";

import { binnacleClient } from "~/util/api";
import phases from "~/data/phases";
import Fa from "~/util/fa";

const dueDiff = due => {
  const now = moment().startOf("day");
  const diff = due.startOf("day").diff(now, "days", true);
  const dueDate = moment(due);
  const currentDate = moment();
  const millisecondsDiff = dueDate.diff(currentDate);
  const daysDiff = millisecondsDiff / 86400000;

  if (diff < 0)
    return (
      <span className="text-danger">-{now.to(due).replace(" ago", "")}</span>
    );
  if (diff === 0) return <span className="text-warning">Due Today</span>;
  if (diff === 1) return <span className="text-warning">Due Tomorrow</span>;
  return <span className="text-warning">{`${Math.round(daysDiff)} days`}</span>;
};

export default function CardPhaseBox({
  currentPhase,
  id,
  phasesData,
  updateItem,
  workOrderId,
  owner,
  boat,
  link,
  userProfile
}) {
  const phase = _.get(phases, currentPhase.phase);
  if (!phase) return null;

  const usedPhases = _.filter(
    phasesData,
    phase => phase.date_estimated !== null
  );
  const index = _.findIndex(usedPhases, { phase: currentPhase.phase });
  const due = moment(_.get(usedPhases, [index, "date_estimated"]));

  const stepProps = i => ({
    style: { width: `${100 / (usedPhases.length + 1)}%` },
    className: classnames("step", {
      complete: i < index,
      current: i === index
    })
  });

  const onClick = e => {
    e.preventDefault();
    e.stopPropagation();

    // GTM-event
    window.dataLayer.push({
      event: "searchProgressPhase",
      eventProps: {
        category: "search_actions",
        action: "search_progress_phase",
        // label will be set in GTM as page hostname
        value: link,
        workOrderId,
        currentPhase: currentPhase?.phase,
        currentPhaseDateEst: currentPhase?.date_estimated,
        userId: userProfile?.id,
        userName: userProfile?.name,
        facilityId: userProfile?.facility_id,
        boatId: boat?.id,
        boatName: boat?.name,
        ownerId: owner?.id,
        ownerName: owner?.name
      }
    });

    console.log(window.dataLayer);

    binnacleClient
      .post(`/work-orders/${id}/complete_phase/`, { phase: phase.id })
      .then(({ data }) => updateItem(data));
  };

  const shouldUseDates = referenceDate => {
    const A_WEEK_BACK = moment().clone().subtract(7, "days");
    const A_WEEK_FORWARD = moment().clone().add(7, "days");

    if (
      referenceDate.isAfter(A_WEEK_FORWARD) ||
      referenceDate.isBefore(A_WEEK_BACK)
    )
      return true;

    return false;
  };

  return (
    <div className=" w-100">
      <div className="phase-progress-bar">
        {_.times(usedPhases.length + 1, i => (
          <div key={`phase-${i}`} {...stepProps(i)} />
        ))}
      </div>
      <div className="d-flex flex-wrap-nowrap w-100 p-2 justify-content-between align-items-center">
        <Col className="p-2">
          <Row className="phase-box__title">Phase</Row>
          <Row style={{ display: "flex", alignItems: "center" }}>
            <span className={`phase-dot phase-${phase.id}`}></span>
            {phase.label}
          </Row>
        </Col>
        <Col className="p-2">
          <Row className="phase-box__title">Due</Row>
          <Row>
            {/* I  */}
            {/* {due ? daysDiff : "N/A"} */}
            {shouldUseDates(moment(due))
              ? dueDiff(due)
              : due.format("MM/DD/YY")}
          </Row>
        </Col>
        <Col className="p-2">
          <Row>
            <Button
              className="item-card__action-button px-1 py-1"
              color="primary"
              onClick={onClick}
            >
              Advance Phase
            </Button>
          </Row>
        </Col>
      </div>
    </div>
  );
}

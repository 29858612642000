function isNone(value) {
  return _.isNull(value) || _.isUndefined(value) || _.isNaN(value);
}

export function currencyFloat(value) {
  return parseFloat(parseFloat(value).toFixed(2));
}

// rounds {value} to the nearest {places}, omitting any zeros on the end
export function smartRound(value, places = 2) {
  value = parseFloat(value);
  if (_.isNaN(value)) {
    return "0";
  }
  let parts = value.toFixed(places).split(".");
  parts[1] = _.trimEnd(parts[1], "0");

  if (parts[1].length === 0) {
    return parts[0];
  } else {
    return parts.join(".");
  }
}

export function currency(value, includeSign = true) {
  if (isNone(value)) {
    return "0.00";
  }

  value = parseFloat(value).toFixed(2);

  let parts = value.split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  value = parts.join(".");

  return "$" + value;
}

import React from "react";
import { fakeFacility } from "./fakeData";

export const getPreviewTypeProps = previewType => {
  let props;
  switch (previewType) {
    case "quote_approval":
      props = {
        modalTitle: "Estimate Note",
        submitButtonLabel: "Send Estimate",
        buttonLabel: "Preview and Send Estimate",
        message: {
          placeholder:
            "Enter a note to send along with your Estimate if desired ",
          title: "Estimate"
        }
      };
      break;
    case "change_order":
      props = {
        modalTitle: "Updated Estimate Preview",
        submitButtonLabel: "Send Updated Estimate",
        buttonLabel: "Preview and Send Estimate",
        message: {
          placeholder:
            "Enter a note to send along with your Change Order if desired",
          title: "Change Order"
        }
      };
      break;
    case "work_update":
      props = {
        modalTitle: "Work Update Preview",
        submitButtonLabel: "Send Update",
        buttonLabel: "Add Note and Update Customer",
        message: {
          placeholder:
            "Harry, we've finished the Haul/Inspect phase and haven't noticed any additional damage or issues with the boat...",
          title: "Work Update"
        }
      };
      break;
    case "comment":
    default:
      props = {
        modalTitle: "Preview Message",
        submitButtonLabel: "Send Message",
        buttonLabel: "Send a Message",
        message: {
          placeholder:
            "Hi Bill, just wanted to reach out and see if you had any questions about the estimate we sent you...",
          title: "Message"
        }
      };
  }
  return props;
};

export const prettyAddress = ({ street, city, state, zip, phone }) => {
  if (!street && !city) return "";
  return `${street}, ${city} ${state} ${zip}${phone ? `, ${phone}` : ""}`;
  // const { address } = fakeFacility;
  // return `${address.street}, ${address.city} ${address.state} ${address.zip}`;
};

export const withTypeProps = WrappedComponent => props => {
  const typeProps = getPreviewTypeProps(props.type);
  return <WrappedComponent {...typeProps} {...props} />;
};

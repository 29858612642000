import React from "react";
import styled from "styled-components";
import { Badge, Button } from "reactstrap";
import { binnacleClient } from "~/util/api";

import withFacility from "~/util/api/withFacility";
import LinkDuo from "~/util/linkDuo";
import StatusPill from "~/components/StatusPill";
import KindIcon from "~/components/dashboard/KindIcon";
import CardPhaseBox from "~/components/dashboard/CardPhaseBox";
import Fa from "~/util/fa";
import ExternalLink from "~/util/ExternalLink";
import EmailPreview from "~/components/emailPreview/EmailPreview";
import ApproveButton from "~/components/approve/ApproveButton";
import QuickBooksStatusIcon from "~/components/dashboard/QuickBooksStatusIcon";

// import getDifferenceInDays from '~/util/dateCalcFunctions.js';

const StyledEmailButton = styled.button`
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin-left: 1rem;

  i {
    color: rgba(0, 0, 0, 0.4);
    position: relative;

    ${p =>
      p.hasBadge
        ? `
          &::after {
            content: "";
            height: 10px;
            width: 10px;
            background-color: #c9241a;
            position: absolute;
            display: block;
            right: -5px;
            top: -3px;
            border-radius: 50%;
            border: 1px solid #fff;
          }
        `
        : ``}
  }
`;

export const ViewButton = ({ link }) => (
  <LinkDuo to={link}>
    <Button className={`item-card__action-button`} color="primary" size="sm">
      View
    </Button>
  </LinkDuo>
);

export const CompleteButton = ({
  updateItem,
  id,
  className,
  workOrder,
  userProfile,
  link
}) => {
  const onClick = e => {
    e.preventDefault();
    e.stopPropagation();

    // GTM-event
    window.dataLayer.push({
      event: "searchDeliverJob",
      eventProps: {
        category: "search_actions",
        action: "deliver_job",
        // label will be set in GTM as page hostname
        workOrderId: id,
        userId: userProfile?.id,
        userName: userProfile?.name,
        facilityId: userProfile?.facility_id,
        boatId: workOrder?.boat?.id,
        boatName: workOrder?.boat?.name,
        ownerId: workOrder?.owner_profile?.id,
        ownerName: workOrder?.owner_profile?.name
      }
    });

    console.log(window.dataLayer);

    binnacleClient
      .patch(`/work-orders/${id}/`, { status: "complete" })
      .then(({ data }) => updateItem(data));
  };
  return (
    <Button className={className} color="success" size="md" onClick={onClick}>
      <Fa icon="check" /> Deliver Job
    </Button>
  );
};

export const CheckButton = ({ updateItem, id, className, link }) => {
  const onCheckChange = e => {
    e.preventDefault();
    e.stopPropagation();
    // binnacleClient
    //   .patch(`/work-orders/${id}/`, { status: "complete" })
    //   .then(({ data }) => updateItem(data));
  };
  return (
    <Button className={className} color="success" size="md" onClick={onClick}>
      <Fa icon="check" /> Deliver Job
    </Button>
  );
};

export const DismissButton = ({ updateItem, id }) => {
  const onClick = e => {
    e.preventDefault();
    e.stopPropagation();
    binnacleClient
      .patch(`/work-orders/${id}/`, { status: "dismissed" })
      .then(({ data }) => updateItem(data));
  };
  return (
    <Button
      className={`item-card__action-button btn btn-outline-primary`}
      color="primary"
      outline
      size="sm"
      onClick={onClick}
    >
      <Fa icon="check" /> Dismiss
    </Button>
  );
};

const phaseDisplay = (
  id,
  status,
  currentPhase,
  phasesData,
  updateItem,
  link,
  workOrder,
  facility,
  userProfile
) => {
  // if (status === 'complete') return <Badge pill color="success" className="mt-2"><Fa icon="check" /> Work Complete</Badge>;
  if (
    status === "approved" &&
    _.every(phasesData, phase => !phase.date_estimated || phase.date_completed)
  ) {
    return (
      <CompleteButton
        {...{ updateItem, userProfile, workOrder, id, link }}
      />
    );
  } else if (status === "approved" && currentPhase) {
    return (
      <CardPhaseBox
        {...{
          currentPhase,
          id,
          phasesData,
          updateItem,
          workOrderId: workOrder.id,
          boat: workOrder.boat,
          owner: workOrder.owner_profile,
          userProfile,
          link
        }}
        key={`phase-box-${id}`}
      />
    );
  } else if (status === "draft") {
    const handleSubmitQuote = (e, workOrder, userProfile, data) => {
      const { id } = workOrder;

      const eventName =
        workOrder?.parent && !!workOrder?.parent
          ? "searchSubmitChangeOrder"
          : "searchSubmitEstimate";
      const actionName =
        workOrder?.parent && !!workOrder?.parent
          ? "submit_change_order"
          : "submit_estimate";

      // GTM-event
      window.dataLayer.push({
        event: eventName,
        eventProps: {
          category: "search_actions",
          action: actionName,
          // label will be set in GTM as page hostname
          workOrderId: id,
          userId: userProfile?.id,
          userName: userProfile?.name,
          facilityId: userProfile?.facility_id,
          boatId: workOrder?.boat?.id,
          boatName: workOrder?.boat?.name,
          ownerId: workOrder?.owner_profile?.id,
          ownerName: workOrder?.owner_profile?.name
        }
      });

      console.log(window.dataLayer);

      e.preventDefault();
      e.stopPropagation();
      binnacleClient
        .patch(`/work-orders/${id}/`, { status: "pending", email: { ...data } })
        .then(({ data }) => updateItem(data));
    };

    return (
      <>
        <EmailPreview
          type={"quote_approval"}
          workOrder={workOrder}
          boatPrimaryImage={workOrder?.boat?.images?.find(image => image?.is_primary)}
          currentPhaseSchedule={workOrder.phases}
          // dont pass sendEmail prop from here because that executes email send in EmailPreview for work updates
          renderSubmitButton={({ disabled, id, getRef, data, closeModal }) => {
            return (
              <Button
                disabled={disabled}
                id={`item-submit-draft-${id}`}
                className="ml-md-1 w-100 d-block d-md-auto w-md-auto"
                color="primary"
                ref={ref => getRef(ref)}
                onClick={e =>
                  handleSubmitQuote(e, workOrder, userProfile, data)
                }
              >
                <Fa icon="paper-plane" className="mr-2" />
                Preview and Send Estimate
              </Button>
            );
          }}
          renderModalButton={({ onShowModal }) => (
            <Button
              className="item-card__action-button primary w-100 d-block d-md-auto w-md-auto"
              color="primary"
              size="sm"
              onClick={onShowModal}
            >
              <Fa className="mr-2" icon="paper-plane" />
              Preview and Send Estimate
            </Button>
          )}
        />
      </>
    );
  } else if (status === "rejected") {
    return (
      <>
        <LinkDuo to={link}>
          <button
            className={`item-card__action-button btn btn-primary mr-2`}
            // color="primary"
            size="sm"
          >
            Review
          </button>
        </LinkDuo>
        <LinkDuo to={link}>
          <DismissButton updateItem={updateItem} id={id} />
        </LinkDuo>
      </>
    );
  } else if (status === "pending") {
    return (
      <ApproveButton
        id={workOrder.id}
        noun="Estimate"
        isPublic={false}
        icon="check"
        onCompleteRequest={data => {
          if (data) {
            updateItem(data);
          } else {
            window.location = `/`;
          }
        }}
        url={`/work-orders/${workOrder.id}/`}
        data={{
          phases: phasesData,
          facility_id: facility.id,
          status: "approved"
        }}
        method="PATCH"
        label="Mark Approved"
        workOrder={workOrder}
        userProfile={userProfile}
        eventName="searchWorkOrderApprove"
      />
    );
  } else {
    return <ViewButton link={link} />;
  }
};

const cardVariants = {
  workorder: ({
    facility,
    extra: workOrder,
    link,
    updateItem,
    hasUnreadNote,
    userProfile
  }) => {
    const {
      id,
      status,
      current_phase,
      status_date,
      phases,
      boat: { name, make, model, year },
      owner_profile,
      representative_profile
    } = workOrder;
    const woNotes = _.get(workOrder, "work_order_notes") || [];
    // const unreadNotes = woNotes.filter(note => !note.read); // changing this to single flag on wo object

    const unreadEmailLink = {
      // https://stackoverflow.com/questions/30115324/pass-props-in-link-react-router
      // remove trailing slash and add card id for anchor when clicking email icon
      pathname: `${link.slice(0, -1)}#project-activity-card`,
      projectActivityOpen: true
    };

    return (
      <div className="workorder-card-content-wrapper w-100 d-flex flex-column justify-content-between flex-lg-row">
        {/* Main content */}

        <div
          data-testid="work-order-card-main-content"
          className="d-flex flex-even flex-column justify-content-center pb-3 pb-lg-0"
        >
          <div
            data-testid="workorder-boat-details"
            className="d-flex flex-nowrap"
          >
            <h5 data-testid="workorder-boat-name" className="title truncate">
              {name}
            </h5>
            <p
              data-testid="workorder-boat-make-model"
              className="gray-600 font-size-md text-uppercase font-italic truncate mb-0"
            >{`${_.startCase(_.lowerCase(make))}, ${_.startCase(
              _.lowerCase(model)
            )}, ${year}`}</p>
          </div>
          <div className="d-flex">
            <StatusPill
              status={status}
              statusDate={status === "approved" ? status_date : null}
            />
            {workOrder.qb_invoiced ? <QuickBooksStatusIcon /> : ""}
            {/* 
            TODO: new email notification icon, should jump to work order page anchor for Project Activity
            should reference has_unread_note on work order when api is done
             */}
            {status === "approved" && hasUnreadNote ? (
              <LinkDuo
                className=" d-flex justify-content-center align-items-center"
                to={unreadEmailLink}
              >
                <StyledEmailButton hasBadge={true}>
                  <Fa icon="envelope" />
                </StyledEmailButton>
              </LinkDuo>
            ) : null}
          </div>
        </div>
        {/* Secondary Content */}
        <div
          data-testid="work-order-card-secondary-content"
          className="d-flex flex-even justify-content-lg-end align-items-center"
        >
          {phaseDisplay(
            id,
            status,
            current_phase,
            phases,
            updateItem,
            link,
            workOrder,
            facility,
            userProfile
          )}
        </div>
      </div>
    );
  },
  boat: ({ extra: { name, make, model, year }, link, updateItem }) => (
    <div className="justify-content-between d-flex w-100">
      <div className="d-flex align-items-center">
        <h5 data-testid="rol-card-boat-name" className="title truncate">
          {name}
        </h5>
        <p
          data-testid="rol-card-boat-details"
          className="gray-600 font-size-md text-uppercase font-italic mb-0"
        >{`${make}, ${model}, ${year}`}</p>
      </div>
      <ViewButton link={link} />
    </div>
  ),
  profile: ({ extra: { name, email, phone } }) => (
    <div className="justify-content-between d-flex w-100">
      <h5 className="m-0">{name}</h5>
      <div className="card-secondary-content">
        <ExternalLink
          onClick={e => e.stopPropagation()}
          type="email"
          link={email}
          className="m-0"
        />
        <ExternalLink
          onClick={e => e.stopPropagation()}
          type="phone"
          link={phone}
          className="ml-0 ml-md-3"
        />
      </div>
    </div>
  ),
  facility: ({
    extra: {
      name,
      address: { street, city, state, zip }
    },
    link = ""
  }) => (
    <div className="justify-content-between d-flex align-items-center w-100">
      <div
        data-testid="rol-card-facility-main"
        className="d-flex w-100 justify-content-between flex-column flex-md-row"
      >
        <h5 className="title">{name}</h5>
        <p
          className="mb-0 mr-3"
          data-testid="rol-card-facility-address"
        >{`${street}, ${city} ${state} ${zip}`}</p>
      </div>
      <div className="card-secondary-content d-flex align-items-center">
        <ViewButton link={link} />
      </div>
    </div>
  ),
  default: ({ body = "" }) => <>{body}</>
};

const ItemCard = props => {
  const {
    kind,
    extra,
    link,
    qbSelectActive,
    updateItem,
    has_unread_note: hasUnreadNote,
    userProfile,
    facility,
    addSelectedItem,
    removeSelectedItem,
    selectedItems
  } = props;

  const CardBody = _.get(cardVariants, kind, cardVariants.default);
  // Add ? to make sure search doesn't break
  const itemId = link?.split("/")[2] || null;
  // console.log("itemId", itemId, props);
  if (!kind || !link) return null;

  const onCheckChange = e => {
    if (e.target.checked) {
      addSelectedItem(itemId);
    } else {
      removeSelectedItem(itemId);
    }
  };

  const invoiceIsReady = () => {
    const { status } = extra;
    const billableStatuses = ["approved", "complete", "replaced"];
    return billableStatuses.includes(status);
  };

  const boatImage = extra => {
    console.log("extra", extra);
    // filter primary image
    const primaryImage = extra.images.find(image => image.is_primary === true);
    console.log("primaryImage", primaryImage);
    return (
      <div
        className="d-flex bg-secondary rounded-circle justify-content-center align-items-center p-1 card-image-outer"
        style={{ height: "44px", width: "44px" }}
      >
        <img
          src={primaryImage.thumbnail}
          alt={extra.name}
          className="rounded-circle img-fluid card-image-inner"
          style={{ height: "44px", width: "44px" }}
        />
      </div>
    );
  };

  return (
    <div className="d-flex">
      {qbSelectActive && kind === "workorder" && invoiceIsReady() && (
        <div className="d-flex align-items-center mr-3">
          <div className="custom-control custom-checkbox">
            <input
              onChange={onCheckChange}
              type="checkbox"
              className="custom-control-input"
              checked={props.selectedItems.includes(itemId)}
              id={`item${itemId}`}
            />
            <label
              className="custom-control-label"
              for={`item${itemId}`}
            ></label>
          </div>
        </div>
      )}
      <LinkDuo
        onClick={() => {
          // GTM-event
          window.dataLayer.push({
            event: "searchCardClick",
            eventProps: {
              category: "search_actions",
              action: "search_card_click",
              // label will be set in GTM as page hostname
              value: link,
              cardType: kind,
              userId: userProfile?.id,
              userName: userProfile?.name,
              facilityId: userProfile?.facility_id
            }
          });
        }}
        to={link || "/400"}
        data-testid={`rol-card-${kind}`}
        className="item-card py-1 px-0 media w-100 align-items-center"
      >
        {/* checkbox */}

        {kind === "boat" && extra.images.length > 0 ? (
          <div className="media-left  text-center p-3 align-self-stretch align-items-center">
            {boatImage(extra)}
          </div>
        ) : (
          <div className="media-left text-center p-3 align-self-stretch align-items-center">
            <KindIcon kind={kind} />{" "}
          </div>
        )}
        <div className="media-body justify-content-start py-2 pl-2 pr-3">
          <CardBody
            extra={extra}
            link={link}
            updateItem={updateItem}
            hasUnreadNote={hasUnreadNote}
            facility={facility}
            userProfile={userProfile}
          />
        </div>
      </LinkDuo>
    </div>
  );
};

export default withFacility(ItemCard);

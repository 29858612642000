import PropTypes from "prop-types";
import { workOrderProps, facilityProps, profileProps } from "~/data/props";

const previewProps = {
  // UI/UX props
  type: PropTypes.oneOf([
    "quote_approval", // For approving an estimate
    "change_order", // For requesting a change order
    "work_update", // For (re-)sending a quote with a work update
    "work_order_approval" // For approving a quote with a message (unused?)
  ]),
  renderModalButton: PropTypes.func,
  renderSubmitButton: PropTypes.func,
  onSubmit: PropTypes.func,
  submitButtonLabel: PropTypes.string,
  modalTitle: PropTypes.string,
  onClose: PropTypes.func,
  onReviewEstimate: PropTypes.func,
  onUpdateMessage: PropTypes.func,
  message: PropTypes.shape({
    placeholder: PropTypes.string,
    title: PropTypes.string,
    defaultMessage: PropTypes.string
  }),
  alwaysShowSchedule: PropTypes.bool,
  modalButtonDisabled: PropTypes.bool,
  workItems: PropTypes.array,
  margin: PropTypes.number,
  totalPrice: PropTypes.number,

  // Data props
  facility: facilityProps,
  profile: profileProps,
  workOrder: workOrderProps
};

export default previewProps;

import axios from "axios/index";
import React, {Component} from "react";


class WithAPIDataBase extends Component {
     static defaultProps = {
         dummyData: null,
         axiosInstance: axios,
     };

    constructor(props) {
        super(props);
        this.state = {
            data: null,
            loading: true
        };
        this.timout = null;
        this.source = null;
        this.dummyData = this.props.dummyData;
        this.checkDummyData = this.checkDummyData.bind(this);
        this.getData = this.getData.bind(this);
    }

    checkDummyData() {
        let component = this;

        if(this.dummyData != null) {
            // fake a loading time
            this.timout = setTimeout(function() {
                component.setState({
                    loading: false,
                    data: component.dummyData
                });
            }, 750);
        } else {
            component.getData();
        }
    }

    getData() {
        let component = this;

        const CancelToken = axios.CancelToken;
        this.source = CancelToken.source();

        let compiled = _.template(this.props.url);
        let url = compiled(this.props.componentProps || {});

        let requestConfig = {
            cancelToken: this.source.token,
            url: url
        };

        this.props.axiosInstance(requestConfig).then((response) => {
            component.setState({
                loading: false,
                data: response.data
            });
        });
    }

    componentDidMount() {
        this.checkDummyData();
    }

    componentWillUnmount() {
        if(this.source != null) {
            this.source.cancel();
        } else if(this.timeout != null) {
            clearTimeout(this.timeout);
        }
    }

    render() {
        return this.props.children({data: this.state.data, loading: this.state.loading});
    }
}

export const WithAPIData = WithAPIDataBase;


export function withAPIData(WrappedComponent, url, dummyData=null, axiosInstance=axios) {
    return class extends Component {
        render() {
            return <WithAPIData url={url} dummyData={dummyData} componentProps={this.props} axiosInstance={axiosInstance}>{({data, loading}) =>
                <WrappedComponent data={data} loading={loading} {...this.props} />
            }</WithAPIData>
        }
    };
}
